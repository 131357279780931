import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
var OverviewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OverviewComponent, _super);
    function OverviewComponent(api, localizedRouter) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.localizedRouter = localizedRouter;
        _this.candidacies = null;
        _this.filteredCandidacies = null;
        return _this;
    }
    OverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions = [
            this.api.getCandidacies()
                .subscribe(function (candidacies) {
                _this.candidacies = candidacies;
                _this.filteredCandidacies = _this.candidacies.filter(function (x) { return !x.isArchivedByUser; });
            })
        ];
    };
    OverviewComponent.prototype.onCandidacySelected = function (id) {
        this.localizedRouter.navigate(['/app/kandidaturen', id]);
    };
    OverviewComponent.prototype.candidacyFilterShowArchived = function (event) {
        if (event) {
            this.filteredCandidacies = this.candidacies;
        }
        else {
            this.filteredCandidacies = this.candidacies.filter(function (x) { return !x.isArchivedByUser; });
        }
    };
    return OverviewComponent;
}(AutoUnsubscribeComponent));
export { OverviewComponent };
