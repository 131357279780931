import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'ra-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() visible;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoggedIn: boolean;

  constructor(private authService: AuthService,
              private elementRef: ElementRef,
              private translate: TranslateService) { }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  toggleMenu() {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }

  logout($event: Event) {
    $event.preventDefault();
    this.authService.logout();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this.visible) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.visible = false;
        this.visibleChange.emit(this.visible);
      }
    }
  }

  termsOfUse() {
    window.open('https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf');
    window.open('https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf');
    const language = this.translate.currentLang;
    if (language === 'nl') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'de') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'fr') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'en') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    return '';
  }
}
