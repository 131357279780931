<ra-modal-header (cancel)="cancel.next()">
  <ng-template>
    <div class="broker">
      <img [src]="candidacy.broker.logoUrl" />
      <h1>{{ candidacy.broker.name }}</h1>
    </div>
  </ng-template>
</ra-modal-header>

<a [href]="'tel:' + phone">
  <div class="icon-container">
    <fa-icon [icon]="faPhone"></fa-icon>
  </div>
  <div>{{ phone }}</div>
</a>
<a [href]="'mailto:' + email">
  <div class="icon-container">
    <fa-icon [icon]="faEnvelopeOpen"></fa-icon>
  </div>
  <div>{{ email }}</div>
</a>
