import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingStatusService } from './shared/loading-status.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';

@Component({
  selector: 'ra-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loading = false;
  showErrors = true;

  private subscriptions: Subscription[];

  constructor(private translate: TranslateService,
              public ls: LoadingStatusService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {

    registerLocaleData(localeNl);
    registerLocaleData(localeFr);
    registerLocaleData(localeEn);
    registerLocaleData(localeDe);

    const langs = ['nl', 'fr', 'en', 'de'];

    this.translate.addLangs(langs);
    this.translate.setDefaultLang('nl');

    this.ls.loading.subscribe(l => {
      // strangely, without setTimeout we have 'expression changed after it was checked' error
      setTimeout(() => {
        this.loading = l;
      }, 0);
    });

    this.subscriptions = [
      this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((p) => this.route.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
          this.showErrors = route.data && route.data.hideErrors !== true;
      })
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
