import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var Session = /** @class */ (function () {
    function Session() {
    }
    return Session;
}());
export { Session };
var SessionService = /** @class */ (function () {
    function SessionService() {
        this.sessionKey = 'ra-session';
        this.storageTypeKey = 'ra-storage-type';
        this.session$ = new BehaviorSubject(undefined);
        try {
            var storageType = localStorage.getItem(this.storageTypeKey);
            this.storage = storageType === 'permanent' ? localStorage : sessionStorage;
        }
        catch (e) {
            console.error(e);
        }
        this.loadSession();
    }
    SessionService.prototype.setToken = function (token) {
        this.token = token;
        this.session$.next({ token: token, user: this.user });
        this.saveSession();
    };
    SessionService.prototype.setUser = function (user) {
        this.user = user;
        this.session$.next({ token: this.token, user: user });
        this.saveSession();
    };
    SessionService.prototype.clearSession = function () {
        this.user = null;
        this.token = null;
        try {
            sessionStorage.removeItem(this.sessionKey);
            localStorage.removeItem(this.sessionKey);
        }
        catch (e) {
            console.error(e);
        }
        this.session$.next(null);
    };
    SessionService.prototype.setStorageType = function (permanent) {
        var storageType = permanent ? 'permanent' : 'session';
        try {
            localStorage.setItem(this.storageTypeKey, storageType);
            this.storage = storageType === 'permanent' ? localStorage : sessionStorage;
        }
        catch (e) {
            console.error(e);
        }
    };
    SessionService.prototype.isAuthenticated = function () {
        return !!this.user;
    };
    SessionService.prototype.saveSession = function () {
        try {
            this.storage.setItem(this.sessionKey, JSON.stringify({ token: this.token, user: this.user }));
        }
        catch (e) {
            console.error(e);
        }
    };
    SessionService.prototype.loadSession = function () {
        try {
            var storedSession = this.storage.getItem(this.sessionKey);
            if (storedSession) {
                var session = JSON.parse(storedSession);
                this.token = session.token;
                this.user = session.user;
                this.session$.next(session);
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
