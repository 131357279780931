/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../shared/components/candidacy-card/candidacy-card.component.ngfactory";
import * as i4 from "../../shared/components/candidacy-card/candidacy-card.component";
import * as i5 from "../../shared/components/house-header/house-header.component.ngfactory";
import * as i6 from "../../shared/components/house-header/house-header.component";
import * as i7 from "../../core/services/localized-router.service";
import * as i8 from "@angular/common";
import * as i9 from "./overview.component";
import * as i10 from "../../shared/api/rentassistant-api";
var styles_OverviewComponent = [i0.styles];
var RenderType_OverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverviewComponent, data: {} });
export { RenderType_OverviewComponent as RenderType_OverviewComponent };
function View_OverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Candidacies.NoCandidaciesFound")); _ck(_v, 1, 0, currVal_0); }); }
function View_OverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "candidacy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ra-candidacy-card", [], null, [[null, "cardClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cardClicked" === en)) {
        var pd_0 = (_co.onCandidacySelected(_v.context.$implicit.uniqueId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CandidacyCardComponent_0, i3.RenderType_CandidacyCardComponent)), i1.ɵdid(2, 114688, null, 0, i4.CandidacyCardComponent, [], { candidacy: [0, "candidacy"] }, { cardClicked: "cardClicked" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-house-header", [], null, [[null, "candidacyFilterShowArchived"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("candidacyFilterShowArchived" === en)) {
        var pd_0 = (_co.candidacyFilterShowArchived($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_HouseHeaderComponent_0, i5.RenderType_HouseHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.HouseHeaderComponent, [i7.LocalizedRouter], { titleKey: [0, "titleKey"], candidacyFilter: [1, "candidacyFilter"] }, { candidacyFilterShowArchived: "candidacyFilterShowArchived" }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "candidacy-overview"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewComponent_2)), i1.ɵdid(6, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Candidacies.Title"; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.candidacies != null) && (_co.filteredCandidacies.length === 0)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.filteredCandidacies; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_OverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-overview", [], null, null, null, View_OverviewComponent_0, RenderType_OverviewComponent)), i1.ɵdid(1, 245760, null, 0, i9.OverviewComponent, [i10.RentAssistantApi, i7.LocalizedRouter], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverviewComponentNgFactory = i1.ɵccf("ra-overview", i9.OverviewComponent, View_OverviewComponent_Host_0, {}, {}, []);
export { OverviewComponentNgFactory as OverviewComponentNgFactory };
