<div class="attachment">
  <div class="image">
    <svg-icon src="assets/images/attachment.svg" [applyCss]="true"></svg-icon>
  </div>
  <div class="text">
    <div>{{ text }}</div>
    <div class="subtext">{{ subText || attachment?.documentName }}</div>
  </div>
  <div class="actions">
    <button
      *ngIf="attachment"
      (click)="onDownload()"
      class="default small-icon-button">
      <svg-icon src="assets/images/download.svg" [applyCss]="true"></svg-icon>
    </button>
    <button
      *ngIf="attachment && !readonly"
      (click)="showDeleteConfirmation()"
      class="default small-icon-button">
      <svg-icon src="assets/images/minus.svg" [applyCss]="true"></svg-icon>
    </button>
    <button
    *ngIf="!attachment && !readonly"
    (click)="hiddenFileInput.click()"
    class="default left-icon-button">
      <svg-icon [applyCss]="true" src="assets/images/upload.svg"></svg-icon>
      <span>
        {{ 'AttachmentsWizard.Upload' | translate }}
      </span>
    </button>
  </div>
</div>

<input
        style="display: none"
        type="file"
        (change)="onUpload($event.target.files)"
        #hiddenFileInput  />

<ng-template #deleteModal let-modal>
  <ra-delete-attachment-confirmation
    [attachment]="attachment"
    [text]="text"
    (delete)="onDelete(modal)"
    (cancel)="modal.dismiss()"
  ></ra-delete-attachment-confirmation>
</ng-template>
