import { Component, Input } from '@angular/core';

@Component({
  selector: 'ra-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent {
  @Input()
  titleKey = '';
  @Input()
  tooltip: string;
}
