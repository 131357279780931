<ra-renter-wizard-header
  [person]="person">
</ra-renter-wizard-header>
<div class="wizard-page container">
  <form class="form-grid">
    <div class="title">{{ 'RenterWizard.Address' | translate }}</div>
    <div class="line">
      <input
        name="street"
        [(ngModel)]="person.address.street"
        [placeholder]="'RenterWizard.Street' | translate"
        [readOnly]="person.organizationFoundInKbo || readonly"
        required
        class="flex-75"
      />
      <div class="flex-gap"></div>
      <input
        name="houseNumber"
        [(ngModel)]="person.address.houseNumber"
        [placeholder]="'RenterWizard.HouseNumber' | translate"
        [readOnly]="person.organizationFoundInKbo || readonly"
        required
        class="flex-25"
      />
    </div>
    <div class="line">
      <input
        name="zipCode"
        [(ngModel)]="person.address.zipCode"
        [placeholder]="'RenterWizard.ZipCode' | translate"
        [readOnly]="person.organizationFoundInKbo || readonly"
        required
        class="flex-25"
      />
      <div class="flex-gap"></div>
      <input
        name="place"
        [(ngModel)]="person.address.place"
        [placeholder]="'RenterWizard.Place' | translate"
        [readOnly]="person.organizationFoundInKbo || readonly"
        required
        class="flex-75"
      />
    </div>
    <div class="line">
      <input
        name="country"
        [(ngModel)]="person.address.country"
        [readOnly]="person.organizationFoundInKbo || readonly"
        required
        [placeholder]="'RenterWizard.Country' | translate"
      />
    </div>
  </form>
</div>
<ra-wizard-footer
  [step]="2" [totalSteps]="3"
  [autoSave]="!profile.isReadonly"
  (next)="next()"
  (previous)="previous()"
></ra-wizard-footer>
