import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingStatusService {
  loading = new BehaviorSubject<boolean>(false);
  private loadingCount = 0;
  pushLoadingFrame() {
    this.loadingCount++;
    if (this.loadingCount === 1) {
      this.loading.next(true);
    }
    return () => {
      this.loadingCount--;
      if (this.loadingCount === 0) {
        this.loading.next(false);
      }
    };
  }
}
