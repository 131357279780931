import { OwnerService } from './../owner.service';
import { RentAssistantApi } from 'src/app/shared/api/rentassistant-api';
import { OwnerProfileDetailsDto, OwnerLogin, OwnerSavePreferenceDto } from './../../../shared/api/rentassistant-api.generated';
import { Component, OnInit, Input } from '@angular/core';
import { DragulaService, DragulaOptions } from 'ng2-dragula';


@Component({
  selector: 'ra-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
 export class TableComponent implements OnInit {

  @Input() profileDetails: OwnerProfileDetailsDto[];
  @Input() login: OwnerLogin;
  detailProfile: OwnerProfileDetailsDto;
  display = false;
  rankedProfiles: OwnerProfileDetailsDto[];
  unrankedProfiles: OwnerProfileDetailsDto[];
  voorkeur: OwnerProfileDetailsDto[];
  overige: OwnerProfileDetailsDto[];

  constructor(private dragulaService: DragulaService,
              private api: RentAssistantApi,
              private ownerService: OwnerService) {
  }

  ngOnInit() {
        this.filterProfiles(this.profileDetails);
        const dragulaOptions: DragulaOptions = {};
        dragulaOptions.moves = (el?: Element, container?: Element, handle?: Element, sibling?: Element) => {
          return el.id !== '';
        };
        this.dragulaService.createGroup('Voorkeurslijst', dragulaOptions);
        this.dragulaService.drop('Voorkeurslijst').subscribe(args => {
          this.clearPreferences();
          const voorkeurtable = document.getElementById('Voorkeurslijst') as HTMLTableElement;

          const rowsWithId: HTMLTableRowElement[] = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < voorkeurtable.rows.length; i++) {
            const id = +voorkeurtable.rows[i].id;
            if (id != null && id !== 0) {
              rowsWithId.push(voorkeurtable.rows[i]);
            }
          }
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < rowsWithId.length; i++) {
            const id = +rowsWithId[i].id;
            const p = this.profileDetails.filter(x => x.candidacyId === id)[0];
            if (p != null) {
              p.preferenceOrder = i + 1;
            }
          }
          const savePref: OwnerSavePreferenceDto = {};
          savePref.ownerLogin = this.login;
          savePref.ownerProfilePreference = this.profileDetails;
          this.api.saveOwnerPreferences(savePref)
          .toPromise()
          .then((newList) => {
            this.profileDetails = newList;
            this.filterProfiles(this.profileDetails);
          });
        });
  }
  clearPreferences() {
    this.profileDetails.forEach((el) => {
      el.preferenceOrder = null;
    });
  }

  filterProfiles(opd: OwnerProfileDetailsDto[]) {
    this.rankedProfiles = opd.filter(x => x.preferenceOrder != null);
    this.unrankedProfiles = opd.filter(x => x.preferenceOrder == null);
  }

  getTotalIncomeForProfile(p: OwnerProfileDetailsDto) {
    if (p == null || p.personDetails == null) {
      return '€ 0';
    }
    let totalIncome = 0;
    p.personDetails.forEach((el) => {
      if (el.monthlyIncome != null) {
        totalIncome = totalIncome + el.monthlyIncome;
      }
      el.otherIncomes.forEach((o) => {
        if (o.income != null) {
          totalIncome = totalIncome + o.income;
        }
      });
    });
    return '€ ' + totalIncome.toString();
  }

  displayDetails(rp: OwnerProfileDetailsDto) {
    this.detailProfile = rp;
    this.display = true;
  }

  boolTranslate(value: boolean | null) {
    return this.ownerService.boolTranslate(value);
  }

  getAvatarIcon(rp: OwnerProfileDetailsDto) {
    return this.ownerService.getAvatarIcon(rp);
  }
}
