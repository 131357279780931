/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-attachment-confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../shared/modal-header/modal-header.component.ngfactory";
import * as i4 from "../../../shared/modal-header/modal-header.component";
import * as i5 from "@angular/common";
import * as i6 from "./delete-attachment-confirmation.component";
var styles_DeleteAttachmentConfirmationComponent = [i0.styles];
var RenderType_DeleteAttachmentConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteAttachmentConfirmationComponent, data: {} });
export { RenderType_DeleteAttachmentConfirmationComponent as RenderType_DeleteAttachmentConfirmationComponent };
function View_DeleteAttachmentConfirmationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Common.Attention")); _ck(_v, 1, 0, currVal_0); }); }
function View_DeleteAttachmentConfirmationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "filename"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachment.documentName; _ck(_v, 1, 0, currVal_0); }); }
export function View_DeleteAttachmentConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ra-modal-header", [], null, [[null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.cancel.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ModalHeaderComponent_0, i3.RenderType_ModalHeaderComponent)), i1.ɵdid(1, 49152, null, 1, i4.ModalHeaderComponent, [], null, { cancel: "cancel" }), i1.ɵqud(603979776, 1, { template: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_DeleteAttachmentConfirmationComponent_1)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "question"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeleteAttachmentConfirmationComponent_2)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "warning"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "default"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.attachment.documentName; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("AttachmentsWizard.DeleteAttachmentQuestion")); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 9, 0, currVal_1); var currVal_3 = _co.isLoading; _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("AttachmentsWizard.DeleteAttachment")); _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.isLoading; _ck(_v, 15, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("Common.Cancel")); _ck(_v, 16, 0, currVal_6); }); }
export function View_DeleteAttachmentConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-delete-attachment-confirmation", [], null, null, null, View_DeleteAttachmentConfirmationComponent_0, RenderType_DeleteAttachmentConfirmationComponent)), i1.ɵdid(1, 49152, null, 0, i6.DeleteAttachmentConfirmationComponent, [], null, null)], null, null); }
var DeleteAttachmentConfirmationComponentNgFactory = i1.ɵccf("ra-delete-attachment-confirmation", i6.DeleteAttachmentConfirmationComponent, View_DeleteAttachmentConfirmationComponent_Host_0, { attachment: "attachment", text: "text" }, { delete: "delete", cancel: "cancel" }, []);
export { DeleteAttachmentConfirmationComponentNgFactory as DeleteAttachmentConfirmationComponentNgFactory };
