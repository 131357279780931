import { OnInit } from '@angular/core';
import { Status } from 'src/app/shared/api/rentassistant-api.generated';
var CandidacyStatusBadgeComponent = /** @class */ (function () {
    function CandidacyStatusBadgeComponent() {
        this.statusField = Status.Draft;
    }
    Object.defineProperty(CandidacyStatusBadgeComponent.prototype, "status", {
        get: function () {
            return this.statusField;
        },
        set: function (status) {
            this.statusField = status;
            this.updateStatus(status);
        },
        enumerable: true,
        configurable: true
    });
    CandidacyStatusBadgeComponent.prototype.ngOnInit = function () { };
    CandidacyStatusBadgeComponent.prototype.getTranslationKeyAndClass = function (status) {
        switch (status) {
            case Status.Submitted:
                return {
                    statusTranslationKey: 'CandidacyStatus.Submitted',
                    statusClassName: 'submitted'
                };
            case Status.InProgress:
                return {
                    statusTranslationKey: 'CandidacyStatus.InProgress',
                    statusClassName: 'in-progress'
                };
            case Status.InfoRequested:
                return {
                    statusTranslationKey: 'CandidacyStatus.Update',
                    statusClassName: 'update'
                };
            case Status.Approved:
                return {
                    statusTranslationKey: 'CandidacyStatus.Selected',
                    statusClassName: 'selected'
                };
            case Status.Rejected:
                return {
                    statusTranslationKey: 'CandidacyStatus.Rejected',
                    statusClassName: 'rejected'
                };
            case Status.Recalled:
                return {
                    statusTranslationKey: 'CandidacyStatus.Recalled',
                    statusClassName: 'recalled'
                };
            case Status.Draft:
                return {
                    statusTranslationKey: 'CandidacyStatus.Draft',
                    statusClassName: 'draft'
                };
        }
        return {
            statusTranslationKey: '',
            statusClassName: ''
        };
    };
    CandidacyStatusBadgeComponent.prototype.updateStatus = function (status) {
        var _a = this.getTranslationKeyAndClass(status), statusTranslationKey = _a.statusTranslationKey, statusClassName = _a.statusClassName;
        this.statusTranslationKey = statusTranslationKey;
        this.statusClassName = statusClassName;
    };
    return CandidacyStatusBadgeComponent;
}());
export { CandidacyStatusBadgeComponent };
