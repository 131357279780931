import { OwnersportalComponent } from './../ownersportal/components/owernsportal/ownersportal.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShellComponent } from './shell/shell.component';
import { LocalizeRouterModule } from 'ngx-localize-router';
import { routes as CandidacyRoutes } from '../candidacies/candidacies-routes';
import { routes as ProfileRoutes } from '../profile/profile-routes';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: 'app', component: ShellComponent,
    children: [
      // { path: 'components-demo', component: ComponentsDemoComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'eigenaarsportaal', component: OwnersportalComponent },
      ...CandidacyRoutes,
      ...ProfileRoutes
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes)
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class ShellRoutingModule { }
