import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { PersonLegalType, ProfileDetailsDto } from 'src/app/shared/api/rentassistant-api.generated';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { ProfileWizardStateService, ProfileWizardState, ProfileWizardMode } from '../../core/services/profilewizard-state.service';
import { filter } from 'rxjs/operators';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

export type ProfileCompletion = 'general' | 'renter' | 'attachments' ;

@Component({
  selector: 'ra-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent extends AutoUnsubscribeComponent
  implements OnInit {

  profile: ProfileDetailsDto;
  state: ProfileWizardState;
  readonly: boolean;

  generalWizardComplete: boolean;
  renterWizardComplete: boolean;
  attachmentsWizardComplete: boolean;

  constructor(private localizedRouter: LocalizedRouter,
              private route: ActivatedRoute,
              private stateService: ProfileWizardStateService,
              private api: RentAssistantApi) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.stateService
          .state$
          .pipe(filter(x => !!x))
          .subscribe(state => {
              this.profile = state.profile;
              this.state = state;
          })
    );

    this.subscriptions.push(
      this.route.data.subscribe(x => {
        this.generalWizardComplete = x.completedStep as ProfileCompletion === 'general';
        this.renterWizardComplete = x.completedStep as ProfileCompletion === 'renter';
        this.attachmentsWizardComplete = x.completedStep as ProfileCompletion === 'attachments';
      })
    );
  }

  navigateToOverview() {
    this.state.closeWizard();
  }

  addRenter() {
    const newPersonId = this.profile.personDetails.length + 1;

    this.profile.personDetails.push({
        id: newPersonId,
        legalType: PersonLegalType.Person,
        address: {}
    });

    const saveProfile$ = this.state.wizardMode === ProfileWizardMode.CandidacyProfile
                            ? this.api.saveCandidacyProfile(this.state.candidacy.uniqueId, this.state.candidacy.profile)
                            : this.api.saveUserProfile(this.profile);

    this.subscriptions.push(
          saveProfile$.subscribe(updatedProfile => {
            this.stateService.updateProfile(updatedProfile);
            this.localizedRouter.navigate([
                              '../../persoon',
                              newPersonId,
                              'persoonlijke-gegevens'
                            ], { relativeTo: this.route });
                        }
          )
    );
  }

  addRenterDocu() {
    const newPersonId = this.profile.personDetails.length + 1;

    this.profile.personDetails.push({
        id: newPersonId,
        legalType: PersonLegalType.Person,
        address: {}
    });

    const saveProfile$ = this.state.wizardMode === ProfileWizardMode.CandidacyProfile
                            ? this.api.saveCandidacyProfile(this.state.candidacy.uniqueId, this.state.candidacy.profile)
                            : this.api.saveUserProfile(this.profile);

    this.subscriptions.push(
          saveProfile$.subscribe(updatedProfile => {
            this.stateService.updateProfile(updatedProfile);
            this.localizedRouter.navigate([
                              '../../../../persoon',
                              newPersonId,
                              'persoonlijke-gegevens'
                            ], { relativeTo: this.route });
                        }
          )
    );
  }

  addDocuments() {
    this.localizedRouter.navigate([
      '../bijlagen',
      'inkomsten'
    ], { relativeTo: this.route });
  }
}
