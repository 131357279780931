/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./houses-background.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./houses-background.component";
var styles_HousesBackgroundComponent = [i0.styles];
var RenderType_HousesBackgroundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HousesBackgroundComponent, data: {} });
export { RenderType_HousesBackgroundComponent as RenderType_HousesBackgroundComponent };
export function View_HousesBackgroundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "background-big"], ["src", "assets/images/background-house-1.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "background-small"], ["src", "assets/images/background-house-2.svg"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_HousesBackgroundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-houses-background", [], null, null, null, View_HousesBackgroundComponent_0, RenderType_HousesBackgroundComponent)), i1.ɵdid(1, 114688, null, 0, i2.HousesBackgroundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HousesBackgroundComponentNgFactory = i1.ɵccf("ra-houses-background", i2.HousesBackgroundComponent, View_HousesBackgroundComponent_Host_0, {}, {}, ["*"]);
export { HousesBackgroundComponentNgFactory as HousesBackgroundComponentNgFactory };
