import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe.component';
import { CandidacyDto, ProfileDetailsDto } from '../../api/rentassistant-api.generated';
import { ProfileWizardMode, ProfileWizardStateService, ProfileWizardState } from '../../../core/services/profilewizard-state.service';
import { filter, concatMap, first } from 'rxjs/operators';
import { from } from 'rxjs';

export type WizardType = 'General' | 'Renters' | 'Attachments';

@Component({
  selector: 'ra-wizard-header',
  templateUrl: './wizard-header.component.html',
  styleUrls: ['./wizard-header.component.scss']
})
export class WizardHeaderComponent extends AutoUnsubscribeComponent implements OnInit {

  @Input() wizardType: WizardType;
  @Input() autoSave = true;

  @Output() help = new EventEmitter();

  candidacy: CandidacyDto;
  profile: ProfileDetailsDto;
  wizardMode: ProfileWizardMode;
  state: ProfileWizardState;

  constructor(private stateService: ProfileWizardStateService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.stateService
          .state$
          .pipe(filter(x => !!x))
          .subscribe(state => {
            this.state = state;
            this.candidacy = state.candidacy;
            this.profile = state.profile;
            this.wizardMode = state.wizardMode;
          })
    );
  }

  showHelp() {
    this.help.next();
  }

  close() {
    if (!this.autoSave ||
        this.profile.isReadonly) {
      this.state.closeWizard();
    }

    if (this.autoSave) {
      this.subscriptions.push(
        this.state.saveProfile()
                  .pipe(
                      concatMap(() => from(this.state.closeWizard())),
                      first()
                    )
                    .subscribe()
      );
    }
  }
}
