import { BehaviorSubject, Observable } from 'rxjs';
import { CandidacyDto, ProfileDetailsDto } from '../../shared/api/rentassistant-api.generated';
import { Injectable } from '@angular/core';

export enum ProfileWizardMode {
  DraftProfile,
  CandidacyProfile,
  PublicProfile
}

export class ProfileWizardState {
  candidacy: CandidacyDto;
  profile: ProfileDetailsDto;
  wizardMode: ProfileWizardMode;
  closeWizard: () => Promise<boolean>;
  saveProfile: () => Observable<ProfileDetailsDto>;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileWizardStateService {
  state$: BehaviorSubject<ProfileWizardState>;

  constructor() {
    this.state$ = new BehaviorSubject(null);
  }

  updateProfile(profile: ProfileDetailsDto) {
    const currentState = this.state$.getValue();
    currentState.profile = profile;
    if (currentState.candidacy) {
      currentState.candidacy.profile = profile;
    }

    this.state$.next(currentState);
  }
}
