import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { makeEmptyCandidacy } from 'src/app/core/models';
import { finalize } from 'rxjs/operators';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { Action } from 'src/app/shared/api/rentassistant-api.generated';
var CandidacyActionConfirmationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CandidacyActionConfirmationComponent, _super);
    function CandidacyActionConfirmationComponent(api, localizedRouter) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.localizedRouter = localizedRouter;
        _this.candidacy = makeEmptyCandidacy();
        _this.action = Action.Recall;
        _this.cancel = new EventEmitter();
        _this.isLoading = false;
        return _this;
    }
    CandidacyActionConfirmationComponent.prototype.recallCandidacy = function () {
        var _this = this;
        this.isLoading = true;
        this.subscriptions.push(this.api
            .recallCandidacy(this.candidacy.uniqueId)
            .pipe(finalize(function () { return _this.isLoading = false; }))
            .subscribe(function () {
            _this.cancel.next();
            _this.localizedRouter.navigate(['/app/kandidaturen']);
        }));
    };
    CandidacyActionConfirmationComponent.prototype.deleteCandidacy = function () {
        var _this = this;
        this.isLoading = true;
        this.subscriptions.push(this.api
            .deleteDraftCandidacy(this.candidacy.uniqueId)
            .pipe(finalize(function () { return _this.isLoading = false; }))
            .subscribe(function () {
            _this.cancel.next();
            _this.localizedRouter.navigate(['/app/kandidaturen']);
        }));
    };
    return CandidacyActionConfirmationComponent;
}(AutoUnsubscribeComponent));
export { CandidacyActionConfirmationComponent };
