import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OwnerLogin, LoginStatus, ShortlistPropertyDto } from './../../../shared/api/rentassistant-api.generated';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RentAssistantApi } from 'src/app/shared/api/rentassistant-api';

@Component({
  selector: 'ra-ownersportal-login',
  templateUrl: './ownersportal-login.component.html',
  styleUrls: ['./ownersportal-login.component.scss']
})
export class OwnersportalLoginComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private api: RentAssistantApi,
    private translateService: TranslateService) { }

  login: OwnerLogin = {};
  LoginStatus = LoginStatus;
  subscriptions: Subscription[] = [];
  ownerProperty: ShortlistPropertyDto;
  submitting = false;
  approvedTermsOfUse = false;
  title = 'Registration.Login';
  ngOnInit() {
    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        this.login.shortlistUniqueId = params.get('id');
        this.api.getOwnerProperty(this.login.shortlistUniqueId)
          .toPromise()
          .then((prop) => {
            this.ownerProperty = prop;
          });
      })
    );
    this.login.phoneNumber = '+32';
    // comment this out when done
    // this.login.ownerSessionId = localStorage.getItem('ownerSessionId');
    // this.login.phoneNumber = localStorage.getItem('phoneNumber');
    // this.login.token = localStorage.getItem('token');
    // this.login.sessionAvailableUntil = new Date(+localStorage.getItem('sessionAvailableUntil'));
    // this.login.loginStatus = LoginStatus.SessionCreated;
    //
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onSubmit() {
    this.login.phoneNumber = this.phoneNumberChanged(this.login.phoneNumber);
    this.submitting = true;
    this.api.ownerLogin(this.login)
      .toPromise()
      .then((newLogin) => {
        this.title = 'Registration.Login';
        this.login = newLogin;
        this.submitting = false;
        if (this.login.loginStatus === LoginStatus.SessionCreated) {
          this.title = 'Owner.Portal';
        }
        // comment this out when done
        // localStorage.setItem('ownerSessionId', this.login.ownerSessionId);
        // localStorage.setItem('phoneNumber', this.login.phoneNumber);
        // localStorage.setItem('token', this.login.token);
        // localStorage.setItem('sessionAvailableUntil', new Date(this.login.sessionAvailableUntil).toString());
        //
      })
      .catch(() => {
        this.submitting = false;
      });
  }

  SendToken() {
    this.login.token = null;
    this.onSubmit();
  }

  phoneNumberChanged(value: string) {

    if (value == null || value.charAt(0) !== '+') {
      this.login.phoneNumber = '+32';
    }
    if (this.login != null) {
      this.login.phoneNumber = this.login.phoneNumber.replace('+320', '+32');
    }
    return this.login.phoneNumber;
  }

  termsOfUse() {
    window.open('https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf');
    window.open('https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf');
    const language = this.translateService.currentLang;
    if (language === 'nl') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'de') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'fr') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'en') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    return '';
  }

  onLoginChanged(event: OwnerLogin) {
    this.login = event;
    this.title = 'Registration.Login';
    if (this.login.loginStatus === LoginStatus.SessionCreated) {
      this.title = 'Owner.Portal';
    }
  }
}
