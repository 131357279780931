<div class="renter-wizard-header__wrapper">
  <div class="container renter-wizard-header">
    <svg-icon src="assets/images/chevron-left.svg"
              (click)="close()"
              [applyCss]="true"
              class="action-icon visible-until-desktop"></svg-icon>
    <div class="person-container">
      <div class="person-icon">
        <svg-icon src="assets/images/person.svg"
                  [applyCss]="true"></svg-icon>
      </div>
      <div class="name">{{ name }}</div>
    </div>
    <svg-icon src="assets/images/trash-bin.svg"
              (click)="removePerson()"
              [applyCss]="true"
              *ngIf="!readonly"
              class="action-icon"></svg-icon>
  </div>
</div>

<ng-template #deleteModal let-modal>
  <ra-delete-person-confirmation
    [profile]="profile"
    [person]="person"
    (delete)="afterDeleted(modal)"
    (cancel)="modal.dismiss()"
  ></ra-delete-person-confirmation>
</ng-template>
