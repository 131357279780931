import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalizedRouter } from 'src/app/core/services/localized-router.service';

@Component({
  selector: 'ra-ownerportal-header',
  templateUrl: './ownerportal-header.component.html',
  styleUrls: ['./ownerportal-header.component.scss']
})
export class OwnerportalHeaderComponent implements OnInit {

  @Input() titleKey: string;
  @Input() subTitle: string;

  menuVisible: boolean;

  @Input() menuIconVisible = true;
  @Input() backIconVisible: boolean;
  @Input() logoVisible = false;

  @Output() logout = new EventEmitter();

  constructor(private localizedRouter: LocalizedRouter) { }

  ngOnInit() {
  }

  toggleMenu($event: Event) {
    this.menuVisible = !this.menuVisible;
    $event.stopImmediatePropagation();
  }

  menuVisibleChange(visible) {
    this.menuVisible = visible;
  }

  navigateHome() {
    this.localizedRouter.navigate(['/']);
  }
  logOut() {
    this.logout.emit();
   }

}
