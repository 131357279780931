import { throwError } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/api/rentassistant-api";
var CandidacyResolver = /** @class */ (function () {
    function CandidacyResolver(api) {
        this.api = api;
    }
    CandidacyResolver.prototype.resolve = function (route) {
        var candidacyId = route.paramMap.get('candidacyId');
        if (candidacyId === null) {
            return throwError('candidacyId parameter missing');
        }
        return this.api.getCandidacy(candidacyId);
    };
    CandidacyResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CandidacyResolver_Factory() { return new CandidacyResolver(i0.ɵɵinject(i1.RentAssistantApi)); }, token: CandidacyResolver, providedIn: "root" });
    return CandidacyResolver;
}());
export { CandidacyResolver };
