import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProfileAttachmentDto } from '../../../shared/api/rentassistant-api.generated';

@Component({
  selector: 'ra-delete-attachment-confirmation',
  templateUrl: './delete-attachment-confirmation.component.html',
  styleUrls: ['./delete-attachment-confirmation.component.scss']
})
export class DeleteAttachmentConfirmationComponent  {

  @Input() attachment: ProfileAttachmentDto;
  @Input() text: string;
  @Output() delete = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  isLoading = false;

  onDelete() {
    this.isLoading = true;
    this.delete.next();
    this.isLoading = false;
  }

}
