<div class="wizard-summary container">
  <h1
    *ngIf="!attachmentsWizardComplete"
    class="centered-text">
    {{ 'Summary.AlmostThere' | translate }}
  </h1>
  <h1
    *ngIf="attachmentsWizardComplete"
    class="centered-text">
    {{ 'Summary.Finished' | translate }}
  </h1>
  <p class="centered-text" *ngIf="generalWizardComplete">
    {{ 'Summary.FirstStepComplete' | translate }}
  </p>
  <p class="centered-text" *ngIf="renterWizardComplete">
    {{ 'Summary.SecondStepComplete' | translate }}
  </p>
  <p class="centered-text" *ngIf="attachmentsWizardComplete">
    {{ 'Summary.ThirdStepComplete' | translate }}
  </p>

  <div class="wizard-steps" *ngIf="generalWizardComplete">
    <div class="wizard-complete-icon">
      <svg-icon src="assets/images/checkmark.svg"></svg-icon>
    </div>
    <div class="icon-container">
      <svg-icon
        [applyCss]="true"
        src="assets/images/wizard-renters.svg"
      ></svg-icon>
    </div>
    <div class="icon-container">
      <svg-icon
        [applyCss]="true"
        src="assets/images/wizard-attachments.svg"
      ></svg-icon>
    </div>
  </div>

  <div class="wizard-steps" *ngIf="renterWizardComplete">
    <div class="wizard-complete-icon-small">
      <svg-icon src="assets/images/checkmark.svg"></svg-icon>
    </div>
    <div class="wizard-complete-icon">
      <svg-icon src="assets/images/checkmark.svg"></svg-icon>
    </div>
    <div class="icon-container">
      <svg-icon
        [applyCss]="true"
        src="assets/images/wizard-attachments.svg"
      ></svg-icon>
    </div>
  </div>

  <div class="wizard-steps" *ngIf="attachmentsWizardComplete">
    <div class="wizard-complete-icon-small">
      <svg-icon src="assets/images/checkmark.svg"></svg-icon>
    </div>
    <div class="wizard-complete-icon-small">
      <svg-icon src="assets/images/checkmark.svg"></svg-icon>
    </div>
    <div class="wizard-complete-icon">
      <svg-icon src="assets/images/checkmark.svg"></svg-icon>
    </div>
  </div>

  <div
    *ngIf="generalWizardComplete"
    class="image-container">
    <svg-icon src="assets/images/general-wizard-summary.svg"></svg-icon>
  </div>
  <div
    *ngIf="renterWizardComplete"
    class="image-container">
    <svg-icon src="assets/images/renter-wizard-summary.svg"></svg-icon>
  </div>
  <div
    *ngIf="attachmentsWizardComplete"
    class="image-container">
    <svg-icon src="assets/images/attachments-wizard-summary.svg"></svg-icon>
  </div>
  <button
    (click)="addRenter()"
    class="primary"
    *ngIf="generalWizardComplete && !readonly"
  >
    {{ 'GeneralWizard.AddRenters' | translate }}
  </button>
  <button
    (click)="addDocuments()"
    class="primary"
    *ngIf="renterWizardComplete && !readonly"
  >
      {{ 'Summary.AddDocuments' | translate }}
    </button>
    
    <div class="action-buttons">
      <button
        (click)="navigateToOverview()"
        [class.primary]="attachmentsWizardComplete"
        class="default">
        {{ 'GeneralWizard.GoToProfileOverview' | translate }}
      </button>
  </div>

  <button *ngIf="attachmentsWizardComplete && !readonly" (click)="addRenterDocu()" class="default list-button left-icon-button">
  <svg-icon [applyCss]="true" src="assets/images/plus.svg"></svg-icon>
  <span>{{ 'RenterWizard.AddExtraRenter' | translate }}</span>
</button>
</div>
