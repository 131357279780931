import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/loading-status.service";
var LoadingInterceptor = /** @class */ (function () {
    function LoadingInterceptor(loaderService) {
        this.loaderService = loaderService;
    }
    LoadingInterceptor.prototype.intercept = function (req, next) {
        var hideLoader = this.loaderService.pushLoadingFrame();
        return next.handle(req).pipe(finalize(function () { return hideLoader(); }));
    };
    LoadingInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingInterceptor_Factory() { return new LoadingInterceptor(i0.ɵɵinject(i1.LoadingStatusService)); }, token: LoadingInterceptor, providedIn: "root" });
    return LoadingInterceptor;
}());
export { LoadingInterceptor };
