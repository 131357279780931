import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { LocalizeRouterService } from 'ngx-localize-router';

@Injectable({
  providedIn: 'root'
})
export class LocalizedRouter {

  constructor(private router: Router,
              private localizeRouterService: LocalizeRouterService) {
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {

      const translatedCommands = this.localizeRouterService.translateRoute(commands) as [];
      return this.router.navigate(translatedCommands, extras);
  }

  isActive(commands: any[], exact: boolean = true) {
      const translatedCommands = this.localizeRouterService.translateRoute(commands) as [];
      const urlTree = this.router.createUrlTree(translatedCommands);
      return this.router.isActive(urlTree, exact);
  }
}
