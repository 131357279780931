/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./candidacy-status-badge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./candidacy-status-badge.component";
var styles_CandidacyStatusBadgeComponent = [i0.styles];
var RenderType_CandidacyStatusBadgeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CandidacyStatusBadgeComponent, data: {} });
export { RenderType_CandidacyStatusBadgeComponent as RenderType_CandidacyStatusBadgeComponent };
export function View_CandidacyStatusBadgeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "candidacy-status"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, [" ", "\n"])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "candidacy-status"; var currVal_1 = _co.statusClassName; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.statusTranslationKey)); _ck(_v, 3, 0, currVal_2); }); }
export function View_CandidacyStatusBadgeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-candidacy-status-badge", [], null, null, null, View_CandidacyStatusBadgeComponent_0, RenderType_CandidacyStatusBadgeComponent)), i1.ɵdid(1, 114688, null, 0, i4.CandidacyStatusBadgeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CandidacyStatusBadgeComponentNgFactory = i1.ɵccf("ra-candidacy-status-badge", i4.CandidacyStatusBadgeComponent, View_CandidacyStatusBadgeComponent_Host_0, { status: "status" }, {}, []);
export { CandidacyStatusBadgeComponentNgFactory as CandidacyStatusBadgeComponentNgFactory };
