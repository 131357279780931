<ra-modal-header (cancel)="closeModal.next()">
  <ng-template>
    <div class="header">{{ 'ShareModal.Title' | translate }}</div>
  </ng-template>
</ra-modal-header>
<div class="info">
  {{ 'ShareModal.Info' | translate }}
</div>

<div class="content">
  <div class="link">
    <input type="text" [(ngModel)]="email" #profileLinkField/>
    <button class="primary"
            (click)="confirmClicked()">
            {{ 'Common.Confirm' | translate }}
    </button>
  </div>
  <p *ngIf="errorMessage != null" style="color: red;">{{errorMessage}}</p>

  <button class="default action" (click)="closeModal.next()">
    {{ 'Common.Close' | translate }}
  </button>
</div>

