import { Component, Input, Output, EventEmitter } from '@angular/core';
import { makeEmptyCandidacy } from 'src/app/core/models';

import { finalize } from 'rxjs/operators';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { Action } from 'src/app/shared/api/rentassistant-api.generated';
import { LocalizedRouter } from '../../../core/services/localized-router.service';
import { RentAssistantApi } from '../../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-candidacy-action-confirmation',
  templateUrl: './action-confirmation.component.html',
  styleUrls: ['./action-confirmation.component.scss']
})
export class CandidacyActionConfirmationComponent extends AutoUnsubscribeComponent {
  @Input()
  candidacy = makeEmptyCandidacy();

  @Input()
  action: Action = Action.Recall;

  @Output()
  cancel = new EventEmitter<void>();

  isLoading = false;

  constructor(
    private api: RentAssistantApi,
    private localizedRouter: LocalizedRouter
  ) {
    super();
  }

  recallCandidacy() {
    this.isLoading = true;

    this.subscriptions.push(
      this.api
          .recallCandidacy(this.candidacy.uniqueId)
          .pipe(
              finalize(() => this.isLoading = false)
          )
          .subscribe(() => {
            this.cancel.next();
            this.localizedRouter.navigate(['/app/kandidaturen']);
          })
    );
  }

  deleteCandidacy() {
    this.isLoading = true;

    this.subscriptions.push(
      this.api
          .deleteDraftCandidacy(this.candidacy.uniqueId)
          .pipe(
              finalize(() => this.isLoading = false)
          )
          .subscribe(() => {
            this.cancel.next();
            this.localizedRouter.navigate(['/app/kandidaturen']);
          })
    );
  }
}
