import { PropertyDto } from './../../../shared/api/rentassistant-api.generated';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ra-realestate-item',
  templateUrl: './realestate-item.component.html',
  styleUrls: ['./realestate-item.component.scss']
})
export class RealestateItemComponent implements OnInit {
  @Input() property: PropertyDto;
  constructor() { }

  ngOnInit() {
  }

}
