import { ShareProfileDto } from './../../shared/api/rentassistant-api.generated';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizedRouter } from '../../core/services/localized-router.service';
import { ProfileDetailsDto, PersonLegalType } from 'src/app/shared/api/rentassistant-api.generated';
import { filter } from 'rxjs/operators';
import { ProfileWizardState, ProfileWizardStateService, ProfileWizardMode } from '../../core/services/profilewizard-state.service';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {

  @ViewChild('shareModal', { static: false })
  shareModal: TemplateRef<any>;


  state: ProfileWizardState;
  profile: ProfileDetailsDto;
  subscriptions: Subscription[];

  isDetail: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private localizedRouter: LocalizedRouter,
              private modal: NgbModal,
              private api: RentAssistantApi,
              private stateService: ProfileWizardStateService) { }

  ngOnInit() {
    this.subscriptions = [];

    this.stateService.state$.next({
      candidacy: null,
      profile: this.route.snapshot.data.profile,
      wizardMode: ProfileWizardMode.DraftProfile,
      closeWizard: () => this.localizedRouter.navigate(['/app/profiel']),
      saveProfile: () => this.api.saveUserProfile(this.profile)
    });

    this.subscriptions.push(
      this.stateService.state$.subscribe(state => {
        this.state = state;
        this.profile = state.profile;
      })
    );

    this.isDetail = this.route.children.length === 0;

    this.subscriptions.push(
      this.router.events
                 .pipe(filter(e => e instanceof NavigationEnd))
                 .subscribe((e: NavigationEnd)  => this.isDetail = this.route.children.length === 0)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  addRenter() {
    const newPersonId = this.profile.personDetails.length + 1;

    this.profile.personDetails.push({
        id: newPersonId,
        legalType: PersonLegalType.Person,
        address: {}
    });

    this.subscriptions.push(
      this.api.saveUserProfile(this.profile)
          .subscribe(prof => {
            this.stateService.updateProfile(prof);
            this.localizedRouter.navigate([
                              'persoon',
                              newPersonId,
                              'persoonlijke-gegevens'
                            ], { relativeTo: this.route });
                          }
      )
    );
  }

  share() {
    this.modal.open(this.shareModal);
  }

  onShareConfirm(event: string) {
    const shareProfileDto: ShareProfileDto = {};
    shareProfileDto.email = event;
    this.api.shareProfile(shareProfileDto).subscribe();
    this.modal.dismissAll();
  }
}
