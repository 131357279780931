import * as tslib_1 from "tslib";
import { StepBaseComponent } from '../step-base.component';
var OrganizationContactInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OrganizationContactInfoComponent, _super);
    function OrganizationContactInfoComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hostClass = 'wizard';
        return _this;
    }
    OrganizationContactInfoComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../organisatie-adres'], { relativeTo: this.route });
    };
    OrganizationContactInfoComponent.prototype.next = function () {
        if (this.readonly) {
            this.state.closeWizard();
        }
        else {
            this.localizedRouter.navigate(['../samenvatting'], { relativeTo: this.route });
        }
    };
    return OrganizationContactInfoComponent;
}(StepBaseComponent));
export { OrganizationContactInfoComponent };
