import { RentAssistantClient, ShareProfileDto } from './../../api/rentassistant-api.generated';
import { RentAssistantApi } from './../../api/rentassistant-api';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'ra-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit, AfterViewInit {

  @Output() closeModal = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<string>();

  @ViewChild('profileLinkField', { static: false }) profileLinkField: ElementRef;

  email = '';
  errorMessage: string;

  constructor(private apiClient: RentAssistantClient,
              private api: RentAssistantApi) { }

  ngOnInit() {
    this.apiClient.users_GetMe()
    .toPromise()
    .then((x) => this.email = x.emailAddress);
  }

  ngAfterViewInit(): void {
    this.profileLinkField.nativeElement.setSelectionRange(0, 0);
  }

  copy() {
    this.profileLinkField.nativeElement.select();
    this.profileLinkField.nativeElement.setSelectionRange(0, 99999);  // for mobile devices
    document.execCommand('copy');
    this.profileLinkField.nativeElement.setSelectionRange(0, 0);
  }

  confirmClicked() {
    const shareProfileDto: ShareProfileDto = {};
    shareProfileDto.email = this.email;
    this.errorMessage = null;
    this.api.shareProfile(shareProfileDto)
    .toPromise().then(() => {
      this.closeModal.emit();
    })
    .catch((error) => {
      const response = JSON.parse(error.response);
      this.errorMessage = response.detail;
    });
  }

}
