/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./other-income-attachments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../attachment/attachment.component.ngfactory";
import * as i6 from "../attachment/attachment.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../shared/components/wizard-header/wizard-header.component.ngfactory";
import * as i9 from "../../../shared/components/wizard-header/wizard-header.component";
import * as i10 from "../../../core/services/profilewizard-state.service";
import * as i11 from "@angular/forms";
import * as i12 from "@angular/common";
import * as i13 from "../../../shared/components/wizard-footer/wizard-footer.component.ngfactory";
import * as i14 from "../../../shared/components/wizard-footer/wizard-footer.component";
import * as i15 from "./other-income-attachments.component";
import * as i16 from "@angular/router";
import * as i17 from "../../../shared/api/rentassistant-api";
import * as i18 from "../../../core/services/localized-router.service";
var styles_OtherIncomeAttachmentsComponent = [i0.styles];
var RenderType_OtherIncomeAttachmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OtherIncomeAttachmentsComponent, data: {} });
export { RenderType_OtherIncomeAttachmentsComponent as RenderType_OtherIncomeAttachmentsComponent };
function View_OtherIncomeAttachmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "default left-icon-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 18).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["src", "assets/images/upload.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "assets/images/upload.svg"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("AttachmentsWizard.UploadProofOfIncome")); _ck(_v, 4, 0, currVal_1); }); }
function View_OtherIncomeAttachmentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-attachment", [], null, [[null, "download"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("download" === en)) {
        var pd_0 = (_co.downloadAttachment(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("delete" === en)) {
        var pd_1 = (_co.deleteAttachment(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_AttachmentComponent_0, i5.RenderType_AttachmentComponent)), i1.ɵdid(1, 114688, null, 0, i6.AttachmentComponent, [i7.NgbModal], { attachment: [0, "attachment"], text: [1, "text"], subText: [2, "subText"], readonly: [3, "readonly"] }, { download: "download", delete: "delete" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = ((i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform("AttachmentsWizard.ProofOfOtherIncome")) + " ") + (_v.context.index + 1)); var currVal_2 = _v.context.$implicit.documentName; var currVal_3 = _co.profile.isReadonly; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_OtherIncomeAttachmentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("AttachmentsWizard.NoDocuments")); _ck(_v, 1, 0, currVal_0); }); }
export function View_OtherIncomeAttachmentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "wizard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ra-wizard-header", [], null, [[null, "closeWizard"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeWizard" === en)) {
        var pd_0 = (_co.closeWizard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_WizardHeaderComponent_0, i8.RenderType_WizardHeaderComponent)), i1.ɵdid(2, 245760, null, 0, i9.WizardHeaderComponent, [i10.ProfileWizardStateService], { wizardType: [0, "wizardType"], autoSave: [1, "autoSave"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 20, "div", [["class", "wizard-page container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 19, "form", [["class", "form-grid"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i11.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(6, 4210688, null, 0, i11.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i11.ControlContainer, null, [i11.NgForm]), i1.ɵdid(8, 16384, null, 0, i11.NgControlStatusGroup, [[4, i11.ControlContainer]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", " ", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OtherIncomeAttachmentsComponent_1)), i1.ɵdid(17, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, [["hiddenFileInput", 1]], null, 0, "input", [["style", "display: none"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFilesSelected($event.target.files, _co.AttachmentType.ProofOfOtherIncome) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OtherIncomeAttachmentsComponent_2)), i1.ɵdid(21, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OtherIncomeAttachmentsComponent_3)), i1.ɵdid(23, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 1, "ra-wizard-footer", [], null, [[null, "previous"], [null, "next"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("previous" === en)) {
        var pd_0 = (_co.previous() !== false);
        ad = (pd_0 && ad);
    } if (("next" === en)) {
        var pd_1 = (_co.next() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i13.View_WizardFooterComponent_0, i13.RenderType_WizardFooterComponent)), i1.ɵdid(25, 245760, null, 0, i14.WizardFooterComponent, [i10.ProfileWizardStateService], { step: [0, "step"], totalSteps: [1, "totalSteps"], autoSave: [2, "autoSave"] }, { previous: "previous", next: "next" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Attachments"; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_12 = !_co.profile.isReadonly; _ck(_v, 17, 0, currVal_12); var currVal_13 = _co.getAttachments(_co.AttachmentType.ProofOfOtherIncome); _ck(_v, 21, 0, currVal_13); var currVal_14 = (_co.profile.isReadonly && (_co.getAttachments(_co.AttachmentType.ProofOfOtherIncome).length === 0)); _ck(_v, 23, 0, currVal_14); var currVal_15 = 2; var currVal_16 = 3; var currVal_17 = false; _ck(_v, 25, 0, currVal_15, currVal_16, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 8).ngClassValid; var currVal_7 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("AttachmentsWizard.ProofOfOtherIncomeTitle")); var currVal_10 = _co.name; _ck(_v, 11, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("AttachmentsWizard.ProofOfOtherIncomeText")); _ck(_v, 14, 0, currVal_11); }); }
export function View_OtherIncomeAttachmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-other-income-attachments", [], null, null, null, View_OtherIncomeAttachmentsComponent_0, RenderType_OtherIncomeAttachmentsComponent)), i1.ɵdid(1, 245760, null, 0, i15.OtherIncomeAttachmentsComponent, [i16.ActivatedRoute, i17.RentAssistantApi, i18.LocalizedRouter, i10.ProfileWizardStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OtherIncomeAttachmentsComponentNgFactory = i1.ɵccf("ra-other-income-attachments", i15.OtherIncomeAttachmentsComponent, View_OtherIncomeAttachmentsComponent_Host_0, {}, {}, []);
export { OtherIncomeAttachmentsComponentNgFactory as OtherIncomeAttachmentsComponentNgFactory };
