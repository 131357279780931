import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AttachmentsWizardPageComponent } from '../attachments-wizard-page.component';
var OtherIncomeAttachmentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OtherIncomeAttachmentsComponent, _super);
    function OtherIncomeAttachmentsComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OtherIncomeAttachmentsComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../inkomsten'], { relativeTo: this.route });
    };
    OtherIncomeAttachmentsComponent.prototype.next = function () {
        this.localizedRouter.navigate(['../identiteit-huur'], { relativeTo: this.route });
    };
    return OtherIncomeAttachmentsComponent;
}(AttachmentsWizardPageComponent));
export { OtherIncomeAttachmentsComponent };
