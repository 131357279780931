import * as i0 from "@angular/core";
import * as i1 from "../../../environments/dynamic-environment";
var AuthorizationInterceptor = /** @class */ (function () {
    function AuthorizationInterceptor(environment) {
        this.environment = environment;
    }
    AuthorizationInterceptor.prototype.intercept = function (request, next) {
        var headerValue = this.environment.immoConnect.partnerKey;
        request = request.clone({
            headers: request.headers.set('Authorization', headerValue)
        });
        return next.handle(request);
    };
    AuthorizationInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationInterceptor_Factory() { return new AuthorizationInterceptor(i0.ɵɵinject(i1.DynamicEnvironment)); }, token: AuthorizationInterceptor, providedIn: "root" });
    return AuthorizationInterceptor;
}());
export { AuthorizationInterceptor };
