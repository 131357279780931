<ra-ownerportal-header  *ngIf="login && login.loginStatus === LoginStatus.SessionCreated"
  [titleKey]="title"
  [menuIconVisible]="false"
  [backIconVisible]="false"
  [logoVisible]="true"
></ra-ownerportal-header>
<ra-houses-background *ngIf="login && login.loginStatus != LoginStatus.SessionCreated" class="login-2fa-bg">
  <div class="ra-card-container login-2fa login">
    <div class="logo-container">
      <div class="logo">
        <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
      </div>
      <div class="name">
        <svg-icon [applyCss]="true" src="assets/images/clee.svg"></svg-icon>
      </div>
    </div>
    <div class="ra-card ra-card-sm">
      <div class="login-2fa__language-container">
        <div class="login-2fa__language">
          <ra-language-selector></ra-language-selector>
        </div>
      </div>
      <form (ngSubmit)="onSubmit()" #ownerLoginForm="ngForm">
        <div class="login__intro" [innerHTML]="'Registration.CandidateList' | translate">
        </div>
        <div class="m-b-25">
          <ra-realestate-item *ngIf="ownerProperty && ownerProperty.property" [property]="ownerProperty.property"></ra-realestate-item>
        </div>
        <div class="form-control-container m-b-10">
          <label for="phoneNumber" class="m-b-10">{{ "Registration.PhoneNumber" | translate }}</label>
          <div class="form-group form-group-inner-btn">
            <input
              id="phoneNumber"
              type="phoneNumber"
              name="phoneNumber"
              [(ngModel)]="login.phoneNumber"
              #phoneNumberInput="ngModel"
              #phoneNumberField
              [placeholder]="'Registration.PhoneNumber' | translate"
              required
              phoneNumber
              (ngModelChange)="phoneNumberChanged($event)"
            />
            <div class="form-group-btn">
              <button
                class="primary"
                [disabled]="!ownerLoginForm.form.valid || submitting || login.loginStatus == LoginStatus.TokenSend"
                (click)="SendToken()"
              >
                <ng-container *ngIf="login.loginStatus != LoginStatus.TokenSend">{{ 'Login.Login_SendToken' | translate }}</ng-container>
                <ng-container *ngIf="login.loginStatus == LoginStatus.TokenSend">{{'Registration.TokenSend' | translate}}</ng-container>
              </button>
            </div>
          </div>
          <p *ngIf="login.loginStatus == LoginStatus.Unauthorized">{{'Registration.Unauthorized' | translate}}</p>
          <p style="color: red;" *ngIf="login.loginStatus == LoginStatus.ShortlistExpired">{{'Registration.ShortlistExpired' | translate}}</p>
          <p style="color: red;" *ngIf="login.loginStatus == LoginStatus.AlreadySubmitted">{{'Registration.ShortlistSubmitted' | translate}}</p>
            <!--div *ngIf="emailInput.invalid && emailInput.touched" class="messages">
              <span *ngIf="!!emailInput.errors.email" class="message">{{ 'Login.Validation.Email_Invalid'|translate }}</span>
              <span *ngIf="!!emailInput.errors.required" class="message">{{ 'Login.Validation.Email_Required'|translate }}</span>
            </div-->
        </div>
        <div class="form-control-container m-b-30">
          <label for="token" class="">{{ "Login.Token" | translate }} <span class="color-blue" tooltip="{{'Login.TokenDescription' | translate}}" placement="auto"><i class="far fa-info-circle"></i></span></label>

          <input
            id="token"
            type="token"
            name="token"
            class="input-6-code"
            maxlength="6"
            [(ngModel)]="login.token"
            #tokenInput="ngModel"
            #tokenField
            [placeholder]=""
            minlength="6"
          />
          <!--div *ngIf="passwordInput.invalid && passwordInput.touched" class="messages">
            <span *ngIf="!!passwordInput.errors.required" class="message">{{ 'Login.Validation.Password_Required'|translate }}</span>
            <span *ngIf="!!passwordInput.errors.minlength" class="message">{{ 'Login.Validation.Password_Length'|translate }}</span>
          </div-->
        </div>
        <!--div class="form-control-container m-b-30">
          <div class="checkbox">
            <div class="checkbox-selector checkbox-circle">
              <p-checkbox
                type="checkbox"
                name="approvedTermsOfUse"
                value="approvedTermsOfUse"
                (onChange)="approvedTermsOfUse = !approvedTermsOfUse"
                id="approvedTermsOfUse">
              </p-checkbox>
            </div>
            <label for="approvedTermsOfUse">{{ 'Registration.TermsOfUsePrepend' | translate }} <a [href]="termsOfUse()" target="_blank">{{ 'Registration.TermsOfUse' | translate }}</a>{{ 'Registration.TermsOfUseAppend' | translate }}</label>
          </div>
        </div-->
        <div class="form-control-container">
          <div class="form-group">
            <button
              class="primary lg"
              type="submit"
              [disabled]="!ownerLoginForm.form.valid || submitting"
            >
              {{ 'Login.Login_Action' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ra-houses-background>

<ra-ownersportal (loginChanged)="onLoginChanged($event)" [login]="login" *ngIf="login.loginStatus == LoginStatus.SessionCreated">
</ra-ownersportal>

