<ra-houses-background>
  <div class="visible-until-desktop">
    <ra-menu
      [visible]="menuVisible"
      (visibleChange)="menuVisibleChange($event)">
    </ra-menu>
    <div class="hamburger-icon visible-until-desktop">
      <span class="menu" (click)="toggleMenu($event)">☰</span>
    </div>
  </div>
  <div class="header visible-from-desktop">
    <div class="left-container">
      <div class="logo">
        <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
      </div>
      <div class="name">clee</div>
      <div class="nav-items">
        <a href [routerLink]="['/app/faq' | localize]" >{{ 'Menu.Faq' | translate }}</a>
        <a href  [routerLink]="['/app/contact' | localize]" >{{ 'Menu.Contact' | translate }}</a>
        <a href="{{'Url.Privacy' | translate}}">
          {{ 'Menu.Privacy' | translate }}
        </a>
        <a href="{{'Url.UserPolicy' | translate}}">
          {{ 'Menu.Conditions' | translate }}
        </a>
      </div>
    </div>
    <div class="right-container">
      <button [routerLink]="'/login' | localize" class="default">
        {{ 'Login.Login_Action' | translate }}</button>
        <button [routerLink]="'/registreer' | localize" class="default-inverted">
          {{ 'Login.Register_Action' | translate }}</button>
        <ra-language-selector class="home__language-choice"></ra-language-selector>
    </div>
  </div>
  <div class="welcome">
    <div class="head">
      <div class="logo">
        <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
      </div>
      <div class="name">clee</div>
    </div>
    <div class="slogan">{{ 'Home.Slogan' | translate }}</div>
    <div class="buttons">
      <button [routerLink]="'/login' | localize" class="default">
        {{ 'Login.Login_Action' | translate }}</button>
      <button [routerLink]="'/registreer' | localize" class="default-inverted">
        {{ 'Login.Register_Action' | translate }}</button>
    </div>

    <div class="col-md-9 col-sm-12 col-lg-6 col-xl-5 text-center pt-4 pb-4">
      <div class="clee-video">
        <iframe src="https://player.vimeo.com/video/478531616" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="mouse-scroll">
      <svg width="140" height="36" viewBox="0 0 140 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M37.0488 26.1983C46.77 16.0383 56.843 0 70.9046 0C84.549 0 94.4196 14.7556 103.272 25.1385C108.455 31.2174 117.698 36 133.956 36C178.561 36 -39.911 36 6.51439 36C22.4393 36 31.7234 31.7641 37.0488 26.1983Z" fill="white"/>
        </svg>
      <i class="far fa-mouse-alt"></i>
  </div>
  <div class="mouse-scroll__rect"></div>
</ra-houses-background>

<section class="key-features">
  <div class="container">

    <div class="row key-feature-right">
      <div class="col-md-6 col-sm-12 key-feature-right__img">
          <img class="responsive-img" src="../../../assets/images/key-feature-1.png">
      </div>
      <div class="col-md-6 col-sm-12 key-feature-right__text">
          <h1 class="home-heading">{{ 'Home.Info1Title' | translate }}</h1>
          <p class="home-text">
            {{ 'Home.Info1Description' | translate }}
              </p>
      </div>
    </div>
    <div class="row key-feature-left">
      <div class="col-md-6 col-sm-12 key-feature-left__img">
          <img class="responsive-img" src="../../../assets/images/key-feature-2.png">
      </div>
      <div class="col-md-6 col-sm-12 key-feature-left__text">
          <h1 class="home-heading">{{ 'Home.Info2Title' | translate }}</h1>
          <p class="home-text">
            {{ 'Home.Info2Description' | translate }}</p>
      </div>
    </div>
  </div>
</section>

<div class="goal">
  <div class="what">
    <div class="question">{{ 'Home.Question' | translate }}</div>
    <div class="text-center">{{ 'Home.Steps' | translate }}</div>
  </div>

  <section class="steps">
    <div class="container">
      <div class="row">
        <div *ngFor="let step of steps" class="col-12 col-md-4 text-center step">
          <div class="image">
            <img [src]="step.image.documentLink" />
          </div>
          <div class="step-title">{{ step.title }}</div>
          <div>
            <p class="step-body" [innerHTML]="step.body">
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<ra-footer></ra-footer>
