import { SessionService } from './../../core/services/session.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {

  name: string;
  email: string;
  phoneNumber: string;
  message: string;
  submitting: boolean;

  showConfirmationMessage: boolean;

  subscriptions: Subscription[] = [];

  @ViewChild('nameField', { static: false }) nameField: ElementRef;

  constructor(private api: RentAssistantApi,
              private sessionService: SessionService) { }

  ngOnInit() {
    this.sessionService.session$.pipe(
      map((x => {
        if (x != null) {
            this.name = x.user.firstName + ' ' + x.user.lastName;
            this.email = x.user.emailAddress;
        }
      }))).subscribe();

  }

  ngAfterViewInit() {
    if (this.nameField) {
      this.nameField.nativeElement.focus();
    }
  }

  onSubmit() {
    this.submitting = true;

    this.subscriptions.push(
      this.api.sendEmail({
        fromName: this.name,
        fromEmailAddress: this.email,
        body: this.message,
        phoneNumber: this.phoneNumber
      })
      .pipe(finalize(() => {
        this.submitting = false;
        this.showConfirmationMessage = true;
      }))
      .subscribe()
    );
  }
}
