<ra-house-header [titleKey]="'CreateCandidacy.Apply'"></ra-house-header>
<div class="create-candidacy">
  <div *ngIf="candidacyLight && !candidacy">
    <div class="candidacy-overview">
      <div class="candidacy">
    <ra-candidacy-card
      [candidacy]="candidacyLight"
      [showArrowRight]="false"
      [showDraftedStatus]="false">
    </ra-candidacy-card>
  </div>
  </div>
    <div class="content">
      <ra-error [showCloseButton]="false"></ra-error>
    </div>
  </div>
  <div *ngIf="candidacy && errors.length === 0">
    <div class="candidacy-overview">
      <div class="candidacy">
    <ra-candidacy-card
      [candidacy]="candidacy"
      [showArrowRight]="false"
      (cardClicked)="showCandidacyDetail(candidacy.uniqueId)"
      [showDraftedStatus]="false">
    </ra-candidacy-card>
  </div>
</div>
    <div class="content">
      <div *ngIf="candidacyExists && !candidacyExistsDrafted">
        <ra-message
          [textKey]="'CreateCandidacy.CandidacyExistsTitle'"
          [subTextKey]="getAlreadyExistsMessage()">
        </ra-message>
      </div>
      <div *ngIf="candidacyExists && candidacyExistsDrafted">
        <ra-message
          [textKey]="'CreateCandidacy.CandidacyExistsDraftTitle'"
          [subTextKey]="getAlreadyExistsMessage()">
        </ra-message>
      </div>
      <div *ngIf="!candidacyExists" class="confirmation" style="text-align: center;">
        <div class="container">
          <div class="title">
            {{'CreateCandidacy.CreateCandidacyTitle' | translate}}
          </div>
          <div class="row create-candidacy__steps">
            <div class="col-12 col-md-4">
              <div class="create-candidacy__step">
                <i class="fal fa-keyboard"></i>
                {{'CreateCandidacy.Step1' | translate}}                
              </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="create-candidacy__step">
                  <i class="fal fa-file-alt"></i>
                  {{'CreateCandidacy.Step2' | translate}}                  
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="create-candidacy__step">
                  <i class="fal fa-poll-people"></i>
                  {{'CreateCandidacy.Step3' | translate}}                  
                </div>
            </div>
          </div>
          <div class="subtitle">
              <p>{{'CreateCandidacy.Create' | translate}}  </p> 
            </div>
        </div>
        <button class="primary full-width full-width-2" (click)="createCandidacy()">
          {{ 'CreateCandidacy.ApplyAsCandidate' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!candidacy && !candidacyLight">
    <div class="loading-card">
      <div class="candidacy-overview">
        <div class="candidacy">
      <ra-candidacy-card-loading [animate]="animate"></ra-candidacy-card-loading>
    </div>
  </div>
    </div>
    <ra-error [showCloseButton]="false"></ra-error>
  </div>
</div>
