import { Component, Input } from '@angular/core';

@Component({
  selector: 'ra-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input()
  textKey = '';

  @Input()
  subTextKey = '';
}
