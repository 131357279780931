import {
  Component,
  Input,
  HostListener,
  HostBinding,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  faCheck,
  faExclamation,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

export type CheckedState = 'unchecked' | 'checked' | 'error';

export interface CheckableItemInfoProvider {
  titleKey(): string;
  subText(): string;
  isChecked(): CheckedState;
  click(): void;
  active(): boolean;
}

@Component({
  selector: 'ra-checkable-list-item',
  templateUrl: './checkable-list-item.component.html',
  styleUrls: ['./checkable-list-item.component.scss']
})
export class CheckableListItemComponent implements OnInit, OnDestroy {
  faCheck = faCheck;
  faExclamation = faExclamation;
  faChevronRight = faChevronRight;
  subscriptions: Subscription[] = [];

  @Input()
  infoProvider?: CheckableItemInfoProvider;

  @HostBinding('class.active') active: boolean;

  constructor(private router: Router) {

  }

  @HostListener('click')
  onClickHost() {
    if (this.infoProvider) {
      this.infoProvider.click();
    }
  }

  ngOnInit() {
    this.active = this.infoProvider.active();

    this.subscriptions.push(
      this.router
          .events
          .pipe(filter(e => e instanceof NavigationEnd))
          .subscribe(() => {
            this.active = this.infoProvider.active();
          })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
