import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe.component';
import { ProfileWizardStateService, ProfileWizardMode, ProfileWizardState } from '../../../core/services/profilewizard-state.service';
import { ProfileDetailsDto, CandidacyDto } from '../../api/rentassistant-api.generated';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ra-wizard-footer',
  templateUrl: './wizard-footer.component.html',
  styleUrls: ['./wizard-footer.component.scss']
})
export class WizardFooterComponent extends AutoUnsubscribeComponent implements OnInit  {
  previousVisible: boolean;
  currentStep: number;

  candidacy: CandidacyDto;
  profile: ProfileDetailsDto;
  wizardMode: ProfileWizardMode;
  state: ProfileWizardState;

  constructor(private stateService: ProfileWizardStateService) {
    super();
  }

  @Input()
  get step() {
    return this.currentStep;
  }
  set step(val: number) {
    this.currentStep = val;
    this.previousVisible = val > 1;
  }

  @Input() totalSteps: number;
  @Input() autoSave = true;

  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() beforeNavigate = new EventEmitter();

  ngOnInit() {
    this.subscriptions.push(
      this.stateService
          .state$
          .pipe(filter(x => !!x))
          .subscribe(state => {
            this.state = state;
            this.candidacy = state.candidacy;
            this.profile = state.profile;
            this.wizardMode = state.wizardMode;
          })
    );
  }

  previousStep() {
    this.navigate(this.previous);
  }

  nextStep() {
    this.navigate(this.next);
  }

  private navigate(eventEmitter: EventEmitter<any>) {
    this.beforeNavigate.next();
    if (!this.autoSave ||
        this.wizardMode === ProfileWizardMode.PublicProfile) {
      eventEmitter.next();
      return;
    }

    this.subscriptions.push(
      this.state.saveProfile().subscribe(updatedProfile => {
        this.stateService.updateProfile(updatedProfile);
        eventEmitter.next();
      })
    );
  }
}
