/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./renter-wizard-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "../delete-person-confirmation/delete-person-confirmation.component.ngfactory";
import * as i5 from "../delete-person-confirmation/delete-person-confirmation.component";
import * as i6 from "@angular/common";
import * as i7 from "./renter-wizard-header.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "../../../core/services/profilewizard-state.service";
var styles_RenterWizardHeaderComponent = [i0.styles];
var RenderType_RenterWizardHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RenterWizardHeaderComponent, data: {} });
export { RenderType_RenterWizardHeaderComponent as RenderType_RenterWizardHeaderComponent };
function View_RenterWizardHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [["class", "action-icon"], ["src", "assets/images/trash-bin.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removePerson() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null)], function (_ck, _v) { var currVal_0 = "assets/images/trash-bin.svg"; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_RenterWizardHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-delete-person-confirmation", [], null, [[null, "delete"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.afterDeleted(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DeletePersonConfirmationComponent_0, i4.RenderType_DeletePersonConfirmationComponent)), i1.ɵdid(1, 49152, null, 0, i5.DeletePersonConfirmationComponent, [], { profile: [0, "profile"], person: [1, "person"] }, { delete: "delete", cancel: "cancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile; var currVal_1 = _co.person; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RenterWizardHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { deleteModal: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "renter-wizard-header__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "container renter-wizard-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "svg-icon", [["class", "action-icon visible-until-desktop"], ["src", "assets/images/chevron-left.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(4, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "person-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "person-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "svg-icon", [["src", "assets/images/person.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(8, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RenterWizardHeaderComponent_1)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["deleteModal", 2]], null, 0, null, View_RenterWizardHeaderComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "assets/images/chevron-left.svg"; var currVal_1 = true; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = "assets/images/person.svg"; var currVal_3 = true; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_5 = !_co.readonly; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.name; _ck(_v, 10, 0, currVal_4); }); }
export function View_RenterWizardHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-renter-wizard-header", [], null, null, null, View_RenterWizardHeaderComponent_0, RenderType_RenterWizardHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i7.RenterWizardHeaderComponent, [i8.TranslateService, i9.NgbModal, i10.ProfileWizardStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RenterWizardHeaderComponentNgFactory = i1.ɵccf("ra-renter-wizard-header", i7.RenterWizardHeaderComponent, View_RenterWizardHeaderComponent_Host_0, { person: "person" }, { beforeClose: "beforeClose" }, []);
export { RenterWizardHeaderComponentNgFactory as RenterWizardHeaderComponentNgFactory };
