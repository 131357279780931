import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  HostListener} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'ra-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CounterComponent),
      multi: true
    }
  ]
})
export class CounterComponent implements OnInit, ControlValueAccessor {

  @Input() count = 0;
  @Input() min = 0;
  @Input() max =  10;
  @Input() labelKey: string;
  @Input() tooltipText: string;
  @Input() asTitle = false;
  @Input() subText: string;

  @Output() countChanged = new EventEmitter<number>();

  onChangeCallback: any;
  onTouchedCallback: any;

  disabled = false;

  constructor() { }

  ngOnInit() {
    if (this.count < this.min) {
      this.count = this.min;
    }

    if (this.count > this.max) {
      this.count = this.max;
    }
  }

  minus() {
    if (this.count - 1 >= this.min) {
      this.count--;
      this.countChanged.emit(this.count);
      this.triggerOnChangeCallack(this.count);
    }
  }

  plus() {
    if (this.count + 1 <= this.max) {
      this.count++;
      this.countChanged.emit(this.count);
      this.triggerOnChangeCallack(this.count);
    }
  }

  get limit(): boolean {
    return this.limitMin || this.limitMax;
  }

  get limitMin(): boolean {
    return this.count === this.min;
  }

  get limitMax(): boolean {
    return this.count === this.max;
  }

  triggerOnChangeCallack(count: number) {
    if (this.onChangeCallback) {
      this.onChangeCallback(count);
    }
  }

  @HostListener('blur')
  onHostBlur() {
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
  }

  writeValue(val: any): void {
    this.count = !val ? this.min : val;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
