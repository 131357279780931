import { CandidacyResolver } from '../core/resolvers/candidacy.resolver';
import { ResidentsComponent } from '../profile/general-wizard/residents/residents.component';
import { WhenComponent } from '../profile/general-wizard/when/when.component';
import { OptionsComponent } from '../profile/general-wizard/options/options.component';
import { SummaryComponent } from '../profile/summary/summary.component';
import { DetailComponent } from './detail/detail.component';
import { OverviewComponent } from './overview/overview.component';
import { AuthGuardService } from '../core/services/auth-guard.service';
import { PersonalInfoComponent } from '../profile/renter-wizard/personal-info/personal-info.component';
import { ContactInfoComponent } from '../profile/renter-wizard/contact-info/contact-info.component';
import { IncomeComponent } from '../profile/renter-wizard/income/income.component';
import { RentingInfoComponent } from '../profile/renter-wizard/renting-info/renting-info.component';
import { OwnerInfoComponent } from '../profile/renter-wizard/owner-info/owner-info.component';
import { OrganizationAddressComponent } from '../profile/renter-wizard/organization-address/organization-address.component';
import { OrganizationContactInfoComponent } from '../profile/renter-wizard/organization-contact-info/organization-contact-info.component';
import { CreateCandidacyComponent } from './create/create.component';
import { IncomeAttachmentsComponent } from '../profile/attachments-wizard/income-attachments/income-attachments.component';
import { OtherIncomeAttachmentsComponent } from '../profile/attachments-wizard/other-income-attachments/other-income-attachments.component';
// tslint:disable-next-line: max-line-length
import { IdentityRentAttachmentsComponent } from '../profile/attachments-wizard/identity-rent-attachments/identity-rent-attachments.component';
var ɵ0 = { completedStep: 'general' }, ɵ1 = { completedStep: 'renter' }, ɵ2 = { completedStep: 'attachments' }, ɵ3 = { hideErrors: true };
export var routes = [
    {
        path: 'kandidaturen',
        component: OverviewComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'kandidaturen/:candidacyId',
        component: DetailComponent,
        resolve: { candidacy: CandidacyResolver },
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'algemeen',
                children: [
                    {
                        path: 'bewoners',
                        component: ResidentsComponent
                    },
                    {
                        path: 'wanneer',
                        component: WhenComponent
                    },
                    {
                        path: 'opties',
                        component: OptionsComponent
                    },
                    {
                        path: 'samenvatting',
                        component: SummaryComponent,
                        data: ɵ0
                    }
                ]
            },
            {
                path: 'persoon/:personId',
                children: [
                    {
                        path: 'persoonlijke-gegevens',
                        component: PersonalInfoComponent
                    },
                    {
                        path: 'contactgegevens',
                        component: ContactInfoComponent
                    },
                    {
                        path: 'organisatie-adres',
                        component: OrganizationAddressComponent
                    },
                    {
                        path: 'organisatie-contactgegevens',
                        component: OrganizationContactInfoComponent
                    },
                    {
                        path: 'inkomsten',
                        component: IncomeComponent
                    },
                    {
                        path: 'huidige-huur',
                        component: RentingInfoComponent
                    },
                    {
                        path: 'huidige-verhuurder',
                        component: OwnerInfoComponent
                    },
                    {
                        path: 'samenvatting',
                        component: SummaryComponent,
                        data: ɵ1
                    },
                    {
                        path: 'bijlagen',
                        children: [
                            {
                                path: 'inkomsten',
                                component: IncomeAttachmentsComponent
                            },
                            {
                                path: 'andere-inkomsten',
                                component: OtherIncomeAttachmentsComponent
                            },
                            {
                                path: 'identiteit-huur',
                                component: IdentityRentAttachmentsComponent
                            },
                            {
                                path: 'samenvatting',
                                component: SummaryComponent,
                                data: ɵ2
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        path: 'kandidaat-stellen/:brokerOrganizationNumber/:realEstateObjectId',
        component: CreateCandidacyComponent,
        canActivate: [AuthGuardService],
        data: ɵ3
    }
];
export { ɵ0, ɵ1, ɵ2, ɵ3 };
