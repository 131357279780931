<ra-modal-header (cancel)="cancel.next()">
  <ng-template>
    <div class="header">{{ 'Candidacies.Attention' | translate }}</div>
  </ng-template>
</ra-modal-header>

<form class="content">
  <div class="text">
    {{ 'Candidacies.SubmitConfirmationText' | translate }}
  </div>

  <ra-candidacy-card
    [candidacy]="candidacy"
    [showArrowRight]="false"
  ></ra-candidacy-card>

  <div class="remarks-title">{{ 'Candidacies.Remarks' | translate }}</div>
  <textarea
    name="remarks"
    [(ngModel)]="remarks"
    [placeholder]="'Candidacies.RemarksPlaceholder' | translate"
  ></textarea>
  <ra-toggle-switch name="visitConfirmed" [(ngModel)]='visitConfirmed'>
    {{ 'Candidacies.VisitConfirmationToggle' | translate }}
  </ra-toggle-switch>  
  <ra-toggle-switch name="dataConfirmed" ngModel ra-required>
    {{ 'Candidacies.SubmitConfirmationToggle' | translate }}
  </ra-toggle-switch>

  <button class="primary" [disabled]="isLoading" (click)="submit()">
    {{ 'Candidacies.Submit' | translate }}
  </button>
</form>
