<div class="sidebar-header"></div>
<ra-realestate-item [property]="property"></ra-realestate-item>

<div class="sidebar-intro">
  <h2>{{ 'Owner.Overview' | translate}}</h2>
  <p [innerHTML]="'Owner.OverviewInfo' | translate"></p>
</div>

<div class="sidebar-alert-message">
  <h2>{{ 'Owner.Expiry' | translate}}: {{ timeLeft$ | async }}</h2>
  <button (click)="showDialog()" class="primary">{{ 'Owner.Submit' | translate}}</button>
</div>

<p-dialog [(visible)]="display" [modal]="true" [draggable]="false" styleClass="confirm-dialog">
  <p-header>
    {{ 'Owner.ConfirmPreference' | translate}}
  </p-header>
  <div class="ui-dialog-intro">
    {{ 'Owner.ConfirmPreferenceDescription' | translate}}
  </div>
  <form (ngSubmit)="onSubmitClick()" #submitForm="ngForm" id="ConfirmPreference">
    <ra-realestate-item [property]="property"></ra-realestate-item>
    <div class="form-control-container m-t-25 m-b-25">
      <label for="executedComment" class="m-b-10"> {{ 'Owner.Comment' | translate }} </label>
      <div class="form-group">
        <textarea type="text" name="executedComment" [(ngModel)]="ownerSubmitPreferenceDto.executedComment" placeholder="{{ 'Owner.CommentPlaceholder' | translate }}">
        </textarea>
      </div>
    </div>

    <div class="form-control-container">
      <div class="checkbox">
        <div class="checkbox-selector checkbox-circle">
          <p-checkbox
            name="hasApproved"
            [(ngModel)]="ownerSubmitPreferenceDto.hasApproved"
            [binary]="true">
          </p-checkbox>
        </div>
        <label for="hasApproved">{{ 'Owner.Approve' | translate }}</label>
      </div>
    </div>
  </form>
  <p-footer>
    <div class="ui-dialog-actions">
      <button
        type="submit"
        class="primary wide"
        form="ConfirmPreference"
        [disabled]="!submitForm.form.valid">
      {{ 'Owner.Confirm' | translate }}
      </button>
    </div>
  </p-footer>
</p-dialog>
