import { shareReplay } from 'rxjs/operators';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs';
import { OnInit } from '@angular/core';
var Styles = /** @class */ (function () {
    function Styles() {
    }
    return Styles;
}());
var Button = /** @class */ (function () {
    function Button() {
    }
    return Button;
}());
var Body = /** @class */ (function () {
    function Body() {
    }
    return Body;
}());
var WidgetComponent = /** @class */ (function () {
    function WidgetComponent(api, route) {
        this.api = api;
        this.route = route;
        this.widgetParams = {};
        this.styles = null;
        this.bodyTag = document.getElementsByTagName('body')[0];
        this.profile = {};
        this.step = 1;
        this.minStep = 1;
        this.maxStep = 4;
        this.validationStep = [];
        this.displayProfile = false;
        this.showButton = true;
        this.showErrorLogs = false;
        this.showProfileSim = false;
        this.profileButtonClass = 'fas fa-chevron-double-down';
        this.number = [
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 }
        ];
        this.localStorageId = 'e3b9daee-b790-11ea-b3de-0242ac130004';
        // validation results
        this.transformStyle = 'rotate(90deg)';
        this.rentPercentageColor = null;
        this.step1Valid = null;
        this.step2Valid = null;
        this.step3Valid = null;
        this.initialShow = true;
        this.errorLog = null;
    }
    WidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        var paramStye = null;
        this.route.queryParamMap.pipe(take(1)).subscribe(function (params) {
            _this.widgetParams.enterpriseNumber = params.get('EnterpriseNumber');
            _this.widgetParams.realEstateIdentifier = params.get('RealEstateIdentifier');
            if (params.get('additionRealEstateIdentifier') != null) {
                if (_this.widgetParams.additionalWidgetParams == null) {
                    _this.widgetParams.additionalWidgetParams = {};
                }
                _this.widgetParams.additionalWidgetParams.realEstateIdentifiers = JSON.parse(params.get('additionRealEstateIdentifier'));
            }
            if (params.get('showErrorLogs') != null) {
                _this.showErrorLogs = params.get('showErrorLogs').toLowerCase() === 'true';
            }
            if (params.get('displayProfile') != null) {
                _this.displayProfile = params.get('displayProfile').toLowerCase() === 'true';
            }
            if (params.get('showButton') != null) {
                _this.showButton = params.get('showButton').toLowerCase() === 'true';
            }
            _this.widgetParams.url = params.get('Url');
            if (params.get('Style') != null && params.get('Style') !== 'null' && params.get('Style') !== 'undefined') {
                paramStye = JSON.parse(params.get('Style'));
            }
            _this.isValidInput = _this.evaluateValidInput(_this.widgetParams);
            if (_this.isValidInput === true) {
                _this.api.saveWidgetProperty(_this.widgetParams).subscribe(function (x) {
                    _this.property = x;
                    _this.profile = _this.getStorage();
                    if (_this.profile != null) {
                        _this.getProfileScore();
                    }
                    return x;
                }, function (error) {
                    var errors = JSON.parse(error.response).errors;
                    errors.forEach(function (element) {
                        _this.errorLog = element.message;
                    });
                });
            }
            return throwError('input was invalid');
        });
        this.styles = this.setStyles(this.styles, paramStye);
        this.bodyTag.style.background = this.styles.body.bkgrnd;
    };
    WidgetComponent.prototype.valueChange = function (add, property) {
        if (add) {
            if (property === 'adults') {
                if (this.profile.numberOfAdults >= 15) {
                    return;
                }
                this.profile.numberOfAdults++;
            }
            if (property === 'children') {
                if (this.profile.numberOfChildren >= 15) {
                    return;
                }
                this.profile.numberOfChildren++;
            }
            if (property === 'cars') {
                if (this.profile.numberOfCars >= 15) {
                    return;
                }
                this.profile.numberOfCars++;
            }
        }
        else {
            if (property === 'adults') {
                if (this.profile.numberOfAdults <= 0) {
                    return;
                }
                this.profile.numberOfAdults--;
            }
            if (property === 'children') {
                if (this.profile.numberOfChildren <= 0) {
                    return;
                }
                this.profile.numberOfChildren--;
            }
            if (property === 'cars') {
                if (this.profile.numberOfCars <= 0) {
                    return;
                }
                this.profile.numberOfCars--;
            }
        }
        this.getProfileScore();
    };
    WidgetComponent.prototype.validateInput = function (score) {
        if (score.residentialLoadPercentage != null) {
            var value = 90;
            if (score.residentialLoadPercentage <= 33.33) {
                var t = ((score.residentialLoadPercentage / 33.33) * 100);
                value = t * 0.6;
            }
            if (score.residentialLoadPercentage > 33.33 && score.residentialLoadPercentage <= 40) {
                var t = ((score.residentialLoadPercentage - 33.33));
                value = 60 + ((t / 6.67) * 60);
            }
            if (score.residentialLoadPercentage > 40) {
                var t = ((score.residentialLoadPercentage - 40));
                if (t > 60) {
                    t = 60;
                }
                value = 120 + t;
            }
            this.transformStyle = 'rotate(' + value + 'deg)';
        }
        this.step1Valid = this.maxCapacityResult(score);
        if (score.residentialLoadPercentage <= 33.33) {
            this.step2Valid = true;
            this.rentPercentageColor = 'color-green';
        }
        else if (score.residentialLoadPercentage > 33.33 && score.residentialLoadPercentage <= 40) {
            this.step2Valid = true;
            this.rentPercentageColor = 'color-yellow';
        }
        else {
            this.step2Valid = false;
            this.rentPercentageColor = 'color-red';
        }
        if (this.score != null && this.profile != null && this.profile.totalMonthlyIncome != null) {
            var shortage = ((this.score.budgetNorm + this.score.rentalPrice) - this.profile.totalMonthlyIncome);
            if (shortage > 0) {
                this.step3Valid = false;
            }
            else {
                this.step3Valid = true;
            }
            if (this.profile != null && this.profile.totalMonthlyIncome > 0 && this.initialShow) {
                this.step = this.maxStep;
            }
        }
        this.initialShow = false;
    };
    WidgetComponent.prototype.setStorage = function () {
        try {
            localStorage.setItem(this.localStorageId, JSON.stringify(this.profile));
        }
        catch (e) {
            console.error(e);
        }
    };
    WidgetComponent.prototype.getStorage = function () {
        var value;
        try {
            value = localStorage.getItem(this.localStorageId);
        }
        catch (e) {
            console.error(e);
        }
        if (value == null) {
            var result = {};
            result.numberOfAdults = 1;
            result.numberOfChildren = 0;
            result.numberOfCars = 0;
            result.totalMonthlyIncome = 0;
            return result;
        }
        return JSON.parse(value);
    };
    WidgetComponent.prototype.isValidated = function (value) {
        var result = this.validationStep.filter(function (x) { return x === value; })[0];
        return result != null;
    };
    WidgetComponent.prototype.addToValidation = function (value) {
        this.getProfileScore();
        this.validationStep.push(value);
    };
    WidgetComponent.prototype.removeFromValidation = function (value) {
        this.validationStep = this.validationStep.filter(function (x) { return x !== value; });
        if (this.validationStep == null) {
            this.validationStep = [];
        }
    };
    WidgetComponent.prototype.showProfile = function () {
        this.displayProfile = !this.displayProfile;
        if (this.displayProfile) {
            this.profileButtonClass = 'fas fa-chevron-double-up';
        }
        else {
            this.profileButtonClass = 'fas fa-chevron-double-down';
        }
    };
    WidgetComponent.prototype.getProgressBarClass = function (value) {
        var result;
        if (value === this.step) {
            result = 'active';
        }
        if (value === 1) {
            if (this.step1Valid === false) {
                result += ' red';
            }
            if (this.step1Valid === true) {
                result += ' green';
            }
        }
        if (this.step >= 2 && value === 2 && this.score != null) {
            if (this.score.residentialLoadPercentage <= 33.33) {
                result += ' green';
            }
            else if (this.score.residentialLoadPercentage > 33.33 && this.score.residentialLoadPercentage <= 40) {
                result += ' yellow';
            }
            else {
                result += ' red';
            }
        }
        if (this.step >= 3 && value === 3) {
            if (this.step3Valid === true) {
                result += ' green';
            }
            if (this.step3Valid === false) {
                result += ' red';
            }
        }
        if (this.step >= 4 && value === 4) {
            if (this.step1Valid != null && this.step2Valid != null && this.step3Valid != null) {
                if (this.step1Valid === true && this.step2Valid === true && this.step3Valid === true) {
                    result += ' green';
                }
                else {
                    result += ' red';
                }
            }
        }
        return result;
    };
    Object.defineProperty(WidgetComponent.prototype, "getResidentialLoadPercentageClassColor", {
        get: function () {
            if (this.score.residentialLoadPercentage <= 33.33) {
                return 'color-green';
            }
            else if (this.score.residentialLoadPercentage > 33.33 && this.score.residentialLoadPercentage <= 40) {
                return 'color-yellow';
            }
            else {
                return 'color-red';
            }
        },
        enumerable: true,
        configurable: true
    });
    WidgetComponent.prototype.getProfileScore = function () {
        var _this = this;
        this.profile.propertyUniqueId = this.property.realEstateObjectId;
        if (this.profile.totalMonthlyIncome < 0) {
            this.profile.totalMonthlyIncome = 0;
        }
        this.score$ = this.api.getWidgetScore(this.profile).pipe(shareReplay());
        this.score$.toPromise()
            .then(function (x) {
            _this.score = x;
            _this.validateInput(x);
        });
        this.setStorage();
    };
    WidgetComponent.prototype.next = function () {
        this.step = this.step + 1;
    };
    WidgetComponent.prototype.previous = function () {
        if (this.isValidated(this.step)) {
            this.removeFromValidation(this.step);
        }
        else {
            this.step = this.step - 1;
        }
    };
    WidgetComponent.prototype.navigateToStep = function (value) {
        if (value >= this.minStep && value <= this.maxStep) {
            this.step = value;
        }
    };
    WidgetComponent.prototype.setStyles = function (toUse, param) {
        var result = toUse;
        if (result == null) {
            result = new Styles();
        }
        if (result.button == null) {
            result.button = new Button();
            result.button.align = 'center';
            result.button.font = 'arial';
            result.button.bkgrnd = '#49a4f3';
            result.button.bordr = '5px';
            result.button.bord = '1px solid #49a4f3';
            result.button.hbord = '1px solid #275c8b';
            result.button.color = 'white';
            result.button.deco = 'none';
            result.button.pad = '15px';
            result.button.hbkgrnd = '#275c8b';
            result.button.disp = 'block';
            result.button.textTransform = 'inherit';
            result.button.width = 'auto';
            result.button.lineHeight = 'normal';
            result.button.fontSize = 'inherit';
            result.button.fontWeight = 'normal';
            result.button.boxShadow = 'none';
            result.button.iconColor = '#fff';
            result.button.iconShow = 'inline';
        }
        if (result.body == null) {
            result.body = new Body();
            result.body.bkgrnd = 'white';
        }
        if (param != null && param.button != null) {
            if (param.button.align != null) {
                result.button.align = param.button.align;
            }
            if (param.button.font != null) {
                result.button.font = param.button.font;
            }
            if (param.button.bkgrnd != null) {
                result.button.bkgrnd = '#' + param.button.bkgrnd;
            }
            if (param.button.bordr != null) {
                result.button.bordr = param.button.bordr;
            }
            if (param.button.bord != null) {
                result.button.bord = param.button.bord;
            }
            if (param.button.hbord != null) {
                result.button.hbord = param.button.hbord;
            }
            if (param.button.color != null) {
                result.button.color = '#' + param.button.color;
            }
            if (param.button.deco != null) {
                result.button.deco = param.button.deco;
            }
            if (param.button.pad != null) {
                result.button.pad = param.button.pad;
            }
            if (param.button.hbkgrnd != null) {
                result.button.hbkgrnd = '#' + param.button.hbkgrnd;
            }
            if (param.button.disp != null) {
                result.button.disp = param.button.disp;
            }
            if (param.button.textTransform != null) {
                result.button.textTransform = param.button.textTransform;
            }
            if (param.button.width != null) {
                result.button.width = param.button.width;
            }
            if (param.button.lineHeight != null) {
                result.button.lineHeight = param.button.lineHeight;
            }
            if (param.button.fontSize != null) {
                result.button.fontSize = param.button.fontSize;
            }
            if (param.button.fontWeight != null) {
                result.button.fontWeight = param.button.fontWeight;
            }
            if (param.button.boxShadow != null) {
                result.button.boxShadow = param.button.boxShadow;
            }
            if (param.button.iconColor != null) {
                result.button.iconColor = param.button.iconColor;
            }
            if (param.button.iconShow != null) {
                result.button.iconShow = param.button.iconShow;
            }
            if (param != null && param.body != null) {
                if (param.body.bkgrnd != null) {
                    result.body.bkgrnd = '#' + param.body.bkgrnd;
                }
            }
        }
        return result;
    };
    WidgetComponent.prototype.mouseenter = function (id) {
        document.getElementById(id).style.background = this.styles.button.hbkgrnd;
        document.getElementById(id).style.border = this.styles.button.hbord;
    };
    WidgetComponent.prototype.maxCapacityResult = function (score) {
        var adults = 0;
        var children = 0;
        if (this.profile != null) {
            if (this.profile.numberOfAdults != null) {
                adults = this.profile.numberOfAdults;
            }
            if (this.profile.numberOfChildren != null) {
                children = this.profile.numberOfChildren;
            }
        }
        return score.maxTenantsForProperty >= (adults + children);
    };
    WidgetComponent.prototype.mouseleave = function (id) {
        document.getElementById(id).style.background = this.styles.button.bkgrnd;
        document.getElementById(id).style.border = this.styles.button.bord;
    };
    WidgetComponent.prototype.evaluateValidInput = function (widgetParams) {
        return !this.isEmpty(widgetParams.enterpriseNumber)
            && !this.isEmpty(widgetParams.realEstateIdentifier)
            && !this.isEmpty(widgetParams.url);
    };
    WidgetComponent.prototype.isEmpty = function (value) {
        return (!value || 0 === value.trim().length);
    };
    return WidgetComponent;
}());
export { WidgetComponent };
