import { TemplateRef, EventEmitter } from '@angular/core';
var ChoiceItemComponent = /** @class */ (function () {
    function ChoiceItemComponent() {
        this.horizontal = false;
        this.isFirst = false;
        this.isLast = false;
        this.selected = false;
        this.disabled = false;
        this.parentDisabled = false;
        this.onClick = new EventEmitter();
    }
    Object.defineProperty(ChoiceItemComponent.prototype, "controlDisabled", {
        get: function () {
            return this.parentDisabled || this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    ChoiceItemComponent.prototype.onClickHost = function () {
        if (!this.controlDisabled) {
            this.onClick.next();
        }
    };
    return ChoiceItemComponent;
}());
export { ChoiceItemComponent };
