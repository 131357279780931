import { enableProdMode } from '@angular/core';
import { DynamicEnvironment } from './environments/dynamic-environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// options need to be loaded before the DI container is constructed,
// because they are sometimes used in resolution and constructors
fetch('assets/app-config.json')
    .then(function (r) { return r.json(); })
    .then(function (r) {
    var environment = new DynamicEnvironment();
    if (typeof r.production === 'boolean') {
        environment.production = r.production;
    }
    if (typeof r.api === 'object') {
        Object.assign(environment.api, r.api);
    }
    if (typeof r.immoConnect === 'object') {
        Object.assign(environment.immoConnect, r.immoConnect);
    }
    return environment;
})
    .then(function (environment) {
    if (environment.production) {
        enableProdMode();
    }
    __NgCli_bootstrap_2.platformBrowser([
        { provide: DynamicEnvironment, useValue: environment }
    ])
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
        providers: []
    })
        .catch(function (err) { return console.error(err); });
});
