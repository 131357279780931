import { Component, OnInit } from '@angular/core';
import { makeEmptyPerson} from 'src/app/core/models';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { PersonDetailsDto, ProfileDetailsDto } from '../../shared/api/rentassistant-api.generated';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { ProfileWizardStateService, ProfileWizardState } from '../../core/services/profilewizard-state.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ra-renters-step-base',
  template: ''
})
export class StepBaseComponent extends AutoUnsubscribeComponent
  implements OnInit {
  personId = 0;
  person: PersonDetailsDto = makeEmptyPerson();
  readonly: boolean;

  state: ProfileWizardState;
  profile: ProfileDetailsDto;

  constructor(protected localizedRouter: LocalizedRouter,
              protected route: ActivatedRoute,
              protected stateService: ProfileWizardStateService) {
    super();
  }

  init() {}

  ngOnInit() {

    this.subscriptions.push(
      combineLatest([this.stateService.state$, this.route.params])
          .pipe(
            map( ([state, params]) => ({state, params}))
          )
          .subscribe(({state, params})  => {
            this.state = state;
            this.profile = state.profile;
            this.readonly = state.profile.isReadonly;
            this.personId = parseInt(params.personId, 10);

            const person = this.profile.personDetails.find(
              x => x.id === this.personId
            );
            if (!!person) {
              this.person = person;
              this.onPersonChanged();
            }
          })
    );

    this.init();
  }

  onPersonChanged() {}
}
