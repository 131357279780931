import { Component, OnInit } from '@angular/core';
import { AttachmentsWizardPageComponent } from '../attachments-wizard-page.component';

@Component({
  selector: 'ra-other-income-attachments',
  templateUrl: './other-income-attachments.component.html',
  styleUrls: ['./other-income-attachments.component.scss']
})
export class OtherIncomeAttachmentsComponent extends AttachmentsWizardPageComponent implements OnInit {

  previous() {
    this.localizedRouter.navigate(['../inkomsten'], { relativeTo: this.route });
  }

  next() {
    this.localizedRouter.navigate(['../identiteit-huur'], { relativeTo: this.route });
  }
}
