import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { makeEmptyCandidacy } from 'src/app/core/models';
import { faPhone, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';

@Component({
  selector: 'ra-contact-broker',
  templateUrl: './contact-broker.component.html',
  styleUrls: ['./contact-broker.component.scss']
})
export class ContactBrokerComponent extends AutoUnsubscribeComponent
  implements OnInit {
  faPhone = faPhone;
  faEnvelopeOpen = faEnvelopeOpen;

  phone: string;
  email: string;

  @Input()
  candidacy = makeEmptyCandidacy();

  @Output()
  cancel = new EventEmitter<void>();

  ngOnInit() {
    if (this.candidacy && this.candidacy.broker) {
      this.phone = this.candidacy.broker.phone;
      this.email = this.candidacy.broker.email;
    }
  }
}
