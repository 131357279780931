import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe.component';
import { filter } from 'rxjs/operators';
import { ErrorType } from '../../core/services/error.service';
import { Status } from 'src/app/shared/api/rentassistant-api.generated';
import { resetBrokerLogoUrl } from '../../core/models';
var CreateCandidacyComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CreateCandidacyComponent, _super);
    function CreateCandidacyComponent(route, localizedRouter, api, errorService, translateService) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.localizedRouter = localizedRouter;
        _this.api = api;
        _this.errorService = errorService;
        _this.translateService = translateService;
        _this.errors = [];
        return _this;
    }
    CreateCandidacyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.errors = [];
        this.brokerNumber = this.route.snapshot.params.brokerOrganizationNumber;
        this.realEstateObjectId = this.route.snapshot.params.realEstateObjectId;
        this.subscriptions.push(this.api.getProperty(this.realEstateObjectId)
            .subscribe(function (property) {
            if (!_this.candidacy) {
                _this.candidacyLight = { property: property };
            }
        }));
        this.subscriptions.push(this.api.createCandidacy(this.brokerNumber, this.realEstateObjectId, false)
            .subscribe(function (candidacy) {
            _this.candidacy = candidacy;
            _this.getAlreadyExistsMessage();
        }));
        this.subscriptions.push(this.errorService
            .errors$
            .pipe(filter(function (error) { return error.errorType === ErrorType.Validation; }))
            .subscribe(function (error) { return _this.errors.unshift(error); }));
        this.subscriptions.push(this.errorService
            .errors$
            .pipe(filter(function (error) { return error.errorType === ErrorType.Conflict; }))
            .subscribe(function (error) {
            _this.candidacyExistsDrafted = false;
            _this.candidacy = resetBrokerLogoUrl(error.response);
            _this.getAlreadyExistsMessage();
            _this.candidacyExists = true;
            var status = error.response.status;
            if (status === Status.Draft) {
                _this.candidacyExistsDrafted = true;
            }
        }));
    };
    Object.defineProperty(CreateCandidacyComponent.prototype, "animate", {
        get: function () {
            return !this.errors || this.errors.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    CreateCandidacyComponent.prototype.showCandidacyDetail = function (candidacyId) {
        if (!this.candidacyExists) {
            this.createCandidacy();
            return;
        }
        this.localizedRouter.navigate(['/app/kandidaturen', candidacyId]);
    };
    CreateCandidacyComponent.prototype.createCandidacy = function () {
        var _this = this;
        this.subscriptions.push(this.api
            .createCandidacy(this.brokerNumber, this.realEstateObjectId, true)
            .subscribe(function (candidacy) {
            _this.localizedRouter.navigate(['/app/kandidaturen', candidacy.uniqueId]);
        }));
    };
    CreateCandidacyComponent.prototype.getAlreadyExistsMessage = function () {
        var result = this.translateService.instant('CreateCandidacy.CandidacyExistsMessage');
        if (this.candidacyExistsDrafted) {
            result = this.translateService.instant('CreateCandidacy.CandidacyExistsDraftMessage');
        }
        var candidacyRoute = 'kandidaturen';
        /*if (this.translateService.currentLang === 'en') {
            candidacyRoute = this.translateService.instant('Route_kandidaturen') as string;
        }*/
        result = result.replace(/{url}/, this.translateService.currentLang + '/app/' + candidacyRoute + '/' + this.candidacy.uniqueId);
        this.candidacyExistsMessage = result;
        return result;
    };
    return CreateCandidacyComponent;
}(AutoUnsubscribeComponent));
export { CreateCandidacyComponent };
