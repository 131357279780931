import { RealestateItemComponent } from './../ownersportal/components/realestate-item/realestate-item.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule, LocalizeRouterService } from 'ngx-localize-router';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import {
  API_BASE_URL_IMMOCONNECT,
  ImmoConnectClient
} from './api/immoconnect-api.generated';
import {
  API_BASE_URL_RENTASSISTANT,
  RentAssistantClient
} from './api/rentassistant-api.generated';

import { AuthorizationInterceptor } from '../core/interceptors/authorization.interceptor';
import { SessionInterceptor } from '../core/interceptors/session.interceptor';
import { CandidacyCardComponent } from './components/candidacy-card/candidacy-card.component';
import { CandidacyStatusBadgeComponent } from './components/candidacy-status-badge/candidacy-status-badge.component';
import { SectionListItemComponent } from './components/section-list-item/section-list-item.component';
import { CheckableListItemComponent } from './components/checkable-list-item/checkable-list-item.component';
import { PersonListItemComponent } from './components/person-list-item/person-list-item.component';
import { ExpandablePersonListItemComponent } from './components/expandable-person-list-item/expandable-person-list-item.component';

import { WizardHeaderComponent } from './components/wizard-header/wizard-header.component';
import { WizardFooterComponent } from './components/wizard-footer/wizard-footer.component';
import { CounterComponent } from './components/counter/counter.component';
import { ChoiceComponent } from './components/choice/choice.component';
import { ChoiceItemComponent } from './components/choice/choice-item/choice-item.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import { HouseHeaderBackgroundComponent } from './components/house-header-background/house-header-background.component';
import { DynamicEnvironment } from '../../environments/dynamic-environment';
import { NgbModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { LoadingInterceptor } from '../core/interceptors/loading.interceptor';
import { HttpErrorInterceptor } from '../core/interceptors/http-error.interceptor';
import { CheckboxRequiredValidatorDirective } from './components/toggle-switch/toggle-switch-required.validator';
import { AutoUnsubscribeComponent } from './auto-unsubscribe.component';
import { LocalizedRouter } from '../core/services/localized-router.service';
import { ErrorComponent } from './components/error/error.component';
import { ErrorService } from '../core/services/error.service';
import { MenuComponent } from './components/menu/menu.component';
import { HouseHeaderComponent } from './components/house-header/house-header.component';
import { MessageComponent } from './components/message/message.component';
import { CandidacyCardLoadingComponent } from './components/candidacy-card-loading/candidacy-card-loading.component';
import { ShareModalComponent } from './components/share-modal/share-modal.component';
import { HousesBackgroundComponent } from './components/houses-background/houses-background.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { DatepickerModalComponent } from './components/datepicker-modal/datepicker-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxCurrencyModule } from 'ngx-currency';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const components = [
  CandidacyCardComponent,
  CandidacyStatusBadgeComponent,
  WizardHeaderComponent,
  WizardFooterComponent,
  SectionListItemComponent,
  CheckableListItemComponent,
  PersonListItemComponent,
  ExpandablePersonListItemComponent,
  CounterComponent,
  ChoiceComponent,
  ChoiceItemComponent,
  ToggleSwitchComponent,
  CheckboxRequiredValidatorDirective,
  InfoPanelComponent,
  HouseHeaderBackgroundComponent,
  ModalHeaderComponent,
  AutoUnsubscribeComponent,
  ErrorComponent,
  MenuComponent,
  HouseHeaderComponent,
  MessageComponent,
  CandidacyCardLoadingComponent,
  ShareModalComponent,
  HousesBackgroundComponent,
  LanguageSelectorComponent,
  DatepickerModalComponent,
  FooterComponent,
  RealestateItemComponent
];

export function getCurrentLang(localizeRouterService: LocalizeRouterService) {
  return localizeRouterService.parser.currentLang;
}

export function getBaseUrlImmoconnect(e: DynamicEnvironment) {
  return e.api.immoConnectUrl;
}

export function getBaseUrlRentassistant(e: DynamicEnvironment) {
  return e.api.rentAssistantUrl;
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
    LocalizeRouterModule,
    FormsModule,
    FontAwesomeModule,
    HttpClientModule,
    TooltipModule,
    AngularSvgIconModule,
    NgbModule,
    NgxCurrencyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(options)
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LocalizeRouterService],
      useFactory: getCurrentLang
    },
    {
      provide: API_BASE_URL_IMMOCONNECT,
      deps: [DynamicEnvironment],
      useFactory: getBaseUrlImmoconnect
    },
    {
      provide: API_BASE_URL_RENTASSISTANT,
      deps: [DynamicEnvironment],
      useFactory: getBaseUrlRentassistant
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, deps: [LocalizedRouter, ErrorService] , multi: true },
    ImmoConnectClient,
    RentAssistantClient
  ],
  exports: [
    TranslateModule,
    FormsModule,
    NgxCurrencyModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    NgbModule,
    NgbDatepickerModule,
    NgxMaskModule,
    ...components
  ]
})
export class SharedModule {}
