import { ActivatedRoute } from '@angular/router';
import { PersonLegalType } from 'src/app/shared/api/rentassistant-api.generated';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getName } from 'src/app/core/models';
import { CheckableItemInfoProvider } from 'src/app/shared/components/checkable-list-item/checkable-list-item.component';
import { DurationType,
         PersonDetailsDto,
         RelocationType,
         ProfileDetailsDto,
         GeneralDetailsDto
} from '../../shared/api/rentassistant-api.generated';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { DatePipe } from '@angular/common';

type DescriptionPart<T> = [
  () => T | undefined,
  (value: T) => string | undefined
];

// helper method just to have type inference when creating parts
function part<T>(spec: DescriptionPart<T>): DescriptionPart<T> {
  return spec;
}

function makeCheckableListItemProvider(opts: {
  onClick: () => void;
  active: () => boolean;
  titleKey: string;
  alert: () => boolean;
  isComplete: () => boolean;
  descriptionParts: Array<DescriptionPart<any>>;
}): CheckableItemInfoProvider {
  return {
    click: opts.onClick,
    active: opts.active,
    isChecked: () => (opts.isComplete() ? 'checked' : opts.alert() ? 'error' : 'unchecked'),
    titleKey: () => opts.titleKey,
    subText: () => {
      return opts.descriptionParts
        .map(x => {
          const [valueGetter, textGetter] = x;
          const value = valueGetter();
          if (
            value === undefined ||
            value === null ||
            value.toString().trim() === '' ||
            (Array.isArray(value) && value.includes(undefined))
          ) {
            return undefined;
          }
          return textGetter(value);
        })
        .filter(x => !!x)
        .join(', ');
    }
  };
}

@Component({
  selector: 'ra-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() profile: ProfileDetailsDto;
  @Input() alert = false;
  @Input() baseUrl = '';
  @Output() addRenter = new EventEmitter();

  expandedPeople = new Set<number>();

  residentsProvider: CheckableItemInfoProvider;
  whenProvider: CheckableItemInfoProvider;
  extraOptionsProvider: CheckableItemInfoProvider;
  personalInfoProvider: (person: PersonDetailsDto) => CheckableItemInfoProvider;
  contactInformationInfoProvider: (person: PersonDetailsDto) => CheckableItemInfoProvider;
  incomeInformationProvider: (person: PersonDetailsDto) => CheckableItemInfoProvider;
  currentRentInformationProvider: (person: PersonDetailsDto) => CheckableItemInfoProvider;
  organizationInfoProvider: (person: PersonDetailsDto) => CheckableItemInfoProvider;
  organizationAddressInfoProvider: (person: PersonDetailsDto) => CheckableItemInfoProvider;
  organizationContactInfoProvider: (person: PersonDetailsDto) => CheckableItemInfoProvider;

  constructor(private localizedRouter: LocalizedRouter,
              private translation: TranslateService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.residentsProvider = this.createResidentsProvider();
    this.whenProvider = this.createWhenProvider();
    this.extraOptionsProvider = this.createExtraOptionsProvider();
    this.personalInfoProvider = this.createPersonalInfoProvider;
    this.contactInformationInfoProvider = this.createContactInformationInfoProvider;
    this.incomeInformationProvider = this.createIncomeInformationProvider;
    this.currentRentInformationProvider = this.createCurrentRentInformationProvider;
    this.organizationInfoProvider = this.createOrganizationInfoProvider;
    this.organizationAddressInfoProvider = this.createOrganizationAddressInfoProvider;
    this.organizationContactInfoProvider = this.createOrganizationContactInfoProvider;
  }

  createResidentsProvider() {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.Residents',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'algemeen',
          'bewoners'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
          this.baseUrl,
          'algemeen',
          'bewoners'
      ]),
      isComplete: () => this.profile.generalDetails &&
                        this.profile.generalDetails.generalDetailsMetaData.residentsComplete,
      descriptionParts: [
        part([
          () => this.profile.generalDetails.numberOfAdults,
          x => x + ' ' + this.translation.instant('Candidacies.Adults')
        ]),
        part([
          () => this.profile.generalDetails.numberOfChildren,
          x => x + ' ' + this.translation.instant('Candidacies.Children')
        ]),
        part([
          () => this.profile.generalDetails.numberOfPets,
          x => x + ' ' + this.translation.instant('Candidacies.Pets')
        ])
      ]
    });
  }

  createWhenProvider() {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.When',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'algemeen',
          'wanneer'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
        'algemeen',
        'wanneer'
      ]),
      isComplete: () => this.profile.generalDetails &&
                        this.profile.generalDetails.generalDetailsMetaData.relocationAndDurationComplete,
      descriptionParts: [
        part([
          () => this.profile.generalDetails.relocationType,
          x => {
            const movingDate = this.profile.generalDetails.expectedMovingDate;

            if (x === RelocationType.SpecificDate && movingDate) {
              const date = new Date(movingDate.year, movingDate.month - 1, movingDate.day);
              const formattedDate = new DatePipe(this.translation.currentLang).transform(date, 'MMMM y');
              return formattedDate;
            }

            return this.getRelocationTypeName(x);
          }
        ]),
        part([
          () => true,
          x => {
            const prefix = this.translation.instant('Candidacies.Duration');
            return `${prefix}: ${this.getDurationDescription(this.profile.generalDetails)}`;
          }
        ])
      ]
    });
  }

  createExtraOptionsProvider() {

    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.ExtraOptions',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'algemeen',
          'opties'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
        'algemeen',
        'opties'
      ]),
      isComplete: () => this.profile.generalDetails &&
                        this.profile.generalDetails.generalDetailsMetaData.extraOptionsComplete,
      descriptionParts: [
        part([
          () => this.profile.generalDetails.willTakeDomicile,
          x => {
            return x
              ? this.translation.instant('Candidacies.Domicile')
              : this.translation.instant('Candidacies.NoDomicile');
          }
        ]),
        part([() => this.profile.generalDetails,
                    details => {
                      return null;
                    }
      ]
    )]
    });
  }

  createPersonalInfoProvider(person: PersonDetailsDto) {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.PersonalInformation',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'persoon',
          person.id,
          'persoonlijke-gegevens'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
        'persoon',
        person.id,
        'persoonlijke-gegevens'
      ]),
      isComplete: () => person.personDetailsMetaData && person.personDetailsMetaData.personalInfoComplete,
      descriptionParts: [
        part([() => getName(person), x => x]),
        part([
          () => person.hasPartner,
          x => x ? this.translation.instant('Candidacies.HasPartner') : undefined
        ])
      ]
    });
  }

  createContactInformationInfoProvider(person: PersonDetailsDto) {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.ContactInformation',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'persoon',
          person.id,
          'contactgegevens'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
        'persoon',
        person.id,
        'contactgegevens'
      ]),
      isComplete: () => person.personDetailsMetaData && person.personDetailsMetaData.contactInfoComplete,
      descriptionParts: [
        part([() => person.email, x => x]),
        part([() => person.phone, x => x]),
        part([
          () => person.address,
          address => [address.street, address.houseNumber, address.zipCode, address.place].join(' ').trim()
        ])
      ]
    });
  }

  createIncomeInformationProvider(person: PersonDetailsDto) {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.IncomeInformation',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'persoon',
          person.id,
          'inkomsten'
        ]);
      },
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
        'persoon',
        person.id,
        'inkomsten'
      ]),
      alert: () => this.alert,
      isComplete: () => person.personDetailsMetaData && person.personDetailsMetaData.incomeInfoComplete,
      descriptionParts: [
        part([
          () => person.monthlyIncome,
          x => this.translation.instant('Candidacies.Paycheck') +
            ': €' + x.toFixed(2)
        ]),
        part([
          () => person.otherIncomes,
          x =>
            this.translation.instant('Candidacies.OtherIncome') +
            ': €' +
            x.reduce((total, otherIncome) => total + (otherIncome.income || 0), 0).toFixed(2)
        ])
      ]
    });
  }

  createCurrentRentInformationProvider(person: PersonDetailsDto) {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.CurrentRentInformation',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'persoon',
          person.id,
          'huidige-huur'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
        'persoon',
        person.id,
        'huidige-huur'
      ]),
      isComplete: () =>  person.personDetailsMetaData != null,
      descriptionParts: [
        part([
          () => person.landlordContactFilled,
          x =>
            x === false
              ? this.translation.instant('Candidacies.NotProvided')
              : undefined
        ]),
        part([
          () =>
            person.landlordName && person.landlordContactFilled
              ? person.landlordName
              : undefined,
          x => x
        ]),
        part([
          () =>
            person.landlordEmail && person.landlordContactFilled
              ? person.landlordEmail
              : undefined,
          x => x
        ]),
        part([
          () =>
            person.landlordPhone && person.landlordContactFilled
              ? person.landlordPhone
              : undefined,
          x => x
        ])
      ]
    });
  }

  createOrganizationInfoProvider(person: PersonDetailsDto) {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.OrganizationInfo',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'persoon',
          person.id,
          'persoonlijke-gegevens'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
        'persoon',
        person.id,
        'persoonlijke-gegevens'
      ]),
      isComplete: () => person.personDetailsMetaData && person.personDetailsMetaData.organizationInfoComplete,
      descriptionParts: [
        part([() => getName(person), name => `${name} (${person.organizationNumber})`])
      ]
    });
  }

  createOrganizationAddressInfoProvider(person: PersonDetailsDto) {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.OrganizationAddressInfo',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'persoon',
          person.id,
          'organisatie-adres'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
          'persoon',
          person.id,
          'organisatie-adres'
      ]),
      isComplete: () => person.personDetailsMetaData && person.personDetailsMetaData.organizationAddressComplete,
      descriptionParts: [
        part ([
          () => person.address,
          address => [address.street, address.houseNumber, address.zipCode, address.place].join(' ').trim()
        ])
      ]
    });
  }

  createOrganizationContactInfoProvider(person: PersonDetailsDto) {
    return makeCheckableListItemProvider({
      titleKey: 'Candidacies.OrganizationContactInfo',
      onClick: () => {
        this.localizedRouter.navigate([
          this.baseUrl,
          'persoon',
          person.id,
          'organisatie-contactgegevens'
        ]);
      },
      alert: () => this.alert,
      active: () => this.localizedRouter.isActive([
        this.baseUrl,
        'persoon',
        person.id,
        'organisatie-contactgegevens'
      ]),
      isComplete: () => person.personDetailsMetaData && person.personDetailsMetaData.organizationContactInfoComplete,
      descriptionParts: [
        part([() => person.organizationContactName, x => x]),
        part([() => person.organizationContactFunction, x => x]),
        part([() => person.organizationContactEmail, x => x]),
        part([() => person.organizationContactPhone, x => x])
      ]
    });
  }

  startGeneralWizard() {
    let baseUrl = this.baseUrl;
    if (!baseUrl.endsWith('/')) {
      baseUrl += '/';
    }
    this.localizedRouter.navigate([baseUrl + 'algemeen/bewoners', { relativeTo: this.route }]);
  }

  startAttachmentsWizard(person: PersonDetailsDto) {
    this.localizedRouter.navigate([
      this.baseUrl,
      'persoon',
      person.id,
      'bijlagen',
      'inkomsten'
    ]);
  }

  initAttachmentsWizard() {
        if (this.profile != null && this.profile.personDetails != null) {
      this.startAttachmentsWizard(this.profile.personDetails[0]);
    }
  }

  getRelocationTypeName(type: RelocationType) {
    switch (type) {
      case RelocationType.Asap:
        return this.translation.instant('Candidacies.AsFastAsPossible');
      case RelocationType.NoPreference:
        return this.translation.instant(
          'Common.NoPreference'
        );
      case RelocationType.SpecificDate:
        return this.translation.instant('Candidacies.SpecificDate');
    }
  }

  getDurationDescription(generalDetails: GeneralDetailsDto) {
    if (!generalDetails.durationPreference) {
      return this.translation.instant('Common.NoPreference');
    }

    switch (generalDetails.durationType) {
      case DurationType.Max1Year:
        return this.translation.instant('GeneralWizard.DurationType_Max1Year');
      case DurationType.Max3Years:
        return this.translation.instant('GeneralWizard.DurationType_Max3Years');
      case DurationType.MoreThan3Years:
        return this.translation.instant('GeneralWizard.DurationType_MoreThan3Years');
      case DurationType.Specific:
        const monthsTranslation = this.translation.instant('Common.Months');
        const yearsTranslation = this.translation.instant('Common.Years');
        let desc = '';
        if (generalDetails.durationYears) {
          desc += `${generalDetails.durationYears} ${yearsTranslation}  `;
        }
        if (generalDetails.durationMonths) {
          desc += `${generalDetails.durationMonths} ${monthsTranslation} `;
        }
        return desc;
    }
  }

  getFullName(person: PersonDetailsDto) {
    return getName(person);
  }

  addPerson() {
    this.addRenter.emit();
  }

  isExpanded(person: PersonDetailsDto) {
    return this.expandedPeople.has(person.id);
  }

  togglePersonExpand(person: PersonDetailsDto) {
    if (this.isExpanded(person)) {
      this.expandedPeople.delete(person.id);
    } else {
      this.expandedPeople.add(person.id);
    }
  }

  startPersonWizard(person: PersonDetailsDto) {
    this.localizedRouter.navigate([
      this.baseUrl,
      'persoon',
      person.id,
      'persoonlijke-gegevens'
    ]);
  }

  initPersonWizard() {
    if (this.profile != null && this.profile.personDetails != null) {
      this.startPersonWizard(this.profile.personDetails[0]);
    }
  }

  getPersonstate(person: PersonDetailsDto) {
    if (person != null) {
      if (person.legalType === PersonLegalType.Person &&
        person.personDetailsMetaData != null &&
        person.personDetailsMetaData.profileDetailsComplete) {
          return 'complete';
      }
    }
    if (person != null) {
      if (person.legalType === PersonLegalType.LegalEntity &&
        person.personDetailsMetaData != null &&
        person.personDetailsMetaData.organizationAddressComplete) {
          return 'complete';
      }
    }
    if (this.alert) {
      return 'error';
    }
    return 'incomplete';
  }

  getPersonAttachmentstate(person: PersonDetailsDto) {
    if (person == null || person.attachments == null ||
      !(person.attachments.length > 0)) {
      if (this.alert) {
        return 'error';
      }
      return 'incomplete';
    }
    return 'complete';
  }
}
