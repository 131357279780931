import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { LocalizedRouter } from '../../core/services/localized-router.service';

@Component({
  selector: 'ra-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('activationCodeField', { static: false }) activationCodeField: ElementRef;

  login: string;
  activationCode: string;
  submitting: boolean;
  realEstateObjectId: string;
  errorKey: string;
  hasResendCode = false;

  subscriptions: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private localizedRouter: LocalizedRouter,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.realEstateObjectId = this.route.snapshot.params.realEstateObjectId;
    this.subscriptions.push(
      this.activatedRoute
          .queryParamMap
          .subscribe(params => {
            this.login = params.get('login');
            this.activationCode = params.get('activationCode');
          })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.activationCodeField.nativeElement.focus();
  }

  onSubmit() {
    this.submitting = true;
    this.errorKey = '';

    this.subscriptions.push(
      this.authService
          .activate(this.login, this.activationCode)
          .pipe(
            catchError(err => {
              const errorCode: string = !!err.response ? err.response : err;
              this.errorKey = `Activation.Error.${errorCode}`;

              return throwError(err);
            }),
            finalize(() => this.submitting = false)
        )
        .subscribe(() => {
          if (this.realEstateObjectId != null) {
            this.localizedRouter.navigate(['/login/' + this.realEstateObjectId], {
              queryParams: { login: this.login } });
          } else {
            this.localizedRouter.navigate(['/login'], {
              queryParams: { login: this.login } });
          }
              })
    );
  }


  onResend() {
    this.hasResendCode = true;
    this.subscriptions.push(
      this.authService
          .resendActionCode(this.login)
          .pipe(
            catchError(err => {
              const errorCode: string = !!err.response ? err.response : err;
              this.errorKey = `Activation.Error.${errorCode}`;
              return throwError(err);
            })
        )
        .subscribe()
    );
  }
}
