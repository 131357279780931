<ra-house-header
    [titleKey]="'Contact.Title'"
    [subTitle]="'Contact.Subtitle' | translate"></ra-house-header>

<div class="ra-card-container">
  <div class="ra-card" *ngIf="!showConfirmationMessage">
    <form class="form-grid" (ngSubmit)="onSubmit()" #contactForm="ngForm">
      <div class="form-control-container">
        <label for="name">{{ "Contact.Name" | translate }}</label>
          <input
            id="name"
            type="name"
            name="name"
            [(ngModel)]="name"
            #nameInput="ngModel"
            #nameField
            [placeholder]="'Contact.NamePlaceholder' | translate"
            required
          />
          <div *ngIf="nameInput.invalid  && nameInput.touched" class="messages">
            <span *ngIf="nameInput.errors.required" class="message">{{ 'Contact.Validation.NameRequired'|translate }}</span>
          </div>
        </div>
      <div class="form-control-container">
        <label for="email">{{ "Contact.Email" | translate }}</label>
        <input
          id="email"
          type="email"
          name="email"
          [(ngModel)]="email"
          #emailInput="ngModel"
          [placeholder]="'Contact.Email' | translate"
          required
          email
        />
        <div *ngIf="emailInput.invalid && emailInput.touched" class="messages">
          <span *ngIf="!!emailInput.errors.email" class="message">{{ 'Contact.Validation.EmailInvalid'|translate }}</span>
          <span *ngIf="!!emailInput.errors.required" class="message">{{ 'Contact.Validation.EmailRequired'|translate }}</span>
        </div>
      </div>
      <div class="form-control-container">
        <label for="email">{{ "Login.PhoneNumber" | translate }}</label>
        <input
          id="phoneNumber"
          type="phoneNumber"
          name="phoneNumber"
          [(ngModel)]="phoneNumber"
          #phoneNumberInput="ngModel"
          [placeholder]="'Login.PhoneNumber' | translate"
          phoneNumber
        />
      </div>
      <div class="form-control-container">
        <label for="name">{{ "Contact.Message" | translate }}</label>
          <textarea
            id="message"
            name="message"
            [(ngModel)]="message"
            #messageTextArea="ngModel"
            [placeholder]="'Contact.Message' | translate"
            required
          ></textarea>
          <div *ngIf="messageTextArea.invalid  && messageTextArea.touched" class="messages">
            <span *ngIf="messageTextArea.errors.required" class="message">{{ 'Contact.Validation.MessageRequired'|translate }}</span>
          </div>
        </div>
      <div>
        <button
          class="primary"
          type="submit"
          [disabled]="!contactForm.form.valid || submitting"
        >
          {{ 'Contact.SendMessage' | translate }}
        </button>
      </div>
    </form>
  </div>
  <div class="ra-card" *ngIf="showConfirmationMessage">
    <ra-message
      [textKey]="'Contact.ConfirmationTitle'"
      [subTextKey]="'Contact.ConfirmationMessage'">
    </ra-message>
    <div class="icon">
      <div class="success-icon-circle">
        <svg-icon
            src="assets/images/checkmark.svg"
            [applyCss]="true"
        ></svg-icon>
      </div>
    </div>
  </div>
</div>

