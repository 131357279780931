import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { catchError, switchMap, finalize } from 'rxjs/operators';
import { LocalizedRouter } from '../../core/services/localized-router.service';

@Component({
  selector: 'ra-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('passwordField', { static: false }) passwordField: ElementRef;
  @ViewChild('passwordConfirmationField', { static: false }) passwordConfirmationField: ElementRef;

  login: string;
  confirmationCode: string;
  password: string;
  passwordConfirmation: string;
  realEstateObjectId: string;
  submitting: boolean;
  errorKey: string;

  subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private authService: AuthService,
              private localizedRouter: LocalizedRouter) { }

  ngOnInit() {
    this.realEstateObjectId = this.route.snapshot.params.realEstateObjectId;
    this.subscriptions.push(
      this.route.queryParamMap.subscribe(params => {
        this.login = params.get('login');
        this.confirmationCode = params.get('confirmationCode');
      })
    );
  }

  getLoginNav() {
    let nav = '/login';
    if (this.realEstateObjectId != null) {
      nav += '/' + this.realEstateObjectId;
    }
    return nav;
  }

  ngAfterViewInit() {
    this.passwordField.nativeElement.focus();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onSubmit() {

    if (this.password !== this.passwordConfirmation) {
      this.errorKey = 'ResetPassword.Validation.PasswordsDoNotMatch';
      this.passwordConfirmationField.nativeElement.select();
      return;
    }

    this.submitting = true;
    this.errorKey = '';


    this.subscriptions.push(
      this.authService
          .resetPassword(this.login, this.confirmationCode, this.password)
          .pipe(
            catchError(err => {
              const errorCode: string = !!err.response ? err.response : err;
              this.errorKey = `ResetPassword.Error.${errorCode}`;

              return throwError(err);
            }),
            switchMap(
              () => this.authService
                        .login(this.login, this.password)
                        .pipe(
                          catchError(err => this.localizedRouter.navigate(['/login'], {
                            queryParams: { login: this.login }
                          }))
                         )
            ),
            finalize(() => this.submitting = false)
        )
        .subscribe()
    );
  }
}
