import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AttachmentsWizardPageComponent } from '../attachments-wizard-page.component';
var IncomeAttachmentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IncomeAttachmentsComponent, _super);
    function IncomeAttachmentsComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IncomeAttachmentsComponent.prototype.next = function () {
        var url = this.hasOtherIncome ? '../andere-inkomsten'
            : '../identiteit-huur';
        this.localizedRouter.navigate([url], { relativeTo: this.route });
    };
    return IncomeAttachmentsComponent;
}(AttachmentsWizardPageComponent));
export { IncomeAttachmentsComponent };
