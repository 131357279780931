<div class="wizard-header flex-until-desktop">
  <svg-icon
    (click)="close()"
    class="action-icon"
    src="assets/images/close.svg"
  ></svg-icon>

  <div class="wizard-icons">
    <div [ngClass]="{ active: wizardType === 'General' }">
      <div class="white-circle">
        <svg-icon
          [applyCss]="true"
          class="blue-icon"
          src="assets/images/wizard-general.svg"
        ></svg-icon>
      </div>
    </div>
    <svg-icon src="assets/images/wizard-separator-dot.svg"></svg-icon>
    <div [ngClass]="{ active: wizardType === 'Renters' }">
      <div class="white-circle">
        <svg-icon
          [applyCss]="true"
          class="blue-icon"
          src="assets/images/wizard-renters.svg"
        ></svg-icon>
      </div>
    </div>
    <svg-icon src="assets/images/wizard-separator-dot.svg"></svg-icon>
    <div [ngClass]="{ active: wizardType === 'Attachments' }">
      <svg-icon
        [applyCss]="true"
        class="white-icon"
        src="assets/images/wizard-attachments.svg"
      ></svg-icon>
    </div>
  </div>
  <div></div>
</div>
