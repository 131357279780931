<div class="label"><ng-content></ng-content></div>
<label class="switch">
  <input type="checkbox"
         [checked]="checked"
         [disabled]="disabled"
         (change)="onInputChange()" />
  <div class="slider-container">
    <div class="slider"></div>
    <div class="thumb"></div>
  </div>
</label>
