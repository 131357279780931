<div class="wizard">
  <ra-wizard-header
    [wizardType]="'Attachments'"
    [autoSave]="false"
    (closeWizard)="closeWizard()"
  ></ra-wizard-header>
  <div class="wizard-page container">
    <form class="form-grid">
      <div>
        <div class="title">{{ 'AttachmentsWizard.ProofOfOtherIncomeTitle' | translate }} {{ name }}</div>
        <div>{{ 'AttachmentsWizard.ProofOfOtherIncomeText' | translate }}</div>
      </div>
      <button
          *ngIf="!profile.isReadonly"
          class="default left-icon-button"
          (click)="hiddenFileInput.click()">
          <svg-icon src="assets/images/upload.svg"></svg-icon>
          <span> {{ 'AttachmentsWizard.UploadProofOfIncome' | translate }}</span>
      </button>
      <input
        style="display: none"
        type="file"
        (change)="onFilesSelected($event.target.files, AttachmentType.ProofOfOtherIncome)"
        #hiddenFileInput  />
      <div>
        <ra-attachment
          *ngFor="let attachment of getAttachments(AttachmentType.ProofOfOtherIncome);index as i"
          [attachment]="attachment"
          [text]="('AttachmentsWizard.ProofOfOtherIncome' | translate) + ' ' + (i+1)"
          [subText]="attachment.documentName"
          [readonly]="profile.isReadonly"
          (download)="downloadAttachment(attachment)"
          (delete)="deleteAttachment(attachment)"
        >
        </ra-attachment>
        <div
          *ngIf="profile.isReadonly &&
                getAttachments(AttachmentType.ProofOfOtherIncome).length === 0"
          class="muted">
          {{ 'AttachmentsWizard.NoDocuments' | translate }}
        </div>
      </div>
    </form>
  </div>
  <ra-wizard-footer
  [step]="2"  [totalSteps]="3"
  [autoSave]="false"
  (previous)="previous()"
  (next)="next()"
></ra-wizard-footer>
</div>

