/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toggle-switch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./toggle-switch.component";
var styles_ToggleSwitchComponent = [i0.styles];
var RenderType_ToggleSwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToggleSwitchComponent, data: {} });
export { RenderType_ToggleSwitchComponent as RenderType_ToggleSwitchComponent };
export function View_ToggleSwitchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 4, "label", [["class", "switch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onInputChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "slider-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "thumb"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checked; var currVal_1 = _co.disabled; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_ToggleSwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-toggle-switch", [], null, [[null, "blur"]], function (_v, en, $event) { var ad = true; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onHostBlur() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ToggleSwitchComponent_0, RenderType_ToggleSwitchComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ToggleSwitchComponent]), i1.ɵdid(2, 49152, null, 0, i3.ToggleSwitchComponent, [], null, null)], null, null); }
var ToggleSwitchComponentNgFactory = i1.ɵccf("ra-toggle-switch", i3.ToggleSwitchComponent, View_ToggleSwitchComponent_Host_0, { checked: "checked" }, { check: "check" }, ["*"]);
export { ToggleSwitchComponentNgFactory as ToggleSwitchComponentNgFactory };
