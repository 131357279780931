/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./section-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/tooltip";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "ngx-bootstrap/positioning";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i7 from "angular-svg-icon";
import * as i8 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i9 from "@fortawesome/angular-fontawesome";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/common";
import * as i12 from "./section-list-item.component";
var styles_SectionListItemComponent = [i0.styles];
var RenderType_SectionListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SectionListItemComponent, data: {} });
export { RenderType_SectionListItemComponent as RenderType_SectionListItemComponent };
function View_SectionListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SectionListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [["class", "item-icon"], ["placement", "bottom"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.TooltipDirective, [i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.TooltipConfig, i1.ElementRef, i1.Renderer2, i4.PositioningService], { tooltip: [0, "tooltip"], placement: [1, "placement"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "svg-icon", [["applyCss", "true"], ["src", "assets/images/info-circle.svg"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(4, 1032192, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i7.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.tooltip)), ""); var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "assets/images/info-circle.svg"; var currVal_3 = "true"; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_SectionListItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "locked-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["src", "assets/images/lock.svg"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i7.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "assets/images/lock.svg"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SectionListItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i8.View_FaIconComponent_0, i8.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i9.FaIconComponent, [i10.DomSanitizer, i9.FaConfig, i9.FaIconLibrary, [2, i9.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faChevronRight; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SectionListItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "section-list-item__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "icon-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionListItemComponent_1)), i1.ɵdid(4, 540672, null, 0, i11.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionListItemComponent_2)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionListItemComponent_3)), i1.ɵdid(11, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "arrow-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionListItemComponent_4)), i1.ɵdid(14, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "item-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconTemplate; _ck(_v, 4, 0, currVal_0); var currVal_2 = _co.tooltip; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.isLocked; _ck(_v, 11, 0, currVal_3); var currVal_4 = !_co.hideRightArrow; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.textKey)); _ck(_v, 6, 0, currVal_1); }); }
export function View_SectionListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-section-list-item", [], null, null, null, View_SectionListItemComponent_0, RenderType_SectionListItemComponent)), i1.ɵdid(1, 49152, null, 1, i12.SectionListItemComponent, [], null, null), i1.ɵqud(603979776, 1, { iconTemplate: 0 })], null, null); }
var SectionListItemComponentNgFactory = i1.ɵccf("ra-section-list-item", i12.SectionListItemComponent, View_SectionListItemComponent_Host_0, { hideRightArrow: "hideRightArrow", iconTemplate: "iconTemplate", textKey: "textKey", tooltip: "tooltip", isLocked: "isLocked" }, { itemClick: "itemClick" }, []);
export { SectionListItemComponentNgFactory as SectionListItemComponentNgFactory };
