import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe.component';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthSignInErrorCode } from '../../shared/api/immoconnect-api.generated';
var LoginComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LoginComponent, _super);
    function LoginComponent(authService, localizedRouter, route, api) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.localizedRouter = localizedRouter;
        _this.route = route;
        _this.api = api;
        return _this;
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.realEstateObjectId = this.route.snapshot.params.realEstateObjectId;
        if (this.realEstateObjectId != null) {
            this.api.getWidgetProperty(this.realEstateObjectId)
                .toPromise()
                .then(function (x) {
                _this.property = x;
            });
        }
        // this.email = this.authService.getStoredUser();
        this.subscriptions.push(this.route
            .queryParamMap
            .subscribe(function (params) {
            _this.email = params.get('login');
        }));
    };
    LoginComponent.prototype.registerLink = function () {
        if (this.realEstateObjectId != null) {
            return '/registreer/' + this.realEstateObjectId;
        }
        else {
            return '/registreer';
        }
    };
    LoginComponent.prototype.forgotPasswordLink = function () {
        var nav = '/wachtwoord-vergeten';
        if (this.realEstateObjectId != null) {
            nav += '/' + this.realEstateObjectId;
        }
        return nav;
    };
    LoginComponent.prototype.ngAfterViewInit = function () {
        if (!this.email) {
            this.emailField.nativeElement.focus();
        }
        else if (!this.password) {
            this.passwordField.nativeElement.focus();
        }
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitting = true;
        this.errorKey = '';
        if (this.realEstateObjectId != null) {
            var broker = '0866381630';
            if (this.property != null && this.property.broker != null && this.property.broker.companyIdentificationNumber != null) {
                broker = this.property.broker.companyIdentificationNumber;
            }
            this.authService.redirectlocalizedRouter = '/app/kandidaat-stellen/' + broker + '/' + this.realEstateObjectId;
        }
        this.subscriptions.push(this.authService
            .login(this.email, this.password, this.rememberMe)
            .pipe(catchError(function (err) {
            if (err in AuthSignInErrorCode) {
                // Run some typescript enum magic
                // In fact err is already a string at runtime, but is recognized as a variable of 'type' AuthSignInErrorCode => which is a problem for string based checks
                var errString = AuthSignInErrorCode[AuthSignInErrorCode[err]];
                _this.errorKey = "Login.Error." + errString;
                // redirect to activation page
                if (err === AuthSignInErrorCode.UnactivatedUser || errString === AuthSignInErrorCode[AuthSignInErrorCode.UnactivatedUser]) { // err is in fact treated as a string at runtime, not as a number, so add string-based check as well
                    if (_this.realEstateObjectId != null) {
                        _this.localizedRouter.navigate(['/activeer/' + _this.realEstateObjectId], { queryParams: { login: _this.email } });
                    }
                    else {
                        _this.localizedRouter.navigate(['/activeer'], { queryParams: { login: _this.email } });
                    }
                }
            }
            return throwError(err);
        }), finalize(function () { return _this.submitting = false; }))
            .subscribe());
    };
    return LoginComponent;
}(AutoUnsubscribeComponent));
export { LoginComponent };
