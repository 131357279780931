import * as tslib_1 from "tslib";
import { AfterViewInit } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';
var ContactInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactInfoComponent, _super);
    function ContactInfoComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hostClass = 'wizard';
        _this.country = '';
        return _this;
    }
    ContactInfoComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.person.address.country === 'Belgie' || _this.person.address.country === 'Nederland' || _this.person.address.country === 'Frankrijk' ||
                _this.person.address.country === 'Duitsland') {
                _this.country = _this.person.address.country;
            }
            else {
                _this.country = 'Andere';
            }
        }, 0);
    };
    ContactInfoComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../persoonlijke-gegevens'], {
            relativeTo: this.route
        });
    };
    ContactInfoComponent.prototype.onPersonChanged = function () {
        if (!this.person.address) {
            this.person.address = {};
        }
    };
    ContactInfoComponent.prototype.next = function () {
        if (this.country && this.country != 'Andere') {
            this.person.address.country = this.country;
        }
        this.localizedRouter.navigate(['../inkomsten'], { relativeTo: this.route });
    };
    return ContactInfoComponent;
}(StepBaseComponent));
export { ContactInfoComponent };
