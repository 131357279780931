import * as i0 from "@angular/core";
import * as i1 from "../../shared/api/rentassistant-api";
var PublicProfileResolver = /** @class */ (function () {
    function PublicProfileResolver(api) {
        this.api = api;
    }
    PublicProfileResolver.prototype.resolve = function (route) {
        var token = route.params.token;
        return this.api.getProfileByToken(token);
    };
    PublicProfileResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublicProfileResolver_Factory() { return new PublicProfileResolver(i0.ɵɵinject(i1.RentAssistantApi)); }, token: PublicProfileResolver, providedIn: "root" });
    return PublicProfileResolver;
}());
export { PublicProfileResolver };
