import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { LocalizeRouterService } from 'ngx-localize-router';

@Component({
  selector: 'ra-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  private allLanguages: string[];

  languages: string[];
  currentLang: string;
  languagesListVisible: boolean;

  constructor(private localizeRouterService: LocalizeRouterService,
              private elementRef: ElementRef) { }

  ngOnInit() {

    this.allLanguages = this.localizeRouterService.parser.locales;
    this.currentLang = this.localizeRouterService.parser.currentLang;

    this.languages = this.allLanguages
                         .filter(lang => lang !== this.currentLang);
    this.languages.unshift(this.currentLang);
  }

  changeLang(lang: string, e: Event) {
    e.preventDefault();
    this.localizeRouterService.changeLanguage(lang);
  }

  isCurrentLang(language: string) {
    return this.localizeRouterService.parser.currentLang === language;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this.languagesListVisible) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.languagesListVisible = false;
      }
    }
  }
}
