import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { IncomeAttachmentsComponent } from './income-attachments/income-attachments.component';
import { OtherIncomeAttachmentsComponent } from './other-income-attachments/other-income-attachments.component';
import { IdentityRentAttachmentsComponent } from './identity-rent-attachments/identity-rent-attachments.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { DeleteAttachmentConfirmationComponent } from './delete-attachment-confirmation/delete-attachment-confirmation.component';
import { AttachmentsWizardPageComponent } from './attachments-wizard-page.component';

@NgModule({
  declarations: [
    AttachmentsWizardPageComponent,
    IncomeAttachmentsComponent,
    OtherIncomeAttachmentsComponent,
    IdentityRentAttachmentsComponent,
    AttachmentComponent,
    DeleteAttachmentConfirmationComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: []
})
export class AttachmentsWizardModule { }
