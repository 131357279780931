<ra-renter-wizard-header
  [person]="person">
</ra-renter-wizard-header>
<div class="wizard-page container">
  <form class="form-grid">
    <ra-toggle-switch name="hasCurrentRent" [(ngModel)]="person.hasCurrentRent" [disabled]="readonly">
      {{ 'RenterWizard.CurrentlyRenting' | translate }}
    </ra-toggle-switch>


    <ng-container>
      <ra-info-panel
      [titleKey]="'RenterWizard.WhyWeNeedCurrentLandlordText'"
    ></ra-info-panel>
    <div>
      <ra-toggle-switch
        name="canContactCurrentLandlord"
        [(ngModel)]="person.landlordContactFilled"
        [disabled]="readonly"
      >
        {{ 'RenterWizard.CanContactCurrentLandlord' | translate }}
      </ra-toggle-switch>
      <div class="hint">
        {{ 'RenterWizard.CanContactCurrentLandlordExplanation' | translate }}
      </div>
    </div>
    <ng-container *ngIf="person.landlordContactFilled">
      <div class="gap"></div>
      <div class="subtitle">{{ 'RenterWizard.Name' | translate }}</div>
      <input
        name="name"
        [(ngModel)]="person.landlordName"
        [placeholder]="'RenterWizard.Name' | translate"
        [readOnly]="readonly"
        required />
      <div class="gap"></div>
      <div class="subtitle">{{ 'RenterWizard.Email' | translate }}</div>
      <input
        name="email"
        type="email"
        [(ngModel)]="person.landlordEmail"
        [placeholder]="'RenterWizard.Email' | translate"
        [readOnly]="readonly"
        required />
      <div class="gap"></div>
      <div class="subtitle">{{ 'RenterWizard.PhoneNumber' | translate }}</div>
      <input
        name="phone"
        [(ngModel)]="person.landlordPhone"
        [placeholder]="'RenterWizard.PhoneNumber' | translate"
        [readOnly]="readonly"
        required />
    </ng-container>
  </ng-container>
    
  </form>
</div>









<ra-wizard-footer
  [step]="4" [totalSteps]="4"
  [autoSave]="!profile.isReadonly"
  (previous)="previous()"
  (next)="next()"
></ra-wizard-footer>
