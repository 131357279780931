import {shareReplay} from 'rxjs/operators';
import {take} from 'rxjs/internal/operators/take';
import {throwError, Observable} from 'rxjs';
import {
  WidgetParams,
  PropertyDto,
  ScoreProfileDto,
  CandidacyScore
} from './../../../shared/api/rentassistant-api.generated';
import {ActivatedRoute} from '@angular/router';
import {RentAssistantApi} from 'src/app/shared/api/rentassistant-api';
import {Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/components/common/selectitem';

class Styles {
  button: Button;
  body: Body;
}

class Button {
  font: string;
  bkgrnd: string;
  disp: string;
  bordr: string;
  bord: string;
  hbord: string;
  text: string;
  align: string;
  color: string;
  deco: string;
  pad: string;
  hbkgrnd: string;
  textTransform: string;
  width: string;
  lineHeight: string;
  fontSize: string;
  fontWeight: string;
  boxShadow: string;
  iconColor: string;
  iconShow: string;
}

class Body {
  bkgrnd: string;
}

@Component({
  selector: 'ra-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {
  private widgetParams: WidgetParams = {};
  isValidInput: boolean;
  property: PropertyDto;
  styles: Styles = null;
  bodyTag: HTMLBodyElement = document.getElementsByTagName('body')[0];
  score$: Observable<CandidacyScore>;
  score: CandidacyScore;
  profile: ScoreProfileDto = {};
  step = 1;
  minStep = 1;
  maxStep = 4;
  validationStep = [];
  displayProfile = false;
  showButton = true;
  showErrorLogs = false;
  showProfileSim = false;
  profileButtonClass = 'fas fa-chevron-double-down';
  number: SelectItem[] = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6}
  ];
  localStorageId = 'e3b9daee-b790-11ea-b3de-0242ac130004';

  // validation results
  transformStyle = 'rotate(90deg)';
  rentPercentageColor: string = null;
  step1Valid: boolean = null;
  step2Valid: boolean = null;
  step3Valid: boolean = null;
  initialShow = true;
  errorLog: string = null;

  constructor(private api: RentAssistantApi,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    let paramStye: Styles = null;
    this.route.queryParamMap.pipe(take(1)).subscribe(params => {
      this.widgetParams.enterpriseNumber = params.get('EnterpriseNumber');
      this.widgetParams.realEstateIdentifier = params.get('RealEstateIdentifier');


      if (params.get('additionRealEstateIdentifier') != null) {
        if (this.widgetParams.additionalWidgetParams == null) {
          this.widgetParams.additionalWidgetParams = {};
        }
        this.widgetParams.additionalWidgetParams.realEstateIdentifiers = JSON.parse(params.get('additionRealEstateIdentifier'));
      }

      if (params.get('showErrorLogs') != null) {
        this.showErrorLogs = params.get('showErrorLogs').toLowerCase() === 'true';
      }
      if (params.get('displayProfile') != null) {
        this.displayProfile = params.get('displayProfile').toLowerCase() === 'true';
      }
      if (params.get('showButton') != null) {
        this.showButton = params.get('showButton').toLowerCase() === 'true';
      }
      this.widgetParams.url = params.get('Url');
      if (params.get('Style') != null && params.get('Style') !== 'null' && params.get('Style') !== 'undefined') {
        paramStye = JSON.parse(params.get('Style'));
      }
      this.isValidInput = this.evaluateValidInput(this.widgetParams);
      if (this.isValidInput === true) {
        this.api.saveWidgetProperty(this.widgetParams).subscribe(x => {
          this.property = x;
          this.profile = this.getStorage();
          if (this.profile != null) {
            this.getProfileScore();
          }
          return x;
        }, (error) => {
          const errors = JSON.parse(error.response).errors as any[];
          errors.forEach(element => {
            this.errorLog = element.message;
          });
        });
      }
      return throwError('input was invalid');
    });
    this.styles = this.setStyles(this.styles, paramStye);
    this.bodyTag.style.background = this.styles.body.bkgrnd;
  }

  valueChange(add: boolean, property: string) {
    if (add) {
      if (property === 'adults') {
        if (this.profile.numberOfAdults >= 15) {
          return;
        }
        this.profile.numberOfAdults++;
      }
      if (property === 'children') {
        if (this.profile.numberOfChildren >= 15) {
          return;
        }
        this.profile.numberOfChildren++;
      }
      if (property === 'cars') {
        if (this.profile.numberOfCars >= 15) {
          return;
        }
        this.profile.numberOfCars++;
      }
    } else {
      if (property === 'adults') {
        if (this.profile.numberOfAdults <= 0) {
          return;
        }
        this.profile.numberOfAdults--;
      }
      if (property === 'children') {
        if (this.profile.numberOfChildren <= 0) {
          return;
        }
        this.profile.numberOfChildren--;
      }
      if (property === 'cars') {
        if (this.profile.numberOfCars <= 0) {
          return;
        }
        this.profile.numberOfCars--;
      }
    }
    this.getProfileScore();
  }

  validateInput(score: CandidacyScore) {
    if (score.residentialLoadPercentage != null) {
      let value = 90;
      if (score.residentialLoadPercentage <= 33.33) {
        const t = ((score.residentialLoadPercentage / 33.33) * 100);
        value = t * 0.6;
      }
      if (score.residentialLoadPercentage > 33.33 && score.residentialLoadPercentage <= 40) {
        const t = ((score.residentialLoadPercentage - 33.33));
        value = 60 + ((t / 6.67) * 60);
      }
      if (score.residentialLoadPercentage > 40) {
        let t = ((score.residentialLoadPercentage - 40));
        if (t > 60) {
          t = 60;
        }
        value = 120 + t;
      }
      this.transformStyle = 'rotate(' + value + 'deg)';
    }

    this.step1Valid = this.maxCapacityResult(score);

    if (score.residentialLoadPercentage <= 33.33) {
      this.step2Valid = true;
      this.rentPercentageColor = 'color-green';
    } else if (score.residentialLoadPercentage > 33.33 && score.residentialLoadPercentage <= 40) {
      this.step2Valid = true;
      this.rentPercentageColor = 'color-yellow';
    } else {
      this.step2Valid = false;
      this.rentPercentageColor = 'color-red';
    }
    if (this.score != null && this.profile != null && this.profile.totalMonthlyIncome != null) {
      const shortage = ((this.score.budgetNorm + this.score.rentalPrice) - this.profile.totalMonthlyIncome);
      if (shortage > 0) {
        this.step3Valid = false;
      } else {
        this.step3Valid = true;
      }
      if (this.profile != null && this.profile.totalMonthlyIncome > 0 && this.initialShow) {
        this.step = this.maxStep;
      }
    }
    this.initialShow = false;
  }

  setStorage() {
    try {
      localStorage.setItem(this.localStorageId, JSON.stringify(this.profile));
    } catch (e) {
      console.error(e);
    }
  }

  getStorage(): ScoreProfileDto {
    let value: string;
    try {
      value = localStorage.getItem(this.localStorageId);
    } catch (e) {
      console.error(e);
    }
    if (value == null) {
      const result: ScoreProfileDto = {};
      result.numberOfAdults = 1;
      result.numberOfChildren = 0;
      result.numberOfCars = 0;
      result.totalMonthlyIncome = 0;
      return result;
    }
    return JSON.parse(value);
  }

  isValidated(value: number): boolean {
    const result = this.validationStep.filter(x => x === value)[0];
    return result != null;
  }

  addToValidation(value: number) {
    this.getProfileScore();
    this.validationStep.push(value);
  }

  removeFromValidation(value: number) {
    this.validationStep = this.validationStep.filter(x => x !== value);
    if (this.validationStep == null) {
      this.validationStep = [];
    }
  }

  showProfile() {
    this.displayProfile = !this.displayProfile;
    if (this.displayProfile) {
      this.profileButtonClass = 'fas fa-chevron-double-up';
    } else {
      this.profileButtonClass = 'fas fa-chevron-double-down';
    }
  }

  getProgressBarClass(value: number) {
    let result: string;
    if (value === this.step) {
      result = 'active';
    }
    if (value === 1) {
      if (this.step1Valid === false) {
        result += ' red';
      }
      if (this.step1Valid === true) {
        result += ' green';
      }
    }

    if (this.step >= 2 && value === 2 && this.score != null) {
      if (this.score.residentialLoadPercentage <= 33.33) {
        result += ' green';
      } else if (this.score.residentialLoadPercentage > 33.33 && this.score.residentialLoadPercentage <= 40) {
        result += ' yellow';
      } else {
        result += ' red';
      }
    }

    if (this.step >= 3 && value === 3) {
      if (this.step3Valid === true) {
        result += ' green';
      }
      if (this.step3Valid === false) {
        result += ' red';
      }
    }
    if (this.step >= 4 && value === 4) {
      if (this.step1Valid != null && this.step2Valid != null && this.step3Valid != null) {
        if (this.step1Valid === true && this.step2Valid === true && this.step3Valid === true) {
          result += ' green';
        } else {
          result += ' red';
        }
      }
    }
    return result;
  }

  get getResidentialLoadPercentageClassColor() {
    if (this.score.residentialLoadPercentage <= 33.33) {
      return 'color-green';
    } else if (this.score.residentialLoadPercentage > 33.33 && this.score.residentialLoadPercentage <= 40) {
      return 'color-yellow';
    } else {
      return 'color-red';
    }
  }


  getProfileScore() {
    this.profile.propertyUniqueId = this.property.realEstateObjectId;
    if (this.profile.totalMonthlyIncome < 0) {
      this.profile.totalMonthlyIncome = 0;
    }
    this.score$ = this.api.getWidgetScore(this.profile).pipe(shareReplay());
    this.score$.toPromise()
      .then((x) => {
        this.score = x;
        this.validateInput(x);
      });
    this.setStorage();
  }

  next() {
    this.step = this.step + 1;
  }

  previous() {
    if (this.isValidated(this.step)) {
      this.removeFromValidation(this.step);
    } else {
      this.step = this.step - 1;
    }
  }

  navigateToStep(value: number) {
    if (value >= this.minStep && value <= this.maxStep) {
      this.step = value;
    }
  }

  private setStyles(toUse: Styles, param: Styles) {
    let result = toUse;
    if (result == null) {
      result = new Styles();
    }
    if (result.button == null) {
      result.button = new Button();
      result.button.align = 'center';
      result.button.font = 'arial';
      result.button.bkgrnd = '#49a4f3';
      result.button.bordr = '5px';
      result.button.bord = '1px solid #49a4f3';
      result.button.hbord = '1px solid #275c8b';
      result.button.color = 'white';
      result.button.deco = 'none';
      result.button.pad = '15px';
      result.button.hbkgrnd = '#275c8b';
      result.button.disp = 'block';
      result.button.textTransform = 'inherit';
      result.button.width = 'auto';
      result.button.lineHeight = 'normal';
      result.button.fontSize = 'inherit';
      result.button.fontWeight = 'normal';
      result.button.boxShadow = 'none';
      result.button.iconColor = '#fff';
      result.button.iconShow = 'inline';
    }
    if (result.body == null) {
      result.body = new Body();
      result.body.bkgrnd = 'white';
    }
    if (param != null && param.button != null) {
      if (param.button.align != null) {
        result.button.align = param.button.align;
      }
      if (param.button.font != null) {
        result.button.font = param.button.font;
      }
      if (param.button.bkgrnd != null) {
        result.button.bkgrnd = '#' + param.button.bkgrnd;
      }
      if (param.button.bordr != null) {
        result.button.bordr = param.button.bordr;
      }
      if (param.button.bord != null) {
        result.button.bord = param.button.bord;
      }
      if (param.button.hbord != null) {
        result.button.hbord = param.button.hbord;
      }
      if (param.button.color != null) {
        result.button.color = '#' + param.button.color;
      }
      if (param.button.deco != null) {
        result.button.deco = param.button.deco;
      }
      if (param.button.pad != null) {
        result.button.pad = param.button.pad;
      }
      if (param.button.hbkgrnd != null) {
        result.button.hbkgrnd = '#' + param.button.hbkgrnd;
      }
      if (param.button.disp != null) {
        result.button.disp = param.button.disp;
      }
      if (param.button.textTransform != null) {
        result.button.textTransform = param.button.textTransform;
      }
      if (param.button.width != null) {
        result.button.width = param.button.width;
      }
      if (param.button.lineHeight != null) {
        result.button.lineHeight = param.button.lineHeight;
      }
      if (param.button.fontSize != null) {
        result.button.fontSize = param.button.fontSize;
      }
      if (param.button.fontWeight != null) {
        result.button.fontWeight = param.button.fontWeight;
      }
      if (param.button.boxShadow != null) {
        result.button.boxShadow = param.button.boxShadow;
      }
      if (param.button.iconColor != null) {
        result.button.iconColor = param.button.iconColor;
      }
      if (param.button.iconShow != null) {
        result.button.iconShow = param.button.iconShow;
      }

      if (param != null && param.body != null) {
        if (param.body.bkgrnd != null) {
          result.body.bkgrnd = '#' + param.body.bkgrnd;
        }
      }
    }
    return result;
  }

  mouseenter(id: string) {
    document.getElementById(id).style.background = this.styles.button.hbkgrnd;
    document.getElementById(id).style.border = this.styles.button.hbord;
  }

  maxCapacityResult(score: CandidacyScore): boolean {
    let adults = 0;
    let children = 0;
    if (this.profile != null) {
      if (this.profile.numberOfAdults != null) {
        adults = this.profile.numberOfAdults;
      }
      if (this.profile.numberOfChildren != null) {
        children = this.profile.numberOfChildren;
      }
    }
    return score.maxTenantsForProperty >= (adults + children);
  }

  mouseleave(id: string) {
    document.getElementById(id).style.background = this.styles.button.bkgrnd;
    document.getElementById(id).style.border = this.styles.button.bord;
  }


  private evaluateValidInput(widgetParams: WidgetParams) {
    return !this.isEmpty(widgetParams.enterpriseNumber)
      && !this.isEmpty(widgetParams.realEstateIdentifier)
      && !this.isEmpty(widgetParams.url);
  }

  private isEmpty(value: string) {
    return (!value || 0 === value.trim().length);
  }
}


