<div class="icon-container">
  <div class="left-circle" [ngClass]="state">
    <svg-icon src="assets/images/user.svg"></svg-icon>
  </div>
</div>

<div class="content">
  {{ name }}
</div>

<div class="arrow-box" *ngIf="isExpandable">
  <fa-icon [icon]="faChevronUp" *ngIf="!isExpanded"></fa-icon>
  <fa-icon [icon]="faChevronDown" *ngIf="isExpanded"></fa-icon>
</div>

<div class="arrow-box" (click)="onRightArrowClick($event)">
  <fa-icon [icon]="faChevronRight"></fa-icon>
</div>
