import { Component, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { ProfileAttachmentDto, AttachmentType } from '../../../shared/api/rentassistant-api.generated';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export class UploadParams {
  attachmentType: AttachmentType;
  files: FileList;
}

@Component({
  selector: 'ra-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  @Input() attachment: ProfileAttachmentDto;
  @Input() text: string;
  @Input() subText: string;
  @Input() attachmentType: AttachmentType;
  @Input() readonly: boolean;

  @Output() download = new EventEmitter<ProfileAttachmentDto>();
  @Output() delete = new EventEmitter<ProfileAttachmentDto>();
  @Output() upload = new EventEmitter<UploadParams>();

  @ViewChild('deleteModal', { static: false })
  deleteModal: TemplateRef<any>;

  constructor(private modal: NgbModal) { }

  ngOnInit() {

  }

  onDownload() {
    this.download.emit(this.attachment);
  }

  showDeleteConfirmation() {
    this.modal.open(this.deleteModal);
  }

  onDelete(modal) {
    modal.dismiss();
    this.delete.emit(this.attachment);
  }

  onUpload(files: FileList) {
    this.upload.emit({ files, attachmentType: this.attachmentType });
  }
}
