import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AttachmentsWizardPageComponent } from '../attachments-wizard-page.component';
var IdentityRentAttachmentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IdentityRentAttachmentsComponent, _super);
    function IdentityRentAttachmentsComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IdentityRentAttachmentsComponent.prototype.previous = function () {
        var url = this.hasOtherIncome ? '../andere-inkomsten'
            : '../inkomsten';
        this.localizedRouter.navigate([url], { relativeTo: this.route });
    };
    IdentityRentAttachmentsComponent.prototype.next = function () {
        if (this.profile.isReadonly) {
            this.closeWizard();
        }
        else {
            this.localizedRouter.navigate(['../samenvatting'], { relativeTo: this.route });
        }
    };
    return IdentityRentAttachmentsComponent;
}(AttachmentsWizardPageComponent));
export { IdentityRentAttachmentsComponent };
