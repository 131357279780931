import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalizedRouter } from '../../../core/services/localized-router.service';

@Component({
  selector: 'ra-house-header',
  templateUrl: './house-header.component.html',
  styleUrls: ['./house-header.component.scss']
})
export class HouseHeaderComponent implements OnInit {

  @Input() titleKey: string;
  @Input() subTitle: string;

  menuVisible: boolean;
  showArchivedValue: boolean;

  @Input() menuIconVisible = true;
  @Input() backIconVisible: boolean;
  @Input() backNavigation: string;
  @Input() logoVisible = false;
  @Input() candidacyFilter = false;

  @Output() logout = new EventEmitter();
  @Output() candidacyFilterShowArchived = new EventEmitter<boolean>();

  constructor(private localizedRouter: LocalizedRouter) { }

  ngOnInit() {
  }

  toggleMenu($event: Event) {
    this.menuVisible = !this.menuVisible;
    $event.stopImmediatePropagation();
  }

  menuVisibleChange(visible) {
    this.menuVisible = visible;
  }

  navigateHome() {
    if (this.backNavigation != null ) {
      this.localizedRouter.navigate([this.backNavigation]);
      return;
    }
    this.localizedRouter.navigate(['/']);
  }
  logOut() {
    this.logout.emit();
  }

  candidacyFilterShowArchivedChanged() {
    this.candidacyFilterShowArchived.emit(this.showArchivedValue);
  }
}
