<div class="profile-container">
  <div class="profile" [ngClass]="{ 'hidden-on-mobile': !isDetail, 'center': isDetail }">
    <div class="scroll-container scroll-light">
      <ra-house-header [titleKey]="'Profile.TitlePublic'" [subTitle]="profile.userName"></ra-house-header>
      <div class="center-on-desktop">
        <div class="center-container">
          <ra-profile [profile]="profile"
                      [baseUrl]="baseUrl"></ra-profile>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isDetail" class="profile-wizard" [ngClass]="{ 'hidden-on-mobile': isDetail }">
    <router-outlet></router-outlet>
  </div>
</div>
