import { Component, OnInit, Input } from '@angular/core';
import { Status } from 'src/app/shared/api/rentassistant-api.generated';

@Component({
  selector: 'ra-candidacy-status-badge',
  templateUrl: './candidacy-status-badge.component.html',
  styleUrls: ['./candidacy-status-badge.component.scss']
})
export class CandidacyStatusBadgeComponent implements OnInit {
  statusField: Status = Status.Draft;
  @Input()
  get status() {
    return this.statusField;
  }
  set status(status: Status) {
    this.statusField = status;
    this.updateStatus(status);
  }

  statusTranslationKey: string;
  statusClassName: string;

  constructor() {}

  ngOnInit() {}

  getTranslationKeyAndClass(status: Status): {
    statusTranslationKey: string;
    statusClassName: string;
  } {
    switch (status) {
      case Status.Submitted:
        return {
          statusTranslationKey: 'CandidacyStatus.Submitted',
          statusClassName: 'submitted'
        };
      case Status.InProgress:
        return {
          statusTranslationKey: 'CandidacyStatus.InProgress',
          statusClassName: 'in-progress'
        };

      case Status.InfoRequested:
        return {
          statusTranslationKey: 'CandidacyStatus.Update',
          statusClassName: 'update'
        };
      case Status.Approved:
        return {
          statusTranslationKey: 'CandidacyStatus.Selected',
          statusClassName: 'selected'
        };
      case Status.Rejected:
        return {
          statusTranslationKey: 'CandidacyStatus.Rejected',
          statusClassName: 'rejected'
        };
      case Status.Recalled:
        return {
          statusTranslationKey: 'CandidacyStatus.Recalled',
          statusClassName: 'recalled'
        };
        case Status.Draft:
          return {
            statusTranslationKey: 'CandidacyStatus.Draft',
            statusClassName: 'draft'
          };
    }

    return {
      statusTranslationKey: '',
      statusClassName: ''
    };
  }

  private updateStatus(status: Status) {
    const {
      statusTranslationKey,
      statusClassName
    } = this.getTranslationKeyAndClass(status);
    this.statusTranslationKey = statusTranslationKey;
    this.statusClassName = statusClassName;
  }
}
