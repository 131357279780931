import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService, Session } from './session.service';
import { AuthService } from './auth.service';
import { LocalizedRouter } from './localized-router.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  session: Session;

  constructor(public sessionService: SessionService,
              public authService: AuthService,
              public localizedRouter: LocalizedRouter) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.sessionService.isAuthenticated()) {
      this.authService.redirectUrl = state.url;
      const params = route.params;
      if (params != null) {
        const paramName = 'realEstateObjectId';
        const realEstateObjectId = params[paramName];
        if (realEstateObjectId != null) {
          this.localizedRouter.navigate(['/login/' + realEstateObjectId]);
          return false;
        }
      }
      this.localizedRouter.navigate(['/login']);
      return false;
    }
    return true;
  }
}
