import { AuthResendActivateRequest } from './../../shared/api/immoconnect-api.generated';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, concatMap } from 'rxjs/operators';

import {
  AuthSignInRequest,
  ImmoConnectClient,
  AuthRegisterRequest,
  AuthRegisterResponse,
  AuthActivateRequest,
  InitiatePasswordResetRequest,
  CompletePasswordResetRequest
} from '../../shared/api/immoconnect-api.generated';
import { SessionService } from './session.service';
import { RentAssistantClient, UserDto } from 'src/app/shared/api/rentassistant-api.generated';
import { LocalizedRouter } from './localized-router.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DynamicEnvironment } from '../../../environments/dynamic-environment';

export interface User {
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  language: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string;
  redirectlocalizedRouter: string;

  constructor(
    private immoConnectClient: ImmoConnectClient,
    private rentAssistantClient: RentAssistantClient,
    private sessionService: SessionService,
    private localizedRouter: LocalizedRouter,
    private router: Router,
    private translateService: TranslateService,
    private environment: DynamicEnvironment) {
  }

  register(newUser: User): Observable<AuthRegisterResponse> {

    const registerRequest = new AuthRegisterRequest({
      ...newUser
    });
    registerRequest.applicationId = this.environment.immoConnect.applicationId;
    return this.immoConnectClient.authentication_Register(registerRequest);
  }

  activate(login: string, activationCode: string) {
    const activateRequest = new AuthActivateRequest({
      login,
      activationCode
    })

      ;
    return this.immoConnectClient.authentication_Activate(activateRequest);
  }

  resendActionCode(login: string) {
    const resendActivationRequest = new AuthResendActivateRequest();
    resendActivationRequest.login = login;
    resendActivationRequest.applicationId = '11f83c57-590d-4460-a01f-5047bc1e26a4';
    return this.immoConnectClient.authentication_ResendActivationCode(resendActivationRequest);
  }

  forgotPassword(login: string, confirmationReturnUrl: string): Observable<boolean> {

    const initiatePAsswordResetRequest = new InitiatePasswordResetRequest({
      login,
      confirmationReturnUrl,
      applicationId: this.environment.immoConnect.applicationId
    });

    return this.immoConnectClient.authentication_InitiatePasswordReset(initiatePAsswordResetRequest);
  }

  resetPassword(login: string, confirmationCode: string, newPassword: string): Observable<boolean> {

    const completePasswordResetRequest = new CompletePasswordResetRequest({
      login,
      confirmationCode,
      newPassword,
      applicationId: this.environment.immoConnect.applicationId
    });

    return this.immoConnectClient.authentication_CompletePasswordReset(completePasswordResetRequest);
  }

  login(login: string, password: string, rememberMe: boolean = false): Observable<UserDto> {
    this.sessionService.clearSession();
    this.sessionService.setStorageType(rememberMe);

    const signinRequest = new AuthSignInRequest({
      applicationId: this.environment.immoConnect.applicationId,
      login,
      password,
      timeoutInMinutes: this.environment.immoConnect.timeoutInMinutes
    });

    return this.immoConnectClient
      .authentication_SignIn(signinRequest)
      .pipe(
        tap(res => this.sessionService.setToken(res.session)),
        concatMap(res => this.updateUser()),
        tap(user => {
          this.sessionService.setUser(user);
          if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
            this.redirectUrl = null;
          } else if (this.redirectlocalizedRouter) {
            this.localizedRouter.navigate([this.redirectlocalizedRouter]);
          } else {
            this.localizedRouter.navigate(['/app/kandidaturen']);
          }
        })
      );
  }

  logout() {
    this.sessionService.clearSession();
    this.localizedRouter.navigate(['/']);
  }

  isLoggedIn() {
    return this.sessionService.isAuthenticated();
  }

  updateUser(): Observable<UserDto> {
    return this.rentAssistantClient.users_CreateOrUpdateUser({
      privacyStatementAccepted: true,
      languageTwoLetterIsoCode: this.translateService.currentLang
    });
  }
}
