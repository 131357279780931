import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DynamicEnvironment } from '../../../environments/dynamic-environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private environment: DynamicEnvironment) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headerValue = this.environment.immoConnect.partnerKey;
    request = request.clone({
      headers: request.headers.set('Authorization', headerValue)
    });
    return next.handle(request);
  }
}
