<div class="shell">
  <header>
    <ra-header></ra-header>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>
    <ra-footer></ra-footer>
  </footer>
</div>
