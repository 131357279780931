import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ngx-localize-router/src/localize-router.service";
var LocalizedRouter = /** @class */ (function () {
    function LocalizedRouter(router, localizeRouterService) {
        this.router = router;
        this.localizeRouterService = localizeRouterService;
    }
    LocalizedRouter.prototype.navigate = function (commands, extras) {
        var translatedCommands = this.localizeRouterService.translateRoute(commands);
        return this.router.navigate(translatedCommands, extras);
    };
    LocalizedRouter.prototype.isActive = function (commands, exact) {
        if (exact === void 0) { exact = true; }
        var translatedCommands = this.localizeRouterService.translateRoute(commands);
        var urlTree = this.router.createUrlTree(translatedCommands);
        return this.router.isActive(urlTree, exact);
    };
    LocalizedRouter.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalizedRouter_Factory() { return new LocalizedRouter(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LocalizeRouterService)); }, token: LocalizedRouter, providedIn: "root" });
    return LocalizedRouter;
}());
export { LocalizedRouter };
