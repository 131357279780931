<div *ngIf="!shortlistSubmitted" class="structure">
  <div class="structure-nav">
    <ra-sidebar *ngIf="shortlist && login" (logout)="onLogout()" [ownerLogin]="login" (submitShortlist)="onSubmitShortlist($event)" (shortlistExpired)="onShortlistExpired()" [property]="shortlist.property"></ra-sidebar>
  </div>
  <div class="structure-content">
    <ra-table *ngIf="shortlist && !shortlistIsExpired" [profileDetails]="shortlist.profileDetails" [login]="login"></ra-table>
    <h1 *ngIf="shortlistIsExpired">{{'Registration.ShortlistExpired' |translate}}</h1>
  </div>
</div>

<div *ngIf="shortlistSubmitted">
  <ra-house-header
  [menuIconVisible]="false"
  [backIconVisible]="false"></ra-house-header>
  <div class="ra-card-container">
    <div class="ra-card m-t-40 p-40 text-center ">
      <h1 class="color-green m-b-10">{{ 'Owner.Congratulations' | translate }}</h1>
      <div>{{ 'Owner.Finished' | translate }}</div>
    </div>
  </div>
</div>
