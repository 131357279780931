import {
  Component,
  QueryList,
  ContentChildren,
  AfterContentInit,
  Input,
  HostBinding,
  forwardRef,
  Output,
  EventEmitter
} from '@angular/core';
import { ChoiceItemComponent } from './choice-item/choice-item.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe.component';

@Component({
  selector: 'ra-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChoiceComponent),
      multi: true
    }
  ]
})
export class ChoiceComponent extends AutoUnsubscribeComponent
  implements AfterContentInit, ControlValueAccessor {
  @HostBinding('class.horizontal')
  @Input()
  horizontal = false;

  private disabledField = false;
  @Input()
  get disabled() {
    return this.disabledField;
  }
  set disabled(v: boolean) {
    this.disabledField = v;
    this.announceDisabledToChildren();
  }

  @Output()
  choiceChange = new EventEmitter<any>();

  @ContentChildren(ChoiceItemComponent)
  children: QueryList<ChoiceItemComponent>;

  selectedValue: any;
  onChangeCallback: any;
  onTouchedCallback: any;

  ngAfterContentInit() {
    if (this.children.length !== 0) {
      this.children.first.isFirst = true;
      this.children.last.isLast = true;
      this.children.forEach(c => (c.horizontal = this.horizontal));
      this.announceDisabledToChildren();
      this.subscriptions = this.children.map(c =>
        c.onClick.subscribe(() => {
          this.onChildClick(c);
        })
      );
    }
    this.tryCoerceSelectedChild();
  }

  private onChildClick(child: ChoiceItemComponent) {
    const previousSelection = this.children.find(c => c.selected);
    this.children.forEach(c => (c.selected = false));
    child.selected = true;
    if (child.valueProvider) {
      child.valueProvider().then(newValue => {
        if (newValue === 'cancel') {
          if (previousSelection) {
            child.selected = false;
            previousSelection.selected = true;
          }
        } else {
          this.selectedValue = newValue.value;
          this.tryInvokeOnChangeCallbacks(newValue.value);
        }
      });
    } else {
      this.selectedValue = child.value;
      this.tryInvokeOnChangeCallbacks(child.value);
    }
  }

  private tryCoerceSelectedChild() {
    if (this.children) {
      this.children.forEach(child => {
        child.selected =
          (child.selectedPredicate &&
            child.selectedPredicate(this.selectedValue)) ||
          (child.value !== undefined && child.value === this.selectedValue);
      });
    }
  }

  private tryInvokeOnChangeCallbacks(newValue: any) {
    this.choiceChange.next(newValue);
    if (this.onChangeCallback) {
      this.onChangeCallback(newValue);
    }
  }

  private announceDisabledToChildren() {
    if (this.children) {
      this.children.forEach(c => (c.parentDisabled = this.disabled));
    }
  }

  writeValue(obj: any): void {
    this.selectedValue = obj;
    this.tryCoerceSelectedChild();
  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.children.forEach(child => {
      child.parentDisabled = isDisabled;
    });
  }
}
