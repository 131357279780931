import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterModule, LocalizeRouterSettings, LocalizeParser } from 'ngx-localize-router';
import { LocalizeRouterHttpLoader } from './localize-router-http-loader';


const routes: Routes = [
  // { path: '**', redirectTo: '/login' }
];

export function HttpLoaderFactory(translate: TranslateService,
                                  location: Location,
                                  settings: LocalizeRouterSettings,
                                  http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

export function defaultLangFunction(languages: string[], cachedLang?: string, browserLang?: string) {
  return cachedLang || 'nl';
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    LocalizeRouterModule.forRoot(routes, {
      useCachedLang: false,
      defaultLangFunction,
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    })
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule {}
