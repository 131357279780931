import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { makeEmptyCandidacy } from 'src/app/core/models';
import { faPhone, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
var ContactBrokerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactBrokerComponent, _super);
    function ContactBrokerComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.faPhone = faPhone;
        _this.faEnvelopeOpen = faEnvelopeOpen;
        _this.candidacy = makeEmptyCandidacy();
        _this.cancel = new EventEmitter();
        return _this;
    }
    ContactBrokerComponent.prototype.ngOnInit = function () {
        if (this.candidacy && this.candidacy.broker) {
            this.phone = this.candidacy.broker.phone;
            this.email = this.candidacy.broker.email;
        }
    };
    return ContactBrokerComponent;
}(AutoUnsubscribeComponent));
export { ContactBrokerComponent };
