/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "ngx-bootstrap/tooltip";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "ngx-bootstrap/positioning";
import * as i6 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i7 from "angular-svg-icon";
import * as i8 from "@angular/common";
import * as i9 from "@angular/forms";
import * as i10 from "./counter.component";
var styles_CounterComponent = [i0.styles];
var RenderType_CounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounterComponent, data: {} });
export { RenderType_CounterComponent as RenderType_CounterComponent };
function View_CounterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.labelKey)); _ck(_v, 1, 0, currVal_0); }); }
function View_CounterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.labelKey)); _ck(_v, 1, 0, currVal_0); }); }
function View_CounterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [["class", "info-icon"], ["placement", "auto"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i3.TooltipDirective, [i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.TooltipConfig, i1.ElementRef, i1.Renderer2, i5.PositioningService], { tooltip: [0, "tooltip"], placement: [1, "placement"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "svg-icon", [["applyCss", "true"], ["src", "assets/images/info-circle.svg"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(4, 1032192, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i7.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.tooltipText)), ""); var currVal_1 = "auto"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "assets/images/info-circle.svg"; var currVal_3 = "true"; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_CounterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container hint row mt-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.subText)); _ck(_v, 1, 0, currVal_0); }); }
export function View_CounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "counter-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "info-icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CounterComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CounterComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CounterComponent_3)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "default"]], [[2, "limit", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.minus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "svg-icon", [["src", "assets/images/minus.svg"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(11, 1032192, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i7.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "count"]], [[2, "limit", null]], null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "default"]], [[2, "limit", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.plus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "svg-icon", [["src", "assets/images/plus.svg"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(16, 1032192, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i7.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CounterComponent_4)), i1.ɵdid(18, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.asTitle; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.asTitle; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.tooltipText; _ck(_v, 7, 0, currVal_2); var currVal_5 = "assets/images/minus.svg"; var currVal_6 = true; _ck(_v, 11, 0, currVal_5, currVal_6); var currVal_11 = "assets/images/plus.svg"; var currVal_12 = true; _ck(_v, 16, 0, currVal_11, currVal_12); var currVal_13 = _co.subText; _ck(_v, 18, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.limitMin; var currVal_4 = (_co.limitMin || _co.disabled); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_7 = _co.limit; _ck(_v, 12, 0, currVal_7); var currVal_8 = _co.count; _ck(_v, 13, 0, currVal_8); var currVal_9 = _co.limitMax; var currVal_10 = (_co.limitMax || _co.disabled); _ck(_v, 14, 0, currVal_9, currVal_10); }); }
export function View_CounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-counter", [], null, [[null, "blur"]], function (_v, en, $event) { var ad = true; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onHostBlur() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CounterComponent_0, RenderType_CounterComponent)), i1.ɵprd(5120, null, i9.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i10.CounterComponent]), i1.ɵdid(2, 114688, null, 0, i10.CounterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CounterComponentNgFactory = i1.ɵccf("ra-counter", i10.CounterComponent, View_CounterComponent_Host_0, { count: "count", min: "min", max: "max", labelKey: "labelKey", tooltipText: "tooltipText", asTitle: "asTitle", subText: "subText" }, { countChanged: "countChanged" }, []);
export { CounterComponentNgFactory as CounterComponentNgFactory };
