import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { ProfileModule } from '../profile/profile.module';
import {TooltipModule} from 'primeng/tooltip';

import { OverviewComponent } from './overview/overview.component';
import { DetailComponent } from './detail/detail.component';
import { FeedbackComponent } from './detail/feedback/feedback.component';
import { FeedbackSelectorComponent } from './detail/feedback-selector/feedback-selector.component';
import { CandidacyActionConfirmationComponent  } from './detail/action-confirmation/action-confirmation.component';
import { SubmitConfirmationComponent } from './detail/submit-confirmation/submit-confirmation.component';
import { ContactBrokerComponent } from './detail/contact-broker/contact-broker.component';
import { CreateCandidacyComponent } from './create/create.component';

@NgModule({
  declarations: [
    OverviewComponent,
    DetailComponent,
    FeedbackComponent,
    FeedbackSelectorComponent,
    CandidacyActionConfirmationComponent,
    SubmitConfirmationComponent,
    ContactBrokerComponent,
    CreateCandidacyComponent
  ],
  imports: [
    CommonModule,
    ProfileModule,
    SharedModule,
    RouterModule.forChild([]),
    TooltipModule
  ]
})
export class CandidaciesModule {}
