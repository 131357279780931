<div class="wizard">
  <ra-wizard-header
    [wizardType]="'Attachments'"
    [autoSave]="false"
    (closeWizard)="closeWizard()"
  ></ra-wizard-header>
  <div class="wizard-page container">
    <form class="form-grid">
      <div class="title">{{ 'AttachmentsWizard.ProofOfIncome' | translate }} {{ name }}</div>
      <ra-info-panel
          *ngIf="!profile.isReadonly"
          [titleKey]="'AttachmentsWizard.ProofOfIncomeInfoTitle'"
          tooltip="AttachmentsWizard.ProofOfIncomeInfoTitleInfo">
        <div class="bold">{{ 'AttachmentsWizard.ProofOfIncomeInfoEmployeeTitle' | translate }}</div>
        <div>{{ 'AttachmentsWizard.ProofOfIncomeInfoEmployeeMessage' | translate }}</div>
        <br/>
        <div class="bold">{{ 'AttachmentsWizard.ProofOfIncomeInfoIndependentTitle' | translate }}</div>
        <div>{{ 'AttachmentsWizard.ProofOfIncomeInfoIndependentMessage' | translate }}</div>
      </ra-info-panel>
      <button
          *ngIf="!profile.isReadonly"
          class="default left-icon-button"
          (click)="hiddenFileInput.click()">
          <svg-icon src="assets/images/upload.svg"></svg-icon>
          <span> {{ 'AttachmentsWizard.UploadProofOfIncome' | translate }}</span>
      </button>
      <input
        style="display: none"
        type="file"
        (change)="onFilesSelected($event.target.files, AttachmentType.ProofOfIncome)"
        #hiddenFileInput  />
      <div>
        <ra-attachment
          *ngFor="let attachment of getAttachments(AttachmentType.ProofOfIncome);index as i"
          [attachment]="attachment"
          [text]="('AttachmentsWizard.ProofOfIncome' | translate) + ' ' + (i+1)"
          [subText]="attachment.documentName"
          [readonly]="profile.isReadonly"
          (download)="downloadAttachment(attachment)"
          (delete)="deleteAttachment(attachment)"
        >
        </ra-attachment>
        <div
          *ngIf="profile.isReadonly &&
                 getAttachments(AttachmentType.ProofOfIncome).length === 0"
          class="muted">
          {{ 'AttachmentsWizard.NoDocuments' | translate }}
        </div>
      </div>
      <div></div>
      <div
        *ngIf="isLegalPerson">
        <div class="title">{{ 'AttachmentsWizard.LegalPersons' | translate }}</div>
        <div>{{ 'AttachmentsWizard.LegalPersonsText' | translate }}</div>
      </div>
      <div *ngIf="isLegalPerson">
        <ra-attachment
          [attachment]="getAttachment(AttachmentType.CompanyPublication)"
          [text]="('AttachmentsWizard.CompanyPublication' | translate)"
          [attachmentType]="'CompanyPublication'"
          [readonly]="profile.isReadonly"
          (download)="downloadAttachment($event)"
          (delete)="deleteAttachment($event)"
          (upload)="uploadAttachment($event)"></ra-attachment>
        <ra-attachment
          [attachment]="getAttachment(AttachmentType.BalanceSheet)"
          [text]="('AttachmentsWizard.BalanceSheet' | translate)"
          [attachmentType]="'BalanceSheet'"
          [readonly]="profile.isReadonly"
          (download)="downloadAttachment($event)"
          (delete)="deleteAttachment($event)"
          (upload)="uploadAttachment($event)"></ra-attachment>
      </div>
    </form>
  </div>
  <ra-wizard-footer
  [step]="1"  [totalSteps]="totalSteps"
  [autoSave]="false"
  (next)="next()"
></ra-wizard-footer>
</div>

