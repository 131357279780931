/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./wizard-footer.component";
import * as i7 from "../../../core/services/profilewizard-state.service";
var styles_WizardFooterComponent = [i0.styles];
var RenderType_WizardFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardFooterComponent, data: {} });
export { RenderType_WizardFooterComponent as RenderType_WizardFooterComponent };
function View_WizardFooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["src", "assets/images/arrow-left.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "assets/images/arrow-left.svg"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WizardFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container wizard-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "left-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardFooterComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "step-counter"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " / ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "button", [["class", "default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 1, "svg-icon", [["src", "assets/images/arrow-right.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(11, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previousVisible; _ck(_v, 3, 0, currVal_0); var currVal_4 = "assets/images/arrow-right.svg"; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.step; var currVal_2 = _co.totalSteps; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("Common.Next")); _ck(_v, 8, 0, currVal_3); }); }
export function View_WizardFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-wizard-footer", [], null, null, null, View_WizardFooterComponent_0, RenderType_WizardFooterComponent)), i1.ɵdid(1, 245760, null, 0, i6.WizardFooterComponent, [i7.ProfileWizardStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WizardFooterComponentNgFactory = i1.ɵccf("ra-wizard-footer", i6.WizardFooterComponent, View_WizardFooterComponent_Host_0, { step: "step", totalSteps: "totalSteps", autoSave: "autoSave" }, { previous: "previous", next: "next", beforeNavigate: "beforeNavigate" }, []);
export { WizardFooterComponentNgFactory as WizardFooterComponentNgFactory };
