<ra-renter-wizard-header
  [person]="person">
  (beforeClose)="beforeNavigate()"></ra-renter-wizard-header>
<div class="wizard-page container">
  <form class="form-grid">
    <ra-info-panel [titleKey]="'RenterWizard.WhyWeNeedInfoHeader'">
      {{ 'RenterWizard.WhyWeNeedInfoHeaderInfo' | translate }}
    </ra-info-panel>
        <div class="title">{{ 'RenterWizard.IncomeInformation' | translate }}</div>
        <div class="info-icon-container">
        <div class="subtitle">
          {{ 'RenterWizard.NettoMonthlyIncome' | translate }}
        </div>
        <div class="info-icon"
             tooltip="{{ 'RenterWizard.NettoMonthlyIncomeInfo' | translate }}"
             placement="auto">
          <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
        </div>
      </div>
    <div>
      <div class="line money-input">
        <input
          currencyMask
          [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }"
          name="monthlyIncome"
          [(ngModel)]="person.monthlyIncome"
          [readOnly]="readonly"
          min="1"
          max="10000"
          required
        />
        <div class="per-month">{{ 'RenterWizard.SlashMonth' | translate }}</div>
      </div>
      <div class="hint">
        {{ 'RenterWizard.NettoMonthlyIncomeExplanation' | translate }}
      </div>
    </div>
    <div class="other-income-header">
      <div class="info-icon-container">
        <div class="subtitle">
          {{ 'RenterWizard.OtherIncome' | translate }}
          <div class="hint">
            {{ 'RenterWizard.OtherIncomeExplanation' | translate }}
          </div>
        </div>
          <div class="info-icon"
               tooltip="{{ 'RenterWizard.OtherIncomeInfo' | translate }}"
               placement="auto">
            <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
          </div>
        </div>
      <button
        *ngIf="!readonly && person.otherIncomes.length <= 10"
        (click)="addOtherIncomeRow()"
        class="default icon-button">
        <svg-icon src="assets/images/plus.svg" [applyCss]="true"></svg-icon>
      </button>
    </div>
    <div class="other-icon-rows">
      <div
        *ngFor="let otherIncome of person.otherIncomes; index as  i"
        class="other-income-row">
        <div class="other-income">
          <select
            name="otherIncomeTypeSelect_{{i}}"
            [(ngModel)]="otherIncome.type"
            [disabled]="readonly"
            >
            <option [ngValue]="null">{{ 'RenterWizard.ChooseOtherIncomeType' | translate }}</option>
            <option
              *ngFor="let otherIncomeType of otherIncomeTypes"
              [value]="otherIncomeType.value">{{ otherIncomeType.text }}</option>
          </select>
          <div class="money-input">
            <input
              currencyMask
              [options]="{ align: 'left', prefix: '', thousands: '.', decimal: ',' }"
              name="otherIncomeValue_{{i}}"
              min="1"
              max="10000"
              [(ngModel)]="otherIncome.income"
              [readOnly]="readonly"
              required />
          </div>
        </div>
        <button
          *ngIf="!readonly"
          (click)="removeOtherIncomeRow(otherIncome)"
          class="default icon-button">
          <svg-icon src="assets/images/minus.svg" [applyCss]="true"></svg-icon>
        </button>
      </div>
    </div>
  </form>
</div>
<ra-wizard-footer
  [step]="3" [totalSteps]="4"
  [autoSave]="!profile.isReadonly"
  (previous)="previous()"
  (next)="next()"
  (beforeNavigate)="beforeNavigate()"
></ra-wizard-footer>
