import { TranslateService } from '@ngx-translate/core';
import { OwnerProfileDetailsDto } from './../../shared/api/rentassistant-api.generated';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {

constructor(private translate: TranslateService) { }

getAvatarIcon(rp: OwnerProfileDetailsDto) {
  if (rp == null || rp.generalDetails == null) {
    return './../../../../assets/images/avatars/avatar-1.svg';
  }
  let avatorNumber = 0;
  if (rp.generalDetails.numberOfAdults != null) {
    avatorNumber = avatorNumber + rp.generalDetails.numberOfAdults;
  }
  if (rp.generalDetails.numberOfChildren != null) {
    avatorNumber = avatorNumber + rp.generalDetails.numberOfChildren;
  }
  if (avatorNumber > 5) {
    avatorNumber = 5;
  }
  return './../../../../assets/images/avatars/avatar-' + avatorNumber.toString() + '.svg';
}

boolTranslate(value: boolean) {
  if (value === true) {
    return this.translate.instant('Common.Yes');
  } else {
    return this.translate.instant('Common.No');
  }
}

}
