<ra-renter-wizard-header
  [person]="person"
  (beforeClose)="beforeClose()">
</ra-renter-wizard-header>
<div class="wizard-page container">
  <form class="form-grid">
    <ra-info-panel
      [titleKey]="'RenterWizard.WhyWeNeedCurrentLandlordText'"
    ></ra-info-panel>
    <div>
      <ra-toggle-switch
        name="canContactCurrentLandlord"
        [(ngModel)]="canContactCurrentLandlord"
        [disabled]="readonly"
      >
        {{ 'RenterWizard.CanContactCurrentLandlord' | translate }}
      </ra-toggle-switch>
      <div class="hint">
        {{ 'RenterWizard.CanContactCurrentLandlordExplanation' | translate }}
      </div>
    </div>
    <ng-container *ngIf="canContactCurrentLandlord">
      <div class="gap"></div>
      <div class="subtitle">{{ 'RenterWizard.Name' | translate }}</div>
      <input
        name="name"
        [(ngModel)]="name"
        [placeholder]="'RenterWizard.Name' | translate"
        [readOnly]="readonly"
        required />
      <div class="gap"></div>
      <div class="subtitle">{{ 'RenterWizard.Email' | translate }}</div>
      <input
        name="email"
        type="email"
        [(ngModel)]="email"
        [placeholder]="'RenterWizard.Email' | translate"
        [readOnly]="readonly"
        required />
      <div class="gap"></div>
      <div class="subtitle">{{ 'RenterWizard.PhoneNumber' | translate }}</div>
      <input
        name="phone"
        [(ngModel)]="phone"
        [placeholder]="'RenterWizard.PhoneNumber' | translate"
        [readOnly]="readonly"
        required />
    </ng-container>
  </form>
</div>
<ra-wizard-footer
  [step]="5" [totalSteps]="5"
  [autoSave]="!profile.isReadonly"
  (previous)="previous()"
  (beforeNavigate)="beforeNavigate()"
  (next)="next()"
></ra-wizard-footer>
