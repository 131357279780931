import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LocalizeRouterModule } from 'ngx-localize-router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ActivateComponent } from './activate/activate.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'login/:realEstateObjectId', component: LoginComponent },
  { path: 'registreer', component: RegisterComponent },
  { path: 'registreer/:realEstateObjectId', component: RegisterComponent },
  { path: 'activeer', component: ActivateComponent },
  { path: 'activeer/:realEstateObjectId', component: ActivateComponent },
  { path: 'wachtwoord-vergeten', component: ForgotPasswordComponent },
  { path: 'wachtwoord-vergeten/:realEstateObjectId', component: ForgotPasswordComponent },
  { path: 'wachtwoord-reset', component: ResetPasswordComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes)
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class HomeRoutingModule { }
