import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  getName,
} from 'src/app/core/models';
import { PersonDetailsDto, ProfileDetailsDto } from 'src/app/shared/api/rentassistant-api.generated';

@Component({
  selector: 'ra-delete-person-confirmation',
  templateUrl: './delete-person-confirmation.component.html',
  styleUrls: ['./delete-person-confirmation.component.scss']
})
export class DeletePersonConfirmationComponent {
  isLoading = false;
  subscriptions: Subscription[] = [];

  @Input()
  profile: ProfileDetailsDto;

  @Input()
  person: PersonDetailsDto = { id: 0 };

  @Output()
  delete = new EventEmitter<void>();

  @Output()
  cancel = new EventEmitter<void>();

  constructor() {}

  getName() {
    return getName(this.person);
  }

  deletePerson() {
    const personIndex = this.profile
                            .personDetails
                            .findIndex(x => x.id === this.person.id);

    this.profile.personDetails.splice(personIndex, 1);
    this.isLoading = true;
    this.delete.next();
    this.isLoading = false;
  }
}
