import { AanDeSlagComponent } from './aan-de-slag/aan-de-slag.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocalizeRouterModule } from 'ngx-localize-router';
const routes: Routes = [
  { path: 'documentation', component: DocumentationComponent },
  { path: 'documentation/aandeslag', component: AanDeSlagComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes)
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class DocumentationRoutingModule { }
