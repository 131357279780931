<div class="section-list-item__container">
  <div class="icon-section">
    <div class="icon-container">
      <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
    </div>
  </div>

  <div class="text">
    {{ textKey | translate }}
  </div>

  <div *ngIf="tooltip" class="item-icon"
  tooltip="{{ tooltip | translate }}"
  placement="bottom">
    <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
  </div>

  <div class="locked-box" *ngIf="isLocked" >
    <svg-icon src="assets/images/lock.svg"></svg-icon>
  </div>
  <div class="arrow-box">
    <fa-icon [icon]="faChevronRight" *ngIf="!hideRightArrow"></fa-icon>
  </div>
  <div (click)="onClickHost()" class="item-link"></div>
</div>
