/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./choice-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./choice-item.component";
var styles_ChoiceItemComponent = [i0.styles];
var RenderType_ChoiceItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChoiceItemComponent, data: {} });
export { RenderType_ChoiceItemComponent as RenderType_ChoiceItemComponent };
export function View_ChoiceItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "left-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "content-container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "right-filler"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconTemplate; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChoiceItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-choice-item", [], [[2, "disabled", null], [2, "horizontal", null], [2, "is-first", null], [2, "is-last", null], [2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChoiceItemComponent_0, RenderType_ChoiceItemComponent)), i1.ɵdid(1, 49152, null, 1, i3.ChoiceItemComponent, [], null, null), i1.ɵqud(603979776, 1, { iconTemplate: 0 })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).controlDisabled; var currVal_1 = i1.ɵnov(_v, 1).horizontal; var currVal_2 = i1.ɵnov(_v, 1).isFirst; var currVal_3 = i1.ɵnov(_v, 1).isLast; var currVal_4 = i1.ɵnov(_v, 1).selected; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
var ChoiceItemComponentNgFactory = i1.ɵccf("ra-choice-item", i3.ChoiceItemComponent, View_ChoiceItemComponent_Host_0, { disabled: "disabled", value: "value", valueProvider: "valueProvider", selectedPredicate: "selectedPredicate" }, {}, ["*"]);
export { ChoiceItemComponentNgFactory as ChoiceItemComponentNgFactory };
