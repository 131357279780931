/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/tooltip";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "ngx-bootstrap/positioning";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i7 from "angular-svg-icon";
import * as i8 from "@angular/common";
import * as i9 from "./info-panel.component";
var styles_InfoPanelComponent = [i0.styles];
var RenderType_InfoPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoPanelComponent, data: {} });
export { RenderType_InfoPanelComponent as RenderType_InfoPanelComponent };
function View_InfoPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [["class", "info-icon"], ["placement", "auto"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.TooltipDirective, [i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.TooltipConfig, i1.ElementRef, i1.Renderer2, i4.PositioningService], { tooltip: [0, "tooltip"], placement: [1, "placement"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "svg-icon", [["applyCss", "true"], ["src", "assets/images/info-circle.svg"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(4, 1032192, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i7.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.tooltip)), ""); var currVal_1 = "auto"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "assets/images/info-circle.svg"; var currVal_3 = "true"; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_InfoPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "svg-icon", [["applyCss", "true"], ["src", "assets/images/info.svg"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(3, 1032192, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i7.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "info-icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "titleText"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoPanelComponent_1)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "assets/images/info.svg"; var currVal_1 = "true"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.tooltip; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.titleKey)); _ck(_v, 6, 0, currVal_2); }); }
export function View_InfoPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-info-panel", [], null, null, null, View_InfoPanelComponent_0, RenderType_InfoPanelComponent)), i1.ɵdid(1, 49152, null, 0, i9.InfoPanelComponent, [], null, null)], null, null); }
var InfoPanelComponentNgFactory = i1.ɵccf("ra-info-panel", i9.InfoPanelComponent, View_InfoPanelComponent_Host_0, { titleKey: "titleKey", tooltip: "tooltip" }, {}, ["*"]);
export { InfoPanelComponentNgFactory as InfoPanelComponentNgFactory };
