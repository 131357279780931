import { Component, HostBinding } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';

@Component({
  selector: 'ra-organization-director',
  templateUrl: './organization-contact-info.component.html',
  styleUrls: ['./organization-contact-info.component.scss']
})
export class OrganizationContactInfoComponent extends StepBaseComponent {
  @HostBinding('class') hostClass = 'wizard';

  previous() {
    this.localizedRouter.navigate(['../organisatie-adres'], { relativeTo: this.route });
  }

  next() {
    if (this.readonly) {
      this.state.closeWizard();
    } else {
      this.localizedRouter.navigate(['../samenvatting'], { relativeTo: this.route });
    }
  }
}
