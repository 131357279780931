/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../feedback/feedback.component.ngfactory";
import * as i3 from "../feedback/feedback.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./feedback-selector.component";
var styles_FeedbackSelectorComponent = [i0.styles];
var RenderType_FeedbackSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackSelectorComponent, data: {} });
export { RenderType_FeedbackSelectorComponent as RenderType_FeedbackSelectorComponent };
function View_FeedbackSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ra-feedback", [["titleKey", "Candidacies.Congrats"], ["titleSubtextKey", "Candidacies.SelectedText"]], null, null, null, i2.View_FeedbackComponent_0, i2.RenderType_FeedbackComponent)), i1.ɵdid(1, 114688, null, 1, i3.FeedbackComponent, [], { titleKey: [0, "titleKey"], titleSubtextKey: [1, "titleSubtextKey"], brokerImageSource: [2, "brokerImageSource"], brokerNameLine: [3, "brokerNameLine"], mainText: [4, "mainText"] }, null), i1.ɵqud(603979776, 1, { footerTemplate: 0 }), i1.ɵpod(3, { brokerName: 0 }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Candidacies.Congrats"; var currVal_1 = "Candidacies.SelectedText"; var currVal_2 = _co.candidacy.broker.logoUrl; var currVal_3 = i1.ɵunv(_v, 1, 3, i1.ɵnov(_v, 4).transform("Candidacies.WillContact", _ck(_v, 3, 0, _co.candidacy.broker.name))); var currVal_4 = _co.statusComment; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_FeedbackSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-feedback", [["titleKey", "Candidacies.Bummer"], ["titleSubtextKey", "Candidacies.NotSelectedText"]], null, null, null, i2.View_FeedbackComponent_0, i2.RenderType_FeedbackComponent)), i1.ɵdid(1, 114688, null, 1, i3.FeedbackComponent, [], { titleKey: [0, "titleKey"], titleSubtextKey: [1, "titleSubtextKey"] }, null), i1.ɵqud(603979776, 2, { footerTemplate: 0 })], function (_ck, _v) { var currVal_0 = "Candidacies.Bummer"; var currVal_1 = "Candidacies.NotSelectedText"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FeedbackSelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-feedback", [["titleKey", "Candidacies.UpdateAsked"], ["titleSubtextKey", "Candidacies.UpdateAskedText"]], null, null, null, i2.View_FeedbackComponent_0, i2.RenderType_FeedbackComponent)), i1.ɵdid(1, 114688, null, 1, i3.FeedbackComponent, [], { titleKey: [0, "titleKey"], titleSubtextKey: [1, "titleSubtextKey"], brokerImageSource: [2, "brokerImageSource"], brokerNameLine: [3, "brokerNameLine"], mainText: [4, "mainText"] }, null), i1.ɵqud(603979776, 3, { footerTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Candidacies.UpdateAsked"; var currVal_1 = "Candidacies.UpdateAskedText"; var currVal_2 = _co.candidacy.broker.logoUrl; var currVal_3 = _co.candidacy.broker.name; var currVal_4 = _co.statusComment; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_FeedbackSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackSelectorComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackSelectorComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackSelectorComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.candidacy.status === "Approved"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.candidacy.status === "Rejected"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.candidacy.status === "InfoRequested"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_FeedbackSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-feedback-selector", [], null, null, null, View_FeedbackSelectorComponent_0, RenderType_FeedbackSelectorComponent)), i1.ɵdid(1, 49152, null, 0, i6.FeedbackSelectorComponent, [], null, null)], null, null); }
var FeedbackSelectorComponentNgFactory = i1.ɵccf("ra-feedback-selector", i6.FeedbackSelectorComponent, View_FeedbackSelectorComponent_Host_0, { candidacy: "candidacy" }, {}, []);
export { FeedbackSelectorComponentNgFactory as FeedbackSelectorComponentNgFactory };
