import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export var ErrorType;
(function (ErrorType) {
    ErrorType["Validation"] = "Validation";
    ErrorType["Server"] = "Server";
    ErrorType["Conflict"] = "Conflict";
})(ErrorType || (ErrorType = {}));
var RentAssistantError = /** @class */ (function () {
    function RentAssistantError(errorType, message, response) {
        this.errorType = errorType;
        this.message = message;
        this.response = response;
    }
    return RentAssistantError;
}());
export { RentAssistantError };
var ErrorService = /** @class */ (function () {
    function ErrorService() {
        this.errors$ = new Subject();
    }
    ErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
