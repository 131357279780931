<ra-modal-header (cancel)="cancel.next()">
  <ng-template>
    <div class="header">{{ titleKey | translate }}</div>
  </ng-template>
</ra-modal-header>

<form class="content">
  <div class="datepicker-container">
    <ngb-datepicker class="full-width"
        #dp
        [(ngModel)]="model"
        [startDate]="model"
        name="datepicker"
        [minDate]="minDate"
        [maxDate]="maxDate">
    </ngb-datepicker>
  </div>
  <button class="primary" (click)="chooseDate()">
    {{ 'Common.Confirm' | translate }}
  </button>
</form>
