import {
  CandidacyDto,
  Status,
  PersonDetailsDto,
  ProfileDetailsDto,
  PersonLegalType} from 'src/app/shared/api/rentassistant-api.generated';

export interface LandlordContact {
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export function getName(person: PersonDetailsDto): string | undefined {
  if (person.legalType === PersonLegalType.LegalEntity) {
    return person.organizationName;
  }
  return `${person.firstName || ''} ${person.lastName || ''}`.trim();
}

export function resetBrokerLogoUrl(candidacy: CandidacyDto): CandidacyDto {
  return candidacy;
}

export function makeEmptyPerson(): PersonDetailsDto {
  return {
    id: 0,
    legalType: PersonLegalType.Person,
    address: {}
  };
}

export function indexCandidacyTenants(candidacy: CandidacyDto): CandidacyDto {
  if (candidacy &&
      candidacy.profile &&
      candidacy.profile.personDetails &&
      candidacy.profile.personDetails.length > 0) {
        candidacy.profile.personDetails.forEach((cand, idx) => cand.id = idx + 1);
      }
  return candidacy;
}

export function indexTenants(profile: ProfileDetailsDto): ProfileDetailsDto {
  profile.personDetails.forEach((cand, idx) => cand.id = idx + 1);
  return profile;
}

export function makeEmptyCandidacy(): CandidacyDto {

  return {
    uniqueId: '',
    status: Status.Draft,
    statusChangedOn: new Date(),
    broker: { id: ' '},
    property: { id: '' },
    profile: {
      generalDetails: {},
      personDetails: [],
      attachments: []
    }
  } as CandidacyDto;
}
