import { Injectable } from '@angular/core';

export interface Environment {
  production?: boolean;
  api: {
    immoConnectUrl: string;
    rentAssistantUrl: string;
  };
  immoConnect: {
    partnerKey: string;
    applicationId: string;
    testLogin: string;
    testPassword: string;
    timeoutInMinutes: number;
  };
}


@Injectable()
export class DynamicEnvironment implements Environment {

  production = false;
  api = {
    immoConnectUrl: '',
    rentAssistantUrl: ''
  };
  immoConnect = {
    partnerKey: '',
    applicationId: '',
    testLogin: '',
    testPassword: '',
    timeoutInMinutes: 60
  };
}
