<ra-menu [visible]="menuVisible" (visibleChange)="menuVisibleChange($event)"></ra-menu>
<div class="header">
  <div class="left-container">
    <div *ngIf="menuIconVisible" class="icon-container visible-until-desktop">
      <span class="menu" (click)="toggleMenu($event)">☰</span>
    </div>
    <div class="action-icon" *ngIf="backIconVisible">
      <svg-icon src="assets/images/chevron-left.svg" (click)="navigateHome()" [applyCss]="true"></svg-icon>
    </div>
    <div class="content" *ngIf="logoVisible">
      <div class="logo">
        <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
      </div>
      <div class="name">
        clee
      </div>
    </div>
    <div class="menu-items">
      <ul>
        <li>
          <a
            href
            [routerLink]="['/app/contact' | localize]"
            [routerLinkActive]="'active'"
          >
            <div class="icon">
              <svg-icon
                src="/assets/images/mail.svg"
                [applyCss]="true"
              ></svg-icon>
            </div>
            <div class="text visible-from-wide-desktop">{{ 'Menu.Contact' | translate }}</div>
          </a>
        </li>
        <li>
          <a
            href
            [routerLink]="['/app/faq' | localize]"
            [routerLinkActive]="'active'"
          >
            <div class="icon">
              <svg-icon
                src="/assets/images/help-circle.svg"
                [applyCss]="true"
              ></svg-icon>
            </div>
            <div class="text visible-from-wide-desktop">{{ 'Menu.Faq' | translate }}</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- <div class="content-container">
    <div class="title">
      <h1>{{ titleKey | translate }}</h1>
      <h4>{{ subTitle }}</h4>
    </div>
  </div> -->
  <div class="right-container">
    <div class="menu-items">
      <ul>
        <li>
          <ra-language-selector></ra-language-selector>
          <!-- Fix Jonas - language change leads to Clee and not OwnersPortal -->
        </li>
        <li>
          <a href (click)="logOut()" class="empty-link">
            <!-- fix Jonas Logout goes to Clee and not Ownersportal -->
            <div class="icon">
              <svg-icon
                src="/assets/images/sign-out-alt-light.svg"
                [applyCss]="true"
              ></svg-icon>
            </div>
            <div class="text visible-from-wide-desktop">{{ 'Menu.LogOut' | translate }}</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

