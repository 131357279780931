<div class="language-choice" (click)="languagesListVisible = true">
  <span>
    {{ currentLang | uppercase }}
  </span>
  <ul *ngIf="languagesListVisible"
      (click)="languagesListVisible = false">
    <li *ngFor="let lang of languages">
      <div class="language"
           (click)="changeLang(lang, $event)"
           [ngClass]="{ 'active': isCurrentLang(lang) }">
          <div class="check">
            <div *ngIf="isCurrentLang(lang)">
              <svg-icon src="/assets/images/check.svg" [applyCss]="true"></svg-icon>
            </div>
          </div>
          {{ 'Menu.' + lang | translate }}
      </div>
    </li>
  </ul>
  <div class="chevron">
    <svg-icon src="/assets/images/chevron-down.svg" [applyCss]="true"></svg-icon>
  </div>
</div>
