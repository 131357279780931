import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { AutoUnsubscribeComponent } from '../../../shared/auto-unsubscribe.component';
import { filter } from 'rxjs/operators';
var WhenComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WhenComponent, _super);
    function WhenComponent(localizedRouter, route, modal, stateService) {
        var _this = _super.call(this) || this;
        _this.localizedRouter = localizedRouter;
        _this.route = route;
        _this.modal = modal;
        _this.stateService = stateService;
        _this.now = new Date();
        return _this;
    }
    WhenComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.stateService
            .state$
            .pipe(filter(function (x) { return !!x; }))
            .subscribe(function (state) {
            _this.profile = state.profile;
            _this.state = state;
        }));
    };
    WhenComponent.prototype.help = function () {
    };
    WhenComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../bewoners'], { relativeTo: this.route });
    };
    WhenComponent.prototype.next = function () {
        this.localizedRouter.navigate(['../opties'], { relativeTo: this.route });
    };
    WhenComponent.prototype.durationPreferenceChanged = function (durationPreference) {
        if (!durationPreference) {
            this.profile.generalDetails.durationType = null;
            this.profile.generalDetails.durationMonths = null;
            this.profile.generalDetails.durationYears = null;
        }
    };
    WhenComponent.prototype.openDatepicker = function () {
        this.modal.open(this.datepickerModal);
    };
    WhenComponent.prototype.onDateChosen = function (dateStruct) {
        this.profile.generalDetails.expectedMovingDate = {
            day: dateStruct.day,
            month: dateStruct.month,
            year: dateStruct.year
        };
    };
    Object.defineProperty(WhenComponent.prototype, "expectedMovingDate", {
        get: function () {
            if (this.profile &&
                this.profile.generalDetails &&
                this.profile.generalDetails.expectedMovingDate) {
                var dateDto = this.profile.generalDetails.expectedMovingDate;
                return new Date(dateDto.year, dateDto.month - 1, dateDto.day);
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return WhenComponent;
}(AutoUnsubscribeComponent));
export { WhenComponent };
