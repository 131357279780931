<ng-template #addressSmall>
  <div class="property-address-small">
    {{ candidacy?.property?.street }}
    {{ candidacy?.property?.houseNumber }}
    {{ candidacy?.property?.postalCode }}
    {{ candidacy?.property?.city }}
  </div>
</ng-template>

<div class="candidacy-card" (click)="onClick()">
  <div class="">
    <img *ngIf="candidacy?.property?.imageUrl; else propertyPlaceholder"
         class="property-image"
         [src]="candidacy?.property?.imageUrl" />
    <ng-template #propertyPlaceholder>
      <img class="property-image" [src]="'assets/images/property-placeholder.svg'" />
    </ng-template>
  </div>
  <div class="info">
    <div class="broker">
      {{ candidacy?.broker?.name }}
      <img [src]="candidacy?.broker?.logoUrl" />
    </div>
    <ng-container [ngSwitch]="candidacy?.status">
      <div *ngSwitchCase="'Approved'">
        <div class="approved">
          {{ 'Candidacies.ApprovedCardText' | translate }}
        </div>
        <ng-container *ngTemplateOutlet="addressSmall"></ng-container>
      </div>
      <div *ngSwitchCase="'InfoRequested'">
        <div class="info-requested">
          {{ 'Candidacies.InfoRequestedCardText' | translate }}
        </div>
        <ng-container *ngTemplateOutlet="addressSmall"></ng-container>
      </div>
      <div *ngSwitchCase="'Rejected'">
        <div class="rejected">
          {{ 'Candidacies.RejectedCardText' | translate }}
        </div>
        <ng-container *ngTemplateOutlet="addressSmall"></ng-container>
      </div>
      <div *ngSwitchDefault>
        <div class="property-address">{{ candidacy?.property?.street }} {{ candidacy?.property?.houseNumber }}</div>
        <div class="property-address">{{ candidacy?.property?.postalCode }} {{ candidacy?.property?.city }}</div>
      </div>
    </ng-container>
    <div class="candidacy-info">
      <div class="candidacy-date">{{ candidacy?.statusChangedOn | date: 'd/M/yyyy' }}</div>
      <div class="filler"></div>
      <div class="candidacy-status">
        <ra-candidacy-status-badge *ngIf="showStatus"
          [status]="candidacy?.status"
        ></ra-candidacy-status-badge>
      </div>
    </div>
  </div>
  <div class="nav-indicator" *ngIf="showArrowRight === true">
    <fa-icon [icon]="faChevronRight"></fa-icon>
  </div>
</div>
