<ra-renter-wizard-header
  [person]="person">
</ra-renter-wizard-header>
<div class="wizard-page container">
  <form class="form-grid">
    <div class="title">{{ 'RenterWizard.ContactInformation' | translate }}</div>
    <div class="subtitle">{{ 'RenterWizard.Name' | translate }}</div>
    <input
      name="organizationContactName"
      [(ngModel)]="person.organizationContactName"
      [placeholder]="'RenterWizard.Name' | translate"
      [readOnly]="person.organizationFoundInKbo  || readonly"
      required
    />
    <div class="subtitle">{{ 'RenterWizard.Function' | translate }}</div>
    <input
      name="organizationContactFunction"
      [(ngModel)]="person.organizationContactFunction"
      [placeholder]="'RenterWizard.Function' | translate"
      [readOnly]="person.organizationFoundInKbo || readonly"
      required
    />
    <div class="subtitle">{{ 'RenterWizard.Email' | translate }}</div>
    <input
      name="organizationContactEmail"
      [(ngModel)]="person.organizationContactEmail"
      [placeholder]="'RenterWizard.Email' | translate"
      [readOnly]="readonly"
      required
    />
    <div class="subtitle">{{ 'RenterWizard.PhoneNumber' | translate }}</div>
    <input
      name="organizationContactPhone"
      [(ngModel)]="person.organizationContactPhone"
      [placeholder]="'RenterWizard.PhoneNumber' | translate"
      [readOnly]="readonly"
      required
    />

  </form>
</div>
<ra-wizard-footer
  [step]="3" [totalSteps]="3"
  [autoSave]="!profile.isReadonly"
  (next)="next()"
  (previous)="previous()"
></ra-wizard-footer>
