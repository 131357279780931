import { Directive, forwardRef, Input } from '@angular/core';

import {
  AbstractControl,
  ValidationErrors,
  Validators,
  NG_VALIDATORS,
  Validator
} from '@angular/forms';

@Directive({
  selector: 'ra-toggle-switch[ra-required][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxRequiredValidatorDirective),
      multi: true
    }
  ]
})
export class CheckboxRequiredValidatorDirective implements Validator {
  // tslint:disable-next-line: no-input-rename
  @Input('ra-required')
  required = '';

  validate(control: AbstractControl): ValidationErrors | null {
    const required = this.required !== 'false';
    return required ? Validators.requiredTrue(control) : null;
  }
}
