import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { CandidacyDto } from '../../shared/api/rentassistant-api.generated';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

@Injectable({
  providedIn: 'root'
})
export class CandidacyResolver implements Resolve<CandidacyDto> {
  constructor(private api: RentAssistantApi) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<CandidacyDto> | Promise<CandidacyDto> {
    const candidacyId = route.paramMap.get('candidacyId');
    if (candidacyId === null) {
      return throwError('candidacyId parameter missing');
    }

    return this.api.getCandidacy(candidacyId);
  }
}
