<ra-feedback
  *ngIf="candidacy.status === 'Approved'"
  titleKey="Candidacies.Congrats"
  titleSubtextKey="Candidacies.SelectedText"
  [brokerImageSource]="candidacy.broker.logoUrl"
  [brokerNameLine]="
    'Candidacies.WillContact' | translate: { brokerName: candidacy.broker.name }
  "
  [mainText]="statusComment"
></ra-feedback>

<ra-feedback
  *ngIf="candidacy.status === 'Rejected'"
  titleKey="Candidacies.Bummer"
  titleSubtextKey="Candidacies.NotSelectedText"
></ra-feedback>

<ra-feedback
  *ngIf="candidacy.status === 'InfoRequested'"
  titleKey="Candidacies.UpdateAsked"
  titleSubtextKey="Candidacies.UpdateAskedText"
  [brokerImageSource]="candidacy.broker.logoUrl"
  [brokerNameLine]="candidacy.broker.name"
  [mainText]="statusComment"
></ra-feedback>
