import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ra-datepicker-modal',
  templateUrl: './datepicker-modal.component.html',
  styleUrls: ['./datepicker-modal.component.scss']
})
export class DatepickerModalComponent implements OnInit {

  model: NgbDateStruct;

  @Input()
  titleKey: string;

  @Input()
  date: NgbDateStruct;

  @Input() minYear: number;
  @Input() maxYear: number;

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  dateChosen = new EventEmitter<NgbDateStruct>();
  minDate = {year: 1920, month: 1, day: 1};
  maxDate = {year: 2005, month: 12, day: 31};
  constructor(private calendar: NgbCalendar) { }

  ngOnInit() {
    this.model = this.date || this.calendar.getToday();
    if (this.minYear == null || this.maxYear == null) {
      this.minDate = null;
      this.maxDate = null;
    } else {
      this.minDate.year = this.minYear;
      this.maxDate.year = this.maxYear;
    }
  }

  chooseDate() {
    this.dateChosen.next(this.model);
    this.cancel.next();
  }
}
