/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "../../shared/components/checkable-list-item/checkable-list-item.component.ngfactory";
import * as i5 from "../../shared/components/checkable-list-item/checkable-list-item.component";
import * as i6 from "@angular/router";
import * as i7 from "../../shared/components/person-list-item/person-list-item.component.ngfactory";
import * as i8 from "../../shared/components/person-list-item/person-list-item.component";
import * as i9 from "@angular/common";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../../shared/components/section-list-item/section-list-item.component.ngfactory";
import * as i12 from "../../shared/components/section-list-item/section-list-item.component";
import * as i13 from "./profile.component";
import * as i14 from "../../core/services/localized-router.service";
var styles_ProfileComponent = [i0.styles];
var RenderType_ProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileComponent, data: {} });
export { RenderType_ProfileComponent as RenderType_ProfileComponent };
function View_ProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [["src", "assets/images/house-small.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "assets/images/house-small.svg"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [["src", "assets/images/people.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "assets/images/people.svg"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProfileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(2, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(4, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(6, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(8, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.personalInfoProvider(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.contactInformationInfoProvider(_v.parent.context.$implicit); _ck(_v, 4, 0, currVal_3); var currVal_5 = _co.incomeInformationProvider(_v.parent.context.$implicit); _ck(_v, 6, 0, currVal_5); var currVal_7 = _co.currentRentInformationProvider(_v.parent.context.$implicit); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).active; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 4).active; _ck(_v, 3, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 6).active; _ck(_v, 5, 0, currVal_4); var currVal_6 = i1.ɵnov(_v, 8).active; _ck(_v, 7, 0, currVal_6); }); }
function View_ProfileComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(2, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(4, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(6, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.organizationInfoProvider(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.organizationAddressInfoProvider(_v.parent.context.$implicit); _ck(_v, 4, 0, currVal_3); var currVal_5 = _co.organizationContactInfoProvider(_v.parent.context.$implicit); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).active; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 4).active; _ck(_v, 3, 0, currVal_2); var currVal_4 = i1.ɵnov(_v, 6).active; _ck(_v, 5, 0, currVal_4); }); }
function View_ProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ra-person-list-item", [], null, [[null, "itemClick"], [null, "rightArrowClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClickHost() !== false);
        ad = (pd_0 && ad);
    } if (("itemClick" === en)) {
        var pd_1 = (_co.togglePersonExpand(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("rightArrowClick" === en)) {
        var pd_2 = (_co.startPersonWizard(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_PersonListItemComponent_0, i7.RenderType_PersonListItemComponent)), i1.ɵdid(2, 49152, null, 0, i8.PersonListItemComponent, [], { name: [0, "name"], state: [1, "state"], isExpanded: [2, "isExpanded"] }, { itemClick: "itemClick", rightArrowClick: "rightArrowClick" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_4)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_5)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFullName(_v.context.$implicit); var currVal_1 = _co.getPersonstate(_v.context.$implicit); var currVal_2 = _co.isExpanded(_v.context.$implicit); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.isExpanded(_v.context.$implicit) && (_v.context.$implicit.legalType !== "LegalEntity")); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.isExpanded(_v.context.$implicit) && (_v.context.$implicit.legalType === "LegalEntity")); _ck(_v, 6, 0, currVal_4); }, null); }
function View_ProfileComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "default list-button left-icon-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addPerson() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["src", "assets/images/plus.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "assets/images/plus.svg"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("RenterWizard.AddRenter")); _ck(_v, 4, 0, currVal_2); }); }
function View_ProfileComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svg-icon", [["src", "assets/images/attachments.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null)], function (_ck, _v) { var currVal_0 = "assets/images/attachments.svg"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProfileComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ra-person-list-item", [], null, [[null, "rightArrowClick"], [null, "itemClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClickHost() !== false);
        ad = (pd_0 && ad);
    } if (("rightArrowClick" === en)) {
        var pd_1 = (_co.startAttachmentsWizard(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("itemClick" === en)) {
        var pd_2 = (_co.startAttachmentsWizard(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_PersonListItemComponent_0, i7.RenderType_PersonListItemComponent)), i1.ɵdid(2, 49152, null, 0, i8.PersonListItemComponent, [], { name: [0, "name"], state: [1, "state"], isExpandable: [2, "isExpandable"] }, { itemClick: "itemClick", rightArrowClick: "rightArrowClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFullName(_v.context.$implicit); var currVal_1 = _co.getPersonAttachmentstate(_v.context.$implicit); var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ra-section-list-item", [["textKey", "Candidacies.General"], ["tooltip", "Candidacies.GeneralInfo"]], null, [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.startGeneralWizard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SectionListItemComponent_0, i11.RenderType_SectionListItemComponent)), i1.ɵdid(1, 49152, null, 1, i12.SectionListItemComponent, [], { textKey: [0, "textKey"], tooltip: [1, "tooltip"], isLocked: [2, "isLocked"] }, { itemClick: "itemClick" }), i1.ɵqud(603979776, 1, { iconTemplate: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["iconTemplate", 2]], null, 0, null, View_ProfileComponent_1)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(5, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(7, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ra-checkable-list-item", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CheckableListItemComponent_0, i4.RenderType_CheckableListItemComponent)), i1.ɵdid(9, 245760, null, 0, i5.CheckableListItemComponent, [i6.Router], { infoProvider: [0, "infoProvider"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "ra-section-list-item", [["textKey", "Candidacies.Tenants"], ["tooltip", "Candidacies.TenantsInfo"]], null, [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.initPersonWizard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SectionListItemComponent_0, i11.RenderType_SectionListItemComponent)), i1.ɵdid(11, 49152, null, 1, i12.SectionListItemComponent, [], { hideRightArrow: [0, "hideRightArrow"], textKey: [1, "textKey"], tooltip: [2, "tooltip"], isLocked: [3, "isLocked"] }, { itemClick: "itemClick" }), i1.ɵqud(603979776, 2, { iconTemplate: 0 }), (_l()(), i1.ɵand(0, [[2, 2], ["iconTemplate", 2]], null, 0, null, View_ProfileComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_3)), i1.ɵdid(15, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_6)), i1.ɵdid(17, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 3, "ra-section-list-item", [["textKey", "Candidacies.Attachments"]], null, [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.initAttachmentsWizard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SectionListItemComponent_0, i11.RenderType_SectionListItemComponent)), i1.ɵdid(19, 49152, null, 1, i12.SectionListItemComponent, [], { hideRightArrow: [0, "hideRightArrow"], textKey: [1, "textKey"], isLocked: [2, "isLocked"] }, { itemClick: "itemClick" }), i1.ɵqud(603979776, 3, { iconTemplate: 0 }), (_l()(), i1.ɵand(0, [[3, 2], ["iconTemplate", 2]], null, 0, null, View_ProfileComponent_7)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_8)), i1.ɵdid(23, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Candidacies.General"; var currVal_1 = "Candidacies.GeneralInfo"; var currVal_2 = _co.profile.isReadonly; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.residentsProvider; _ck(_v, 5, 0, currVal_4); var currVal_6 = _co.whenProvider; _ck(_v, 7, 0, currVal_6); var currVal_8 = _co.extraOptionsProvider; _ck(_v, 9, 0, currVal_8); var currVal_9 = false; var currVal_10 = "Candidacies.Tenants"; var currVal_11 = "Candidacies.TenantsInfo"; var currVal_12 = _co.profile.isReadonly; _ck(_v, 11, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = _co.profile.personDetails; _ck(_v, 15, 0, currVal_13); var currVal_14 = !_co.profile.isReadonly; _ck(_v, 17, 0, currVal_14); var currVal_15 = true; var currVal_16 = "Candidacies.Attachments"; var currVal_17 = _co.profile.isReadonly; _ck(_v, 19, 0, currVal_15, currVal_16, currVal_17); var currVal_18 = _co.profile.personDetails; _ck(_v, 23, 0, currVal_18); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 5).active; _ck(_v, 4, 0, currVal_3); var currVal_5 = i1.ɵnov(_v, 7).active; _ck(_v, 6, 0, currVal_5); var currVal_7 = i1.ɵnov(_v, 9).active; _ck(_v, 8, 0, currVal_7); }); }
export function View_ProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-profile", [], null, null, null, View_ProfileComponent_0, RenderType_ProfileComponent)), i1.ɵdid(1, 114688, null, 0, i13.ProfileComponent, [i14.LocalizedRouter, i10.TranslateService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileComponentNgFactory = i1.ɵccf("ra-profile", i13.ProfileComponent, View_ProfileComponent_Host_0, { profile: "profile", alert: "alert", baseUrl: "baseUrl" }, { addRenter: "addRenter" }, []);
export { ProfileComponentNgFactory as ProfileComponentNgFactory };
