import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, } from '@angular/core';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe.component';
import { ProfileWizardMode } from '../../../core/services/profilewizard-state.service';
import { filter } from 'rxjs/operators';
var WizardFooterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WizardFooterComponent, _super);
    function WizardFooterComponent(stateService) {
        var _this = _super.call(this) || this;
        _this.stateService = stateService;
        _this.autoSave = true;
        _this.previous = new EventEmitter();
        _this.next = new EventEmitter();
        _this.beforeNavigate = new EventEmitter();
        return _this;
    }
    Object.defineProperty(WizardFooterComponent.prototype, "step", {
        get: function () {
            return this.currentStep;
        },
        set: function (val) {
            this.currentStep = val;
            this.previousVisible = val > 1;
        },
        enumerable: true,
        configurable: true
    });
    WizardFooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.stateService
            .state$
            .pipe(filter(function (x) { return !!x; }))
            .subscribe(function (state) {
            _this.state = state;
            _this.candidacy = state.candidacy;
            _this.profile = state.profile;
            _this.wizardMode = state.wizardMode;
        }));
    };
    WizardFooterComponent.prototype.previousStep = function () {
        this.navigate(this.previous);
    };
    WizardFooterComponent.prototype.nextStep = function () {
        this.navigate(this.next);
    };
    WizardFooterComponent.prototype.navigate = function (eventEmitter) {
        var _this = this;
        this.beforeNavigate.next();
        if (!this.autoSave ||
            this.wizardMode === ProfileWizardMode.PublicProfile) {
            eventEmitter.next();
            return;
        }
        this.subscriptions.push(this.state.saveProfile().subscribe(function (updatedProfile) {
            _this.stateService.updateProfile(updatedProfile);
            eventEmitter.next();
        }));
    };
    return WizardFooterComponent;
}(AutoUnsubscribeComponent));
export { WizardFooterComponent };
