<div class="candidacy-card">
  <img class="property-image" src="assets/images/property-placeholder.svg" />
  <div class="info">
    <div class="broker" [ngClass]="{ 'animated-background': animate }">
    </div>
    <div>
      <div class="property-address" [ngClass]="{ 'animated-background': animate }"></div>
      <div class="property-address"[ngClass]="{ 'animated-background': animate }"></div>
    </div>
  </div>
</div>
