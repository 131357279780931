import { SharedModule } from './../shared/shared.module';
import { OwnersportalLoginComponent } from './components/ownersportal-login/ownersportal-login.component';
import { OwnersPortalRoutingModule } from './ownersportal-routes';
import { DropdownModule } from 'primeng/dropdown';
import { DragulaModule } from 'ng2-dragula';
import { CandidatureItemComponent } from './components/candidature-item/candidature-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwnersportalComponent } from './components/owernsportal/ownersportal.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TableComponent } from './components/table/table.component';
import { CandidatureDetailComponent } from './components/candidature-detail/candidature-detail.component';
import { ModalConfirmCandidatureComponent } from './components/modal-confirm-candidature/modal-confirm-candidature.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OwnerportalHeaderComponent } from './components/ownerportal-header/ownerportal-header.component';

@NgModule({
  imports: [
      BrowserModule,
      CommonModule,
      FormsModule,
      DragulaModule,
      DropdownModule,
      BrowserAnimationsModule,
      SidebarModule,
      OwnersPortalRoutingModule,
      SharedModule,
      DialogModule,
      TooltipModule,
      CheckboxModule,
      TooltipModule
  ],
  declarations: [
    OwnersportalComponent,
    SidebarComponent,
    TableComponent,
    CandidatureDetailComponent,
    CandidatureItemComponent,
    ModalConfirmCandidatureComponent,
    OwnersportalLoginComponent,
    OwnerportalHeaderComponent
  ],
  exports: [

  ]
})
export class OwnersportalModule { }
