import * as tslib_1 from "tslib";
import { StepBaseComponent } from '../step-base.component';
import { OtherIncomeType } from '../../../shared/api/rentassistant-api.generated';
var IncomeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IncomeComponent, _super);
    function IncomeComponent(localizedRouter, route, translate, stateService) {
        var _this = _super.call(this, localizedRouter, route, stateService) || this;
        _this.localizedRouter = localizedRouter;
        _this.route = route;
        _this.translate = translate;
        _this.stateService = stateService;
        _this.hostClass = 'wizard';
        return _this;
    }
    IncomeComponent.prototype.init = function () {
        var _this = this;
        if (!this.person.otherIncomes) {
            this.person.otherIncomes = [];
        }
        this.otherIncomeTypes =
            Object.keys(OtherIncomeType)
                .map(function (key) { return ({
                value: OtherIncomeType[key],
                text: _this.translate.instant("RenterWizard.OtherIncomeType_" + OtherIncomeType[key])
            }); })
                .sort(function (a, b) { return a.text > b.text ? 1 : -1; });
    };
    IncomeComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../contactgegevens'], {
            relativeTo: this.route
        });
    };
    IncomeComponent.prototype.next = function () {
        this.localizedRouter.navigate(['../huidige-huur'], { relativeTo: this.route });
    };
    IncomeComponent.prototype.addOtherIncomeRow = function () {
        this.person.otherIncomes.push({ type: null });
    };
    IncomeComponent.prototype.removeOtherIncomeRow = function (otherIncome) {
        var idx = this.person.otherIncomes.indexOf(otherIncome);
        if (idx > -1) {
            this.person.otherIncomes.splice(idx, 1);
        }
    };
    IncomeComponent.prototype.beforeNavigate = function () {
        // filter out invalid items (no type or no income)
        this.person.otherIncomes = this.person.otherIncomes.filter(function (otherIncome) { return !!otherIncome.type &&
            !!otherIncome.income; });
    };
    return IncomeComponent;
}(StepBaseComponent));
export { IncomeComponent };
