<ra-house-header
  [titleKey]="'Activation.Title'"
  [menuIconVisible]="false"
  [backIconVisible]="true">
</ra-house-header>
<div class="ra-card-container">
  <div class="ra-card">
    <form class="form-grid" (ngSubmit)="onSubmit()" #activateForm="ngForm">
      <p class="title">{{ "Activation.SubTitle" | translate }}</p>
      <div class="form-control-container">
        <label for="login">{{ "Activation.Login" | translate }}</label>
        <input type="text" [value]="login" readonly/>
      </div>
      <div class="form-control-container">
        <label for="activationCode">{{ "Activation.Code" | translate }}</label>
        <input
          id="activationCode"
          type="activationCode"
          name="activationCode"
          [(ngModel)]="activationCode"
          #activationCodeInput="ngModel"
          #activationCodeField
          [placeholder]="'Activation.Code' | translate"
          minlength="6"
          required
        />
        <div *ngIf="activationCodeInput.invalid && activationCodeInput.touched" class="messages">
          <span *ngIf="!!activationCodeInput.errors.required" class="message">
            {{ 'Activation.Validation.CodeRequired'|translate }}</span>
        </div>
      </div>
      
        <div class="container row">
        <p [innerHTML]="'Activation.ResendActivation' | translate"></p> 
        <p style="float: right;" (click)="onResend()" [innerHTML]="'Activation.ResendActivationLink' | translate" >  </p> 
        </div>
      <div>
        <button
          type="submit"
          class="primary"
          [disabled]="!activateForm.form.valid || submitting"
        >
          {{ 'Activation.Activate' | translate }}
        </button>
      </div>
      <div class="messages">
        <span class="message" *ngIf="errorKey">{{ errorKey | translate }}</span>
      </div>
    </form>
  </div>
</div>
