
import { Component, HostBinding } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';
import { PersonLegalType, OrganizationDto } from 'src/app/shared/api/rentassistant-api.generated';
import { Subject, of } from 'rxjs';
import { LocalizedRouter } from '../../../core/services/localized-router.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, filter, catchError, finalize, tap } from 'rxjs/operators';
import { ProfileWizardStateService } from '../../../core/services/profilewizard-state.service';
import { RentAssistantApi } from '../../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['../common.scss', './personal-info.component.scss']
})
export class PersonalInfoComponent extends StepBaseComponent {
  @HostBinding('class') hostClass = 'wizard';
  constructor(localizedRouter: LocalizedRouter,
              route: ActivatedRoute,
              private api: RentAssistantApi,
              protected stateService: ProfileWizardStateService) {
    super(localizedRouter, route, stateService);
    this.organizationNumber$ = new Subject();
  }

  totalSteps: number;


  loading: boolean;
  organizationNumber$: Subject<string>;

  init() {
    this.totalSteps = this.person.legalType === PersonLegalType.Person ? 4 : 3;

    this.subscriptions.push(
      this.organizationNumber$
          .pipe(
            filter(() => !this.readonly),
            filter((orgNumber: string) => !!orgNumber && orgNumber.length === 10),
            tap(() => this.loading = true),
            switchMap(organizationNumber => this.api.findOrganization(organizationNumber)
                                                    .pipe(
                                                        catchError(() => {
                                                          this.person.organizationFoundInKbo = false;
                                                          return of(null);
                                                        }),
                                                        finalize(() => this.loading = false)
                                                    )
            ),
            filter(x => !!x)
          )
          .subscribe(organization => {
            this.saveOrganizationInfoInProfile(organization);
          })
    );
  }

  modFunction(nr: number) { return 97 - (nr % 97); }

  IsValidRRN2000(n: string) {
    // RR numbers need to be 11 chars long
    if (n.length !== 11) {
        return false;
    }
    const checkDigit = +(n.substr(n.length - 2, 2));
    let nrToCheck = +(n.substr(0, 9));
    // then check with 2 appended for y2k+ births
    nrToCheck = +('2' + n.substr(0, 9));
    return (this.modFunction(nrToCheck) === checkDigit);
}
    IsValidRRN1900(n: string) {
  // RR numbers need to be 11 chars long
  if (n.length !== 11) {
      return false;
  }
  const checkDigit = +(n.substr(n.length - 2, 2));
  const nrToCheck = +(n.substr(0, 9));
  if (this.modFunction(nrToCheck) === checkDigit) {
    return true;
  }
  return (this.modFunction(nrToCheck) === checkDigit);
}

  private saveOrganizationInfoInProfile(organization: OrganizationDto) {
    this.person.organizationFoundInKbo = true;
    this.person.organizationName = organization.organizationName;
    if (organization.address) {
      this.person.address.street = organization.address.street;
      this.person.address.houseNumber = organization.address.streetNumber;
      this.person.address.zipCode = organization.address.postalCode;
      this.person.address.place = organization.address.city;
      this.person.address.country = organization.address.countryISOCode;
    }

    if (organization.functions && organization.functions.length > 0) {
      const func = organization.functions[0];
      this.person.organizationContactName = `${func.firstName || ''} ${func.lastName || ''}`.trim();
      this.person.organizationContactFunction = func.description;
    }
  }

  organizationNumberChanged(organizationNumber: string) {
    this.person.organizationNumber = organizationNumber;
    this.organizationNumber$.next(organizationNumber);
  }

  legalTypeChanged(newLegalType: PersonLegalType) {
    const personId = this.person.id;

    for (const prop of Object.getOwnPropertyNames(this.person)) {
      this.person[prop] = null;
    }

    this.person.id = personId;
    this.person.address = {};
    this.person.legalType = newLegalType;

    this.totalSteps = newLegalType === PersonLegalType.Person ? 4 : 3;
  }

  next() {
    if (this.person.legalType === PersonLegalType.LegalEntity) {
      this.localizedRouter.navigate(['../organisatie-adres'], { relativeTo: this.route });
    } else {
      this.localizedRouter.navigate(['../contactgegevens'], { relativeTo: this.route });
    }

  }
}
