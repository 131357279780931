<div *ngIf="profile">
<div class="container-fluid">
  <div class="candidature-detail__header p-t-25">
    <div class="candidature-detail__header-left">
      <img src="{{getAvatarIcon(profile)}}" alt="">
      <div class="info-icon-container">
        <h1 class="page-title">{{ 'Owner.General' | translate}}</h1>
          <div class="info-icon" tooltip="{{ 'Owner.GeneralInformation' | translate }}" placement="auto">
            <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
          </div>
        </div>
    </div>

    <div class="candidature-detail__header-right">
      <span class="preference-title">{{ 'Owner.Preferences' | translate}}:</span>
      <span class="preference-number">
        <ng-container *ngIf="profile.preferenceOrder">{{ profile.preferenceOrder }}</ng-container>
        <ng-container *ngIf="!profile.preferenceOrder">-</ng-container>
      </span>
    </div>
  </div>


  <div class="candidature-detail__card">
    <div class="candidature-detail__card-row">
      <h3 class="candidature-detail_card-title">{{ 'Owner.Residents' | translate}}</h3>
      <ul class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">
          <span class="keyword">{{ 'Owner.Adults' | translate}}:</span>
          <span>{{ profile.generalDetails.numberOfAdults }}</span>
        </li>
        <li class="candidature-detail__card-item">
          <span class="keyword">{{ 'Owner.Children' | translate}}:</span>
          <span>{{ profile.generalDetails.numberOfChildren }}</span>
        </li>
      </ul>
      <ul class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">
          <span class="keyword">{{ 'Owner.Pets' | translate}}:</span>
          <span>{{ profile.generalDetails.numberOfPets == null ? 0 : profile.generalDetails.numberOfPets}}</span>
        </li>
        <li *ngIf="profile.generalDetails.petsDescription != null" class="candidature-detail__card-item">
          <span class="keyword">{{ 'Owner.Comment' | translate}}:</span>
          <span>{{ profile.generalDetails.petsDescription }}</span>
        </li>
      </ul>
      <ul class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">
          <div *ngIf="profile.additionalBrokerInfo && profile.additionalBrokerInfo.tenantsInfo">
            <div class="candidature-detail__header">
              <span>{{ 'Owner.Comment' | translate}}</span>
            </div>
            <div class="form-control-container">
              <textarea disabled type="text">{{ profile.additionalBrokerInfo.tenantsInfo }}</textarea>
            </div>
          </div>
        </li>
      </ul>
      <ul class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">
          <div *ngIf="profile.additionalBrokerInfo && profile.additionalBrokerInfo.petsInfo">
            <div class="candidature-detail__header">
              <span>{{ 'Owner.Comment' | translate}}</span>
            </div>
            <div class="form-control-container">
              <textarea disabled type="text">{{ profile.additionalBrokerInfo.petsInfo }}</textarea>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div  class="candidature-detail__card-row">
      <h3 class="candidature-detail_card-title">{{ 'Owner.When' | translate}}</h3>
      <ul class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">
            <span class="keyword">{{ 'Owner.PreferenceMoveDate' | translate}}:</span>
            <span *ngIf="profile.generalDetails.relocationType != null && profile.generalDetails.relocationType == RelocationType.SpecificDate">{{ profile.generalDetails.expectedMovingDate.day }}/{{ profile.generalDetails.expectedMovingDate.month }}/{{ profile.generalDetails.expectedMovingDate.year }}</span>
            <span *ngIf="profile.generalDetails.relocationType != null && profile.generalDetails.relocationType == RelocationType.NoPreference">Geen voorkeur</span>
            <span *ngIf="profile.generalDetails.relocationType != null && profile.generalDetails.relocationType == RelocationType.Asap">{{ 'GeneralWizard.AsSoonAsPossible' | translate }}</span>
        </li>
      </ul>
      <ul class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">
          <span class="keyword">{{ 'Owner.PreferenceRentTerm' | translate}}:</span>
          <span *ngIf="profile.generalDetails.durationType">{{ 'GeneralWizard.DurationType_'+profile.generalDetails.durationType | translate }}</span>
          <span *ngIf="!profile.generalDetails.durationType">Geen voorkeur</span>
        </li>
      </ul>
    </div>

    <div class="candidature-detail__card-row">
      <h3 class="candidature-detail_card-title">{{ 'Owner.ExtraOptions' | translate}}</h3>
      <ul class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">          
          <div class="info-icon-container">       
            <span class="keyword">{{ 'Owner.Domicile' | translate}}:</span>
            <span>{{ boolTranslate( profile.generalDetails.willTakeDomicile ) }}</span>
              <div class="info-icon" tooltip="{{ 'Owner.WillTakeDomicileInformation' | translate }}" placement="auto">
                <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
              </div>
            </div>
        </li>
        <li class="candidature-detail__card-item">          
          <div class="info-icon-container"> 
            <span class="keyword">{{ 'Owner.ProfessionalUse' | translate}}:</span>
            <span>{{ boolTranslate( profile.generalDetails.isPartiallyProfessionalUse ) }}</span>
              <div class="info-icon"
                   tooltip="{{ 'Owner.IsPartialProffesionalUseInfo' | translate }}" placement="auto">
                <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
              </div>
            </div>
        </li>
      </ul>
      <ul *ngIf="notNullOrEmpty(profile.generalDetails.reasonForMoving)" class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">
          <span class="keyword">{{ 'GeneralWizard.ReasonForMoving' | translate}}:</span>
          <span>{{ profile.generalDetails.reasonForMoving }}</span>
        </li>
      </ul>
      <ul class="candidature-detail__card-items">
        <li class="candidature-detail__card-item">
          <div *ngIf="profile.additionalBrokerInfo && profile.additionalBrokerInfo.accessibilityInfo">
            <div class="candidature-detail__header">
              <span>{{ 'Owner.Comment' | translate}}</span>
            </div>
            <div class="form-control-container">
              <textarea disabled type="text">{{ profile.additionalBrokerInfo.accessibilityInfo }}</textarea>
            </div>
          </div>
          </li>
        </ul>
    </div>
  </div>

  <div class="candidature-detail__header">
    <div class="candidature-detail__header-left">
      <h1 class="page-title">{{ 'Owner.Tenants' | translate}} ({{profile.personDetails.length}})</h1>
    </div>
    <div class="candidature-detail__header-right">
      <span class="label-blue">€ {{getTotalIncomeForProfile(profile)}}</span>
    </div>
  </div>

  <div class="row">
    <div *ngFor="let pd of profile.personDetails" class="col-12 col-md-6 mb-3">
      <div *ngIf="isPerson(pd)" class="candidature-detail__card candidate">
        <div class="candidature-detail__card-header">
          <i class="fas fa-user"></i>
          <h3>{{ pd.firstName }} {{ pd.lastName }}</h3>
        </div>

        <div class="candidature-detail__card-content">

          <div class="candidature-detail__card-row">
            <h3 class="candidature-detail_card-title">{{ 'Owner.Income' | translate}}</h3>
            <ul class="candidature-detail__card-items">
              <li class="candidature-detail__card-item">
                <span class="keyword">{{ 'Owner.MonthlyIncome' | translate}}: <span>€ {{ pd.monthlyIncome }}</span></span>
                
              </li>
              <li *ngFor="let o of pd.otherIncomes" class="candidature-detail__card-item">
                <span class="keyword">{{ 'RenterWizard.OtherIncomeType_' + o.type |translate }}: <span>€ {{ o.income }}</span></span>                
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div *ngIf="!isPerson(pd)" class="candidature-detail__card candidate">
        <div class="candidature-detail__card-header">
          <i class="fas fa-user"></i>
          <h3> {{ pd.organizationName }} </h3>
        </div>

        <div class="candidature-detail__card-content">

          <div class="candidature-detail__card-row">
            <h3 class="candidature-detail_card-title">{{ 'Owner.OrganisationInfo' | translate}}</h3>
          </div>
          <div class="candidature-detail__card-row">
            <h3 class="candidature-detail_card-title">{{ 'Owner.ContactInfo' | translate}}</h3>
            <ul class="candidature-detail__card-items">
              <li class="candidature-detail__card-item">
                <span class="keyword">{{ 'Owner.OrganisationNumber' | translate}}:</span>
                <span>{{ pd.organizationNumber }}</span>
              </li>
              <li class="candidature-detail__card-item">
                <span class="keyword">{{ 'Owner.OrganisationFoundKbo' | translate}}:</span>
                <span>{{ boolTranslate( pd.organizationFoundInKbo ) }}</span>
              </li>
              <li class="candidature-detail__card-item">
                <span class="keyword">{{ 'Owner.OrganisationContantName' | translate}}:</span>
                <span>{{ pd.organizationContactName }}</span>
              </li>
              <li class="candidature-detail__card-item">
                <span class="keyword">{{ 'Owner.OrganisationContactFuntion' | translate}}:</span>
                <span>{{ pd.organizationContactFunction }}</span>
              </li>
              <li class="candidature-detail__card-item">
                <span class="keyword">{{ 'Owner.OrganisationContactEmail' | translate}}:</span>
                <span>{{ pd.organizationContactEmail }}</span>
              </li>
              <li class="candidature-detail__card-item">
                <span class="keyword">{{ 'Owner.OrganisationPhoneNumber' | translate}}:</span>
                <span>{{ pd.organizationContactPhone }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>


    </div>
  </div>
<div *ngIf="profile.additionalBrokerInfo && profile.additionalBrokerInfo.generalInfo">
  <div class="candidature-detail__header">
    <h1 class="page-title">{{ 'Owner.Comment' | translate}}</h1>
  </div>
  <div class="form-control-container">
    <textarea disabled type="text">{{ profile.additionalBrokerInfo.generalInfo }}</textarea>
  </div>
</div>
</div>
</div>
