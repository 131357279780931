import * as i0 from "@angular/core";
import * as i1 from "../../shared/api/rentassistant-api";
var ProfileResolver = /** @class */ (function () {
    function ProfileResolver(api) {
        this.api = api;
    }
    ProfileResolver.prototype.resolve = function (route) {
        return this.api.getUserProfile();
    };
    ProfileResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileResolver_Factory() { return new ProfileResolver(i0.ɵɵinject(i1.RentAssistantApi)); }, token: ProfileResolver, providedIn: "root" });
    return ProfileResolver;
}());
export { ProfileResolver };
