import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'ra-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true
    }
  ]
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  @Input()
  checked = false;

  @Output()
  check = new EventEmitter<boolean>();

  onChangeCallback: any;
  onTouchedCallback: any;

  disabled = false;

  @HostListener('blur')
  onHostBlur() {
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
  }

  onInputChange() {
    this.checked = !this.checked;
    this.check.next(this.checked);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.checked);
    }
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
  }

  writeValue(obj: any): void {
    this.checked = !!obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
