import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileDetailsDto } from 'src/app/shared/api/rentassistant-api.generated';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { LocalizedRouter } from '../../../core/services/localized-router.service';
import { ProfileWizardStateService, ProfileWizardState } from '../../../core/services/profilewizard-state.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ra-residents',
  templateUrl: './residents.component.html',
  styleUrls: ['./residents.component.scss']
})
export class ResidentsComponent extends AutoUnsubscribeComponent
  implements OnInit {

  profile: ProfileDetailsDto;
  state: ProfileWizardState;

  constructor(private localizedRouter: LocalizedRouter,
              private route: ActivatedRoute,
              private stateService: ProfileWizardStateService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.stateService
          .state$
          .pipe(filter(x => !!x))
          .subscribe(state => {
              this.profile = state.profile;
              this.state = state;
          })
    );
    if (this.profile.generalDetails.numberOfAdults == null) {
      this.profile.generalDetails.numberOfAdults = 1;
    }
    if (this.profile.generalDetails.numberOfChildren == null) {
      this.profile.generalDetails.numberOfChildren = 0;
    }
  }

  help() {}

  next() {
    this.localizedRouter.navigate(['../wanneer'], { relativeTo: this.route });
  }
}
