import * as i0 from "@angular/core";
import * as i1 from "./session.service";
import * as i2 from "./auth.service";
import * as i3 from "./localized-router.service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(sessionService, authService, localizedRouter) {
        this.sessionService = sessionService;
        this.authService = authService;
        this.localizedRouter = localizedRouter;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        if (!this.sessionService.isAuthenticated()) {
            this.authService.redirectUrl = state.url;
            var params = route.params;
            if (params != null) {
                var paramName = 'realEstateObjectId';
                var realEstateObjectId = params[paramName];
                if (realEstateObjectId != null) {
                    this.localizedRouter.navigate(['/login/' + realEstateObjectId]);
                    return false;
                }
            }
            this.localizedRouter.navigate(['/login']);
            return false;
        }
        return true;
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.LocalizedRouter)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
