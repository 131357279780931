import { OwnerService } from './../owner.service';
import { OwnerProfileDetailsDto, PersonLegalType, OwnerPersonDetailsDto,
   RelocationType } from './../../../shared/api/rentassistant-api.generated';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ra-candidature-detail',
  templateUrl: './candidature-detail.component.html',
  styleUrls: ['./candidature-detail.component.scss']
})
export class CandidatureDetailComponent implements OnInit {
  RelocationType = RelocationType;

  @Input() profile: OwnerProfileDetailsDto;
  constructor(private ownerService: OwnerService) { }

  ngOnInit() {
  }

  isPerson(p: OwnerPersonDetailsDto) {
    return p.legalType === PersonLegalType.Person;
  }

  boolTranslate(value: boolean | null) {
    return this.ownerService.boolTranslate(value);
  }

  getAvatarIcon(rp: OwnerProfileDetailsDto) {
    return this.ownerService.getAvatarIcon(rp);
  }

  getTotalIncomeForProfile(p: OwnerProfileDetailsDto) {
    if (p == null || p.personDetails == null) {
      return 0;
    }
    let totalIncome = 0;
    p.personDetails.forEach((el) => {
      totalIncome = totalIncome + this.getTotalIncomeForPerson(el);
    });
    return totalIncome;
  }

  getTotalIncomeForPerson(p: OwnerPersonDetailsDto) {
    if (p == null) {
      return 0;
    }
    let totalIncome = 0;
    if (p.monthlyIncome != null) {
      totalIncome = p.monthlyIncome;
    }
    p.otherIncomes.forEach((o) => {
      if (o.income != null) {
        totalIncome = totalIncome + o.income;
      }
    });
    return totalIncome;
  }

  notNullOrEmpty(value: string) {
    if (value == null || value.trim() === '') {
      return false;
    }  else {
        return true;
      }
  }

}
