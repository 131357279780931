/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./share-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../modal-header/modal-header.component.ngfactory";
import * as i4 from "../../modal-header/modal-header.component";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./share-modal.component";
import * as i8 from "../../api/rentassistant-api.generated";
import * as i9 from "../../api/rentassistant-api";
var styles_ShareModalComponent = [i0.styles];
var RenderType_ShareModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShareModalComponent, data: {} });
export { RenderType_ShareModalComponent as RenderType_ShareModalComponent };
function View_ShareModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ShareModal.Title")); _ck(_v, 1, 0, currVal_0); }); }
function View_ShareModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_ShareModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { profileLinkField: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ra-modal-header", [], null, [[null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.closeModal.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ModalHeaderComponent_0, i3.RenderType_ModalHeaderComponent)), i1.ɵdid(2, 49152, null, 1, i4.ModalHeaderComponent, [], null, { cancel: "cancel" }), i1.ɵqud(603979776, 2, { template: 0 }), (_l()(), i1.ɵand(0, [[2, 2]], null, 0, null, View_ShareModalComponent_1)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 15, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["profileLinkField", 1]], null, 5, "input", [["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.email = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(15, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareModalComponent_2)), i1.ɵdid(20, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["class", "default action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.email; _ck(_v, 13, 0, currVal_8); var currVal_10 = (_co.errorMessage != null); _ck(_v, 20, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("ShareModal.Info")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 15).ngClassValid; var currVal_6 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("Common.Confirm")); _ck(_v, 17, 0, currVal_9); var currVal_11 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("Common.Close")); _ck(_v, 22, 0, currVal_11); }); }
export function View_ShareModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-share-modal", [], null, null, null, View_ShareModalComponent_0, RenderType_ShareModalComponent)), i1.ɵdid(1, 4308992, null, 0, i7.ShareModalComponent, [i8.RentAssistantClient, i9.RentAssistantApi], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShareModalComponentNgFactory = i1.ɵccf("ra-share-modal", i7.ShareModalComponent, View_ShareModalComponent_Host_0, {}, { closeModal: "closeModal", confirm: "confirm" }, []);
export { ShareModalComponentNgFactory as ShareModalComponentNgFactory };
