import * as tslib_1 from "tslib";
import { TemplateRef, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { makeEmptyCandidacy } from '../../core/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { Action, PersonLegalType, Status } from '../../shared/api/rentassistant-api.generated';
import { filter, shareReplay, tap } from 'rxjs/operators';
import { ProfileWizardMode } from '../../core/services/profilewizard-state.service';
var DetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DetailComponent, _super);
    function DetailComponent(router, urlSerializer, location, route, localizedRouter, localizedRouterService, modal, api, stateService, translateService) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.urlSerializer = urlSerializer;
        _this.location = location;
        _this.route = route;
        _this.localizedRouter = localizedRouter;
        _this.localizedRouterService = localizedRouterService;
        _this.modal = modal;
        _this.api = api;
        _this.stateService = stateService;
        _this.translateService = translateService;
        _this.candidacy = makeEmptyCandidacy();
        _this.alert = false;
        _this.Status = Status;
        _this.remarks = '';
        _this.visitConfirmed = false;
        _this.actions = [];
        _this.canArchive = false;
        return _this;
    }
    DetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.candidacy = this.route.snapshot.data.candidacy;
        this.canArchive = (this.candidacy.status === Status.Approved || this.candidacy.status === Status.Expired ||
            this.candidacy.status === Status.Recalled || this.candidacy.status === Status.Rejected);
        this.stateService.state$.next({
            candidacy: this.candidacy,
            profile: this.candidacy.profile,
            wizardMode: ProfileWizardMode.CandidacyProfile,
            closeWizard: function () { return _this.localizedRouter.navigate(['/app/kandidaturen/', _this.candidacy.uniqueId]); },
            saveProfile: function () {
                var result = _this.api.saveCandidacyProfile(_this.candidacy.uniqueId, _this.candidacy.profile).pipe(tap(function (x) {
                    _this.candidacy.profile = x;
                    _this.stateService.state$.value.profile = x;
                }), shareReplay());
                return result;
            }
        });
        this.subscriptions.push(this.stateService.state$.subscribe(function (state) { return _this.state = state; }));
        this.subscriptions.push(this.api.getCandidacyActions(this.candidacy.uniqueId)
            .subscribe(function (actions) { return _this.actions = actions; }));
        this.isDetail = this.route.children.length === 0;
        this.subscriptions.push(this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }))
            .subscribe(function (e) { return _this.isDetail = _this.route.children.length === 0; }));
    };
    Object.defineProperty(DetailComponent.prototype, "hasActions", {
        get: function () {
            return !!this.actions && this.actions.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    DetailComponent.prototype.goBack = function () {
        var _this = this;
        if (this.candidacy.isReadonly) {
            this.localizedRouter.navigate(['/app/kandidaturen']);
            return;
        }
        this.subscriptions.push(this.api
            .saveCandidacyProfile(this.candidacy.uniqueId, this.candidacy.profile)
            .subscribe(function (profile) { return _this.localizedRouter.navigate(['/app/kandidaturen']); }));
    };
    DetailComponent.prototype.showAction = function (action) {
        return this.actions && this.actions.indexOf(Action[action]) > -1;
    };
    DetailComponent.prototype.submitConfirmation = function () {
        this.alert = false;
        if (this.candidacy.profile.profileComplete) {
            var remarks = this.candidacy.candidacyHistories.filter(function (x) { return x.status === Status.Submitted; });
            if (remarks != null && remarks.length > 0) {
                this.remarks = remarks[remarks.length - 1].comment;
                var h = remarks[remarks.length - 1].hasVisitedProperty;
                if (h != null) {
                    this.visitConfirmed = h;
                }
            }
            this.modal.open(this.submitModal);
        }
        else {
            this.alert = true;
            this.modal.open(this.incompleteModal);
        }
    };
    DetailComponent.prototype.recallConfirmation = function () {
        this.modal.open(this.recallModal);
    };
    DetailComponent.prototype.delete = function () {
        this.modal.open(this.deleteModal);
    };
    DetailComponent.prototype.archive = function () {
        var _this = this;
        var archiveDto = {};
        archiveDto.archive = !this.candidacy.isArchivedByUser;
        archiveDto.candidacyUniqueId = this.candidacy.uniqueId;
        this.api.archiveCandidacy(archiveDto)
            .subscribe(function (Response) { return _this.localizedRouter.navigate(['/app/kandidaturen']); });
    };
    DetailComponent.prototype.contactBroker = function () {
        this.modal.open(this.contactBrokerModal);
    };
    DetailComponent.prototype.share = function () {
        var _this = this;
        this.api.getProfilePublicToken(this.candidacy.uniqueId)
            .subscribe(function (token) {
            var urlTree = _this.router.createUrlTree(['/app/profiel', token]);
            var relativeUrl = _this.urlSerializer.serialize(urlTree);
            var translatedRelativeUrl = _this.localizedRouterService
                .translateRoute(relativeUrl);
            _this.shareableProfileLink = window.location.origin + _this.location.prepareExternalUrl(translatedRelativeUrl);
            _this.modal.open(_this.shareModal);
        });
    };
    DetailComponent.prototype.addRenter = function () {
        var _this = this;
        var newPersonId = this.candidacy.profile.personDetails.length + 1;
        this.candidacy.profile.personDetails.push({
            id: newPersonId,
            legalType: PersonLegalType.Person,
            address: {}
        });
        this.subscriptions.push(this.api.saveCandidacyProfile(this.candidacy.uniqueId, this.candidacy.profile)
            .subscribe(function (prof) {
            _this.stateService.updateProfile(prof);
            _this.localizedRouter.navigate([
                'persoon',
                newPersonId,
                'persoonlijke-gegevens'
            ], { relativeTo: _this.route });
        }));
    };
    DetailComponent.prototype.isAlreadySubmitted = function (candidacy) {
        return candidacy.status === Status.Submitted;
    };
    DetailComponent.prototype.getSubmitText = function () {
        if (this.candidacy.status === Status.InfoRequested) {
            return this.translateService.get('Candidacies.UpdateCandidacy');
        }
        else {
            return this.translateService.get('Candidacies.SubmitCandidacy');
        }
    };
    return DetailComponent;
}(AutoUnsubscribeComponent));
export { DetailComponent };
