import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { GeneralWizardModule } from './general-wizard/general-wizard.module';
import { RenterWizardModule } from './renter-wizard/renter-wizard.module';
import { AttachmentsWizardModule } from './attachments-wizard/attachments-wizard.module';

import { ProfileComponent } from '../profile/profile/profile.component';
import { SummaryComponent } from './summary/summary.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { PublicProfileComponent } from './public-profile/public-profile.component';

const components = [
  ProfileComponent,
  SummaryComponent,
  EditProfileComponent,
  PublicProfileComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    GeneralWizardModule,
    RenterWizardModule,
    AttachmentsWizardModule,
    SharedModule,
    RouterModule.forChild([])
  ],
  exports: [
    ...components,
    GeneralWizardModule,
    RenterWizardModule,
    AttachmentsWizardModule
  ]
})
export class ProfileModule { }
