import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { IncomeComponent } from './income/income.component';
import { RentingInfoComponent } from './renting-info/renting-info.component';
import { OwnerInfoComponent } from './owner-info/owner-info.component';
import { RenterWizardHeaderComponent } from './renter-wizard-header/renter-wizard-header.component';
import { DeletePersonConfirmationComponent } from './delete-person-confirmation/delete-person-confirmation.component';
import { StepBaseComponent } from './step-base.component';
import { OrganizationAddressComponent } from './organization-address/organization-address.component';
import { OrganizationContactInfoComponent } from './organization-contact-info/organization-contact-info.component';

@NgModule({
  declarations: [
    PersonalInfoComponent,
    ContactInfoComponent,
    IncomeComponent,
    RentingInfoComponent,
    OwnerInfoComponent,
    RenterWizardHeaderComponent,
    DeletePersonConfirmationComponent,
    StepBaseComponent,
    OrganizationAddressComponent,
    OrganizationContactInfoComponent
  ],
  imports: [CommonModule, SharedModule, TooltipModule]
})
export class RenterWizardModule {}
