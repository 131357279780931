/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./house-header-background.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./house-header-background.component";
var styles_HouseHeaderBackgroundComponent = [i0.styles];
var RenderType_HouseHeaderBackgroundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HouseHeaderBackgroundComponent, data: {} });
export { RenderType_HouseHeaderBackgroundComponent as RenderType_HouseHeaderBackgroundComponent };
export function View_HouseHeaderBackgroundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "house-background"], ["src", "assets/images/house-header-background.svg"]], null, null, null, null, null))], null, null); }
export function View_HouseHeaderBackgroundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-house-header-background", [], null, null, null, View_HouseHeaderBackgroundComponent_0, RenderType_HouseHeaderBackgroundComponent)), i1.ɵdid(1, 49152, null, 0, i2.HouseHeaderBackgroundComponent, [], null, null)], null, null); }
var HouseHeaderBackgroundComponentNgFactory = i1.ɵccf("ra-house-header-background", i2.HouseHeaderBackgroundComponent, View_HouseHeaderBackgroundComponent_Host_0, {}, {}, []);
export { HouseHeaderBackgroundComponentNgFactory as HouseHeaderBackgroundComponentNgFactory };
