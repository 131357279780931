import { DocumentationModule } from './documentation/documentation.module';
import { WidgetModule } from './widget/widget.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ShellModule } from './shell/shell.module';
import { CandidaciesModule } from './candidacies/candidacies.module';
import { HomeModule } from './home/home.module';
import { AppComponent } from './app.component';
import { ProfileModule } from './profile/profile.module';
import { DragulaModule } from 'ng2-dragula';
import { DropdownModule } from 'primeng/dropdown';
import { OwnersportalModule } from './ownersportal/ownersportal.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    CoreModule,
    ShellModule,
    ProfileModule,
    CandidaciesModule,
    OwnersportalModule,
    AppRoutingModule,
    HomeModule,
    DocumentationModule,
    DragulaModule.forRoot(),
    DropdownModule,
    WidgetModule,
    TooltipModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
