import { Component, HostBinding } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';
import { PersonDetailsDto } from 'src/app/shared/api/rentassistant-api.generated';

@Component({
  selector: 'ra-renting-info',
  templateUrl: './renting-info.component.html',
  styleUrls: ['../common.scss', './renting-info.component.scss']
})
export class RentingInfoComponent extends StepBaseComponent {
  @HostBinding('class') hostClass = 'wizard';

  person: PersonDetailsDto;

  previous() {
    this.localizedRouter.navigate(['../inkomsten'], {
      relativeTo: this.route
    });
  }

  next() {
      if (this.readonly) {
        this.state.closeWizard();
      } else {
        this.localizedRouter.navigate(['../samenvatting'], { relativeTo: this.route });
      }
  }
}
