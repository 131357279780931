import { Component, OnInit, OnDestroy } from '@angular/core';
import { QuestionAndAnswerDto } from '../../shared/api/rentassistant-api.generated';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';
export type FaqItem = QuestionAndAnswerDto & { expanded?: boolean };

@Component({
  selector: 'ra-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {

  questionAndAnswers: FaqItem[] = [];
  subscriptions: Subscription[];
  visible: boolean;

  constructor(private api: RentAssistantApi) { }

  ngOnInit() {
    this.subscriptions = [
      this.api
          .getQuestionAndAnswers()
          .pipe(finalize(() => this.visible = this.questionAndAnswers.length > 0))
          .subscribe(faq => this.questionAndAnswers = faq)
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  toggle(qa: FaqItem) {
    qa.expanded = !qa.expanded;
  }
}
