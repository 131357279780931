import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { DynamicEnvironment, Environment } from './environments/dynamic-environment';

// options need to be loaded before the DI container is constructed,
// because they are sometimes used in resolution and constructors
fetch('assets/app-config.json')
  .then(r => r.json())
  .then(r => {
    const environment: Environment = new DynamicEnvironment();

    if (typeof r.production === 'boolean') {
      environment.production = r.production;
    }
    if (typeof r.api === 'object') {
      Object.assign(environment.api, r.api);
    }
    if (typeof r.immoConnect === 'object') {
      Object.assign(environment.immoConnect, r.immoConnect);
    }

    return environment;
  })
  .then(environment => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      { provide: DynamicEnvironment, useValue: environment }
    ])
      .bootstrapModule(AppModule, {
        providers: []
      })
      .catch(err => console.error(err));
  });
