import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ProfileDetailsDto } from '../../shared/api/rentassistant-api.generated';
import { Subscription, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProfileWizardState, ProfileWizardStateService, ProfileWizardMode } from '../../core/services/profilewizard-state.service';
import { LocalizedRouter } from '../../core/services/localized-router.service';

@Component({
  selector: 'ra-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent implements OnInit, OnDestroy {

  state: ProfileWizardState;
  profile: ProfileDetailsDto;

  baseUrl: string;
  isDetail: boolean;
  subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private stateService: ProfileWizardStateService,
              private localizedRouter: LocalizedRouter) { }

  ngOnInit() {
    const token = this.route.snapshot.params.token;
    this.baseUrl = `/app/profiel/${token}`;

    this.profile = this.route.snapshot.data.profile;
    this.stateService.state$.next({
      candidacy: null,
      profile: this.profile,
      wizardMode: ProfileWizardMode.PublicProfile,
      closeWizard: () => this.localizedRouter.navigate(['/app/profiel', token]),
      saveProfile: () => of(this.profile)
    });

    this.isDetail = this.route.children.length === 0;

    this.subscriptions.push(
      this.router.events
                 .pipe(filter(e => e instanceof NavigationEnd))
                 .subscribe((e: NavigationEnd)  => this.isDetail = this.route.children.length === 0)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
