import { AuthGuardService } from '../core/services/auth-guard.service';
import { ProfileResolver } from '../core/resolvers/profile.resolver';

import { EditProfileComponent } from './edit-profile/edit-profile.component';

import { ResidentsComponent } from './general-wizard/residents/residents.component';
import { WhenComponent } from './general-wizard/when/when.component';
import { OptionsComponent } from './general-wizard/options/options.component';

import { PersonalInfoComponent } from './renter-wizard/personal-info/personal-info.component';
import { ContactInfoComponent } from './renter-wizard/contact-info/contact-info.component';
import { IncomeComponent } from './renter-wizard/income/income.component';
import { RentingInfoComponent } from './renter-wizard/renting-info/renting-info.component';
import { OwnerInfoComponent } from './renter-wizard/owner-info/owner-info.component';
import { SummaryComponent } from './summary/summary.component';
import { OrganizationAddressComponent } from './renter-wizard/organization-address/organization-address.component';
import { OrganizationContactInfoComponent } from './renter-wizard/organization-contact-info/organization-contact-info.component';
import { IncomeAttachmentsComponent } from './attachments-wizard/income-attachments/income-attachments.component';
import { OtherIncomeAttachmentsComponent } from './attachments-wizard/other-income-attachments/other-income-attachments.component';
import { IdentityRentAttachmentsComponent } from './attachments-wizard/identity-rent-attachments/identity-rent-attachments.component';
import { PublicProfileResolver } from '../core/resolvers/public-profile.resolver';
import { PublicProfileComponent } from './public-profile/public-profile.component';


const profileChildRoutes = [
  {
    path: 'algemeen',
    children: [
      {
        path: 'bewoners',
        component: ResidentsComponent
      },
      {
        path: 'wanneer',
        component: WhenComponent
      },
      {
        path: 'opties',
        component: OptionsComponent
      },
      {
        path: 'samenvatting',
        component: SummaryComponent,
        data: { completedStep: 'general' }
      }
    ]
  },
  {
    path: 'persoon/:personId',
    children: [
      {
        path: 'persoonlijke-gegevens',
        component: PersonalInfoComponent
      },
      {
        path: 'contactgegevens',
        component: ContactInfoComponent
      },
      {
        path: 'organisatie-adres',
        component: OrganizationAddressComponent
      },
      {
        path: 'organisatie-contactgegevens',
        component: OrganizationContactInfoComponent
      },
      {
        path: 'inkomsten',
        component: IncomeComponent
      },
      {
        path: 'huidige-huur',
        component: RentingInfoComponent
      },
      {
        path: 'huidige-verhuurder',
        component: OwnerInfoComponent
      },
      {
        path: 'samenvatting',
        component: SummaryComponent,
        data: { completedStep: 'renter' }
      },
      {
        path: 'bijlagen',
        children: [
          {
            path: 'inkomsten',
            component: IncomeAttachmentsComponent
          },
          {
            path: 'andere-inkomsten',
            component: OtherIncomeAttachmentsComponent
          },
          {
            path: 'identiteit-huur',
            component: IdentityRentAttachmentsComponent
          },
          {
            path: 'samenvatting',
            component: SummaryComponent,
            data: { completedStep: 'attachments' }
          }
        ]
      }
    ]
  }
];

export const routes = [
  {
    path: 'profiel',
    component: EditProfileComponent,
    resolve: { profile: ProfileResolver },
    canActivate: [AuthGuardService],
    children: profileChildRoutes
  },
  {
    path: 'profiel/:token',
    component: PublicProfileComponent,
    resolve: { profile: PublicProfileResolver },
    children: profileChildRoutes
  }
];
