<ra-message [textKey]="titleKey" [subTextKey]="titleSubtextKey"></ra-message>

<ng-container *ngIf="brokerNameLine">
  <div class="main">
    <div class="image">
    </div>
    <div class="text">
      <div class="broker">{{ brokerNameLine }}</div>
      <div class="description" [innerHTML]="mainText"></div>
    </div>
  </div>
</ng-container>

<div class="filler"></div>

<div class="footer">
  <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
</div>
