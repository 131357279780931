import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, throwError } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { catchError, finalize } from 'rxjs/operators';
import { Router, UrlSerializer, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from 'ngx-localize-router';

@Component({
  selector: 'ra-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('emailField', { static: false }) emailField: ElementRef;

  email: string;

  submitting: boolean;
  errorKey: string;
  realEstateObjectId: string;
  showConfirmation: boolean;

  subscriptions: Subscription[] = [];

  constructor(private authService: AuthService,
              private router: Router,
              private urlSerializer: UrlSerializer,
              private route: ActivatedRoute,
              private location: Location,
              private localizedRouterService: LocalizeRouterService) { }

  ngOnInit() {
    this.realEstateObjectId = this.route.snapshot.params.realEstateObjectId;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getLoginNav() {
    let nav = '/login';
    if (this.realEstateObjectId != null) {
      nav += '/' + this.realEstateObjectId;
    }
    return nav;
  }

  ngAfterViewInit() {
    if (this.emailField) {
      this.emailField.nativeElement.focus();
    }
  }

  private buildConfirmationReturnUrl(): string {
    const urlTree = this.router.createUrlTree(['/wachtwoord-reset'], {
                                    relativeTo: this.route });
    const relativeUrl = this.urlSerializer.serialize(urlTree);
    const translatedRelativeUrl = this.localizedRouterService.translateRoute(relativeUrl);

    return window.location.origin + this.location.prepareExternalUrl(translatedRelativeUrl as string);
  }

  onSubmit() {
    this.submitting = true;
    this.errorKey = '';

    const confirmationReturnUrl = this.buildConfirmationReturnUrl();

    this.subscriptions.push(
      this.authService
          .forgotPassword(this.email, confirmationReturnUrl)
          .pipe(
            catchError(err => {
              const errorCode: string = !!err.response ? err.response : err;
              this.errorKey = `ForgotPassword.Error.${errorCode}`;

              return throwError(err);
            }),
            finalize(() => this.submitting = false)
          )
          .subscribe(success => this.showConfirmation = true)
    );
  }
}
