import { Component, HostBinding } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';

@Component({
  selector: 'ra-owner-info',
  templateUrl: './owner-info.component.html',
  styleUrls: ['../common.scss', './owner-info.component.scss']
})
export class OwnerInfoComponent extends StepBaseComponent {
  @HostBinding('class') hostClass = 'wizard';

  canContactCurrentLandlord = false;
  name = '';
  email = '';
  phone = '';

  onPersonChanged() {
    if (this.person.landlordContactFilled === true) {
      this.canContactCurrentLandlord = true;
      this.name = this.person.landlordName;
      this.email = this.person.landlordEmail;
      this.phone = this.person.landlordPhone;
    }
  }

  previous() {
    this.localizedRouter.navigate(['../huidige-huur'], {
      relativeTo: this.route
    });
  }

  saveCurrentLandlord() {
    if (this.canContactCurrentLandlord) {
      this.person.landlordContactFilled = true;

      this.person.landlordName = this.name;
      this.person.landlordEmail = this.email;
      this.person.landlordPhone = this.phone;
    } else {
      this.person.landlordContactFilled = false;

      this.person.landlordName = '';
      this.person.landlordEmail = '';
      this.person.landlordPhone = '';
    }
  }

  beforeNavigate() {
    this.saveCurrentLandlord();
  }

  beforeClose() {
    this.saveCurrentLandlord();
  }

  next() {
    this.saveCurrentLandlord();
    if (this.readonly) {
      this.state.closeWizard();
    } else {
      this.localizedRouter.navigate(['../samenvatting'], { relativeTo: this.route });
    }
  }
}
