import * as tslib_1 from "tslib";
import { LocalizeParser } from 'ngx-localize-router';
var LocalizeRouterHttpLoader = /** @class */ (function (_super) {
    tslib_1.__extends(LocalizeRouterHttpLoader, _super);
    function LocalizeRouterHttpLoader(translate, location, settings, http, path) {
        if (path === void 0) { path = 'assets/locales.json'; }
        var _this = _super.call(this, translate, location, settings) || this;
        _this.http = http;
        _this.path = path;
        return _this;
    }
    LocalizeRouterHttpLoader.prototype.load = function (routes) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.http.get("" + _this.path)
                .subscribe(function (data) {
                _this.locales = data.locales;
                _this.prefix = data.prefix || '';
                _this.init(routes).then(resolve);
            });
        });
    };
    return LocalizeRouterHttpLoader;
}(LocalizeParser));
export { LocalizeRouterHttpLoader };
