import { TranslateService } from '@ngx-translate/core';
import { LocalizedRouter } from './../../core/services/localized-router.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'ra-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLoggedIn: boolean;

  constructor(private authService: AuthService,
              private localizedRouter: LocalizedRouter,
              private translate: TranslateService) { }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  logout($event: Event) {
    $event.preventDefault();
    this.authService.logout();
  }
  onCleeClick() {
    const loggedIn = this.authService.isLoggedIn();
    if (loggedIn) {
      this.localizedRouter.navigate(['/app/kandidaturen']);
    } else {
      this.localizedRouter.navigate(['']);
    }
  }
  termsOfUse() {
    this.translate.get('Url.Privacy').toPromise()
    .then(x => {
      window.open(x);
    });
    this.translate.get('Url.UserPolicy').toPromise()
    .then(x => {
      window.open(x);
    });
  }
}
