import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import {
  faUser,
  faChevronDown,
  faChevronUp,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

export type UserEntryState = 'incomplete' | 'complete' | 'error';

@Component({
  selector: 'ra-person-list-item',
  templateUrl: './person-list-item.component.html',
  styleUrls: ['./person-list-item.component.scss']
})
export class PersonListItemComponent {
  faUser = faUser;
  faChevronRight = faChevronRight;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  @Input()
  name = '';

  @Input()
  state: UserEntryState = 'incomplete';

  @Input()
  isExpandable = true;

  @Input()
  isExpanded = false;

  @Output()
  itemClick = new EventEmitter<void>();

  @Output()
  rightArrowClick = new EventEmitter<void>();

  @HostListener('click')
  onClickHost() {
    this.itemClick.next();
  }

  onRightArrowClick(e: MouseEvent) {
    e.stopPropagation();
    this.rightArrowClick.next();
  }
}
