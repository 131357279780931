<ra-renter-wizard-header
  [person]="person">
</ra-renter-wizard-header>
<div class="wizard-page container">
  <form class="form-grid">
    <div class="info-icon-container">      
      <div class="title">{{ 'RenterWizard.ContactInformation' | translate }}</div>
        <div class="info-icon"
             tooltip="{{ 'RenterWizard.ContactInformationInfo' | translate }}"
             placement="auto">
          <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
        </div>
      </div>
    <div class="subtitle">{{ 'RenterWizard.Email' | translate }}</div>
    <input name="email"
           type="email"
           [(ngModel)]="person.email"
           [placeholder]="'RenterWizard.Email' | translate"
           [readOnly]="readonly"
           required />
    <div class="subtitle">{{ 'RenterWizard.PhoneNumber' | translate }}</div>
    <input name="phone"
          [(ngModel)]="person.phone"
          [placeholder]="'RenterWizard.PhoneNumber' | translate"
          [readOnly]="readonly"
          required />
    <div class="subtitle">{{ 'RenterWizard.CurrentAddress' | translate }}</div>
    <div class="line">
      <input name="street"
            [(ngModel)]="person.address.street"
            [placeholder]="'RenterWizard.Street' | translate"
            [readOnly]="readonly"
            required
            class="flex-75"
      />
      <div class="flex-gap"></div>
      <input
        name="houseNumber"
        [(ngModel)]="person.address.houseNumber"
        [placeholder]="'RenterWizard.HouseNumber' | translate"
        [readOnly]="readonly"
        required
        class="flex-25"
      />
    </div>
    <div class="line">
      <input
        name="zipCode"
        [(ngModel)]="person.address.zipCode"
        [placeholder]="'RenterWizard.ZipCode' | translate"
        [readOnly]="readonly"
        required
        class="flex-25"
      />
      <div class="flex-gap"></div>
      <input
        name="place"
        [(ngModel)]="person.address.place"
        [placeholder]="'RenterWizard.Place' | translate"
        [readOnly]="readonly"
        required
        class="flex-75"
      />
    </div>
    <div class="line">
      <div class="subtitle" class="flex-25">{{ 'RenterWizard.Country' | translate }}</div>
      <select name="countrySelect" [(ngModel)]="country" class="flex-75">
        <option [value]="'Belgie'">{{'RenterWizard.Belgie' | translate}}</option>
        <option [value]="'Nederland'">{{'RenterWizard.Nederland' | translate}}</option>
        <option [value]="'Frankrijk'">{{'RenterWizard.Frankrijk' | translate}}</option>
        <option [value]="'Duitsland'">{{'RenterWizard.Duitsland' | translate}}</option>
        <option [value]="'Andere'">{{'RenterWizard.Andere' | translate}}</option>
      </select>
      <ng-container *ngIf="country == 'Andere'">
        <input
          name="country"
          [(ngModel)]="person.address.country"
          required
          [placeholder]="'RenterWizard.Country' | translate"
          [readOnly]="readonly"
        />
      </ng-container>
    </div>
  </form>
</div>
<ra-wizard-footer
  [step]="2" [totalSteps]="4"
  [autoSave]="!profile.isReadonly"
  (next)="next()"
  (previous)="previous()"
></ra-wizard-footer>
