import { Component, Input } from '@angular/core';
import { makeEmptyCandidacy } from 'src/app/core/models';
import { Status } from 'src/app/shared/api/rentassistant-api.generated';

@Component({
  selector: 'ra-feedback-selector',
  templateUrl: './feedback-selector.component.html',
  styleUrls: ['./feedback-selector.component.scss']
})
export class FeedbackSelectorComponent {
  @Input()
  candidacy = makeEmptyCandidacy();

  get statusComment() {
    if (
      this.candidacy.status === Status.Approved ||
      this.candidacy.status === Status.InfoRequested
    ) {
      return this.candidacy.statusComment || '';
    }
    return '';
  }
}
