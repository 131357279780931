<div class="candidacy-card">
  <div class="visible-from-tablet">
      <img class="property-image" [src]="property.imageUrl" />
      <span class="price-tag" *ngIf="property.price">€ {{property.price}}/mnd</span>
  </div>
  <div class="info">
    <div *ngIf="property.broker != null" class="broker">
      {{property.broker.name}}
      <img src="{{property.broker.logoUrl}}" />
    </div>

    <div class="address">
      <div class="property-address"> {{ property.street }} {{ property.houseNumber }}, </div>
      <div class="property-address"> {{ property.postalCode }} {{ property.city }}</div>
    </div>

    <div class="candidacy-info">
      <div class="candidacy-date"></div>
    </div>
  </div>
</div>
