import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe.component';
import { filter, concatMap, first } from 'rxjs/operators';
import { from } from 'rxjs';
var WizardHeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WizardHeaderComponent, _super);
    function WizardHeaderComponent(stateService) {
        var _this = _super.call(this) || this;
        _this.stateService = stateService;
        _this.autoSave = true;
        _this.help = new EventEmitter();
        return _this;
    }
    WizardHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.stateService
            .state$
            .pipe(filter(function (x) { return !!x; }))
            .subscribe(function (state) {
            _this.state = state;
            _this.candidacy = state.candidacy;
            _this.profile = state.profile;
            _this.wizardMode = state.wizardMode;
        }));
    };
    WizardHeaderComponent.prototype.showHelp = function () {
        this.help.next();
    };
    WizardHeaderComponent.prototype.close = function () {
        var _this = this;
        if (!this.autoSave ||
            this.profile.isReadonly) {
            this.state.closeWizard();
        }
        if (this.autoSave) {
            this.subscriptions.push(this.state.saveProfile()
                .pipe(concatMap(function () { return from(_this.state.closeWizard()); }), first())
                .subscribe());
        }
    };
    return WizardHeaderComponent;
}(AutoUnsubscribeComponent));
export { WizardHeaderComponent };
