<div class="container wizard-footer">
  <div class="left-container">
    <button (click)="previousStep()" *ngIf="previousVisible" class="default" >
      <svg-icon src="assets/images/arrow-left.svg"></svg-icon>
    </button>
    <div class="step-counter">{{ step }} / {{ totalSteps }}</div>
  </div>
  <button (click)="nextStep()" class="default">
    <span>{{ 'Common.Next' | translate }}</span>
    <svg-icon src="assets/images/arrow-right.svg"></svg-icon>
  </button>
</div>
