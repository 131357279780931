import { RentAssistantApi } from 'src/app/shared/api/rentassistant-api';
import { OwnerLogin, OwnerShortlistDto, LoginStatus, OwnerSubmitPreferenceDto } from './../../../shared/api/rentassistant-api.generated';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ra-ownersportal',
  templateUrl: './ownersportal.component.html',
  styleUrls: ['./ownersportal.component.scss']
})
export class OwnersportalComponent implements OnInit {

  @Input() login: OwnerLogin;
  shortlist: OwnerShortlistDto;
  shortlistSubmitted = false;
  shortlistIsExpired = false;
  @Output() loginChanged = new EventEmitter<OwnerLogin>();
  constructor(private api: RentAssistantApi) { }

  ngOnInit() {
    if (this.login.loginStatus === LoginStatus.SessionCreated) {
      this.getShortlist();
    }
  }

  getShortlist() {
    this.api.getOwnerShortlist(this.login)
    .toPromise()
    .then((sl) => {
      this.shortlist = sl;
    });
  }

  onSubmitShortlist(event: OwnerSubmitPreferenceDto) {
    this.api.submitOwnerPreferences(event)
    .toPromise()
    .then(() => {this.shortlistSubmitted = true;
    });
  }

  onShortlistExpired() {
    this.login.token = null;
    this.login.loginStatus = LoginStatus.SessionExpired;
    this.loginChanged.emit(this.login);
  }

  onLogout() {
    this.login.token = null;
    this.login.loginStatus = LoginStatus.SessionExpired;
    this.loginChanged.emit(this.login);
  }
}
