<ra-house-header *ngIf="property == null"
  [menuIconVisible]="false"
  [backIconVisible]="true">
</ra-house-header>

<ra-house-header *ngIf="property != null"
[menuIconVisible]="false"
[backIconVisible]="false">
</ra-house-header>


<div class="ra-card-container login">
  <div class="logo-container">
    <div class="logo">
      <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
    </div>
    <div class="name">
      <svg-icon [applyCss]="true" src="assets/images/clee.svg"></svg-icon>
    </div>
  </div>
  <div class="ra-card ra-card-sm">
    <div style="float: right;">
      <ra-language-selector></ra-language-selector>
    </div>
    <div class="login__intro" *ngIf="property != null">
      {{'Login.Candidacy' | translate}}
    </div>
    <div *ngIf="property != null" class="m-b-25">
      <ra-realestate-item  [property]="property"></ra-realestate-item>
    </div>
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm" [ngClass]="{ 'm-t-20': property == null }" >
      <div class="form-control-container">
        <label for="email" class="m-b-10">{{ "Login.Email" | translate }}</label>
        <div class="form-group">
          <input
            id="email"
            type="email"
            name="email"
            [(ngModel)]="email"
            #emailInput="ngModel"
            #emailField
            [placeholder]="'Login.Email' | translate"
            required
            email
          />
        </div>
        <div *ngIf="emailInput.invalid && emailInput.touched" class="messages">
          <span *ngIf="!!emailInput.errors.email" class="message">{{ 'Login.Validation.Email_Invalid'|translate }}</span>
          <span *ngIf="!!emailInput.errors.required" class="message">{{ 'Login.Validation.Email_Required'|translate }}</span>
        </div>
      </div>
      <div class="form-control-container">
        <label for="password" class="m-b-10">{{ "Login.Password" | translate }}</label>
        <div class="form-group">
          <input
            id="password"
            type="password"
            name="password"
            [(ngModel)]="password"
            #passwordInput="ngModel"
            #passwordField
            [placeholder]="'Login.Password' | translate"
            minlength="8"
            required
          />
        </div>
        <div *ngIf="passwordInput.invalid && passwordInput.touched" class="messages">
          <span *ngIf="!!passwordInput.errors.required" class="message">{{ 'Login.Validation.Password_Required'|translate }}</span>
          <span *ngIf="!!passwordInput.errors.minlength" class="message">{{ 'Login.Validation.Password_Length'|translate }}</span>
        </div>
      </div>

      <div class="form-control-container messages">
        <span class="message" *ngIf="errorKey">{{ errorKey | translate }}</span>
      </div>

      <div class="form-control-container">
        <div class="checkbox">
          <input type="checkbox"
                [checked]="rememberMe"
                (change)="rememberMe = !rememberMe"
                id="rememberMe"/>
          <label for="rememberMe">{{ 'Login.RememberMe' | translate }}</label>
        </div>
      </div>

      <div class="form-control-container form-control-actions">
        <div class="form-group">
          <button
            class="primary"
            type="submit"
            [disabled]="!loginForm.form.valid || submitting"
          >
            {{ 'Login.Login_Action' | translate }}
          </button>
        </div>
        <div class="form-group">
          <a [routerLink]="forgotPasswordLink() | localize">{{ 'Login.ForgotPassword' | translate }}</a>
        </div>
      </div>
    </form>
    <form>
      <div class="form-control-container m-b-20">
        <hr/>
        <label class="m-b-10">{{ 'Login.NoAccountYet' | translate  }}</label>
        <button type="button" [routerLink]="registerLink() | localize" class="default">{{ 'Login.Register_Action' | translate }}</button>
      </div>
    </form>
  </div>
  <div class="ra-below-card">
    <img src="assets/images/powered-by-immo-connect-9.svg"/>
  </div>
</div>
