import { Component, HostBinding } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';
import { OtherIncome, OtherIncomeType } from '../../../shared/api/rentassistant-api.generated';
import { LocalizedRouter } from '../../../core/services/localized-router.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProfileWizardStateService } from '../../../core/services/profilewizard-state.service';

@Component({
  selector: 'ra-income',
  templateUrl: './income.component.html',
  styleUrls: ['../common.scss', './income.component.scss']
})
export class IncomeComponent extends StepBaseComponent {
  @HostBinding('class') hostClass = 'wizard';

  otherIncomeTypes: { value: OtherIncomeType, text: string }[];

  constructor(protected localizedRouter: LocalizedRouter,
              protected route: ActivatedRoute,
              private translate: TranslateService,
              protected stateService: ProfileWizardStateService) {
    super(localizedRouter, route, stateService);
  }

  init() {
    if (!this.person.otherIncomes) {
      this.person.otherIncomes = [];
    }

    this.otherIncomeTypes =
        Object.keys(OtherIncomeType)
              .map(key => ({
                value: OtherIncomeType[key],
                text: this.translate.instant(`RenterWizard.OtherIncomeType_${OtherIncomeType[key]}`)
                })
              )
              .sort((a, b) => a.text > b.text ? 1 : -1);
  }

  previous() {
    this.localizedRouter.navigate(['../contactgegevens'], {
      relativeTo: this.route
    });
  }

  next() {
    this.localizedRouter.navigate(['../huidige-huur'], { relativeTo: this.route });
  }

  addOtherIncomeRow() {
    this.person.otherIncomes.push({ type: null });
  }

  removeOtherIncomeRow(otherIncome: OtherIncome) {
    const idx = this.person.otherIncomes.indexOf(otherIncome);
    if (idx > -1) {
      this.person.otherIncomes.splice(idx, 1);
    }
  }

  beforeNavigate() {
    // filter out invalid items (no type or no income)
    this.person.otherIncomes = this.person.otherIncomes.filter(
      otherIncome => !!otherIncome.type &&
                     !!otherIncome.income
                    );
  }
}
