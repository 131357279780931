import { map } from 'rxjs/operators';
import { indexTenants, resetBrokerLogoUrl, indexCandidacyTenants } from '../../core/models';
import { RentAssistantClient, Action, ProfileDetailsDto, CandidacyDto, OrganizationDto, AttachmentType, ContentItemLanguage, PropertyDto, BrokerDto, EmailDto, OwnerLogin, OwnerSavePreferenceDto, OwnerSubmitPreferenceDto, WidgetParams, ShareProfileDto, ScoreProfileDto, CandidacyArchiveDto } from './rentassistant-api.generated';
import * as i0 from "@angular/core";
import * as i1 from "./rentassistant-api.generated";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-localize-router/src/localize-router.service";
var RentAssistantApi = /** @class */ (function () {
    function RentAssistantApi(api, httpClient, localizeRouterService, baseUrl) {
        this.api = api;
        this.httpClient = httpClient;
        this.localizeRouterService = localizeRouterService;
        this.baseUrl = baseUrl;
    }
    RentAssistantApi.prototype.getCandidacies = function () {
        return this.api.candidacies_GetMyCandidacies()
            .pipe(map(function (candidacies) { return candidacies.map(function (cand) { return resetBrokerLogoUrl(cand); }); }));
    };
    RentAssistantApi.prototype.ownerLogin = function (login) {
        return this.api.owner_OwnerLogin(login);
    };
    RentAssistantApi.prototype.getCandidacy = function (id) {
        return this.api
            .candidacies_GetCandidacy(id, true)
            .pipe(map(function (cand) { return indexCandidacyTenants(cand); }), map(function (cand) { return resetBrokerLogoUrl(cand); }));
    };
    RentAssistantApi.prototype.recallCandidacy = function (id) {
        return this.api.candidacies_CandidacyAction(id, {
            action: Action.Recall
        });
    };
    RentAssistantApi.prototype.deleteDraftCandidacy = function (id) {
        return this.api.candidacies_CandidacyAction(id, {
            action: Action.Delete
        });
    };
    RentAssistantApi.prototype.submitCandidacy = function (id) {
        return this.api
            .candidacies_CandidacyAction(id.candidacyId, {
            action: Action.Submit,
            comment: id.remarks,
            hasVisitedProperty: id.hasVisitedProperty
        })
            .pipe(map(function () { return void 0; }));
    };
    RentAssistantApi.prototype.saveCandidacyProfile = function (candidacyId, profile) {
        return this.api.profiles_SaveCandidacyProfile(candidacyId, profile);
    };
    RentAssistantApi.prototype.createCandidacy = function (brokerNumber, realEstateObjectId, saveCandidacy) {
        return this.api.candidacies_CreateCandidacy({
            brokerOrganizationNumber: brokerNumber,
            realEstateObjectId: realEstateObjectId,
            commit: saveCandidacy
        }).pipe(map(function (cand) { return resetBrokerLogoUrl(cand); }));
    };
    RentAssistantApi.prototype.getCandidacyActions = function (candidacyId) {
        return this.api.actions_GetCandidacyActions(candidacyId);
    };
    RentAssistantApi.prototype.getUserProfile = function () {
        return this.api.profiles_GetUserProfile()
            .pipe(map(function (profile) { return indexTenants(profile); }));
    };
    RentAssistantApi.prototype.saveUserProfile = function (profileDetailsDto) {
        return this.api.profiles_SaveUserProfile(profileDetailsDto);
    };
    RentAssistantApi.prototype.findOrganization = function (organizationNumber) {
        return this.api.organizations_Get(organizationNumber);
    };
    RentAssistantApi.prototype.addUserProfileAttachment = function (personId, attachmentType, file, profile) {
        var url = this.baseUrl + "/profiles/latest/attachments?type=" + attachmentType + "&personId=" + personId + "&originalFileName=" + file.name;
        var formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('profile', JSON.stringify(profile));
        return this.httpClient.post(url, formData);
    };
    RentAssistantApi.prototype.addCandidacyProfileAttachment = function (candidacyId, personId, attachmentType, file, profile) {
        var url = this.baseUrl + "/candidacies/" + candidacyId + "/profiles/attachments" +
            ("?type=" + attachmentType + "&personId=" + personId + "&originalFileName=" + file.name);
        var formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('profile', JSON.stringify(profile));
        return this.httpClient.post(url, formData);
    };
    RentAssistantApi.prototype.deleteUserProfileAttachment = function (personId, documentId, profile) {
        return this.api.profilesAttachments_DeleteUserProfileAttachment(profile, documentId, personId);
    };
    RentAssistantApi.prototype.deleteCandidacyProfileAttachment = function (candidacyId, personId, documentId, profile) {
        return this.api.profilesAttachments_DeleteCandidacyProfileAttachment(candidacyId, profile, documentId, personId);
    };
    RentAssistantApi.prototype.downloadAttachment = function (documentId, fileName) {
        var _this = this;
        return this.httpClient
            .get(this.baseUrl + "/attachments/" + documentId, { responseType: 'arraybuffer' })
            .subscribe(function (file) { return _this.openBlob(file, 'application/octect-stream', fileName); });
    };
    RentAssistantApi.prototype.openBlob = function (arrayBuffer, type, fileName) {
        var blob = new Blob([arrayBuffer], { type: type });
        var url = window.URL.createObjectURL(blob);
        var fileLink = document.createElement('a');
        fileLink.href = url;
        // it forces the name of the downloaded file
        fileLink.download = fileName;
        fileLink.click();
    };
    RentAssistantApi.prototype.getProfilePublicToken = function (candidacyId) {
        return this.api.profiles_GetPublicToken(candidacyId);
    };
    RentAssistantApi.prototype.shareProfile = function (shareProfileDto) {
        return this.api.profiles_ShareLatestProfile(shareProfileDto);
    };
    RentAssistantApi.prototype.getProfileByToken = function (token) {
        return this.api.tokens_GetProfileByToken(token)
            .pipe(map(function (profile) { return indexTenants(profile); }));
    };
    RentAssistantApi.prototype.getQuestionAndAnswers = function () {
        var currentLang = this.localizeRouterService.parser.currentLang;
        return this.api.content_GetQuestionsAndAnswers(currentLang);
    };
    RentAssistantApi.prototype.getWebTexts = function () {
        var currentLang = this.localizeRouterService.parser.currentLang;
        return this.api.content_GetWebTexts(currentLang);
    };
    RentAssistantApi.prototype.getProperty = function (realEstateObjectId) {
        return this.api.properties_Get(realEstateObjectId);
    };
    RentAssistantApi.prototype.getBroker = function (organizationNumber) {
        return this.api.brokers_Get(organizationNumber);
    };
    RentAssistantApi.prototype.sendEmail = function (email) {
        return this.api.actions_SendEmail(email);
    };
    RentAssistantApi.prototype.getOwnerShortlist = function (ownerLogin) {
        return this.api.owner_GetOwnerShortlistDto(ownerLogin.shortlistUniqueId, ownerLogin.phoneNumber, ownerLogin.token, ownerLogin.ownerSessionId);
    };
    RentAssistantApi.prototype.saveOwnerPreferences = function (ownerSavePreferences) {
        return this.api.owner_SaveOwnerPreference(ownerSavePreferences);
    };
    RentAssistantApi.prototype.submitOwnerPreferences = function (ownerSubmitPreferenceDto) {
        return this.api.owner_SubmitPreference(ownerSubmitPreferenceDto);
    };
    RentAssistantApi.prototype.saveWidgetProperty = function (widgetParams) {
        return this.api.widget_SaveRealEstateObject(widgetParams);
    };
    RentAssistantApi.prototype.getOwnerProperty = function (shortlistUniqueId) {
        return this.api.owner_GetShortlistsProperty(shortlistUniqueId);
    };
    RentAssistantApi.prototype.getWidgetProperty = function (realEstateObjectId) {
        return this.api.widget_GetWidgetProperty(realEstateObjectId);
    };
    RentAssistantApi.prototype.getWidgetScore = function (profile) {
        return this.api.widget_GetProfileScore(profile);
    };
    RentAssistantApi.prototype.archiveCandidacy = function (candidacyArchive) {
        return this.api.candidacies_ArchiveCandidacy(candidacyArchive);
    };
    RentAssistantApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RentAssistantApi_Factory() { return new RentAssistantApi(i0.ɵɵinject(i1.RentAssistantClient), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LocalizeRouterService), i0.ɵɵinject(i1.API_BASE_URL_RENTASSISTANT)); }, token: RentAssistantApi, providedIn: "root" });
    return RentAssistantApi;
}());
export { RentAssistantApi };
