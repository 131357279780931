import * as tslib_1 from "tslib";
import { OnInit, TemplateRef, EventEmitter } from '@angular/core';
import { getName, makeEmptyPerson } from '../../../core/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { filter, concatMap, first } from 'rxjs/operators';
import { from } from 'rxjs';
var RenterWizardHeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RenterWizardHeaderComponent, _super);
    function RenterWizardHeaderComponent(translate, modal, stateService) {
        var _this = _super.call(this) || this;
        _this.translate = translate;
        _this.modal = modal;
        _this.stateService = stateService;
        _this.person = makeEmptyPerson();
        _this.beforeClose = new EventEmitter();
        return _this;
    }
    RenterWizardHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.stateService
            .state$
            .pipe(filter(function (x) { return !!x; }))
            .subscribe(function (state) {
            _this.state = state;
            _this.profile = state.profile;
            _this.readonly = _this.profile.isReadonly;
        }));
    };
    Object.defineProperty(RenterWizardHeaderComponent.prototype, "name", {
        get: function () {
            var name = getName(this.person);
            if (name === undefined) {
                return this.translate.instant('RenterWizard.NewRenter');
            }
            return name;
        },
        enumerable: true,
        configurable: true
    });
    RenterWizardHeaderComponent.prototype.removePerson = function () {
        this.modal.open(this.deleteModal);
    };
    RenterWizardHeaderComponent.prototype.close = function () {
        var _this = this;
        this.beforeClose.next();
        if (this.readonly) {
            this.state.closeWizard();
        }
        else {
            this.subscriptions.push(this.state.saveProfile()
                .pipe(concatMap(function () { return from(_this.state.closeWizard()); }), first())
                .subscribe());
        }
    };
    RenterWizardHeaderComponent.prototype.afterDeleted = function (modal) {
        var _this = this;
        modal.dismiss();
        this.subscriptions.push(this.state.saveProfile()
            .pipe(concatMap(function (x) {
            return from(_this.state.closeWizard());
        }), first())
            .subscribe());
    };
    return RenterWizardHeaderComponent;
}(AutoUnsubscribeComponent));
export { RenterWizardHeaderComponent };
