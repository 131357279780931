import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { makeEmptyCandidacy } from 'src/app/core/models';
import { NgForm } from '@angular/forms';
import { tap, finalize } from 'rxjs/operators';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
var SubmitConfirmationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SubmitConfirmationComponent, _super);
    function SubmitConfirmationComponent(api, localizedRouter) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.localizedRouter = localizedRouter;
        _this.candidacy = makeEmptyCandidacy();
        _this.cancel = new EventEmitter();
        _this.remarks = '';
        _this.visitConfirmed = false;
        _this.dataConfirmed = false;
        _this.isLoading = false;
        return _this;
    }
    SubmitConfirmationComponent.prototype.submit = function () {
        var _this = this;
        this.form.control.markAllAsTouched();
        if (!this.form.valid) {
            return;
        }
        this.isLoading = true;
        this.subscriptions.push(this.api
            .submitCandidacy({
            candidacyId: this.candidacy.uniqueId,
            remarks: this.remarks,
            hasVisitedProperty: this.visitConfirmed
        })
            .pipe(tap(function () {
            _this.cancel.next();
            _this.localizedRouter.navigate(['/app/kandidaturen']);
        }, finalize(function () {
            _this.isLoading = false;
        })))
            .subscribe(function () {
            _this.cancel.next();
        }, function () {
            _this.isLoading = false;
        }));
    };
    return SubmitConfirmationComponent;
}(AutoUnsubscribeComponent));
export { SubmitConfirmationComponent };
