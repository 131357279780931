import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { UserDto } from '../../shared/api/rentassistant-api.generated';

export class Session {
  token: string;
  user: UserDto;
}

type StorageType = 'session' | 'permanent';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  sessionKey = 'ra-session';
  storageTypeKey = 'ra-storage-type';
  session$: Subject<Session>;
  returnUrl: string;

  storage: Storage;

  private token: string;
  private user: UserDto;

  constructor() {
    this.session$ = new BehaviorSubject(undefined);
    try {
      const storageType = localStorage.getItem(this.storageTypeKey) as StorageType;
      this.storage = storageType === 'permanent' ? localStorage : sessionStorage;
    } catch (e) {
      console.error(e);
    }
    this.loadSession();
  }

  setToken(token: string) {
    this.token = token;
    this.session$.next({ token, user: this.user });
    this.saveSession();
  }

  setUser(user: UserDto) {
    this.user = user;
    this.session$.next({ token: this.token, user });
    this.saveSession();
  }

  clearSession() {
    this.user = null;
    this.token = null;
    try {
      sessionStorage.removeItem(this.sessionKey);
      localStorage.removeItem(this.sessionKey);
    } catch (e) {
      console.error(e);
    }
    this.session$.next(null);
  }

  setStorageType(permanent: boolean) {
    const storageType: StorageType = permanent ? 'permanent' : 'session';
    try {
      localStorage.setItem(this.storageTypeKey, storageType);
      this.storage = storageType === 'permanent' ? localStorage : sessionStorage;
    } catch (e) {
      console.error(e);
    }
  }

  isAuthenticated() {
    return !!this.user;
  }

  private saveSession() {
    try {
      this.storage.setItem(this.sessionKey, JSON.stringify({ token: this.token, user: this.user }));
    } catch (e) {
      console.error(e);
    }
  }

  private loadSession() {
    try {
      const storedSession = this.storage.getItem(this.sessionKey);
      if (storedSession) {
        const session = JSON.parse(storedSession);
        this.token = session.token;
        this.user = session.user;
        this.session$.next(session);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
