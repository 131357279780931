/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "../../core/services/auth.service";
import * as i5 from "../../core/services/localized-router.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/router";
import * as i8 from "../../shared/components/footer/footer.component.ngfactory";
import * as i9 from "../../shared/components/footer/footer.component";
import * as i10 from "./shell.component";
var styles_ShellComponent = [i0.styles];
var RenderType_ShellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShellComponent, data: {} });
export { RenderType_ShellComponent as RenderType_ShellComponent };
export function View_ShellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "shell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ra-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.HeaderComponent, [i4.AuthService, i5.LocalizedRouter, i6.TranslateService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ra-footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i9.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 9, 0); }, null); }
export function View_ShellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-shell", [], null, null, null, View_ShellComponent_0, RenderType_ShellComponent)), i1.ɵdid(1, 114688, null, 0, i10.ShellComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShellComponentNgFactory = i1.ɵccf("ra-shell", i10.ShellComponent, View_ShellComponent_Host_0, {}, {}, []);
export { ShellComponentNgFactory as ShellComponentNgFactory };
