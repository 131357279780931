<ra-house-header
  [titleKey]="'Registration.Title'"
  [subTitle]="'Registration.SubTitle' | translate"
  [menuIconVisible]="false"
  [backIconVisible]="true"
  [backNavigation]="getLoginNav()">
</ra-house-header>
<div class="ra-card-container">
  <div class="ra-card">
    <div style="float: right;">
      <ra-language-selector></ra-language-selector>
    </div>
    <form class="form-grid"
          (ngSubmit)="onSubmit()"
          #registrationForm="ngForm">
          <div class="form-control-container">
        <label for="firstName">{{ 'Registration.FirstName' | translate }}</label>
        <input
          id="firstName"
          type="text"
          name="firstName"
          [(ngModel)]="firstName"
          #firstNameInput="ngModel"
          #firstNameField
          [placeholder]="'Registration.FirstName' | translate"
          required
        />
        <div *ngIf="firstNameInput.invalid && firstNameInput.touched" class="messages">
          <span *ngIf="!!firstNameInput.errors.required" class="message">
            {{ 'Registration.Validation.FirstNameRequired'|translate }}
          </span>
        </div>
      </div>

      <div class="form-control-container">
        <label for="lastName">{{ 'Registration.LastName' | translate }}</label>
        <input
          id="lastName"
          type="text"
          name="lastName"
          [(ngModel)]="lastName"
          #lastNameInput="ngModel"
          [placeholder]="'Registration.LastName' | translate"
          required
        />
        <div *ngIf="lastNameInput.invalid && lastNameInput.touched" class="messages">
          <span *ngIf="!!lastNameInput.errors.required" class="message">
            {{ 'Registration.Validation.LastNameRequired' | translate }}
          </span>
        </div>
      </div>

      <div class="form-control-container">
        <label for="email">{{ 'Registration.Email' | translate }}</label>
        <input
          id="email"
          type="text"
          name="email"
          [(ngModel)]="email"
          #emailInput="ngModel"
          [placeholder]="'Registration.Email' | translate"
          required
          email
        />
        <div *ngIf="emailInput.invalid && emailInput.touched" class="messages">
          <span *ngIf="!!emailInput.errors.email" class="message">{{ 'Registration.Validation.EmailInvalid'|translate }}</span>
          <span *ngIf="!!emailInput.errors.required" class="message">{{ 'Registration.Validation.EmailRequired'|translate }}</span>
        </div>
      </div>

      <div class="form-control-container">
        <label for="password">{{ 'Registration.Password' | translate }}</label>
        <input
          id="password"
          type="password"
          name="password`"
          [(ngModel)]="password"
          #passwordInput="ngModel"
          [placeholder]="'Registration.Password' | translate"
          minlength="8"
          pattern="^(?:(?=.*\d)(?=.*[A-Z]).*)$"
          required
        />
        <div *ngIf="passwordInput.invalid && passwordInput.touched" class="messages">
          <span *ngIf="!!passwordInput.errors.required" class="message">{{ 'Registration.Validation.PasswordRequired'|translate }}</span>
          <span *ngIf="!!passwordInput.errors.minlength || !!passwordInput.errors.pattern" class="message">{{ 'Registration.Validation.PasswordPattern'|translate }}</span>
        </div>
      </div>

      <div class="checkbox">
        <input type="checkbox"
               (change)="privacyStatementAccepted = !privacyStatementAccepted"
               id="privacyStatementAccepted"/>
        <label for="privacyStatementAccepted">
          {{ 'Registration.AcceptPrivacyStatement' | translate }}
          <a href="{{'Url.Privacy' | translate}}">
            {{ 'Menu.Privacy' | translate }}
          </a>
          &
          <a href="{{'Url.UserPolicy' | translate}}">
            {{ 'Menu.Conditions' | translate }}
          </a>
        </label>
      </div>

      <div>
        <button
          type="submit"
          class="primary"
          [disabled]="!registrationForm.form.valid || submitting || !privacyStatementAccepted"
        >
          {{ 'Registration.Register' | translate }}
        </button>
      </div>
      <div class="messages">
        <span class="message" *ngIf="errorKey">{{ errorKey | translate }}</span>
      </div>
      <div>
        <hr/>
        <span>{{ 'Registration.DoYouHaveAnAccount' | translate  }}</span>
      </div>
      <div>
        <button [routerLink]="loginLink() | localize" class="default">{{ 'Registration.Login' | translate }}</button>
      </div>
    </form>
  </div>
  <div class="ra-below-card">
    <img src="assets/images/powered-by-immo-connect-9.svg"/>
  </div>
</div>
