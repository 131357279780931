/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ownersportal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../sidebar/sidebar.component.ngfactory";
import * as i3 from "../sidebar/sidebar.component";
import * as i4 from "../table/table.component.ngfactory";
import * as i5 from "../table/table.component";
import * as i6 from "ng2-dragula";
import * as i7 from "../../../shared/api/rentassistant-api";
import * as i8 from "../owner.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "@angular/common";
import * as i11 from "../../../shared/components/house-header/house-header.component.ngfactory";
import * as i12 from "../../../shared/components/house-header/house-header.component";
import * as i13 from "../../../core/services/localized-router.service";
import * as i14 from "./ownersportal.component";
var styles_OwnersportalComponent = [i0.styles];
var RenderType_OwnersportalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OwnersportalComponent, data: {} });
export { RenderType_OwnersportalComponent as RenderType_OwnersportalComponent };
function View_OwnersportalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-sidebar", [], null, [[null, "logout"], [null, "submitShortlist"], [null, "shortlistExpired"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logout" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } if (("submitShortlist" === en)) {
        var pd_1 = (_co.onSubmitShortlist($event) !== false);
        ad = (pd_1 && ad);
    } if (("shortlistExpired" === en)) {
        var pd_2 = (_co.onShortlistExpired() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SidebarComponent_0, i2.RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarComponent, [], { property: [0, "property"], ownerLogin: [1, "ownerLogin"] }, { submitShortlist: "submitShortlist", shortlistExpired: "shortlistExpired", logout: "logout" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shortlist.property; var currVal_1 = _co.login; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OwnersportalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-table", [], null, null, null, i4.View_TableComponent_0, i4.RenderType_TableComponent)), i1.ɵdid(1, 114688, null, 0, i5.TableComponent, [i6.DragulaService, i7.RentAssistantApi, i8.OwnerService], { profileDetails: [0, "profileDetails"], login: [1, "login"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shortlist.profileDetails; var currVal_1 = _co.login; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OwnersportalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Registration.ShortlistExpired")); _ck(_v, 1, 0, currVal_0); }); }
function View_OwnersportalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "structure"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "structure-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnersportalComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "structure-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnersportalComponent_3)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnersportalComponent_4)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.shortlist && _co.login); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.shortlist && !_co.shortlistIsExpired); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.shortlistIsExpired; _ck(_v, 8, 0, currVal_2); }, null); }
function View_OwnersportalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ra-house-header", [], null, null, null, i11.View_HouseHeaderComponent_0, i11.RenderType_HouseHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i12.HouseHeaderComponent, [i13.LocalizedRouter], { menuIconVisible: [0, "menuIconVisible"], backIconVisible: [1, "backIconVisible"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "ra-card-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "ra-card m-t-40 p-40 text-center "]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h1", [["class", "color-green m-b-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = false; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Owner.Congratulations")); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("Owner.Finished")); _ck(_v, 9, 0, currVal_3); }); }
export function View_OwnersportalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnersportalComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OwnersportalComponent_5)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.shortlistSubmitted; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.shortlistSubmitted; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_OwnersportalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-ownersportal", [], null, null, null, View_OwnersportalComponent_0, RenderType_OwnersportalComponent)), i1.ɵdid(1, 114688, null, 0, i14.OwnersportalComponent, [i7.RentAssistantApi], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OwnersportalComponentNgFactory = i1.ɵccf("ra-ownersportal", i14.OwnersportalComponent, View_OwnersportalComponent_Host_0, { login: "login" }, { loginChanged: "loginChanged" }, []);
export { OwnersportalComponentNgFactory as OwnersportalComponentNgFactory };
