import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { filter } from 'rxjs/operators';
var ResidentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResidentsComponent, _super);
    function ResidentsComponent(localizedRouter, route, stateService) {
        var _this = _super.call(this) || this;
        _this.localizedRouter = localizedRouter;
        _this.route = route;
        _this.stateService = stateService;
        return _this;
    }
    ResidentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.stateService
            .state$
            .pipe(filter(function (x) { return !!x; }))
            .subscribe(function (state) {
            _this.profile = state.profile;
            _this.state = state;
        }));
        if (this.profile.generalDetails.numberOfAdults == null) {
            this.profile.generalDetails.numberOfAdults = 1;
        }
        if (this.profile.generalDetails.numberOfChildren == null) {
            this.profile.generalDetails.numberOfChildren = 0;
        }
    };
    ResidentsComponent.prototype.help = function () { };
    ResidentsComponent.prototype.next = function () {
        this.localizedRouter.navigate(['../wanneer'], { relativeTo: this.route });
    };
    return ResidentsComponent;
}(AutoUnsubscribeComponent));
export { ResidentsComponent };
