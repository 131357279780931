import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizedRouter } from '../../../core/services/localized-router.service';
import { ProfileDetailsDto } from 'src/app/shared/api/rentassistant-api.generated';
import { AutoUnsubscribeComponent } from '../../../shared/auto-unsubscribe.component';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ProfileWizardStateService, ProfileWizardState } from '../../../core/services/profilewizard-state.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ra-when',
  templateUrl: './when.component.html',
  styleUrls: ['./when.component.scss']
})
export class WhenComponent extends AutoUnsubscribeComponent implements OnInit {

  profile: ProfileDetailsDto;
  state: ProfileWizardState;

  @ViewChild('datepickerModal', { static: false })
  datepickerModal: TemplateRef<any>;

  now = new Date();

  constructor(private localizedRouter: LocalizedRouter,
              private route: ActivatedRoute,
              private modal: NgbModal,
              private stateService: ProfileWizardStateService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.stateService
          .state$
          .pipe(filter(x => !!x))
          .subscribe(state => {
              this.profile = state.profile;
              this.state = state;
          })
    );
  }

  help() {
  }

  previous() {
    this.localizedRouter.navigate(['../bewoners'], { relativeTo: this.route });
  }

  next() {
    this.localizedRouter.navigate(['../opties'], { relativeTo: this.route });
  }

  durationPreferenceChanged(durationPreference: boolean) {
    if (!durationPreference) {
      this.profile.generalDetails.durationType = null;
      this.profile.generalDetails.durationMonths = null;
      this.profile.generalDetails.durationYears = null;
    }
  }

  openDatepicker() {
    this.modal.open(this.datepickerModal);
  }

  onDateChosen(dateStruct: NgbDateStruct) {
    this.profile.generalDetails.expectedMovingDate = {
      day: dateStruct.day,
      month: dateStruct.month,
      year: dateStruct.year
     };
  }
  get expectedMovingDate(): Date {
    if (this.profile &&
        this.profile.generalDetails &&
        this.profile.generalDetails.expectedMovingDate) {
        const dateDto = this.profile.generalDetails.expectedMovingDate;
        return new Date(dateDto.year,
                        dateDto.month - 1,
                        dateDto.day);
    }
    return null;
  }
}
