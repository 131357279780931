import { LocalizeRouterModule } from 'ngx-localize-router';
import { RouterModule, Routes } from '@angular/router';
import { OwnersportalLoginComponent } from './components/ownersportal-login/ownersportal-login.component';
import { NgModule } from '@angular/core';


export const routes: Routes = [
  {
    path: 'eigenaarsportaal/:id',
    component: OwnersportalLoginComponent
  },
];

@NgModule({
    imports: [
      RouterModule.forChild(routes),
      LocalizeRouterModule.forChild(routes)
    ],
    exports: [RouterModule, LocalizeRouterModule]
  })
  export class OwnersPortalRoutingModule { }
