<div class="wizard">
    <ra-wizard-header
      [wizardType]="'General'"
      [autoSave]="!profile.isReadonly"
      (help)="help()"
    ></ra-wizard-header>
    <div class="wizard-page container">
        <form class="form-grid">
          <div class="info-icon-container">
            <div class="title">{{ 'GeneralWizard.WhenToMove' | translate }}</div>
              <div class="info-icon"
                   tooltip="{{ 'GeneralWizard.WhenToMoveInfo' | translate }}"
                   placement="auto">
                <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
              </div>
            </div>
          <ra-choice [(ngModel)]="profile.generalDetails.relocationType"
                     [disabled]="profile.isReadonly"
                     name="relocationType">
            <ra-choice-item value="Asap">{{ 'GeneralWizard.AsSoonAsPossible' | translate }}</ra-choice-item>
            <ra-choice-item value="SpecificDate" (click)="openDatepicker()">
              <ng-template #iconTemplate>
                <svg-icon src="assets/images/calendar.svg"></svg-icon>
              </ng-template>
              <div *ngIf="profile.generalDetails.expectedMovingDate">
                {{ expectedMovingDate | date:'MMMM y' | titlecase }}
              </div>
              <div *ngIf="!profile.generalDetails.expectedMovingDate">
                  {{ 'Common.ChooseDate' | translate }}
              </div>
            </ra-choice-item>
            <ra-choice-item value="NoPreference">{{ 'Common.NoPreference' | translate  }}</ra-choice-item>
          </ra-choice>
          <div class="gap"></div>
          <div class="info-icon-container">
            <div class="title">{{ 'GeneralWizard.DurationPreference' | translate }}</div>
              <div class="info-icon"
                   tooltip="{{ 'GeneralWizard.DurationPreferenceInfo' | translate }}"
                   placement="top">
                <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
              </div>
            </div>
          <ra-choice horizontal="true"
                     [disabled]="profile.isReadonly"
                     [(ngModel)]="profile.generalDetails.durationPreference"
                     (choiceChange)="durationPreferenceChanged($event)"
                     name="durationPreference">
            <ra-choice-item [value]="false" name="noPreference">
              {{ 'Common.NoPreference' | translate }}
            </ra-choice-item>
            <ra-choice-item [value]="true" name="preference">
              {{ 'GeneralWizard.Preference' | translate }}
            </ra-choice-item>
          </ra-choice>
          <div *ngIf="profile.generalDetails.durationPreference">
            <ra-choice [disabled]="profile.isReadonly"
                      [(ngModel)]="profile.generalDetails.durationType"
                      name="durationType">
              <ra-choice-item [value]="'Max1Year'" name="max1Year">
                {{ 'GeneralWizard.DurationType_Max1Year' | translate }}
              </ra-choice-item>
              <ra-choice-item [value]="'Max3Years'" name="max3Years">
                {{ 'GeneralWizard.DurationType_Max3Years' | translate }}
              </ra-choice-item>
              <ra-choice-item [value]="'MoreThan3Years'" name="moreThan3Years">
                {{ 'GeneralWizard.DurationType_MoreThan3Years' | translate }}
              </ra-choice-item>
              <ra-choice-item [value]="'Specific'" name="specific">
                {{ 'GeneralWizard.DurationType_Specific' | translate }}
              </ra-choice-item>
            </ra-choice>
            <div *ngIf="profile.generalDetails.durationType === 'Specific'"
                 class="specific-duration">
              <input type="number"
                     name="years"
                     min="1"
                     max="10"
                     pattern="^(?:[1-9]|0[1-9]|10)$"
                     [(ngModel)]="profile.generalDetails.durationYears"
                     [readOnly]="profile.isReadonly"
                     #durationYears="ngModel"/>
              <span>{{ 'Common.Years' | translate }}</span>
              <input type="number"
                     name="months"
                     min="1"
                     max="11"
                     pattern="^[2-9]|1[0-1]?$"
                     [readOnly]="profile.isReadonly"
                     [(ngModel)]="profile.generalDetails.durationMonths" />
              <span>{{ 'Common.Months' | translate }}</span>
            </div>
          </div>
        </form>
      </div>
    <ra-wizard-footer
      [step]="2"  [totalSteps]="3"
      [autoSave]="!profile.isReadonly"
      (previous)="previous()"
      (next)="next()"
    ></ra-wizard-footer>
  </div>

  <ng-template #datepickerModal let-modal>
    <ra-datepicker-modal
      [titleKey]="'GeneralWizard.ChooseMoveDate'"
      [date]="profile.generalDetails.expectedMovingDate"
      (cancel)="modal.dismiss()"
      (dateChosen)="onDateChosen($event)">
    </ra-datepicker-modal>
  </ng-template>
