import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var OwnerService = /** @class */ (function () {
    function OwnerService(translate) {
        this.translate = translate;
    }
    OwnerService.prototype.getAvatarIcon = function (rp) {
        if (rp == null || rp.generalDetails == null) {
            return './../../../../assets/images/avatars/avatar-1.svg';
        }
        var avatorNumber = 0;
        if (rp.generalDetails.numberOfAdults != null) {
            avatorNumber = avatorNumber + rp.generalDetails.numberOfAdults;
        }
        if (rp.generalDetails.numberOfChildren != null) {
            avatorNumber = avatorNumber + rp.generalDetails.numberOfChildren;
        }
        if (avatorNumber > 5) {
            avatorNumber = 5;
        }
        return './../../../../assets/images/avatars/avatar-' + avatorNumber.toString() + '.svg';
    };
    OwnerService.prototype.boolTranslate = function (value) {
        if (value === true) {
            return this.translate.instant('Common.Yes');
        }
        else {
            return this.translate.instant('Common.No');
        }
    };
    OwnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OwnerService_Factory() { return new OwnerService(i0.ɵɵinject(i1.TranslateService)); }, token: OwnerService, providedIn: "root" });
    return OwnerService;
}());
export { OwnerService };
