<div class="left-circle" [ngClass]="infoProvider.isChecked()">
  <svg-icon
    src="assets/images/check.svg"
    *ngIf="infoProvider.isChecked() === 'checked'"
  ></svg-icon>
  <svg-icon
    src="assets/images/exclamation.svg"
    *ngIf="infoProvider.isChecked() === 'error'"
  ></svg-icon>
</div>

<div class="content">
  <div class="main-text">{{ infoProvider.titleKey() | translate }}</div>
  <div class="sub-text" [ngClass]="infoProvider.isChecked()" *ngIf="infoProvider.subText().length > 0">
    {{ infoProvider.subText() }}
  </div>
</div>

<div class="arrow-box">
  <fa-icon [icon]="faChevronRight"></fa-icon>
</div>
