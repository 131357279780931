import * as tslib_1 from "tslib";
import { AuthResendActivateRequest } from './../../shared/api/immoconnect-api.generated';
import { tap, concatMap } from 'rxjs/operators';
import { AuthSignInRequest, AuthRegisterRequest, AuthActivateRequest, InitiatePasswordResetRequest, CompletePasswordResetRequest } from '../../shared/api/immoconnect-api.generated';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/api/immoconnect-api.generated";
import * as i2 from "../../shared/api/rentassistant-api.generated";
import * as i3 from "./session.service";
import * as i4 from "./localized-router.service";
import * as i5 from "@angular/router";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../environments/dynamic-environment";
var AuthService = /** @class */ (function () {
    function AuthService(immoConnectClient, rentAssistantClient, sessionService, localizedRouter, router, translateService, environment) {
        this.immoConnectClient = immoConnectClient;
        this.rentAssistantClient = rentAssistantClient;
        this.sessionService = sessionService;
        this.localizedRouter = localizedRouter;
        this.router = router;
        this.translateService = translateService;
        this.environment = environment;
    }
    AuthService.prototype.register = function (newUser) {
        var registerRequest = new AuthRegisterRequest(tslib_1.__assign({}, newUser));
        registerRequest.applicationId = this.environment.immoConnect.applicationId;
        return this.immoConnectClient.authentication_Register(registerRequest);
    };
    AuthService.prototype.activate = function (login, activationCode) {
        var activateRequest = new AuthActivateRequest({
            login: login,
            activationCode: activationCode
        });
        return this.immoConnectClient.authentication_Activate(activateRequest);
    };
    AuthService.prototype.resendActionCode = function (login) {
        var resendActivationRequest = new AuthResendActivateRequest();
        resendActivationRequest.login = login;
        resendActivationRequest.applicationId = '11f83c57-590d-4460-a01f-5047bc1e26a4';
        return this.immoConnectClient.authentication_ResendActivationCode(resendActivationRequest);
    };
    AuthService.prototype.forgotPassword = function (login, confirmationReturnUrl) {
        var initiatePAsswordResetRequest = new InitiatePasswordResetRequest({
            login: login,
            confirmationReturnUrl: confirmationReturnUrl,
            applicationId: this.environment.immoConnect.applicationId
        });
        return this.immoConnectClient.authentication_InitiatePasswordReset(initiatePAsswordResetRequest);
    };
    AuthService.prototype.resetPassword = function (login, confirmationCode, newPassword) {
        var completePasswordResetRequest = new CompletePasswordResetRequest({
            login: login,
            confirmationCode: confirmationCode,
            newPassword: newPassword,
            applicationId: this.environment.immoConnect.applicationId
        });
        return this.immoConnectClient.authentication_CompletePasswordReset(completePasswordResetRequest);
    };
    AuthService.prototype.login = function (login, password, rememberMe) {
        var _this = this;
        if (rememberMe === void 0) { rememberMe = false; }
        this.sessionService.clearSession();
        this.sessionService.setStorageType(rememberMe);
        var signinRequest = new AuthSignInRequest({
            applicationId: this.environment.immoConnect.applicationId,
            login: login,
            password: password,
            timeoutInMinutes: this.environment.immoConnect.timeoutInMinutes
        });
        return this.immoConnectClient
            .authentication_SignIn(signinRequest)
            .pipe(tap(function (res) { return _this.sessionService.setToken(res.session); }), concatMap(function (res) { return _this.updateUser(); }), tap(function (user) {
            _this.sessionService.setUser(user);
            if (_this.redirectUrl) {
                _this.router.navigate([_this.redirectUrl]);
                _this.redirectUrl = null;
            }
            else if (_this.redirectlocalizedRouter) {
                _this.localizedRouter.navigate([_this.redirectlocalizedRouter]);
            }
            else {
                _this.localizedRouter.navigate(['/app/kandidaturen']);
            }
        }));
    };
    AuthService.prototype.logout = function () {
        this.sessionService.clearSession();
        this.localizedRouter.navigate(['/']);
    };
    AuthService.prototype.isLoggedIn = function () {
        return this.sessionService.isAuthenticated();
    };
    AuthService.prototype.updateUser = function () {
        return this.rentAssistantClient.users_CreateOrUpdateUser({
            privacyStatementAccepted: true,
            languageTwoLetterIsoCode: this.translateService.currentLang
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.ImmoConnectClient), i0.ɵɵinject(i2.RentAssistantClient), i0.ɵɵinject(i3.SessionService), i0.ɵɵinject(i4.LocalizedRouter), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.TranslateService), i0.ɵɵinject(i7.DynamicEnvironment)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
