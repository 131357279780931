import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
 } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalizedRouter } from '../services/localized-router.service';
import { KeyValue } from '@angular/common';
import { ErrorService, RentAssistantError, ErrorType } from '../services/error.service';

interface ValidationErrorResponse {
  errors: KeyValue<string, string[]>[];
  title: string;
  status: number;
}

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private localizedRouter: LocalizedRouter,
              private errorService: ErrorService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // don't intercept & show Immo-Connect http errors here
    if (request.url.toLowerCase().indexOf('immo-connect') !== -1) {
      return next.handle(request);
    }

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.localizedRouter.navigate(['/login']);
          }

          if (error.error instanceof Blob) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
              try {
                const response: ValidationErrorResponse = JSON.parse(event.target.result);
                if (error.status === 400 && response &&  response.errors) {
                  const validationErrors = Object.keys(response.errors)
                                                  .map(message =>
                                    new RentAssistantError(ErrorType.Validation, message));
                  validationErrors.forEach(err =>
                          this.errorService.errors$.next(err));
                } else if (error.status === 409) {
                  this.errorService.errors$.next(
                        new RentAssistantError(ErrorType.Conflict, 'conflict', response));
                }
              } catch (error) {
                // throw general error here
                this.errorService.errors$.next(new RentAssistantError(ErrorType.Server, error));
              }
            };
            reader.readAsText(error.error);
          } else {
            this.errorService.errors$.next(new RentAssistantError(ErrorType.Server, error.message));
          }

          return throwError(error);
        })
      );
  }
}
