/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./message.component";
var styles_MessageComponent = [i0.styles];
var RenderType_MessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageComponent, data: {} });
export { RenderType_MessageComponent as RenderType_MessageComponent };
export function View_MessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "text-center p-l-15 p-r-15 subtitle"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.textKey)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.subTextKey)); _ck(_v, 3, 0, currVal_1); }); }
export function View_MessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-message", [], null, null, null, View_MessageComponent_0, RenderType_MessageComponent)), i1.ɵdid(1, 49152, null, 0, i3.MessageComponent, [], null, null)], null, null); }
var MessageComponentNgFactory = i1.ɵccf("ra-message", i3.MessageComponent, View_MessageComponent_Host_0, { textKey: "textKey", subTextKey: "subTextKey" }, {}, []);
export { MessageComponentNgFactory as MessageComponentNgFactory };
