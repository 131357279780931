<div class="desktop">
  <div class="candidacy" [ngClass]="{ 'hidden-on-mobile': !isDetail, 'center': isDetail }" >
    <div class="candidacy-container">
      <div class="content-container scroll-container scroll-light" [ngClass]="{'has-actions': hasActions || canArchive}">
        <ra-house-header-background></ra-house-header-background>
        <div class="header">
          <div class="left-container">
            <div class="icon-container" (click)="goBack()">
              <svg-icon src="assets/images/close.svg"></svg-icon>
            </div>
          </div>
          <div class="content-container">{{ 'Candidacies.Candidacy' | translate }}</div>
        </div>
        <div class="center-on-desktop">
          <div class="center-container">
              <ra-candidacy-card
                showArrowRight="false"
                [candidacy]="candidacy"
              ></ra-candidacy-card>
            <div *ngIf="candidacy && candidacy.profile && candidacy.status == Status.Draft" style="text-align: center;">
              <p *ngIf="!candidacy.profile.profileComplete">{{ 'Candidacies.ProfileIncomplete' | translate }}</p>
              <p *ngIf="candidacy.profile.profileComplete">{{ 'Candidacies.ProfileComplete' | translate }}</p>
            </div>
            <ra-feedback-selector [candidacy]="candidacy"></ra-feedback-selector>
            <ra-profile [profile]="candidacy.profile"
                        [baseUrl]="'/app/kandidaturen/' + candidacy.uniqueId"
                        [alert]="alert"
                        (addRenter)="addRenter()"></ra-profile>
          </div>
        </div>
      </div>
      <div class="actions-container">
        <div class="actions">
          <ng-container *ngIf="hasActions">
            <button
              class="primary"
              *ngIf="showAction('Submit') && !isAlreadySubmitted(candidacy)"
              (click)="submitConfirmation()">
              {{ getSubmitText() | async }}
            </button>
            <button
              class="primary"
              *ngIf="showAction('Submit') && isAlreadySubmitted(candidacy)"
              (click)="submitConfirmation()">
              {{ 'Candidacies.ReSubmitCandidacy' | translate }}
            </button>
            <button
              class="primary"
              *ngIf="showAction('Recall')"
              (click)="recallConfirmation()">
              {{ 'Candidacies.Withdraw' | translate }}
            </button>
            <button
              class="default"
              *ngIf="showAction('Delete')"
              (click)="delete()">
              {{ 'Candidacies.Remove' | translate }}
            </button>
            <button
              class="default"
              *ngIf="showAction('ContactBroker')"
              (click)="contactBroker()">
              {{ 'Candidacies.ContactBroker' | translate }}
            </button>
          </ng-container>

          <ng-container *ngIf="canArchive">
            <button
              class="default"
              *ngIf="candidacy.isArchivedByUser"
              (click)="archive()">
              {{ 'Candidacies.Unarchive' | translate }}
            </button>
            <button
              class="default"
              *ngIf="!candidacy.isArchivedByUser"
              (click)="archive()">
              {{ 'Candidacies.Archive' | translate }}
            </button>
          </ng-container>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="!isDetail" class="profile-wizard" [ngClass]="{ 'hidden-on-mobile': isDetail }">
    <router-outlet></router-outlet>
  </div>
</div>


<ng-template #submitModal let-modal>
  <ra-submit-confirmation
    [candidacy]="candidacy"
    (cancel)="modal.dismiss()"
    [remarks]="remarks"
    [visitConfirmed]="visitConfirmed"
  ></ra-submit-confirmation>
</ng-template>

<ng-template #recallModal let-modal>
  <ra-candidacy-action-confirmation
    [candidacy]="candidacy"
    [action]="'Recall'"
    (cancel)="modal.dismiss()"
  ></ra-candidacy-action-confirmation>
</ng-template>

<ng-template #deleteModal let-modal>
  <ra-candidacy-action-confirmation
    [candidacy]="candidacy"
    [action]="'Delete'"
    (cancel)="modal.dismiss()"
  ></ra-candidacy-action-confirmation>
</ng-template>

<ng-template #incompleteModal let-modal>
  <ra-modal-header (cancel)="modal.dismiss()">
  <ng-template>
    <div class="dialog-header">{{'Candidacies.Attention' | translate}}</div>
  </ng-template>
</ra-modal-header>

<div class="dialog-text">
  <span>{{'Candidacies.Incomplete' | translate}}</span>
</div>

<div class="circle">
  <svg-icon [applyCss]="true" src="assets/images/withdraw.svg"></svg-icon>
</div>

<ra-candidacy-card
[candidacy]="candidacy"
[showArrowRight]="false"
></ra-candidacy-card>


<button class="default btn-dialog"
        (click)="modal.dismiss()">
  {{ 'Candidacies.Cancel' | translate }}
</button>
</ng-template>

<ng-template #contactBrokerModal let-modal>
  <ra-contact-broker
    [candidacy]="candidacy"
    (cancel)="modal.dismiss()"
  ></ra-contact-broker>
</ng-template>

<ng-template #shareModal let-modal>
  <ra-share-modal
    (cancel)="modal.dismiss()"
    (closeModal)="modal.dismiss()"
  ></ra-share-modal>
</ng-template>
