import { ProfileDetailsDto } from './../../../shared/api/rentassistant-api.generated';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

import {
  getName,
  makeEmptyPerson
} from '../../../core/models';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { PersonDetailsDto } from 'src/app/shared/api/rentassistant-api.generated';
import { ProfileWizardStateService, ProfileWizardState } from '../../../core/services/profilewizard-state.service';
import { filter, concatMap, first } from 'rxjs/operators';
import { from } from 'rxjs';

@Component({
  selector: 'ra-renter-wizard-header',
  templateUrl: './renter-wizard-header.component.html',
  styleUrls: ['./renter-wizard-header.component.scss']
})
export class RenterWizardHeaderComponent extends AutoUnsubscribeComponent
  implements OnInit {

  @Input() person: PersonDetailsDto = makeEmptyPerson();

  @Output() beforeClose = new EventEmitter();

  @ViewChild('deleteModal', { static: false })
  deleteModal: TemplateRef<any>;

  profile: ProfileDetailsDto;
  state: ProfileWizardState;
  readonly: boolean;

  constructor(private translate: TranslateService,
              private modal: NgbModal,
              private stateService: ProfileWizardStateService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.stateService
          .state$
          .pipe(filter(x => !!x))
          .subscribe(state => {
            this.state = state;
            this.profile = state.profile;
            this.readonly = this.profile.isReadonly;
          })
    );
  }

  get name() {
    const name = getName(this.person);
    if (name === undefined) {
      return this.translate.instant('RenterWizard.NewRenter');
    }
    return name;
  }

  removePerson() {
    this.modal.open(this.deleteModal);
  }

  close() {
    this.beforeClose.next();
    if (this.readonly) {
      this.state.closeWizard();
    } else {
      this.subscriptions.push(
        this.state.saveProfile()
                  .pipe(
                    concatMap(() => from(this.state.closeWizard())),
                    first()
                  )
                  .subscribe()
      );
    }
  }

  afterDeleted(modal: NgbModalRef) {
    modal.dismiss();
    this.subscriptions.push(
      this.state.saveProfile()
                  .pipe(
                    concatMap((x) => {
                     return from(this.state.closeWizard());
                    }),
                    first()
                  )
                  .subscribe()
    );
  }
}
