import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ErrorType {
  Validation = 'Validation',
  Server = 'Server',
  Conflict = 'Conflict'
}

export class RentAssistantError {
  errorType: ErrorType;
  message: string;
  response: any;

  constructor(errorType: ErrorType, message: string, response?: any) {
    this.errorType = errorType;
    this.message = message;
    this.response = response;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  errors$: Subject<RentAssistantError>;

  constructor() {
    this.errors$ = new Subject();
  }
}
