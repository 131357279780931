<div class="wizard">
  <ra-wizard-header
    [wizardType]="'General'"
    [autoSave]="!profile.isReadonly"
    (help)="help()"
  ></ra-wizard-header>
  <div class="wizard-page container">
    <form class="form-grid">
      <div class="info-icon-container">
        <div class="title">{{ 'GeneralWizard.HowMany' | translate }}</div>
        <div class="info-icon"
             tooltip="{{ 'Candidacies.ResidentsInfo' | translate }}" placement="auto">
          <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
        </div>
      </div>
      <ra-counter name="numberOfAdults"
                  [min]="1"
                  [max]="10"
                  [labelKey]="'GeneralWizard.Adults'"
                  [disabled]="profile.isReadonly"
                  [(ngModel)]="profile.generalDetails.numberOfAdults">
                </ra-counter>
      <ra-counter name="numberOfChildren"
                  [min]="0"
                  [max]="10"
                  [(ngModel)]="profile.generalDetails.numberOfChildren"
                  [disabled]="profile.isReadonly"
                  [labelKey]="'GeneralWizard.Children'"></ra-counter>
      <div *ngIf="profile.generalDetails.numberOfChildren > 0">
        <input name="childrenDescription"
              type="text"
              [(ngModel)]="profile.generalDetails.childrenDescription"
              [readonly]="profile.isReadonly"
              [placeholder]="'GeneralWizard.ChildrenPlaceholder' | translate" />
      </div>
      <div class="big-gap"></div>
      <ra-counter name="numberOfPets"
                  [min]="0"
                  [max]="10"
                  [(ngModel)]="profile.generalDetails.numberOfPets"
                  [disabled]="profile.isReadonly"
                  [labelKey]="'GeneralWizard.NumberOfPets'"></ra-counter>
      <div *ngIf="profile.generalDetails.numberOfPets > 0">
        <input name="petsDescription"
               type="text"
               [(ngModel)]="profile.generalDetails.petsDescription"
               [readOnly]="profile.isReadonly"
               [placeholder]="'GeneralWizard.PetsPlaceholder' | translate" />
        <div class="hint">{{ 'GeneralWizard.PetsHint' | translate }}</div>
      </div>
      <div>

      </div>
    </form>
  </div>
  <ra-wizard-footer
    [step]="1"  [totalSteps]="3"
    [autoSave]="!profile.isReadonly"
    (next)="next()"
  ></ra-wizard-footer>
</div>
