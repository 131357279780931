/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/message/message.component.ngfactory";
import * as i3 from "../../../shared/components/message/message.component";
import * as i4 from "@angular/common";
import * as i5 from "./feedback.component";
var styles_FeedbackComponent = [i0.styles];
var RenderType_FeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackComponent, data: {} });
export { RenderType_FeedbackComponent as RenderType_FeedbackComponent };
function View_FeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "broker"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.brokerNameLine; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.mainText; _ck(_v, 6, 0, currVal_1); }); }
function View_FeedbackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_FeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-message", [], null, null, null, i2.View_MessageComponent_0, i2.RenderType_MessageComponent)), i1.ɵdid(1, 49152, null, 0, i3.MessageComponent, [], { textKey: [0, "textKey"], subTextKey: [1, "subTextKey"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "filler"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackComponent_2)), i1.ɵdid(7, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleKey; var currVal_1 = _co.titleSubtextKey; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.brokerNameLine; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.footerTemplate; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_FeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-feedback", [], null, null, null, View_FeedbackComponent_0, RenderType_FeedbackComponent)), i1.ɵdid(1, 114688, null, 1, i5.FeedbackComponent, [], null, null), i1.ɵqud(603979776, 1, { footerTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackComponentNgFactory = i1.ɵccf("ra-feedback", i5.FeedbackComponent, View_FeedbackComponent_Host_0, { titleKey: "titleKey", titleSubtextKey: "titleSubtextKey", brokerImageSource: "brokerImageSource", brokerNameLine: "brokerNameLine", mainText: "mainText", footerTemplate: "footerTemplate" }, {}, []);
export { FeedbackComponentNgFactory as FeedbackComponentNgFactory };
