<div class="wizard">
  <ra-wizard-header
    [wizardType]="'Attachments'"
    [autoSave]="false"
    (closeWizard)="closeWizard()"
  ></ra-wizard-header>
  <div class="wizard-page container">
    <form class="form-grid">
      <div>
        <div class="title">{{ 'AttachmentsWizard.IdentityTitle' | translate }} {{ name }}</div>
        <div>{{ 'AttachmentsWizard.IdentityText' | translate }}</div>
      </div>
      <div>
        <ra-attachment
          [attachment]="getAttachment(AttachmentType.Identification, 0)"
          [text]="('AttachmentsWizard.IdentityFront' | translate)"
          [attachmentType]="'Identification'"
          [readonly]="profile.isReadonly"
          (download)="downloadAttachment($event)"
          (delete)="deleteAttachment($event)"
          (upload)="uploadAttachment($event)"></ra-attachment>
        <ra-attachment
          [attachment]="getAttachment(AttachmentType.Identification, 1)"
          [text]="('AttachmentsWizard.IdentityBack' | translate)"
          [attachmentType]="'Identification'"
          [readonly]="profile.isReadonly"
          (download)="downloadAttachment($event)"
          (delete)="deleteAttachment($event)"
          (upload)="uploadAttachment($event)"></ra-attachment>
      </div>
      <div></div>
      <div *ngIf="isRenting">
        <div class="title">{{ 'AttachmentsWizard.ProofOfPaymentOfRentTitle' | translate }}</div>
        <div>{{ 'AttachmentsWizard.ProofOfPaymentOfRentText' | translate }}</div>
      </div>
      <button
        *ngIf="isRenting && !profile.isReadonly"
        class="default left-icon-button"
        (click)="hiddenFileInput.click()">
        <svg-icon src="assets/images/upload.svg"></svg-icon>
        <span> {{ 'AttachmentsWizard.UploadExtract' | translate }}</span>
      </button>
      <input
        style="display: none"
        type="file"
        (change)="onFilesSelected($event.target.files, AttachmentType.ProofOfPaymentOfRent)"
        #hiddenFileInput  />
      <div *ngIf="isRenting">
        <ra-attachment
          *ngFor="let attachment of getAttachments(AttachmentType.ProofOfPaymentOfRent);index as i"
          [attachment]="attachment"
          [text]="('AttachmentsWizard.Extract' | translate) + ' ' + (i+1)"
          [subText]="attachment.documentName"
          [readonly]="profile.isReadonly"
          (download)="downloadAttachment(attachment)"
          (delete)="deleteAttachment(attachment)"
        >
        </ra-attachment>
        <div
          *ngIf="profile.isReadonly &&
                getAttachments(AttachmentType.ProofOfPaymentOfRent).length === 0"
          class="muted">
          {{ 'AttachmentsWizard.NoDocuments' | translate }}
        </div>
      </div>
    </form>
  </div>
  <ra-wizard-footer
  [step]="totalSteps"  [totalSteps]="totalSteps"
  [autoSave]="false"
  (previous)="previous()"
  (next)="next()"
></ra-wizard-footer>
</div>

