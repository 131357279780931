<div class="content page-container">
  <div class="content-body">
    <div class="content-title-bar">
      <h1 class="page-title">{{ 'Owner.PreferenceList' | translate}}</h1>
      <!--div class="option-sort">
        <span>Sorteer op</span>
        <p-dropdown class="sort-filter" [options]="sortOptions" [(ngModel)]="sortOption"></p-dropdown>
      </div-->
    </div>
    <div class="content-table">
      <div class="table-overflow">
        <table>
          <colgroup>
            <col span="1" class="col-name" />
            <col span="1" class="col-name" />
            <col span="1" class="col-number" />
            <col span="1" class="col-number" />
            <col span="1" class="col-actions" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>{{ 'Owner.PreferenceRentTerm' | translate}}</th>
              <th>{{ 'Owner.Adults' | translate}}</th>
              <th>{{ 'Owner.Children' | translate}}</th>
              <th>
                {{ 'Owner.Income' | translate}} 
                <div class="info-icon" tooltip="{{ 'Owner.IncomeInformation' | translate }}" placement="auto">
                  <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
                </div>
            </th>
              <th>{{ 'Owner.Domicile' | translate}}</th>
              <th>{{ 'Owner.ProfessionalUse' | translate}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody dragula="Voorkeurslijst" id="Voorkeurslijst">
            <tr (click)="displayDetails(rp);" *ngFor="let rp of rankedProfiles" id={{rp.candidacyId}}>
              <td class="first table-icon"> <i class="far fa-arrows-alt"></i> </td>
              <td>
                <img src="{{getAvatarIcon(rp)}}" alt="">
              </td>
              <td>{{ 'GeneralWizard.DurationType_'+rp.generalDetails.durationType | translate }}</td>
              <td>{{ rp.generalDetails.numberOfAdults}}</td>
              <td>{{ rp.generalDetails.numberOfChildren}}</td>
              <td>
                <span class="table-income">{{getTotalIncomeForProfile(rp)}}</span>
              </td>
              <td>{{boolTranslate( rp.generalDetails.willTakeDomicile)}}</td>
              <td>{{boolTranslate( rp.generalDetails.isPartiallyProfessionalUse)}}</td>
              <td class="last">
                <span class="preference-number">{{rp.preferenceOrder}}</span>
              </td>
            </tr>
            <tr *ngIf="!rankedProfiles.length" class="empty-tr">
              <td class="empty-td" colspan="9">{{ 'Owner.DragDropCandidate' | translate}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <p-paginator [rows]="10" [totalRecords]="100"></p-paginator> -->
    </div>
  </div>
</div>
<div class="content page-container">
  <div class="content-body">
    <div class="content-title-bar">
      <h1 class="page-title grey">{{ 'Owner.OtherList' | translate}}</h1>
    </div>
    <div class="content-table">
      <div class="table-overflow">
        <table>
          <colgroup>
            <col span="1" class="col-name" />
            <col span="1" class="col-name" />
            <col span="1" class="col-number" />
            <col span="1" class="col-number" />
            <col span="1" class="col-actions" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>{{ 'Owner.PreferenceRentTerm' | translate}}</th>
              <th>{{ 'Owner.Adults' | translate}}</th>
              <th>{{ 'Owner.Children' | translate}}</th>
              <th>{{ 'Owner.Income' | translate}}<div class="info-icon"
                tooltip="{{ 'Owner.IncomeInformation' | translate }}"
                placement="auto">
             <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
           </div></th>
              <th>{{ 'Owner.Domicile' | translate}}</th>
              <th>{{ 'Owner.ProfessionalUse' | translate}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody dragula="Voorkeurslijst"  id="NietVoorkeurslijst">
            <tr (click)="displayDetails(rp);" *ngFor="let rp of unrankedProfiles" id={{rp.candidacyId}}>
              <td class="first table-icon"> <i class="far fa-arrows-alt"></i> </td>
              <td>
                <img src="{{getAvatarIcon(rp)}}" alt="">
              </td>
              <td>{{ 'GeneralWizard.DurationType_'+rp.generalDetails.durationType | translate }}</td>
              <td>{{ rp.generalDetails.numberOfAdults}}</td>
              <td>{{ rp.generalDetails.numberOfChildren}}</td>
              <td>
                <span class="table-income">{{getTotalIncomeForProfile(rp)}}</span>
              </td>
              <td>{{ boolTranslate( rp.generalDetails.willTakeDomicile) }}</td>
              <td>{{ boolTranslate( rp.generalDetails.isPartiallyProfessionalUse) }}</td>
              <td class="last">
                &nbsp;
              </td>
            </tr>
            <tr *ngIf="!unrankedProfiles.length" class="empty-tr">
              <td class="empty-td" colspan="9">{{ 'Owner.DragDropEmpty' | translate}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <p-paginator [rows]="10" [totalRecords]="100"></p-paginator> -->
    </div>
  </div>
</div>

<p-sidebar [(visible)]="display" position="right" blockScroll="true" styleClass="detail-profile">
  <ra-candidature-detail [profile]="detailProfile"></ra-candidature-detail>
</p-sidebar>
