import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProfileDetailsDto, AttachmentType, PersonLegalType, ProfileAttachmentDto } from '../../shared/api/rentassistant-api.generated';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { Subscription } from 'rxjs';
import { UploadParams } from './attachment/attachment.component';
import { getName } from '../../core/models';
import { ProfileWizardStateService, ProfileWizardState, ProfileWizardMode } from '../../core/services/profilewizard-state.service';
import { filter } from 'rxjs/operators';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-attachments-wizard-page',
  template: ''
})
export class AttachmentsWizardPageComponent implements OnInit, OnDestroy {

  state: ProfileWizardState;
  profile: ProfileDetailsDto;
  totalSteps: number;

  public AttachmentType = AttachmentType;

  hasOtherIncome: boolean;
  isLegalPerson: boolean;
  isRenting: boolean;

  personId: number;
  name: string;
  attachments: ProfileAttachmentDto[] = [];

  subscriptions: Subscription[] = [];

  constructor(public route: ActivatedRoute,
              private api: RentAssistantApi,
              public localizedRouter: LocalizedRouter,
              private stateService: ProfileWizardStateService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.stateService
          .state$
          .pipe(filter(x => !!x))
          .subscribe(state => {
              this.profile = state.profile;
              this.state = state;
              this.updateAttachments();
          })
    );


    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        this.personId = parseInt(params.get('personId'), 10);
        this.updateAttachments();
      })
    );
  }

  updateAttachments() {
    if (!this.personId) {
      return;
    }

    this.attachments = this.getPersonAttachments(this.profile);

    const person = this.profile.personDetails.find(x => x.id === this.personId);
    this.name = getName(person);
    this.hasOtherIncome = !!person.otherIncomes && person.otherIncomes.length > 0;
    this.isLegalPerson = person.legalType === PersonLegalType.LegalEntity;
    this.isRenting = !!person.hasCurrentRent;

    this.totalSteps = !this.hasOtherIncome ? 2 : 3;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private getPersonAttachments(profile: ProfileDetailsDto) {
    return this.profile.profileAttachments.filter(x => x.personId === this.personId);
  }

  closeWizard() {
    this.state.closeWizard();
  }

  getAttachments(attachmentType: AttachmentType) {
    return this.attachments.filter(att => att.attachmentType === attachmentType);
  }

  getAttachment(attachmentType: AttachmentType, index: number = 0) {
    return this.attachments.filter(att => att.attachmentType === attachmentType)[index];
  }

  uploadAttachment(uploadParams: UploadParams) {
    this.onFilesSelected(uploadParams.files, uploadParams.attachmentType);
  }


  onFilesSelected(files: FileList, attachmentType: AttachmentType) {
    if (files.length === 1) {

      const addAttachment$ = this.state.wizardMode === ProfileWizardMode.CandidacyProfile
                              ? this.api.addCandidacyProfileAttachment(
                                  this.state.candidacy.uniqueId, this.personId, attachmentType, files.item(0), this.profile)
                              : this.api.addUserProfileAttachment(this.personId, attachmentType, files.item(0), this.profile);

      this.subscriptions.push(
            addAttachment$.subscribe(updatedProfile => this.stateService.updateProfile(updatedProfile))
      );
    }
  }

  deleteAttachment(attachment: ProfileAttachmentDto) {

    const deleteAttachment$ = this.state.wizardMode === ProfileWizardMode.CandidacyProfile
                                ? this.api.deleteCandidacyProfileAttachment(
                                      this.state.candidacy.uniqueId, this.personId, attachment.uniqueId, this.profile)
                                : this.api.deleteUserProfileAttachment(this.personId, attachment.uniqueId, this.profile);

    this.subscriptions.push(
          deleteAttachment$.subscribe(updatedProfile => this.stateService.updateProfile(updatedProfile))
    );
  }

  downloadAttachment(attachment: ProfileAttachmentDto) {
      this.api.downloadAttachment(attachment.uniqueId, attachment.documentName);
  }
}
