<div class="profile-container">
  <div class="profile" [ngClass]="{ 'hidden-on-mobile': !isDetail, 'center': isDetail }">
    <div class="scroll-container scroll-light">
      <ra-house-header [titleKey]="'Profile.Title'">
        <div class="share-icon" (click)="share()">
          <svg-icon src="assets/images/share.svg" [applyCss]="true"></svg-icon>
        </div>
      </ra-house-header>
      <div class="center-on-desktop">
        <div class="center-container">
          <ra-profile [profile]="profile"
                      [baseUrl]="'/app/profiel/'"
                      (addRenter)="addRenter()"></ra-profile>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isDetail" class="profile-wizard" [ngClass]="{ 'hidden-on-mobile': isDetail }">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #shareModal let-modal>
  <ra-share-modal
    (cancel)="modal.dismiss()"
    (closeModal)="modal.dismiss()"
    (confirm)="onShareConfirm($event)"
  ></ra-share-modal>
</ng-template>


