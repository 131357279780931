import { PropertyDto, OwnerLogin, OwnerSubmitPreferenceDto } from './../../../shared/api/rentassistant-api.generated';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, timer, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'ra-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() property: PropertyDto;
  @Input() ownerLogin: OwnerLogin;
  ownerSubmitPreferenceDto: OwnerSubmitPreferenceDto = {};
  @Output() submitShortlist = new EventEmitter<OwnerSubmitPreferenceDto>();
  @Output() shortlistExpired = new EventEmitter();
  @Output() logout = new EventEmitter();

  timeLeft$ = new BehaviorSubject<string>(null);
  interval$: Observable<number>;
  constructor() { }
  display = false;

  ngOnInit() {
    this.ownerSubmitPreferenceDto.executedComment = null;
    this.ownerSubmitPreferenceDto.hasApproved = true;

    this.interval$ = timer(1, 1000);
    this.interval$.pipe(
      switchMap(() => {
        this.getTimeLeft();
        return of(null);
      }
      )
    ).subscribe();
  }

  showDialog() {
      this.display = true;
  }

  onSubmitClick() {
    this.ownerSubmitPreferenceDto.ownerLogin = this.ownerLogin;
    this.submitShortlist.emit(this.ownerSubmitPreferenceDto);
  }

  getTimeLeft() {
    const expiryDate = new Date(this.ownerLogin.sessionAvailableUntil);
    const now = new Date(Date.now());
    const timeTillExpiry = (expiryDate.getTime() - now.getTime());
    if (timeTillExpiry <= 0) {
      this.timeLeft$.next('Expired');
      this.shortlistExpired.emit();
    } else {
      this.timeLeft$.next(new Date(timeTillExpiry).getMinutes().toString() + ':' + new Date(timeTillExpiry).getSeconds().toString());
    }
   }

   logOut() {
    this.logout.emit();
   }
}
