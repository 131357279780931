import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export var ProfileWizardMode;
(function (ProfileWizardMode) {
    ProfileWizardMode[ProfileWizardMode["DraftProfile"] = 0] = "DraftProfile";
    ProfileWizardMode[ProfileWizardMode["CandidacyProfile"] = 1] = "CandidacyProfile";
    ProfileWizardMode[ProfileWizardMode["PublicProfile"] = 2] = "PublicProfile";
})(ProfileWizardMode || (ProfileWizardMode = {}));
var ProfileWizardState = /** @class */ (function () {
    function ProfileWizardState() {
    }
    return ProfileWizardState;
}());
export { ProfileWizardState };
var ProfileWizardStateService = /** @class */ (function () {
    function ProfileWizardStateService() {
        this.state$ = new BehaviorSubject(null);
    }
    ProfileWizardStateService.prototype.updateProfile = function (profile) {
        var currentState = this.state$.getValue();
        currentState.profile = profile;
        if (currentState.candidacy) {
            currentState.candidacy.profile = profile;
        }
        this.state$.next(currentState);
    };
    ProfileWizardStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileWizardStateService_Factory() { return new ProfileWizardStateService(); }, token: ProfileWizardStateService, providedIn: "root" });
    return ProfileWizardStateService;
}());
export { ProfileWizardStateService };
