<div class="header">
  <div class="icon">
    <svg-icon applyCss="true" src="assets/images/info.svg"></svg-icon>
  </div>
  <div class="info-icon-container">   
    <div class="titleText">{{ titleKey | translate }}</div>
      <div *ngIf="tooltip" class="info-icon"
           tooltip="{{ tooltip | translate }}"
           placement="auto">
        <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
      </div>
    </div>
</div>
<div class="text"><ng-content></ng-content></div>
