import {
  Component,
  ContentChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { faLock, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ra-section-list-item',
  templateUrl: './section-list-item.component.html',
  styleUrls: ['./section-list-item.component.scss']
})
export class SectionListItemComponent {
  faLock = faLock;
  faChevronRight = faChevronRight;

  @Input()
  hideRightArrow = false;

  @ContentChild(TemplateRef, { static: false })
  @Input()
  iconTemplate: TemplateRef<any>;

  @Input()
  textKey = '';

  @Input()
  tooltip: string = null;

  @Input()
  isLocked = false;

  @Output()
  itemClick = new EventEmitter<void>();

  onClickHost() {
    this.itemClick.next();
  }
}
