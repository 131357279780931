import { Component, OnInit } from '@angular/core';
import { AttachmentsWizardPageComponent } from '../attachments-wizard-page.component';

@Component({
  selector: 'ra-income-attachments',
  templateUrl: './income-attachments.component.html',
  styleUrls: ['./income-attachments.component.scss']
})
export class IncomeAttachmentsComponent extends AttachmentsWizardPageComponent implements OnInit {

  next() {
    const url = this.hasOtherIncome ? '../andere-inkomsten'
                                    : '../identiteit-huur';

    this.localizedRouter.navigate([url], { relativeTo: this.route });
  }
}
