import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ra-candidacy-card-loading',
  templateUrl: './candidacy-card-loading.component.html',
  styleUrls: ['./candidacy-card-loading.component.scss']
})
export class CandidacyCardLoadingComponent implements OnInit {

  @Input() animate = true;

  constructor() { }

  ngOnInit() {
  }

}
