import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';

import { ErrorService, RentAssistantError } from '../../../core/services/error.service';

@Component({
  selector: 'ra-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  @Input() fixed = false;
  @Input() showCloseButton = true;

  errors: RentAssistantError[];
  subscriptions: Subscription[];

  faExclamationTriangle = faExclamationTriangle;
  faTimes = faTimes;

  constructor(private errorService: ErrorService,
              private router: Router) { }

  ngOnInit() {
    this.errors = [];

    this.subscriptions = [
      this.errorService
          .errors$
          .subscribe(error => this.errors.unshift(error)),
      this.router.events
                 .pipe(filter(e => e instanceof NavigationStart))
                 .subscribe(e => this.errors = [])
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  close() {
    this.errors = [];
  }
}
