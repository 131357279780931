import { PropertyDto } from './../../shared/api/rentassistant-api.generated';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { AuthService } from '../../core/services/auth.service';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe.component';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthSignInErrorCode } from '../../shared/api/immoconnect-api.generated';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { ActivatedRoute } from '@angular/router';
import { RentAssistantApi } from './../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {

  email: string;
  password: string;
  rememberMe: boolean;
  submitting: boolean;
  realEstateObjectId: string;
  errorKey: string;
  property: PropertyDto;

  @ViewChild('emailField', { static: false }) emailField: ElementRef;
  @ViewChild('passwordField', { static: false }) passwordField: ElementRef;

  constructor(private authService: AuthService,
    private localizedRouter: LocalizedRouter,
    private route: ActivatedRoute,
    private api: RentAssistantApi) {
    super();
  }

  ngOnInit() {
    this.realEstateObjectId = this.route.snapshot.params.realEstateObjectId;
    if (this.realEstateObjectId != null) {
      this.api.getWidgetProperty(this.realEstateObjectId)
        .toPromise()
        .then((x) => {
          this.property = x;
        });
    }

    // this.email = this.authService.getStoredUser();
    this.subscriptions.push(
      this.route
        .queryParamMap
        .subscribe(params => {
          this.email = params.get('login');
        }
        )
    );
  }

  registerLink() {
    if (this.realEstateObjectId != null) {
      return '/registreer/' + this.realEstateObjectId;
    } else {
      return '/registreer';
    }
  }

  forgotPasswordLink() {
    let nav = '/wachtwoord-vergeten';
    if (this.realEstateObjectId != null) {
      nav += '/' + this.realEstateObjectId;
    }
    return nav;
  }

  ngAfterViewInit(): void {
    if (!this.email) {
      this.emailField.nativeElement.focus();
    } else if (!this.password) {
      this.passwordField.nativeElement.focus();
    }
  }

  onSubmit() {
    this.submitting = true;
    this.errorKey = '';
    if (this.realEstateObjectId != null) {
      let broker = '0866381630';
      if (this.property != null && this.property.broker != null && this.property.broker.companyIdentificationNumber != null) {
        broker = this.property.broker.companyIdentificationNumber;
      }
      this.authService.redirectlocalizedRouter = '/app/kandidaat-stellen/' + broker + '/' + this.realEstateObjectId;
    }
    this.subscriptions.push(
      this.authService
        .login(this.email, this.password, this.rememberMe)
        .pipe(
          catchError((err: AuthSignInErrorCode) => {
            if (err in AuthSignInErrorCode) {
              // Run some typescript enum magic
              // In fact err is already a string at runtime, but is recognized as a variable of 'type' AuthSignInErrorCode => which is a problem for string based checks
              var errString = AuthSignInErrorCode[AuthSignInErrorCode[err]];
              this.errorKey = `Login.Error.${errString}`;

              // redirect to activation page
              if (err === AuthSignInErrorCode.UnactivatedUser || errString === AuthSignInErrorCode[AuthSignInErrorCode.UnactivatedUser]) { // err is in fact treated as a string at runtime, not as a number, so add string-based check as well
                if (this.realEstateObjectId != null) {
                  this.localizedRouter.navigate(['/activeer/' + this.realEstateObjectId],
                    { queryParams: { login: this.email } });
                } else {
                  this.localizedRouter.navigate(['/activeer'],
                    { queryParams: { login: this.email } });
                }
              }
            }
            return throwError(err);
          }),
          finalize(() => this.submitting = false)
        )
        .subscribe()
    );
  }
}
