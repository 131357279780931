<ra-modal-header (cancel)="cancel.next()">
  <ng-template>
    <div class="header">{{ 'Candidacies.Attention' | translate }}</div>
  </ng-template>
</ra-modal-header>
<div class="text">
  {{ 'Candidacies.DeletePersonConfirmationText' | translate }}
</div>

<div class="circle">
  <svg-icon [applyCss]="true" src="assets/images/delete-person.svg"></svg-icon>
</div>

<div class="name">{{ getName() }}</div>

<button class="warning" [disabled]="isLoading" (click)="deletePerson()">
  {{ 'Candidacies.DeletePerson' | translate }}
</button>
<button class="default" [disabled]="isLoading" (click)="cancel.next()">
  {{ 'Candidacies.Cancel' | translate }}
</button>
