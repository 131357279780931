<ra-house-header
  [titleKey]="'ResetPassword.Title'"
  [menuIconVisible]="false"
  [backIconVisible]="true"
  [backNavigation]="getLoginNav()">
</ra-house-header>
<div class="ra-card-container">
  <div class="ra-card">
    <form class="form-grid" (ngSubmit)="onSubmit()" #resetPasswordForm="ngForm">
      <div class="form-control-container">
        <label for="login">{{ "ResetPassword.Email" | translate }}</label>
        <input id="login" type="login" [value]="login" readonly/>
      </div>

      <div class="form-control-container">
        <label for="password">{{ "ResetPassword.NewPassword" | translate }}</label>
        <input
          id="password"
          type="password"
          name="password"
          [(ngModel)]="password"
          #passwordInput="ngModel"
          #passwordField
          [placeholder]="'ResetPassword.NewPassword' | translate"
          minlength="8"
          pattern="^(?:(?=.*\d)(?=.*[A-Z]).*)$"
          required
        />
        <div *ngIf="passwordInput.invalid && passwordInput.touched" class="messages">
          <span *ngIf="!!passwordInput.errors.required" class="message">{{ 'ResetPassword.Validation.PasswordRequired'|translate }}</span>
          <span *ngIf="!!passwordInput.errors.minlength || !!passwordInput.errors.pattern" class="message">{{ 'ResetPassword.Validation.PasswordPattern'|translate }}</span>
        </div>
      </div>

      <div class="form-control-container">
        <label for="passwordConfirmation">{{ "ResetPassword.NewPasswordConfirmation" | translate }}</label>
        <input
          id="passwordConfirmation"
          type="password"
          name="passwordConfirmation"
          [(ngModel)]="passwordConfirmation"
          #passwordConfirmationInput="ngModel"
          #passwordConfirmationField
          [placeholder]="'ResetPassword.NewPasswordConfirmation' | translate"
          required
        />
        <div *ngIf="passwordConfirmationInput.invalid && passwordConfirmationInput.touched" class="messages">
          <span *ngIf="!!passwordConfirmationInput.errors.required" class="message">{{ 'ResetPassword.Validation.PasswordConfirmationRequired'|translate }}</span>
        </div>
      </div>

      <div>
        <button
          type="submit"
          class="primary"
          [disabled]="!resetPasswordForm.form.valid || submitting"
        >
          {{ 'ResetPassword.ResetPassword' | translate }}
        </button>
      </div>
      <div class="messages">
        <span class="message" *ngIf="errorKey">{{ errorKey | translate }}</span>
      </div>
    </form>
  </div>
</div>
