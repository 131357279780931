import { Status } from './../../api/rentassistant-api.generated';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CandidacyDto } from '../../api/rentassistant-api.generated';

@Component({
  selector: 'ra-candidacy-card',
  templateUrl: './candidacy-card.component.html',
  styleUrls: ['./candidacy-card.component.scss']
})
export class CandidacyCardComponent implements OnInit {
  faChevronRight = faChevronRight;

  @Input() candidacy: CandidacyDto;

  @Input() showArrowRight = true;
  @Output() cardClicked = new EventEmitter();
  @Input() showDraftedStatus = true;

  showStatus = true;

  constructor() {}

  ngOnInit() {
    this.showStatus = true;
    if (!this.showDraftedStatus && this.candidacy.status === Status.Draft) {
      this.showStatus = false;
    }
  }

  onClick() {
    this.cardClicked.emit();
  }
}
