import { Component, OnInit } from '@angular/core';
import { AttachmentsWizardPageComponent } from '../attachments-wizard-page.component';

@Component({
  selector: 'ra-identity-rent-attachments',
  templateUrl: './identity-rent-attachments.component.html',
  styleUrls: ['./identity-rent-attachments.component.scss']
})
export class IdentityRentAttachmentsComponent extends AttachmentsWizardPageComponent implements OnInit {

  previous() {
    const url = this.hasOtherIncome ? '../andere-inkomsten'
                                    : '../inkomsten';
    this.localizedRouter.navigate([url], { relativeTo: this.route });
  }

  next() {
    if (this.profile.isReadonly) {
      this.closeWizard();
    } else {
      this.localizedRouter.navigate(['../samenvatting'], { relativeTo: this.route });
    }
  }
}
