import {
  Component,
  OnInit,
  Input,
  ContentChild,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ra-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  @Input()
  titleKey = '';
  @Input()
  titleSubtextKey = '';

  @Input()
  brokerImageSource = '';
  @Input()
  brokerNameLine = '';
  @Input()
  mainText = '';

  @ContentChild('footerTemplate', { static: false })
  @Input()
  footerTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
