import { Component, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ra-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent {
  @Output()
  cancel = new EventEmitter<void>();

  @ContentChild(TemplateRef, {static: false})
  template: TemplateRef<any>;
}
