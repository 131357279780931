import * as tslib_1 from "tslib";
import { StepBaseComponent } from '../step-base.component';
var RentingInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RentingInfoComponent, _super);
    function RentingInfoComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hostClass = 'wizard';
        return _this;
    }
    RentingInfoComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../inkomsten'], {
            relativeTo: this.route
        });
    };
    RentingInfoComponent.prototype.next = function () {
        if (this.readonly) {
            this.state.closeWizard();
        }
        else {
            this.localizedRouter.navigate(['../samenvatting'], { relativeTo: this.route });
        }
    };
    return RentingInfoComponent;
}(StepBaseComponent));
export { RentingInfoComponent };
