<ra-section-list-item
  textKey="Candidacies.General"
  (itemClick)="startGeneralWizard()"
  [isLocked]="profile.isReadonly"
  tooltip="Candidacies.GeneralInfo"
>
  <ng-template #iconTemplate>
    <svg-icon src="assets/images/house-small.svg"></svg-icon>
  </ng-template>
</ra-section-list-item>


<ra-checkable-list-item [infoProvider]="residentsProvider"></ra-checkable-list-item>
<ra-checkable-list-item [infoProvider]="whenProvider"></ra-checkable-list-item>
<ra-checkable-list-item [infoProvider]="extraOptionsProvider"></ra-checkable-list-item>

<ra-section-list-item
  textKey="Candidacies.Tenants"
  [hideRightArrow]="false"
  [isLocked]="profile.isReadonly"
  tooltip="Candidacies.TenantsInfo"
  (itemClick)="initPersonWizard()">
  <ng-template #iconTemplate>
    <svg-icon src="assets/images/people.svg"></svg-icon>
  </ng-template>
</ra-section-list-item>

<ng-container *ngFor="let person of profile.personDetails">
  <ra-person-list-item
    [name]="getFullName(person)"
    (itemClick)="togglePersonExpand(person)"
    [isExpanded]="isExpanded(person)"
    (rightArrowClick)="startPersonWizard(person)"
    [state]="getPersonstate(person)"
  ></ra-person-list-item>
  <ng-container
    *ngIf="isExpanded(person) && person.legalType !== 'LegalEntity'">
    <ra-checkable-list-item
      [infoProvider]="personalInfoProvider(person)"
    ></ra-checkable-list-item>
    <ra-checkable-list-item
      [infoProvider]="contactInformationInfoProvider(person)"
    ></ra-checkable-list-item>
    <ra-checkable-list-item
      [infoProvider]="incomeInformationProvider(person)"
    ></ra-checkable-list-item>
    <ra-checkable-list-item
      [infoProvider]="currentRentInformationProvider(person)"
    ></ra-checkable-list-item>
  </ng-container>
  <ng-container
    *ngIf="isExpanded(person) && person.legalType === 'LegalEntity'">
  <ra-checkable-list-item
    [infoProvider]="organizationInfoProvider(person)"
  ></ra-checkable-list-item>
  <ra-checkable-list-item
    [infoProvider]="organizationAddressInfoProvider(person)"
  ></ra-checkable-list-item>
  <ra-checkable-list-item
    [infoProvider]="organizationContactInfoProvider(person)"
  ></ra-checkable-list-item>
</ng-container>
</ng-container>

<button *ngIf="!profile.isReadonly"
        (click)="addPerson()"
        class="default list-button left-icon-button">
  <svg-icon [applyCss]="true" src="assets/images/plus.svg"></svg-icon>
  <span>{{ 'RenterWizard.AddRenter' | translate }}</span>
</button>

<ra-section-list-item
    textKey="Candidacies.Attachments"
    [isLocked]="profile.isReadonly"
    [hideRightArrow]="true"
    (itemClick)="initAttachmentsWizard()">
  <ng-template #iconTemplate>
    <svg-icon src="assets/images/attachments.svg"></svg-icon>
  </ng-template>
</ra-section-list-item>
<ng-container *ngFor="let person of profile.personDetails">
  <ra-person-list-item
    [name]="getFullName(person)"
    [isExpandable]="false"
    (rightArrowClick)="startAttachmentsWizard(person)"
    (itemClick)="startAttachmentsWizard(person)"
    [state]="getPersonAttachmentstate(person)"
  ></ra-person-list-item>
</ng-container>
