import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { makeEmptyPerson } from 'src/app/core/models';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
var StepBaseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(StepBaseComponent, _super);
    function StepBaseComponent(localizedRouter, route, stateService) {
        var _this = _super.call(this) || this;
        _this.localizedRouter = localizedRouter;
        _this.route = route;
        _this.stateService = stateService;
        _this.personId = 0;
        _this.person = makeEmptyPerson();
        return _this;
    }
    StepBaseComponent.prototype.init = function () { };
    StepBaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(combineLatest([this.stateService.state$, this.route.params])
            .pipe(map(function (_a) {
            var _b = tslib_1.__read(_a, 2), state = _b[0], params = _b[1];
            return ({ state: state, params: params });
        }))
            .subscribe(function (_a) {
            var state = _a.state, params = _a.params;
            _this.state = state;
            _this.profile = state.profile;
            _this.readonly = state.profile.isReadonly;
            _this.personId = parseInt(params.personId, 10);
            var person = _this.profile.personDetails.find(function (x) { return x.id === _this.personId; });
            if (!!person) {
                _this.person = person;
                _this.onPersonChanged();
            }
        }));
        this.init();
    };
    StepBaseComponent.prototype.onPersonChanged = function () { };
    return StepBaseComponent;
}(AutoUnsubscribeComponent));
export { StepBaseComponent };
