<div class="wizard">
  <ra-wizard-header
    [wizardType]="'General'"
    [autoSave]="!profile.isReadonly"
    (help)="help()"
  ></ra-wizard-header>
  <div class="wizard-page container">
    <form class="form-grid">
      <div class="info-icon-container">
        <div class="title">{{ 'GeneralWizard.WillTakeDomicile' | translate }}</div>
          <div class="info-icon"
               tooltip="{{ 'GeneralWizard.WillTakeDomicileInfo' | translate }}"
               placement="auto">
            <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
          </div>
        </div>
      <ra-choice horizontal="true"
                 [disabled]="profile.isReadonly"
                 [(ngModel)]="profile.generalDetails.willTakeDomicile"
                 name="willTakeDomicileChoice">
        <ra-choice-item [value]="true" name="yes">{{ 'Common.Yes' | translate }}</ra-choice-item>
        <ra-choice-item [value]="false" name="no">{{ 'Common.No' | translate }}</ra-choice-item>
      </ra-choice>
      <div class="gap"></div>
      <ra-toggle-switch [(ngModel)]="profile.generalDetails.isPartiallyProfessionalUse"
                        [disabled]="profile.isReadonly"
                        name="isPartiallyProfessionalUseToggle">
        <div class="info-icon-container">
          <div class="title">{{ 'GeneralWizard.IsPartialProfessionalUse' | translate }}</div>
          <div class="info-icon"
             tooltip="{{ 'GeneralWizard.IsPartialProfessionalUseInfo' | translate }}"
             placement="auto">
             <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
          </div>
        </div>
      </ra-toggle-switch>
      <textarea name="PartialProfessionalUseInfo"
        *ngIf="profile.generalDetails.isPartiallyProfessionalUse"
        [(ngModel)]="profile.generalDetails.partiallyProfessionalComment"
        [readOnly]="profile.isReadonly"
        [placeholder]="'GeneralWizard.PartialProfessionalUseInfoPlaceholder' | translate">
      </textarea>
      <ra-counter name="numberOfCars"
      [min]="0"
      [max]="10"
      [(ngModel)]="profile.generalDetails.numberOfCars"
      [disabled]="profile.isReadonly"
      [labelKey]="'GeneralWizard.NumberOfCars'"
      [tooltipText]="'GeneralWizard.NumberOfCarsInfo'"
      [asTitle]="true"
      [subText]="'GeneralWizard.NumberOfCarsSub'">
    </ra-counter>
      <div class="info-icon-container">
        <div class="title">{{ 'GeneralWizard.ReasonForMoving' | translate }}</div>
      </div>
      <textarea name="ReasonForMoving"
      [(ngModel)]="profile.generalDetails.reasonForMoving"
      [readOnly]="profile.isReadonly"
      [placeholder]="'GeneralWizard.ReasonForMoving' | translate">
    </textarea>
    </form>
  </div>
  <ra-wizard-footer
    [step]="3"  [totalSteps]="3"
    [autoSave]="!profile.isReadonly"
    (previous)="previous()"
    (next)="next()"
  ></ra-wizard-footer>
</div>
