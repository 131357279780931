import { WidgetComponent } from './components/widget/widget.component';
import { LocalizeRouterModule } from 'ngx-localize-router';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: 'widget',
    component: WidgetComponent
  },
];


@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes)
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class WidgetRoutes { }
