import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { PersonLegalType } from 'src/app/shared/api/rentassistant-api.generated';
import { ProfileWizardMode } from '../../core/services/profilewizard-state.service';
import { filter } from 'rxjs/operators';
var SummaryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SummaryComponent, _super);
    function SummaryComponent(localizedRouter, route, stateService, api) {
        var _this = _super.call(this) || this;
        _this.localizedRouter = localizedRouter;
        _this.route = route;
        _this.stateService = stateService;
        _this.api = api;
        return _this;
    }
    SummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.stateService
            .state$
            .pipe(filter(function (x) { return !!x; }))
            .subscribe(function (state) {
            _this.profile = state.profile;
            _this.state = state;
        }));
        this.subscriptions.push(this.route.data.subscribe(function (x) {
            _this.generalWizardComplete = x.completedStep === 'general';
            _this.renterWizardComplete = x.completedStep === 'renter';
            _this.attachmentsWizardComplete = x.completedStep === 'attachments';
        }));
    };
    SummaryComponent.prototype.navigateToOverview = function () {
        this.state.closeWizard();
    };
    SummaryComponent.prototype.addRenter = function () {
        var _this = this;
        var newPersonId = this.profile.personDetails.length + 1;
        this.profile.personDetails.push({
            id: newPersonId,
            legalType: PersonLegalType.Person,
            address: {}
        });
        var saveProfile$ = this.state.wizardMode === ProfileWizardMode.CandidacyProfile
            ? this.api.saveCandidacyProfile(this.state.candidacy.uniqueId, this.state.candidacy.profile)
            : this.api.saveUserProfile(this.profile);
        this.subscriptions.push(saveProfile$.subscribe(function (updatedProfile) {
            _this.stateService.updateProfile(updatedProfile);
            _this.localizedRouter.navigate([
                '../../persoon',
                newPersonId,
                'persoonlijke-gegevens'
            ], { relativeTo: _this.route });
        }));
    };
    SummaryComponent.prototype.addRenterDocu = function () {
        var _this = this;
        var newPersonId = this.profile.personDetails.length + 1;
        this.profile.personDetails.push({
            id: newPersonId,
            legalType: PersonLegalType.Person,
            address: {}
        });
        var saveProfile$ = this.state.wizardMode === ProfileWizardMode.CandidacyProfile
            ? this.api.saveCandidacyProfile(this.state.candidacy.uniqueId, this.state.candidacy.profile)
            : this.api.saveUserProfile(this.profile);
        this.subscriptions.push(saveProfile$.subscribe(function (updatedProfile) {
            _this.stateService.updateProfile(updatedProfile);
            _this.localizedRouter.navigate([
                '../../../../persoon',
                newPersonId,
                'persoonlijke-gegevens'
            ], { relativeTo: _this.route });
        }));
    };
    SummaryComponent.prototype.addDocuments = function () {
        this.localizedRouter.navigate([
            '../bijlagen',
            'inkomsten'
        ], { relativeTo: this.route });
    };
    return SummaryComponent;
}(AutoUnsubscribeComponent));
export { SummaryComponent };
