import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { AuthService, User } from '../../core/services/auth.service';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'ra-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('firstNameField', { static: false }) firstNameField: ElementRef;

  firstName: string;
  lastName: string;
  email: string;
  password: string;
  privacyStatementAccepted: boolean;
  realEstateObjectId: string;
  errorKey: string;

  submitting: boolean;
  subscriptions: Subscription[] = [];

  constructor(private authService: AuthService,
              private localizedRouter: LocalizedRouter,
              private route: ActivatedRoute,
              private translate: TranslateService) { }

  ngOnInit() {
    this.realEstateObjectId = this.route.snapshot.params.realEstateObjectId;
  }

  ngAfterViewInit() {
    this.firstNameField.nativeElement.focus();
  }
  getLoginNav() {
    let nav = '/login';
    if (this.realEstateObjectId != null) {
      nav += '/' + this.realEstateObjectId;
    }
    return nav;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onSubmit() {
    this.submitting = true;
    this.errorKey  = '';
    let curLanguage = 'nl-BE';
    if (this.translate.currentLang === 'fr') {
      curLanguage = 'fr-BE';
    }
    if (this.translate.currentLang === 'en') {
      curLanguage = 'en-GB';
    }
    if (this.translate.currentLang === 'de') {
      curLanguage = 'de-DE';
    }
    const user: User = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      language: curLanguage
    };

    this.subscriptions.push(
      this.authService
          .register(user)
          .pipe(
              catchError(err => {
                const errorCode: string = !!err.response ? err.response : err;
                this.errorKey = `Registration.Error.${errorCode}`;

                return throwError(err);
              }),
              finalize(() => this.submitting = false)
          )
          .subscribe(registerResponse => {
            if (this.realEstateObjectId != null) {
              this.localizedRouter.navigate(['/activeer/' + this.realEstateObjectId],
              { queryParams: { login:  this.email } });
            } else {
              this.localizedRouter.navigate(['/activeer'],
                      { queryParams: { login:  this.email } });
            }
          })
    );
  }
  loginLink() {
    if (this.realEstateObjectId != null) {
      return '/login/' + this.realEstateObjectId;
    } else {
      return '/login';
    }
  }

  termsOfUse() {
    const language = this.translate.currentLang;
    window.open('https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf');
    window.open('https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf');
    if (language === 'nl') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'de') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'fr') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    if (language === 'en') {
      return 'https://myrentpro.be/Content/TermsOfUse/20180627%20-%20MRP%20Gebruiksvoorwaarden%20NL%20-%20MRP%203.0.pdf';
    }
    return '';
  }
}

