import { OnInit } from '@angular/core';
var TableComponent = /** @class */ (function () {
    function TableComponent(dragulaService, api, ownerService) {
        this.dragulaService = dragulaService;
        this.api = api;
        this.ownerService = ownerService;
        this.display = false;
    }
    TableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filterProfiles(this.profileDetails);
        var dragulaOptions = {};
        dragulaOptions.moves = function (el, container, handle, sibling) {
            return el.id !== '';
        };
        this.dragulaService.createGroup('Voorkeurslijst', dragulaOptions);
        this.dragulaService.drop('Voorkeurslijst').subscribe(function (args) {
            _this.clearPreferences();
            var voorkeurtable = document.getElementById('Voorkeurslijst');
            var rowsWithId = [];
            // tslint:disable-next-line: prefer-for-of
            for (var i = 0; i < voorkeurtable.rows.length; i++) {
                var id = +voorkeurtable.rows[i].id;
                if (id != null && id !== 0) {
                    rowsWithId.push(voorkeurtable.rows[i]);
                }
            }
            var _loop_1 = function (i) {
                var id = +rowsWithId[i].id;
                var p = _this.profileDetails.filter(function (x) { return x.candidacyId === id; })[0];
                if (p != null) {
                    p.preferenceOrder = i + 1;
                }
            };
            // tslint:disable-next-line: prefer-for-of
            for (var i = 0; i < rowsWithId.length; i++) {
                _loop_1(i);
            }
            var savePref = {};
            savePref.ownerLogin = _this.login;
            savePref.ownerProfilePreference = _this.profileDetails;
            _this.api.saveOwnerPreferences(savePref)
                .toPromise()
                .then(function (newList) {
                _this.profileDetails = newList;
                _this.filterProfiles(_this.profileDetails);
            });
        });
    };
    TableComponent.prototype.clearPreferences = function () {
        this.profileDetails.forEach(function (el) {
            el.preferenceOrder = null;
        });
    };
    TableComponent.prototype.filterProfiles = function (opd) {
        this.rankedProfiles = opd.filter(function (x) { return x.preferenceOrder != null; });
        this.unrankedProfiles = opd.filter(function (x) { return x.preferenceOrder == null; });
    };
    TableComponent.prototype.getTotalIncomeForProfile = function (p) {
        if (p == null || p.personDetails == null) {
            return '€ 0';
        }
        var totalIncome = 0;
        p.personDetails.forEach(function (el) {
            if (el.monthlyIncome != null) {
                totalIncome = totalIncome + el.monthlyIncome;
            }
            el.otherIncomes.forEach(function (o) {
                if (o.income != null) {
                    totalIncome = totalIncome + o.income;
                }
            });
        });
        return '€ ' + totalIncome.toString();
    };
    TableComponent.prototype.displayDetails = function (rp) {
        this.detailProfile = rp;
        this.display = true;
    };
    TableComponent.prototype.boolTranslate = function (value) {
        return this.ownerService.boolTranslate(value);
    };
    TableComponent.prototype.getAvatarIcon = function (rp) {
        return this.ownerService.getAvatarIcon(rp);
    };
    return TableComponent;
}());
export { TableComponent };
