<ra-renter-wizard-header
  [person]="person">
</ra-renter-wizard-header>
<div class="wizard-page container">
  <form class="form-grid">
    <div class="info-icon-container">
      <div class="title">{{ 'RenterWizard.PersonalInfo' | translate }}</div>
        <div class="info-icon"
             tooltip="{{ 'RenterWizard.PersonalInfoInfo' | translate }}"
             placement="auto">
          <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
        </div>
      </div>
    <ra-choice
      name="legalType"
      [(ngModel)]="person.legalType"
      [horizontal]="true"
      [disabled]="readonly"
      (choiceChange)='legalTypeChanged($event)'
    >
      <ra-choice-item value="Person">
        {{ 'RenterWizard.NaturalPerson' | translate }}
      </ra-choice-item>
      <ra-choice-item value="LegalEntity">
        {{ 'RenterWizard.LegalPerson' | translate }}
      </ra-choice-item>
    </ra-choice>
    <div class="gap"></div>
    <div *ngIf="person.legalType === 'Person'" class="form-grid">
      <div class="subtitle">{{ 'RenterWizard.FirstName' | translate }}</div>
      <input name="firstName"
            [(ngModel)]="person.firstName"
            [placeholder]="'RenterWizard.FirstName' | translate"
            [readOnly]="readonly"
            required />
      <div class="subtitle">{{ 'RenterWizard.LastName' | translate }}</div>
      <input name="lastName"
            [(ngModel)]="person.lastName"
            [placeholder]="'RenterWizard.LastName' | translate"
            [readOnly]="readonly"
            required />

            <div class="info-icon-container">
              <div class="subtitle">{{ 'RenterWizard.IdentificationNumber' | translate }}</div>
          <div class="info-icon"
               tooltip="{{ 'RenterWizard.IdentificationNumberInfo' | translate }}"
               placement="auto">
            <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
          </div>
        </div>
      <input name="identificationNumber"
            [(ngModel)]="person.identificationNumber"
            [placeholder]="'RenterWizard.IdentificationNumber' | translate"
            [readOnly]="readonly"
            required />

 
      <ra-toggle-switch name="hasPartner" [(ngModel)]="person.hasPartner" [disabled]="readonly">
        <div class="info-icon-container">       
          {{ 'RenterWizard.HasPartner' | translate }}
            <div class="info-icon"
                 tooltip="{{ 'RenterWizard.HasPartnerInfo' | translate }}"
                 placement="auto">
              <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
            </div>
          </div>
      </ra-toggle-switch>
    </div>
    <div *ngIf="person.legalType === 'LegalEntity'" class="form-grid">
      <div class="form-control-container">
        <label for="organizationNumber">
          {{ 'RenterWizard.OrganizationNumber' | translate }}
        </label>
        <input
          id="organizationNumber"
          name="organizationNumber"
          #organizationNumberInput="ngModel"
          mask="0000.000.000"
          [showMaskTyped]="true"
          [validation]="true"
          [readOnly]="readonly"
          [ngModel]="person.organizationNumber"
          (ngModelChange)="organizationNumberChanged($event)"
          required />
        <div class="messages" *ngIf="!readonly">
          <span *ngIf="organizationNumberInput.invalid && organizationNumberInput.getError('Mask error')"
                class="message">
            {{ 'Candidacies.InvalidOrganizationNumber' | translate }}
          </span>
          <span *ngIf="!loading && organizationNumberInput.valid && !person.organizationFoundInKbo"
                class="message">
            {{ 'Candidacies.OrganizationNumberNotFound' | translate }}
          </span>
        </div>
        <div
          *ngIf="person.organizationNumber && person.organizationNumber.length === 10 && !readonly"
          class="hint">
            <span *ngIf="loading">
              {{ 'Candidacies.SearchingOrganizationNumber' | translate }}
            </span>
            <span *ngIf="!loading && person.organizationFoundInKbo">
              {{ 'Candidacies.ValidOrganizationNumber' | translate }}
            </span>
        </div>
      </div>
      <div class="form-control-container">
        <label for="">
          {{ 'RenterWizard.OrganizationName' | translate }}</label>
        <input
          id="organizationName"
          name="organizationName"
          [(ngModel)]="person.organizationName"
          [readOnly]="person.organizationFoundInKbo || readonly"
          required />
      </div>
    </div>
  </form>
</div>
<ra-wizard-footer
  [step]="1"
  [totalSteps]="totalSteps"
  [autoSave]="!profile.isReadonly"
  (next)="next()"
></ra-wizard-footer>

