import * as tslib_1 from "tslib";
import { QueryList, AfterContentInit, EventEmitter } from '@angular/core';
import { ChoiceItemComponent } from './choice-item/choice-item.component';
import { ControlValueAccessor } from '@angular/forms';
import { AutoUnsubscribeComponent } from '../../auto-unsubscribe.component';
var ChoiceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ChoiceComponent, _super);
    function ChoiceComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.horizontal = false;
        _this.disabledField = false;
        _this.choiceChange = new EventEmitter();
        return _this;
    }
    Object.defineProperty(ChoiceComponent.prototype, "disabled", {
        get: function () {
            return this.disabledField;
        },
        set: function (v) {
            this.disabledField = v;
            this.announceDisabledToChildren();
        },
        enumerable: true,
        configurable: true
    });
    ChoiceComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (this.children.length !== 0) {
            this.children.first.isFirst = true;
            this.children.last.isLast = true;
            this.children.forEach(function (c) { return (c.horizontal = _this.horizontal); });
            this.announceDisabledToChildren();
            this.subscriptions = this.children.map(function (c) {
                return c.onClick.subscribe(function () {
                    _this.onChildClick(c);
                });
            });
        }
        this.tryCoerceSelectedChild();
    };
    ChoiceComponent.prototype.onChildClick = function (child) {
        var _this = this;
        var previousSelection = this.children.find(function (c) { return c.selected; });
        this.children.forEach(function (c) { return (c.selected = false); });
        child.selected = true;
        if (child.valueProvider) {
            child.valueProvider().then(function (newValue) {
                if (newValue === 'cancel') {
                    if (previousSelection) {
                        child.selected = false;
                        previousSelection.selected = true;
                    }
                }
                else {
                    _this.selectedValue = newValue.value;
                    _this.tryInvokeOnChangeCallbacks(newValue.value);
                }
            });
        }
        else {
            this.selectedValue = child.value;
            this.tryInvokeOnChangeCallbacks(child.value);
        }
    };
    ChoiceComponent.prototype.tryCoerceSelectedChild = function () {
        var _this = this;
        if (this.children) {
            this.children.forEach(function (child) {
                child.selected =
                    (child.selectedPredicate &&
                        child.selectedPredicate(_this.selectedValue)) ||
                        (child.value !== undefined && child.value === _this.selectedValue);
            });
        }
    };
    ChoiceComponent.prototype.tryInvokeOnChangeCallbacks = function (newValue) {
        this.choiceChange.next(newValue);
        if (this.onChangeCallback) {
            this.onChangeCallback(newValue);
        }
    };
    ChoiceComponent.prototype.announceDisabledToChildren = function () {
        var _this = this;
        if (this.children) {
            this.children.forEach(function (c) { return (c.parentDisabled = _this.disabled); });
        }
    };
    ChoiceComponent.prototype.writeValue = function (obj) {
        this.selectedValue = obj;
        this.tryCoerceSelectedChild();
    };
    ChoiceComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    ChoiceComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    ChoiceComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
        this.children.forEach(function (child) {
            child.parentDisabled = isDisabled;
        });
    };
    return ChoiceComponent;
}(AutoUnsubscribeComponent));
export { ChoiceComponent };
