import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { indexTenants,
         resetBrokerLogoUrl,
         indexCandidacyTenants } from '../../core/models';

import {
  RentAssistantClient,
  Action,
  ProfileDetailsDto,
  CandidacyDto,
  OrganizationDto,
  API_BASE_URL_RENTASSISTANT,
  AttachmentType,
  ContentItemLanguage,
  PropertyDto,
  BrokerDto,
  EmailDto,
  OwnerLogin,
  OwnerSavePreferenceDto,
  OwnerSubmitPreferenceDto,
  WidgetParams,
  ShareProfileDto,
  ScoreProfileDto,
  CandidacyArchiveDto
} from './rentassistant-api.generated';
import { HttpClient } from '@angular/common/http';
import { LocalizeRouterService } from 'ngx-localize-router';

export interface CandidacySubmission {
  candidacyId: string;
  remarks: string;
  hasVisitedProperty: boolean;
}

@Injectable({ providedIn: 'root' })
export class RentAssistantApi {
  constructor(private api: RentAssistantClient,
              private httpClient: HttpClient,
              private localizeRouterService: LocalizeRouterService,
              @Inject(API_BASE_URL_RENTASSISTANT) private baseUrl: string) {}

  getCandidacies(): Observable<CandidacyDto[]> {
    return this.api.candidacies_GetMyCandidacies()
               .pipe(map(candidacies => candidacies.map(cand => resetBrokerLogoUrl(cand))));
  }

  ownerLogin(login: OwnerLogin) {
    return this.api.owner_OwnerLogin(login);
  }

  getCandidacy(id: string): Observable<CandidacyDto> {
    return this.api
      .candidacies_GetCandidacy(id, true)
      .pipe(
          map(cand => indexCandidacyTenants(cand)),
          map(cand => resetBrokerLogoUrl(cand))
      );
  }

  recallCandidacy(id: string): Observable<CandidacyDto> {
    return this.api.candidacies_CandidacyAction(id, {
       action: Action.Recall
    });
  }

  deleteDraftCandidacy(id: string): Observable<CandidacyDto> {
    return this.api.candidacies_CandidacyAction(id, {
      action: Action.Delete
    });
  }

  submitCandidacy(id: CandidacySubmission): Observable<void> {
    return this.api
      .candidacies_CandidacyAction(id.candidacyId, {
        action: Action.Submit,
        comment: id.remarks,
        hasVisitedProperty: id.hasVisitedProperty
      })
      .pipe(map(() => void 0));
  }

  saveCandidacyProfile(
    candidacyId: string,
    profile: ProfileDetailsDto
  ): Observable<ProfileDetailsDto> {
    return this.api.profiles_SaveCandidacyProfile(candidacyId, profile);
  }

  createCandidacy(
    brokerNumber: string,
    realEstateObjectId: string,
    saveCandidacy: boolean
  ): Observable<CandidacyDto> {
    return this.api.candidacies_CreateCandidacy({
      brokerOrganizationNumber: brokerNumber,
      realEstateObjectId,
      commit: saveCandidacy
    }).pipe(map(cand => resetBrokerLogoUrl(cand)));
  }

  getCandidacyActions(candidacyId: string): Observable<Action[]> {
    return this.api.actions_GetCandidacyActions(candidacyId);
  }

  getUserProfile(): Observable<ProfileDetailsDto> {
    return this.api.profiles_GetUserProfile()
                   .pipe(map(profile => indexTenants(profile)));
  }

  saveUserProfile(profileDetailsDto: ProfileDetailsDto): Observable<ProfileDetailsDto> {
    return this.api.profiles_SaveUserProfile(profileDetailsDto);
  }

  findOrganization(organizationNumber: string): Observable<OrganizationDto> {
    return this.api.organizations_Get(organizationNumber);
  }

  addUserProfileAttachment(personId: number, attachmentType: AttachmentType,
                           file: File, profile: ProfileDetailsDto): Observable<ProfileDetailsDto> {
    const url = `${this.baseUrl}/profiles/latest/attachments?type=${attachmentType}&personId=${personId}&originalFileName=${file.name}`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('profile', JSON.stringify(profile));

    return this.httpClient.post(url, formData);
  }

  addCandidacyProfileAttachment(candidacyId: string, personId: number, attachmentType: AttachmentType,
                                file: File, profile: ProfileDetailsDto): Observable<ProfileDetailsDto> {
    const url = `${this.baseUrl}/candidacies/${candidacyId}/profiles/attachments` +
                      `?type=${attachmentType}&personId=${personId}&originalFileName=${file.name}`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('profile', JSON.stringify(profile));

    return this.httpClient.post(url, formData);
  }

  deleteUserProfileAttachment(personId: number, documentId: string, profile: ProfileDetailsDto): Observable<ProfileDetailsDto> {
    return this.api.profilesAttachments_DeleteUserProfileAttachment(profile, documentId, personId);
  }

  deleteCandidacyProfileAttachment(candidacyId: string, personId: number, documentId: string,
                                   profile: ProfileDetailsDto): Observable<ProfileDetailsDto> {
    return this.api.profilesAttachments_DeleteCandidacyProfileAttachment(candidacyId, profile, documentId, personId);
  }

  downloadAttachment(documentId: string, fileName: string) {
    return this.httpClient
               .get(`${this.baseUrl}/attachments/${documentId}`,
                     { responseType: 'arraybuffer' })
                .subscribe(file => this.openBlob(file, 'application/octect-stream', fileName));
  }

  openBlob(arrayBuffer: any, type: string, fileName: string) {
    const blob = new Blob([arrayBuffer], { type });
    const url = window.URL.createObjectURL(blob);
    const fileLink = document.createElement('a');
    fileLink.href = url;
    // it forces the name of the downloaded file
    fileLink.download = fileName;
    fileLink.click();
  }

  getProfilePublicToken(candidacyId?: string): Observable<string> {
    return this.api.profiles_GetPublicToken(candidacyId);
  }

  shareProfile(shareProfileDto: ShareProfileDto) {
    return this.api.profiles_ShareLatestProfile(shareProfileDto);
  }

  getProfileByToken(token: string): Observable<ProfileDetailsDto> {
    return this.api.tokens_GetProfileByToken(token)
                   .pipe(map(profile => indexTenants(profile)));
  }

  getQuestionAndAnswers() {
    const currentLang = this.localizeRouterService.parser.currentLang;
    return this.api.content_GetQuestionsAndAnswers(currentLang as ContentItemLanguage);
  }

  getWebTexts() {
    const currentLang = this.localizeRouterService.parser.currentLang;
    return this.api.content_GetWebTexts(currentLang as ContentItemLanguage);
  }

  getProperty(realEstateObjectId: string): Observable<PropertyDto> {
    return this.api.properties_Get(realEstateObjectId);
  }

  getBroker(organizationNumber: string): Observable<BrokerDto> {
    return this.api.brokers_Get(organizationNumber);
  }

  sendEmail(email: EmailDto): Observable<void> {
    return this.api.actions_SendEmail(email);
  }

  getOwnerShortlist(ownerLogin: OwnerLogin) {
    return this.api.owner_GetOwnerShortlistDto(ownerLogin.shortlistUniqueId,
      ownerLogin.phoneNumber,
      ownerLogin.token,
      ownerLogin.ownerSessionId);
  }

  saveOwnerPreferences(ownerSavePreferences: OwnerSavePreferenceDto) {
    return this.api.owner_SaveOwnerPreference(ownerSavePreferences);
  }

  submitOwnerPreferences(ownerSubmitPreferenceDto: OwnerSubmitPreferenceDto) {
    return this.api.owner_SubmitPreference(ownerSubmitPreferenceDto);
  }

  saveWidgetProperty(widgetParams: WidgetParams) {
    return this.api.widget_SaveRealEstateObject(widgetParams);
  }

  getOwnerProperty(shortlistUniqueId: string) {
    return this.api.owner_GetShortlistsProperty(shortlistUniqueId);
  }

  getWidgetProperty(realEstateObjectId: string) {
    return this.api.widget_GetWidgetProperty(realEstateObjectId);
  }

  getWidgetScore(profile: ScoreProfileDto) {
    return this.api.widget_GetProfileScore(profile);
  }

  archiveCandidacy(candidacyArchive: CandidacyArchiveDto) {
    return this.api.candidacies_ArchiveCandidacy(candidacyArchive);
  }
}
