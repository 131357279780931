<div class="counter-container">

  <div class="info-icon-container">
    <label *ngIf="!asTitle">{{ labelKey | translate }}
    </label> 
    <div class="title" *ngIf="asTitle">{{ labelKey | translate }}</div>        
      <div *ngIf="tooltipText" class="info-icon"
           tooltip="{{ tooltipText | translate }}"
           placement="auto">
        <svg-icon  applyCss="true" src="assets/images/info-circle.svg"></svg-icon>
      </div>

    </div>
    
    
    
    <div class="buttons">
      <button
      (click)="minus()"
      [class.limit]="limitMin"
      [disabled]="limitMin || disabled"
      class="default"
      >
      <svg-icon [applyCss]="true" src="assets/images/minus.svg"></svg-icon>
    </button>
    <span class="count" [class.limit]="limit">{{ count }}</span>
    <button
    (click)="plus()"
    [class.limit]="limitMax"
    [disabled]="limitMax || disabled"
    class="default"
    >
    <svg-icon [applyCss]="true" src="assets/images/plus.svg"></svg-icon>
  </button>
</div>
</div>
<div *ngIf="subText" class="container hint row mt-0"> {{ subText | translate}}</div>
