import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var LoadingStatusService = /** @class */ (function () {
    function LoadingStatusService() {
        this.loading = new BehaviorSubject(false);
        this.loadingCount = 0;
    }
    LoadingStatusService.prototype.pushLoadingFrame = function () {
        var _this = this;
        this.loadingCount++;
        if (this.loadingCount === 1) {
            this.loading.next(true);
        }
        return function () {
            _this.loadingCount--;
            if (_this.loadingCount === 0) {
                _this.loading.next(false);
            }
        };
    };
    LoadingStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingStatusService_Factory() { return new LoadingStatusService(); }, token: LoadingStatusService, providedIn: "root" });
    return LoadingStatusService;
}());
export { LoadingStatusService };
