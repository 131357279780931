<ra-modal-header (cancel)="cancel.next()">
  <ng-template>
    <div class="header">{{'Candidacies.Attention' | translate}}</div>
  </ng-template>
</ra-modal-header>
<div class="text">
  <span *ngIf="action === 'Recall'">{{ 'Candidacies.WithdrawConfirmationText' | translate }}</span>
  <span *ngIf="action === 'Delete'">{{ 'Candidacies.DeleteCandidacyConfirmationText' | translate }}</span>
</div>

<div class="circle">
  <svg-icon [applyCss]="true" src="assets/images/withdraw.svg"></svg-icon>
</div>

<ra-candidacy-card
  [candidacy]="candidacy"
  [showArrowRight]="false"
></ra-candidacy-card>

<button class="warning"
        *ngIf="action === 'Delete'"
        [disabled]="isLoading"
        (click)="deleteCandidacy()">
  {{ 'Candidacies.DeleteCandidacy' | translate }}
</button>
<button class="warning"
        *ngIf="action === 'Recall'"
        [disabled]="isLoading"
        (click)="recallCandidacy()">
  {{ 'Candidacies.Withdraw' | translate }}
</button>
<button class="default"
        [disabled]="isLoading"
        (click)="cancel.next()">
  {{ 'Candidacies.Cancel' | translate }}
</button>
