import {
  Component,
  HostBinding,
  Input,
  ContentChild,
  TemplateRef,
  EventEmitter,
  HostListener
} from '@angular/core';

@Component({
  selector: 'ra-choice-item',
  templateUrl: './choice-item.component.html',
  styleUrls: ['./choice-item.component.scss']
})
export class ChoiceItemComponent {
  @HostBinding('class.disabled')
  get controlDisabled() {
    return this.parentDisabled || this.disabled;
  }

  @HostBinding('class.horizontal')
  horizontal = false;

  @HostBinding('class.is-first')
  isFirst = false;

  @HostBinding('class.is-last')
  isLast = false;

  @HostBinding('class.selected')
  selected = false;

  @Input()
  disabled = false;

  parentDisabled = false;

  @Input()
  value: any;

  @Input()
  valueProvider?: () => Promise<'cancel' | { value: any }>;

  @Input()
  selectedPredicate?: (value: any) => boolean;

  onClick = new EventEmitter<void>();

  // template to place in the item, while keeping rest of content centered
  @ContentChild(TemplateRef, { static: false })
  iconTemplate: TemplateRef<any>;

  @HostListener('click')
  onClickHost() {
    if (!this.controlDisabled) {
      this.onClick.next();
    }
  }
}
