/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./choice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./choice.component";
var styles_ChoiceComponent = [i0.styles];
var RenderType_ChoiceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChoiceComponent, data: {} });
export { RenderType_ChoiceComponent as RenderType_ChoiceComponent };
export function View_ChoiceComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_ChoiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ra-choice", [], [[2, "horizontal", null]], null, null, View_ChoiceComponent_0, RenderType_ChoiceComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ChoiceComponent]), i1.ɵdid(2, 1228800, null, 1, i3.ChoiceComponent, [], null, null), i1.ɵqud(603979776, 1, { children: 1 })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).horizontal; _ck(_v, 0, 0, currVal_0); }); }
var ChoiceComponentNgFactory = i1.ɵccf("ra-choice", i3.ChoiceComponent, View_ChoiceComponent_Host_0, { horizontal: "horizontal", disabled: "disabled" }, { choiceChange: "choiceChange" }, ["*"]);
export { ChoiceComponentNgFactory as ChoiceComponentNgFactory };
