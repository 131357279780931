import { Component, OnInit } from '@angular/core';
import { CandidacyDto } from '../../shared/api/rentassistant-api.generated';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent extends AutoUnsubscribeComponent
  implements OnInit {
  candidacies: CandidacyDto[] = null;
  filteredCandidacies: CandidacyDto[] = null;

  constructor(private api: RentAssistantApi,
              private localizedRouter: LocalizedRouter) {
    super();
  }

  ngOnInit() {
    this.subscriptions = [
      this.api.getCandidacies()
              .subscribe(candidacies => {
                this.candidacies = candidacies;
                this.filteredCandidacies = this.candidacies.filter(x => !x.isArchivedByUser);
              })
    ];
  }

  onCandidacySelected(id: string) {
    this.localizedRouter.navigate(['/app/kandidaturen', id]);
  }
  candidacyFilterShowArchived(event: boolean) {
    if (event) {
      this.filteredCandidacies = this.candidacies;
    } else {
      this.filteredCandidacies = this.candidacies.filter(x => !x.isArchivedByUser);
    }
  }
}
