/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./attachment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../delete-attachment-confirmation/delete-attachment-confirmation.component.ngfactory";
import * as i6 from "../delete-attachment-confirmation/delete-attachment-confirmation.component";
import * as i7 from "@angular/common";
import * as i8 from "./attachment.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_AttachmentComponent = [i0.styles];
var RenderType_AttachmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AttachmentComponent, data: {} });
export { RenderType_AttachmentComponent as RenderType_AttachmentComponent };
function View_AttachmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "default small-icon-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["src", "assets/images/download.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null)], function (_ck, _v) { var currVal_0 = "assets/images/download.svg"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AttachmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "default small-icon-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showDeleteConfirmation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["src", "assets/images/minus.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null)], function (_ck, _v) { var currVal_0 = "assets/images/minus.svg"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AttachmentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "default left-icon-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 17).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["src", "assets/images/upload.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "assets/images/upload.svg"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("AttachmentsWizard.Upload")); _ck(_v, 4, 0, currVal_2); }); }
function View_AttachmentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-delete-attachment-confirmation", [], null, [[null, "delete"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.onDelete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_v.context.$implicit.dismiss() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DeleteAttachmentConfirmationComponent_0, i5.RenderType_DeleteAttachmentConfirmationComponent)), i1.ɵdid(1, 49152, null, 0, i6.DeleteAttachmentConfirmationComponent, [], { attachment: [0, "attachment"], text: [1, "text"] }, { delete: "delete", cancel: "cancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachment; var currVal_1 = _co.text; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AttachmentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { deleteModal: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "attachment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "svg-icon", [["src", "assets/images/attachment.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(4, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "subtext"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentComponent_1)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentComponent_2)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentComponent_3)), i1.ɵdid(16, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, [["hiddenFileInput", 1]], null, 0, "input", [["style", "display: none"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onUpload($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(0, [[1, 2], ["deleteModal", 2]], null, 0, null, View_AttachmentComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "assets/images/attachment.svg"; var currVal_1 = true; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_4 = _co.attachment; _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.attachment && !_co.readonly); _ck(_v, 14, 0, currVal_5); var currVal_6 = (!_co.attachment && !_co.readonly); _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.text; _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.subText || ((_co.attachment == null) ? null : _co.attachment.documentName)); _ck(_v, 9, 0, currVal_3); }); }
export function View_AttachmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-attachment", [], null, null, null, View_AttachmentComponent_0, RenderType_AttachmentComponent)), i1.ɵdid(1, 114688, null, 0, i8.AttachmentComponent, [i9.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AttachmentComponentNgFactory = i1.ɵccf("ra-attachment", i8.AttachmentComponent, View_AttachmentComponent_Host_0, { attachment: "attachment", text: "text", subText: "subText", attachmentType: "attachmentType", readonly: "readonly" }, { download: "download", delete: "delete", upload: "upload" }, []);
export { AttachmentComponentNgFactory as AttachmentComponentNgFactory };
