import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { SharedModule } from '../../shared/shared.module';
import { ResidentsComponent } from './residents/residents.component';
import { WhenComponent } from './when/when.component';
import { OptionsComponent } from './options/options.component';

@NgModule({
  declarations: [ResidentsComponent, WhenComponent, OptionsComponent],
  imports: [
    CommonModule,
    SharedModule,
    TooltipModule
  ],
  exports: []
})
export class GeneralWizardModule { }
