import { SharedModule } from './../shared/shared.module';
import { WidgetComponent } from './components/widget/widget.component';
import { WidgetRoutes } from './widget.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SliderModule} from 'primeng/slider';

@NgModule({
  imports: [
    CommonModule,
    WidgetRoutes,
    SharedModule,
    SelectButtonModule,
    SliderModule
  ],
  declarations: [WidgetComponent]
})
export class WidgetModule { }
