<div class="errors-panel"
     [ngClass]="{ 'fixed': fixed }"
    *ngIf="errors && errors.length > 0">
  <div class="errors">
    <div *ngFor="let error of errors"
         class="error">
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
        <span *ngIf="error.errorType === 'Validation'">{{ 'ServerValidationErrors.' + error.message  | translate }}</span>
        <span *ngIf="error.errorType !== 'Validation'">{{ error.message }}</span>
    </div>
  </div>
  <div class="close" *ngIf="showCloseButton">
    <fa-icon [icon]="faTimes" (click)="close()"></fa-icon>
  </div>
</div>
