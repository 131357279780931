import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileDetailsDto } from '../../shared/api/rentassistant-api.generated';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolver implements Resolve<ProfileDetailsDto> {
  constructor(private api: RentAssistantApi) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProfileDetailsDto> | Promise<ProfileDetailsDto> {
    return this.api.getUserProfile();
  }
}
