<ra-modal-header (cancel)="cancel.next()">
  <ng-template>
    <div class="header">{{ 'Common.Attention' | translate }}</div>
  </ng-template>
</ra-modal-header>
<div class="question">
  {{ 'AttachmentsWizard.DeleteAttachmentQuestion' | translate }}
</div>

<div class="info">
  <span>{{ text }} </span>
  <span *ngIf="attachment.documentName" class="filename">{{ attachment.documentName }} </span>
</div>

<button class="warning" [disabled]="isLoading" (click)="onDelete()">
  {{ 'AttachmentsWizard.DeleteAttachment' | translate }}
</button>
<button class="default" [disabled]="isLoading" (click)="cancel.next()">
  {{ 'Common.Cancel' | translate }}
</button>
