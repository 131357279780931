import * as tslib_1 from "tslib";
import { StepBaseComponent } from '../step-base.component';
var OwnerInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OwnerInfoComponent, _super);
    function OwnerInfoComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hostClass = 'wizard';
        _this.canContactCurrentLandlord = false;
        _this.name = '';
        _this.email = '';
        _this.phone = '';
        return _this;
    }
    OwnerInfoComponent.prototype.onPersonChanged = function () {
        if (this.person.landlordContactFilled === true) {
            this.canContactCurrentLandlord = true;
            this.name = this.person.landlordName;
            this.email = this.person.landlordEmail;
            this.phone = this.person.landlordPhone;
        }
    };
    OwnerInfoComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../huidige-huur'], {
            relativeTo: this.route
        });
    };
    OwnerInfoComponent.prototype.saveCurrentLandlord = function () {
        if (this.canContactCurrentLandlord) {
            this.person.landlordContactFilled = true;
            this.person.landlordName = this.name;
            this.person.landlordEmail = this.email;
            this.person.landlordPhone = this.phone;
        }
        else {
            this.person.landlordContactFilled = false;
            this.person.landlordName = '';
            this.person.landlordEmail = '';
            this.person.landlordPhone = '';
        }
    };
    OwnerInfoComponent.prototype.beforeNavigate = function () {
        this.saveCurrentLandlord();
    };
    OwnerInfoComponent.prototype.beforeClose = function () {
        this.saveCurrentLandlord();
    };
    OwnerInfoComponent.prototype.next = function () {
        this.saveCurrentLandlord();
        if (this.readonly) {
            this.state.closeWizard();
        }
        else {
            this.localizedRouter.navigate(['../samenvatting'], { relativeTo: this.route });
        }
    };
    return OwnerInfoComponent;
}(StepBaseComponent));
export { OwnerInfoComponent };
