<ra-house-header
  [titleKey]="'ForgotPassword.Title'"
  [menuIconVisible]="false"
  [backIconVisible]="true"
  [backNavigation]="getLoginNav()">
</ra-house-header>
  <div class="ra-card-container">
    <div class="ra-card">

    <form *ngIf="!showConfirmation" class="form-grid" (ngSubmit)="onSubmit()" #forgotPasswordForm="ngForm">
      <p class="subtitle">{{ "ForgotPassword.Subtitle" | translate }}</p>
      <div class="form-control-container">
      <label for="email">{{ "ForgotPassword.Email" | translate }}</label>
        <input
          id="email"
          type="email"
          name="email"
          [(ngModel)]="email"
          #emailInput="ngModel"
          #emailField
          [placeholder]="'ForgotPassword.Email' | translate"
          required
          email
        />
        <div *ngIf="emailInput.invalid && emailInput.touched" class="messages">
          <span *ngIf="!!emailInput.errors.email" class="message">{{ 'ForgotPassword.Validation.EmailInvalid'|translate }}</span>
          <span *ngIf="!!emailInput.errors.required" class="message">{{ 'ForgotPassword.Validation.EmailRequired'|translate }}</span>
        </div>
      </div>
      <div>
        <button
          type="submit"
          class="primary"
          [disabled]="!forgotPasswordForm.form.valid || submitting"
        >
          {{ 'ForgotPassword.Send' | translate }}
        </button>
      </div>
      <div class="messages">
        <span class="message" *ngIf="errorKey">{{ errorKey | translate }}</span>
      </div>
    </form>

    <form class="form-grid" *ngIf="showConfirmation">
      <p class="subtitle">{{ "ForgotPassword.Confirmation" | translate }}</p>
      <div class="center">
        <div class="success-icon-circle">
          <svg-icon
              src="assets/images/checkmark.svg"
              [applyCss]="true"
          ></svg-icon>
        </div>
      </div>
    </form>
  </div>
</div>
