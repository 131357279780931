/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i4 from "angular-svg-icon";
import * as i5 from "./modal-header.component";
var styles_ModalHeaderComponent = [i0.styles];
var RenderType_ModalHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalHeaderComponent, data: {} });
export { RenderType_ModalHeaderComponent as RenderType_ModalHeaderComponent };
function View_ModalHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_ModalHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "left-filler"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalHeaderComponent_1)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "right-filler"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "close-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "svg-icon", [["src", "assets/images/close.svg"]], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(7, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.template; _ck(_v, 3, 0, currVal_0); var currVal_1 = "assets/images/close.svg"; var currVal_2 = true; _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_ModalHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ra-modal-header", [], null, null, null, View_ModalHeaderComponent_0, RenderType_ModalHeaderComponent)), i1.ɵdid(1, 49152, null, 1, i5.ModalHeaderComponent, [], null, null), i1.ɵqud(603979776, 1, { template: 0 })], null, null); }
var ModalHeaderComponentNgFactory = i1.ɵccf("ra-modal-header", i5.ModalHeaderComponent, View_ModalHeaderComponent_Host_0, {}, { cancel: "cancel" }, []);
export { ModalHeaderComponentNgFactory as ModalHeaderComponentNgFactory };
