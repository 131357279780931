<ra-house-header [titleKey]="'Faq.Title'"></ra-house-header>

<div class="ra-card-container">
  <div class="ra-card" *ngIf="visible">
    <div class="faq-item" *ngFor="let qa of questionAndAnswers">
      <div class="question" [ngClass]="{ 'expanded': qa.expanded }">
        <h1 class="question-text" (click)="toggle(qa)">{{ qa.question }}</h1>
        <div class="toggle" (click)="toggle(qa)">
          <svg-icon *ngIf="!qa.expanded" [applyCss]="true" src="assets/images/plus.svg"></svg-icon>
          <svg-icon *ngIf="qa.expanded" [applyCss]="true" src="assets/images/minus.svg"></svg-icon>
        </div>
      </div>
      <div class="answer" *ngIf="qa.expanded">
        <p [innerHTML]="qa.answer"></p>
      </div>
    </div>
  </div>
</div>
