import { AfterViewInit, Component, HostBinding } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';

@Component({
  selector: 'ra-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['../common.scss', './contact-info.component.scss']
})
export class ContactInfoComponent extends StepBaseComponent implements AfterViewInit {
  @HostBinding('class') hostClass = 'wizard';
  country = '';

  ngAfterViewInit(): void {
    setTimeout(() => {      
      if (this.person.address.country === 'Belgie' || this.person.address.country === 'Nederland' || this.person.address.country === 'Frankrijk' ||
      this.person.address.country === 'Duitsland') {
        this.country = this.person.address.country;
      } else {
        this.country = 'Andere';
      }
    }, 0);
  }

  previous() {
    this.localizedRouter.navigate(['../persoonlijke-gegevens'], {
      relativeTo: this.route
    });
  }

  onPersonChanged() {
    if (!this.person.address) {
      this.person.address = {};
    }
  }

  next() {
    if (this.country && this.country != 'Andere'){
      this.person.address.country = this.country;
    }
    this.localizedRouter.navigate(['../inkomsten'], { relativeTo: this.route });
  }
}
