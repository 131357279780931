import { switchMap } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ra-aan-de-slag',
  templateUrl: './aan-de-slag.component.html',
  styleUrls: ['./aan-de-slag.component.scss']
})
export class AanDeSlagComponent implements OnInit {

  buttonStyle =
  {
    button: {
        align: 'left',
        font: 'arial',
        bkgrnd: '22668a',
        bordr: '5px',
        bord: '4px',
        hbord: '0px',
        color: 'FFFFFF',
        deco: 'none',
        pad: '15px',
        hbkgrnd: '275C8B',
        disp: 'block',
        textTransform: 'uppercase',
        width: 'auto',
        lineHeight: 'normal',
        fontSize: '16px',
        fontWeight: 'normal',
        boxShadow: 'none',
        iconColor: 'fff',
        iconShow: 'inline'
    },
    body: {
        bkgrnd: 'FFFFFF'
    }
};

house =
{
  '@context': 'https:\/\/schema.org',
  '@type': 'House',
  name: 'TITLE OF OBJECT',
  numberOfRooms: '3',
  numberOfBedrooms: '1',
  petsAllowed: '0',
  floorSize: '100',
  url: 'URL OF OBJECT DETAILS',
  identifier: 'testRefReds117',
  image: 'URL TO IMAGE',
  description: 'DESCRIPTION OF OBJECT',
  accommodationCategory: 'Studio',
  reference: 'JZ-117'
  };

  retnAction =
  {
    '@context': 'https:\/\/schema.org',
    '@type': 'RentAction',
    priceSpecification: {
    '@context': 'https:\/\/schema.org',
    '@type': 'PriceSpecification',
    price: '800',
    monthlyExtraCosts: '50',
    priceCurrency: 'EUR'
    },
    location: {
    '@context': 'https:\/\/schema.org',
    '@type': 'PostalAddress',
    streetAddress: 'STREET + NR',
    addressLocality: 'CITY',
    postalCode: 'ZIP',
    addressCountry: 'BE'
    }
  };

  constructor(private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.fragment.pipe(switchMap((x) => {
      const element = this.document.getElementById(x);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
      return x;
    })).subscribe();
  }

  scrollTo(id: string) {
    const element = this.document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
