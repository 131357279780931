import * as tslib_1 from "tslib";
import { StepBaseComponent } from '../step-base.component';
var OrganizationAddressComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OrganizationAddressComponent, _super);
    function OrganizationAddressComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hostClass = 'wizard';
        return _this;
    }
    OrganizationAddressComponent.prototype.previous = function () {
        this.localizedRouter.navigate(['../persoonlijke-gegevens'], {
            relativeTo: this.route
        });
    };
    OrganizationAddressComponent.prototype.next = function () {
        this.localizedRouter.navigate(['../organisatie-contactgegevens'], { relativeTo: this.route });
    };
    return OrganizationAddressComponent;
}(StepBaseComponent));
export { OrganizationAddressComponent };
