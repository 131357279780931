import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { AutoUnsubscribeComponent } from '../../shared/auto-unsubscribe.component';
import { filter } from 'rxjs/operators';
import { RentAssistantError, ErrorService, ErrorType } from '../../core/services/error.service';
import { CandidacyDto, Status } from 'src/app/shared/api/rentassistant-api.generated';
import { resetBrokerLogoUrl } from '../../core/models';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateCandidacyComponent extends AutoUnsubscribeComponent implements OnInit {

  brokerNumber: string;
  realEstateObjectId: string;

  candidacy: CandidacyDto;
  candidacyLight: CandidacyDto;
  candidacyExists: boolean;
  candidacyExistsDrafted: boolean;
  errors: RentAssistantError[] = [];
  candidacyExistsMessage: string;

  constructor(private route: ActivatedRoute,
    private localizedRouter: LocalizedRouter,
    private api: RentAssistantApi,
    private errorService: ErrorService,
    private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.errors = [];

    this.brokerNumber = this.route.snapshot.params.brokerOrganizationNumber;
    this.realEstateObjectId = this.route.snapshot.params.realEstateObjectId;

    this.subscriptions.push(
      this.api.getProperty(this.realEstateObjectId)
        .subscribe(property => {
          if (!this.candidacy) {
            this.candidacyLight = { property };
          }
        })
    );

    this.subscriptions.push(
      this.api.createCandidacy(this.brokerNumber, this.realEstateObjectId, false)
        .subscribe(candidacy => {
          this.candidacy = candidacy;
          this.getAlreadyExistsMessage();
        })
    );

    this.subscriptions.push(
      this.errorService
        .errors$
        .pipe(
          filter(error => error.errorType === ErrorType.Validation)
        )
        .subscribe(error => this.errors.unshift(error))
    );

    this.subscriptions.push(
      this.errorService
        .errors$
        .pipe(
          filter(error => error.errorType === ErrorType.Conflict)
        )
        .subscribe(error => {
          this.candidacyExistsDrafted = false;
          this.candidacy = resetBrokerLogoUrl(error.response);
          this.getAlreadyExistsMessage();
          this.candidacyExists = true;
          const status = error.response.status;
          if (status === Status.Draft) {
            this.candidacyExistsDrafted = true;
          }
        }),
    );
  }

  get animate(): boolean {
    return !this.errors || this.errors.length === 0;
  }

  showCandidacyDetail(candidacyId: string) {
    if (!this.candidacyExists) {
      this.createCandidacy();
      return;
    }
    this.localizedRouter.navigate(['/app/kandidaturen', candidacyId]);
  }

  createCandidacy() {
    this.subscriptions.push(
      this.api
        .createCandidacy(this.brokerNumber, this.realEstateObjectId, true)
        .subscribe(candidacy => {
          this.localizedRouter.navigate(['/app/kandidaturen', candidacy.uniqueId]);
        })
    );
  }

  getAlreadyExistsMessage() {
    let result: string = this.translateService.instant('CreateCandidacy.CandidacyExistsMessage') as string;
    if (this.candidacyExistsDrafted) {
      result = this.translateService.instant('CreateCandidacy.CandidacyExistsDraftMessage') as string;
    }
    const candidacyRoute = 'kandidaturen';
    /*if (this.translateService.currentLang === 'en') {
        candidacyRoute = this.translateService.instant('Route_kandidaturen') as string;
    }*/
    result = result.replace(/{url}/, this.translateService.currentLang + '/app/' + candidacyRoute + '/' + this.candidacy.uniqueId);
    this.candidacyExistsMessage = result;
    return result;
  }

}
