import * as tslib_1 from "tslib";
import { StepBaseComponent } from '../step-base.component';
import { PersonLegalType } from 'src/app/shared/api/rentassistant-api.generated';
import { Subject, of } from 'rxjs';
import { switchMap, filter, catchError, finalize, tap } from 'rxjs/operators';
var PersonalInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PersonalInfoComponent, _super);
    function PersonalInfoComponent(localizedRouter, route, api, stateService) {
        var _this = _super.call(this, localizedRouter, route, stateService) || this;
        _this.api = api;
        _this.stateService = stateService;
        _this.hostClass = 'wizard';
        _this.organizationNumber$ = new Subject();
        return _this;
    }
    PersonalInfoComponent.prototype.init = function () {
        var _this = this;
        this.totalSteps = this.person.legalType === PersonLegalType.Person ? 4 : 3;
        this.subscriptions.push(this.organizationNumber$
            .pipe(filter(function () { return !_this.readonly; }), filter(function (orgNumber) { return !!orgNumber && orgNumber.length === 10; }), tap(function () { return _this.loading = true; }), switchMap(function (organizationNumber) { return _this.api.findOrganization(organizationNumber)
            .pipe(catchError(function () {
            _this.person.organizationFoundInKbo = false;
            return of(null);
        }), finalize(function () { return _this.loading = false; })); }), filter(function (x) { return !!x; }))
            .subscribe(function (organization) {
            _this.saveOrganizationInfoInProfile(organization);
        }));
    };
    PersonalInfoComponent.prototype.modFunction = function (nr) { return 97 - (nr % 97); };
    PersonalInfoComponent.prototype.IsValidRRN2000 = function (n) {
        // RR numbers need to be 11 chars long
        if (n.length !== 11) {
            return false;
        }
        var checkDigit = +(n.substr(n.length - 2, 2));
        var nrToCheck = +(n.substr(0, 9));
        // then check with 2 appended for y2k+ births
        nrToCheck = +('2' + n.substr(0, 9));
        return (this.modFunction(nrToCheck) === checkDigit);
    };
    PersonalInfoComponent.prototype.IsValidRRN1900 = function (n) {
        // RR numbers need to be 11 chars long
        if (n.length !== 11) {
            return false;
        }
        var checkDigit = +(n.substr(n.length - 2, 2));
        var nrToCheck = +(n.substr(0, 9));
        if (this.modFunction(nrToCheck) === checkDigit) {
            return true;
        }
        return (this.modFunction(nrToCheck) === checkDigit);
    };
    PersonalInfoComponent.prototype.saveOrganizationInfoInProfile = function (organization) {
        this.person.organizationFoundInKbo = true;
        this.person.organizationName = organization.organizationName;
        if (organization.address) {
            this.person.address.street = organization.address.street;
            this.person.address.houseNumber = organization.address.streetNumber;
            this.person.address.zipCode = organization.address.postalCode;
            this.person.address.place = organization.address.city;
            this.person.address.country = organization.address.countryISOCode;
        }
        if (organization.functions && organization.functions.length > 0) {
            var func = organization.functions[0];
            this.person.organizationContactName = ((func.firstName || '') + " " + (func.lastName || '')).trim();
            this.person.organizationContactFunction = func.description;
        }
    };
    PersonalInfoComponent.prototype.organizationNumberChanged = function (organizationNumber) {
        this.person.organizationNumber = organizationNumber;
        this.organizationNumber$.next(organizationNumber);
    };
    PersonalInfoComponent.prototype.legalTypeChanged = function (newLegalType) {
        var e_1, _a;
        var personId = this.person.id;
        try {
            for (var _b = tslib_1.__values(Object.getOwnPropertyNames(this.person)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var prop = _c.value;
                this.person[prop] = null;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.person.id = personId;
        this.person.address = {};
        this.person.legalType = newLegalType;
        this.totalSteps = newLegalType === PersonLegalType.Person ? 4 : 3;
    };
    PersonalInfoComponent.prototype.next = function () {
        if (this.person.legalType === PersonLegalType.LegalEntity) {
            this.localizedRouter.navigate(['../organisatie-adres'], { relativeTo: this.route });
        }
        else {
            this.localizedRouter.navigate(['../contactgegevens'], { relativeTo: this.route });
        }
    };
    return PersonalInfoComponent;
}(StepBaseComponent));
export { PersonalInfoComponent };
