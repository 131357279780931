import { AanDeSlagComponent } from './aan-de-slag/aan-de-slag.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentationComponent } from './documentation/documentation.component';
import { DocumentationRoutingModule } from './documentation.routing';

@NgModule({
  imports: [
    CommonModule,
    DocumentationRoutingModule
  ],
  declarations: [DocumentationComponent, AanDeSlagComponent]
})
export class DocumentationModule { }
