import { TranslateService } from '@ngx-translate/core';
import { CandidacyArchiveDto } from './../../shared/api/rentassistant-api.generated';
import { CandidacyDto } from 'src/app/shared/api/rentassistant-api.generated';
import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, UrlSerializer, NavigationEnd } from '@angular/router';

import { RentAssistantApi } from '../../shared/api/rentassistant-api';
import { makeEmptyCandidacy } from '../../core/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { Action, PersonLegalType, Status } from '../../shared/api/rentassistant-api.generated';
import { LocalizedRouter } from '../../core/services/localized-router.service';
import { LocalizeRouterService } from 'ngx-localize-router';
import { filter, shareReplay, tap } from 'rxjs/operators';
import { ProfileWizardStateService, ProfileWizardMode, ProfileWizardState } from '../../core/services/profilewizard-state.service';

@Component({
  selector: 'ra-candidacy-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent extends AutoUnsubscribeComponent implements OnInit {
  candidacy = makeEmptyCandidacy();
  alert = false;
  state: ProfileWizardState;
  Status = Status;
  @ViewChild('submitModal', { static: false })
  submitModal: TemplateRef<any>;
  remarks = '';
  visitConfirmed = false;
  @ViewChild('recallModal', { static: false })
  recallModal: TemplateRef<any>;

  @ViewChild('deleteModal', { static: false })
  deleteModal: TemplateRef<any>;
  @ViewChild('incompleteModal', { static: false })
  incompleteModal: TemplateRef<any>;

  @ViewChild('contactBrokerModal', { static: false })
  contactBrokerModal: TemplateRef<any>;

  @ViewChild('shareModal', { static: false })
  shareModal: TemplateRef<any>;

  actions: Action[] = [];

  shareableProfileLink: string;

  isDetail: boolean;
  canArchive = false;

  constructor(
    private router: Router,
    private urlSerializer: UrlSerializer,
    private location: Location,
    private route: ActivatedRoute,
    private localizedRouter: LocalizedRouter,
    private localizedRouterService: LocalizeRouterService,
    private modal: NgbModal,
    private api: RentAssistantApi,
    private stateService: ProfileWizardStateService,
    private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.candidacy = this.route.snapshot.data.candidacy;
    this.canArchive = (this.candidacy.status === Status.Approved || this.candidacy.status === Status.Expired  ||
    this.candidacy.status === Status.Recalled  || this.candidacy.status === Status.Rejected);
    this.stateService.state$.next({
      candidacy: this.candidacy,
      profile: this.candidacy.profile,
      wizardMode: ProfileWizardMode.CandidacyProfile,
      closeWizard: () => this.localizedRouter.navigate(['/app/kandidaturen/', this.candidacy.uniqueId]),
      saveProfile: () => {
        const result =  this.api.saveCandidacyProfile(this.candidacy.uniqueId, this.candidacy.profile).pipe(tap((x) => {
          this.candidacy.profile = x;
          this.stateService.state$.value.profile = x;
        }), shareReplay());
        return result;
      }
    });
    this.subscriptions.push(
      this.stateService.state$.subscribe(state => this.state = state)
    );

    this.subscriptions.push(
        this.api.getCandidacyActions(this.candidacy.uniqueId)
                .subscribe((actions) => this.actions = actions)
    );

    this.isDetail = this.route.children.length === 0;

    this.subscriptions.push(
      this.router.events
                 .pipe(filter(e => e instanceof NavigationEnd))
                 .subscribe((e: NavigationEnd)  => this.isDetail = this.route.children.length === 0)
    );
  }

  get hasActions() {
    return !!this.actions && this.actions.length > 0;
  }

  goBack() {
    if (this.candidacy.isReadonly) {
      this.localizedRouter.navigate(['/app/kandidaturen']);
      return;
    }

    this.subscriptions.push(
      this.api
          .saveCandidacyProfile(this.candidacy.uniqueId, this.candidacy.profile)
          .subscribe(profile => this.localizedRouter.navigate(['/app/kandidaturen']))
    );
  }

  showAction(action: string) {
    return this.actions && this.actions.indexOf(Action[action]) > -1;
  }
  submitConfirmation() {
    this.alert = false;
    if (this.candidacy.profile.profileComplete) {
      const remarks = this.candidacy.candidacyHistories.filter(x => x.status === Status.Submitted);
      if (remarks != null && remarks.length > 0) {
        this.remarks = remarks[remarks.length - 1].comment;
        const h = remarks[remarks.length - 1].hasVisitedProperty;
        if (h != null) {
          this.visitConfirmed = h;
        }
      }
      this.modal.open(this.submitModal);
    } else {
      this.alert = true;
      this.modal.open(this.incompleteModal);
    }
  }

  recallConfirmation() {
    this.modal.open(this.recallModal);
  }

  delete() {
    this.modal.open(this.deleteModal);
  }

  archive() {
    const archiveDto: CandidacyArchiveDto = {};
    archiveDto.archive = !this.candidacy.isArchivedByUser;
    archiveDto.candidacyUniqueId = this.candidacy.uniqueId;
    this.api.archiveCandidacy(archiveDto)
    .subscribe(Response => this.localizedRouter.navigate(['/app/kandidaturen']));
  }

  contactBroker() {
    this.modal.open(this.contactBrokerModal);
  }

  share() {
      this.api.getProfilePublicToken(this.candidacy.uniqueId)
          .subscribe(token => {
            const urlTree = this.router.createUrlTree(['/app/profiel', token]);
            const relativeUrl = this.urlSerializer.serialize(urlTree);
            const translatedRelativeUrl = this.localizedRouterService
                                              .translateRoute(relativeUrl);
            this.shareableProfileLink = window.location.origin + this.location.prepareExternalUrl(translatedRelativeUrl as string);
            this.modal.open(this.shareModal);
          });
  }

  addRenter() {
    const newPersonId = this.candidacy.profile.personDetails.length + 1;

    this.candidacy.profile.personDetails.push({
        id: newPersonId,
        legalType: PersonLegalType.Person,
        address: {}
    });

    this.subscriptions.push(
      this.api.saveCandidacyProfile(this.candidacy.uniqueId,
                                    this.candidacy.profile)
              .subscribe(prof => {
                this.stateService.updateProfile(prof);
                this.localizedRouter.navigate([
                              'persoon',
                              newPersonId,
                              'persoonlijke-gegevens'
                            ], { relativeTo: this.route });
                }
              )
    );
  }

  isAlreadySubmitted(candidacy: CandidacyDto) {
    return candidacy.status === Status.Submitted;
  }

  getSubmitText() {
      if (this.candidacy.status === Status.InfoRequested) {
        return this.translateService.get('Candidacies.UpdateCandidacy');
      } else {
        return this.translateService.get('Candidacies.SubmitCandidacy');
    }
}
}
