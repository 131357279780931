import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebTextDto } from '../../shared/api/rentassistant-api.generated';
import { Subscription } from 'rxjs';
import { RentAssistantApi } from '../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  steps: WebTextDto[] = [];
  subscriptions: Subscription[];

  menuVisible: boolean;

  constructor(private api: RentAssistantApi) { }

  ngOnInit() {
    this.subscriptions = [
      this.api
          .getWebTexts()
          .subscribe(webTexts => {
            if (webTexts) {
              this.steps = webTexts.sort((a, b) => a.dateModified > b.dateModified ? -1 : 1);
            }
          })
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  toggleMenu($event: Event) {
    this.menuVisible = !this.menuVisible;
    $event.stopImmediatePropagation();
  }

  menuVisibleChange(visible) {
    this.menuVisible = visible;
  }
}
