import { OwnersportalModule } from './../ownersportal/ownersportal.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { ShellRoutingModule } from './shell-routing.module';

import { HeaderComponent } from './header/header.component';
import { ShellComponent } from './shell/shell.component';
import { ComponentsDemoComponent } from './components-demo/components-demo.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';


@NgModule({
  declarations: [
    HeaderComponent,
    ShellComponent,
    ComponentsDemoComponent,
    FaqComponent,
    ContactComponent],
  imports: [
    CommonModule,
    ShellRoutingModule,
    SharedModule,
    OwnersportalModule
  ]
})
export class ShellModule { }
