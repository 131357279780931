/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./person-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i7 from "angular-svg-icon";
import * as i8 from "./person-list-item.component";
var styles_PersonListItemComponent = [i0.styles];
var RenderType_PersonListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonListItemComponent, data: {} });
export { RenderType_PersonListItemComponent as RenderType_PersonListItemComponent };
function View_PersonListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faChevronUp; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PersonListItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faChevronDown; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PersonListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "arrow-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonListItemComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonListItemComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isExpanded; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isExpanded; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PersonListItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "left-circle"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "svg-icon", [["src", "assets/images/user.svg"]], null, null, null, i6.View_SvgIconComponent_0, i6.RenderType_SvgIconComponent)), i1.ɵdid(5, 1032192, null, 0, i7.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i7.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonListItemComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "arrow-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRightArrowClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(12, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "left-circle"; var currVal_1 = _co.state; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "assets/images/user.svg"; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.isExpandable; _ck(_v, 9, 0, currVal_4); var currVal_7 = _co.faChevronRight; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.name; _ck(_v, 7, 0, currVal_3); var currVal_5 = i1.ɵnov(_v, 12).title; var currVal_6 = i1.ɵnov(_v, 12).renderedIconHTML; _ck(_v, 11, 0, currVal_5, currVal_6); }); }
export function View_PersonListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-person-list-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClickHost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PersonListItemComponent_0, RenderType_PersonListItemComponent)), i1.ɵdid(1, 49152, null, 0, i8.PersonListItemComponent, [], null, null)], null, null); }
var PersonListItemComponentNgFactory = i1.ɵccf("ra-person-list-item", i8.PersonListItemComponent, View_PersonListItemComponent_Host_0, { name: "name", state: "state", isExpandable: "isExpandable", isExpanded: "isExpanded" }, { itemClick: "itemClick", rightArrowClick: "rightArrowClick" }, []);
export { PersonListItemComponentNgFactory as PersonListItemComponentNgFactory };
