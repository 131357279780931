<ra-house-header [titleKey]="'Candidacies.Title'" (candidacyFilterShowArchived)="candidacyFilterShowArchived($event)" [candidacyFilter]="true"></ra-house-header>
<div class="candidacy-overview">
  <h2 *ngIf="candidacies != null && filteredCandidacies.length === 0"> {{ 'Candidacies.NoCandidaciesFound' | translate}}</h2>
  <div class="candidacy" *ngFor="let candidacy of filteredCandidacies">
    <ra-candidacy-card
      [candidacy]="candidacy"
      (cardClicked)="onCandidacySelected(candidacy.uniqueId)"      
    ></ra-candidacy-card>
  </div>
</div>
