<div class="header visible-from-desktop">
  <div class="content">
    <div class="logo">
      <svg-icon [applyCss]="true" src="assets/images/key.svg"></svg-icon>
    </div>
    <a style="text-decoration: none; cursor: pointer;" (click)="onCleeClick()">
    <div class="name">clee</div>
  </a>
  </div>
  <div class="menu-items">    
    <ul>
      <li *ngIf="isLoggedIn">
        <a
          href
          [routerLink]="['/app/kandidaturen' | localize]"
          [routerLinkActive]="'active'"
        >
          <div class="icon">
            <svg-icon
              src="/assets/images/bookmark.svg"
              [applyCss]="true"
            ></svg-icon>
          </div>
          <div class="text">{{ 'Menu.MyCandidacies' | translate }}</div>
        </a>
      </li>
      <li *ngIf="isLoggedIn">        
        <a
          href
          [routerLink]="['/app/profiel' | localize]"
          [routerLinkActive]="'active'"
        >
          <div class="icon">
            <svg-icon
              src="/assets/images/user-2.svg"
              [applyCss]="true"
            ></svg-icon>
          </div>
          <div class="text">{{ 'Menu.MyProfile' | translate }}</div>
        </a>
      </li>
      <li>
        <a
          href
          [routerLink]="['/app/faq' | localize]"
          [routerLinkActive]="'active'"
        >
          <div class="icon">
            <svg-icon
              src="/assets/images/help-circle.svg"
              [applyCss]="true"
            ></svg-icon>
          </div>
          <div class="text visible-from-wide-desktop">{{ 'Menu.Faq' | translate }}</div>
        </a>
      </li>
      <li>
        <a
          href
          [routerLink]="['/app/contact' | localize]"
          [routerLinkActive]="'active'"
        >
          <div class="icon">
            <svg-icon
              src="/assets/images/mail.svg"
              [applyCss]="true"
            ></svg-icon>
          </div>
          <div class="text visible-from-wide-desktop">{{ 'Menu.Contact' | translate }}</div>
        </a>
      </li>
      <li *ngIf="isLoggedIn">
        <a href="{{'Url.Privacy' | translate}}">
          <div class="icon">
            <svg-icon src="/assets/images/file-text.svg" [applyCss]="true"></svg-icon>
          </div>
          <div class="text">{{ 'Menu.Privacy' | translate }}</div>
        </a>
      </li>
      <li *ngIf="isLoggedIn">
        <a href="{{'Url.UserPolicy' | translate}}">
          <div class="icon">
            <svg-icon src="/assets/images/file-text.svg" [applyCss]="true"></svg-icon>
          </div>
          <div class="text">{{ 'Menu.Conditions' | translate }}</div>
        </a>
      </li>
    </ul>
  </div>
  <div class="right-container">
    <a *ngIf="isLoggedIn" href (click)="logout($event)" class="logout">
      {{ 'Menu.LogOut' | translate }}
    </a>
    <a *ngIf="!isLoggedIn" [routerLink]="'/login' | localize" class="logout">
      {{ 'Menu.LogIn' | translate }}
    </a>
    <a *ngIf="!isLoggedIn" [routerLink]="'/registreer' | localize" class="logout">
      {{ 'Menu.Register' | translate }}
    </a>
    <ra-language-selector></ra-language-selector>
  </div>
</div>
