import * as i0 from "@angular/core";
import * as i1 from "../services/session.service";
var SessionInterceptor = /** @class */ (function () {
    function SessionInterceptor(sessionService) {
        var _this = this;
        this.sessionService = sessionService;
        this.sessionService.session$.subscribe(function (session) { return _this.session = session; });
    }
    SessionInterceptor.prototype.intercept = function (request, next) {
        if (this.session && this.session.token) {
            request = request.clone({
                headers: request.headers.set('x-ImmoConnect-sessionid', this.session.token)
            });
        }
        return next.handle(request);
    };
    SessionInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionInterceptor_Factory() { return new SessionInterceptor(i0.ɵɵinject(i1.SessionService)); }, token: SessionInterceptor, providedIn: "root" });
    return SessionInterceptor;
}());
export { SessionInterceptor };
