import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { makeEmptyCandidacy } from 'src/app/core/models';
import { NgForm } from '@angular/forms';
import { tap, finalize } from 'rxjs/operators';
import { AutoUnsubscribeComponent } from 'src/app/shared/auto-unsubscribe.component';
import { LocalizedRouter } from '../../../core/services/localized-router.service';
import { RentAssistantApi } from '../../../shared/api/rentassistant-api';

@Component({
  selector: 'ra-submit-confirmation',
  templateUrl: './submit-confirmation.component.html',
  styleUrls: ['./submit-confirmation.component.scss']
})
export class SubmitConfirmationComponent extends AutoUnsubscribeComponent {
  @Input()
  candidacy = makeEmptyCandidacy();

  @Output()
  cancel = new EventEmitter<void>();

  @Input() remarks = '';
  @Input() visitConfirmed = false;
  dataConfirmed = false;

  isLoading = false;

  @ViewChild(NgForm, { static: false })
  form: NgForm;

  constructor(
    private api: RentAssistantApi,
    private localizedRouter: LocalizedRouter
  ) {
    super();
  }

  submit() {
    this.form.control.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;

    this.subscriptions.push(
      this.api
        .submitCandidacy({
          candidacyId: this.candidacy.uniqueId,
          remarks: this.remarks,
          hasVisitedProperty: this.visitConfirmed
        })
        .pipe(
          tap(
            () => {
              this.cancel.next();
              this.localizedRouter.navigate(['/app/kandidaturen']);
            },
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
        .subscribe(
          () => {
            this.cancel.next();
          },
          () => {
            this.isLoading = false;
          }
        )
    );
  }
}
