import { makeEmptyCandidacy } from 'src/app/core/models';
import { Status } from 'src/app/shared/api/rentassistant-api.generated';
var FeedbackSelectorComponent = /** @class */ (function () {
    function FeedbackSelectorComponent() {
        this.candidacy = makeEmptyCandidacy();
    }
    Object.defineProperty(FeedbackSelectorComponent.prototype, "statusComment", {
        get: function () {
            if (this.candidacy.status === Status.Approved ||
                this.candidacy.status === Status.InfoRequested) {
                return this.candidacy.statusComment || '';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    return FeedbackSelectorComponent;
}());
export { FeedbackSelectorComponent };
