import { OnInit, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
var CounterComponent = /** @class */ (function () {
    function CounterComponent() {
        this.count = 0;
        this.min = 0;
        this.max = 10;
        this.asTitle = false;
        this.countChanged = new EventEmitter();
        this.disabled = false;
    }
    CounterComponent.prototype.ngOnInit = function () {
        if (this.count < this.min) {
            this.count = this.min;
        }
        if (this.count > this.max) {
            this.count = this.max;
        }
    };
    CounterComponent.prototype.minus = function () {
        if (this.count - 1 >= this.min) {
            this.count--;
            this.countChanged.emit(this.count);
            this.triggerOnChangeCallack(this.count);
        }
    };
    CounterComponent.prototype.plus = function () {
        if (this.count + 1 <= this.max) {
            this.count++;
            this.countChanged.emit(this.count);
            this.triggerOnChangeCallack(this.count);
        }
    };
    Object.defineProperty(CounterComponent.prototype, "limit", {
        get: function () {
            return this.limitMin || this.limitMax;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CounterComponent.prototype, "limitMin", {
        get: function () {
            return this.count === this.min;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CounterComponent.prototype, "limitMax", {
        get: function () {
            return this.count === this.max;
        },
        enumerable: true,
        configurable: true
    });
    CounterComponent.prototype.triggerOnChangeCallack = function (count) {
        if (this.onChangeCallback) {
            this.onChangeCallback(count);
        }
    };
    CounterComponent.prototype.onHostBlur = function () {
        if (this.onTouchedCallback) {
            this.onTouchedCallback();
        }
    };
    CounterComponent.prototype.writeValue = function (val) {
        this.count = !val ? this.min : val;
    };
    CounterComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    CounterComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    CounterComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    return CounterComponent;
}());
export { CounterComponent };
