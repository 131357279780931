import { Component, HostBinding } from '@angular/core';
import { StepBaseComponent } from '../step-base.component';

@Component({
  selector: 'ra-organization-contact-info',
  templateUrl: './organization-address.component.html',
  styleUrls: ['../common.scss',
              './organization-address.component.scss']
})
export class OrganizationAddressComponent extends StepBaseComponent {
  @HostBinding('class') hostClass = 'wizard';

  previous() {
    this.localizedRouter.navigate(['../persoonlijke-gegevens'], {
      relativeTo: this.route
    });
  }

  next() {
    this.localizedRouter.navigate(['../organisatie-contactgegevens'], { relativeTo: this.route });
  }
}
