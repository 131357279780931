<div *ngIf="property">

  <ng-container>

    <div *ngIf="showProfileSim" (mouseenter)="mouseenter('CleeButtonProfile')"
      (mouseleave)="mouseleave('CleeButtonProfile')"
      [ngStyle]="{'background': styles.button.bkgrnd, 'color': styles.button.color, 'border-bottom-left-radius': styles.button.bordr, 'border-bottom-right-radius': styles.button.bordr
  , 'border-top-left-radius': (showButton) ? 0 : styles.button.bordr, 'border-top-right-radius': (showButton) ? 0 : styles.button.bordr}" id="CleeButtonProfile"
      (click)="showProfile()">
      <i [ngClass]="profileButtonClass"></i>
    </div>

    <ng-container *ngIf="displayProfile">
      <div class="widget-scoring-wrapper">
        <div class="container widget-scoring">
          <div class="widget-scoring__header">
            <!-- <img src="./../../../../assets/images/self-assesment-wizard.svg" alt="logo" class="logo"> -->
            <div class="widget-scoring__title">
              <span [innerHTML]="'Widget.Header' | translate" class="heading"></span>
              <img src="./../../../../assets/images/self-assesment-wizard-logo.svg" alt="logo" class="logo">
            </div>
            <ra-language-selector></ra-language-selector>
          </div>

          <div class="widget-scoring__content">
            <div *ngIf="step == 1">
              <ng-container *ngTemplateOutlet="numberOfAdults"></ng-container>
            </div>
            <div *ngIf="step == 2">
              <ng-container *ngTemplateOutlet="totalMonthlyIncome"></ng-container>
            </div>

            <div *ngIf="step == 3">
              <ng-container *ngTemplateOutlet="numberOfCars"></ng-container>
            </div>
            <div *ngIf="step == 4">
              <ng-container *ngTemplateOutlet="overview"></ng-container>
            </div>

          </div>
        </div>
        <div class="widget-scoring__footer-wrapper">
          <ul class="widget-scoring__progress-bar">
            <li [ngClass]="getProgressBarClass(1)"></li>
            <li [ngClass]="getProgressBarClass(2)"></li>
            <li [ngClass]="getProgressBarClass(3)"></li>
            <li [ngClass]="getProgressBarClass(4)"></li>
          </ul>
          <div class="widget-scoring__footer">
            <div class="container widget-scoring">
              <button class="btn btn-primary btn-block" *ngIf="!(step == maxStep)"
                (click)="next()">{{ 'Widget.Next' | translate }}</button>
              <button class="btn btn-previous" *ngIf="!(step == minStep)" (click)="previous()"><i
                  class="far fa-long-arrow-left"></i> {{ 'Widget.Previous' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <a *ngIf="showButton" id="CleeButton"
      [ngStyle]="{'font-family': styles.button.font, 'text-align': styles.button.align, 'display': styles.button.disp, 'border': styles.button.bord, 'background': styles.button.bkgrnd, 'border-top-left-radius': styles.button.bordr, 'border-top-right-radius': styles.button.bordr, 'color': styles.button.color, 'text-decoration': styles.button.deco, 'padding': styles.button.pad, 'border-bottom-left-radius': (showProfileSim) ? 0 : styles.button.bordr, 'border-bottom-right-radius': (showProfileSim) ? 0 : styles.button.bordr, 'text-transform': (showProfileSim) ? 0 : styles.button.textTransform, 'width': (showProfileSim) ? 0 : styles.button.width, 'line-height': (showProfileSim) ? 0 : styles.button.lineHeight, 'font-size': (showProfileSim) ? 0 : styles.button.fontSize, 'font-weight': (showProfileSim) ? 0 : styles.button.fontWeight, 'box-shadow': (showProfileSim) ? 0 : styles.button.boxShadow }"
      (mouseenter)="mouseenter('CleeButton')" (mouseleave)="mouseleave('CleeButton')"
      href="{{property.createCandidacyUrl}}" target="_parent _blank">
      <svg [ngStyle]="{'display': styles.button.iconShow}" class="clee-key" viewBox="0 0 70 123" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path [ngStyle]="{'fill': styles.button.iconColor}"
          d="M36.5225 122.989L36.5225 88.2985C36.5225 87.1939 35.627 86.2985 34.5225 86.2985L15.6964 86.2985C14.5918 86.2985 13.6964 87.1939 13.6964 88.2985L13.6964 97.7935C13.6964 98.8981 14.5918 99.7935 15.6964 99.7935L32.0569 99.7935C32.6091 99.7935 33.0569 100.241 33.0569 100.793L33.0569 108.854C33.0569 109.406 32.6091 109.854 32.0569 109.854L15.6964 109.854C14.5918 109.854 13.6964 110.749 13.6964 111.854L13.6964 120.989C13.6964 122.094 14.5918 122.989 15.6964 122.989L36.5225 122.989Z"
          fill="#fff" />
        <path [ngStyle]="{'fill': styles.button.iconColor}"
          d="M44.2915 60.2776L44.2915 120.989C44.2915 122.094 43.3961 122.989 42.2915 122.989L34.8407 122.989L36.3624 113.658L36.3624 90.832L25.7103 86.207L25.7103 60.2776L44.2915 60.2776Z"
          fill="#fff" />
        <path [ngStyle]="{'fill': styles.button.iconColor}" fill-rule="evenodd" clip-rule="evenodd"
          d="M1.37289e-05 35.2883C1.2884e-05 54.6183 15.67 70.2883 35 70.2883C54.33 70.2883 70 54.6183 70 35.2883C70 15.9584 54.33 0.288329 35 0.288329C15.67 0.288328 1.45738e-05 15.9584 1.37289e-05 35.2883ZM16.7384 35.2879C16.7384 45.3731 24.9141 53.5487 34.9993 53.5487C45.0845 53.5487 53.2601 45.3731 53.2601 35.2879C53.2601 25.2027 45.0845 17.027 34.9993 17.027C24.9141 17.027 16.7384 25.2027 16.7384 35.2879Z"
          fill="#fff" />
      </svg>
      {{ 'Widget.ApplyAsCandidate' | translate }}
    </a>
  </ng-container>
</div>

<div *ngIf="showErrorLogs && errorLog">
  <p>{{errorLog}}</p>
</div>

<ng-template #numberOfAdults>
  <div>
    <h3 class="title">{{ 'Widget.NumberOfAdults' | translate }}</h3>
    <br>
    <div class="amount-of">
      <div class="amount-of__label">
        <div class="amount-of__label-icon">
          <i class="far fa-male"></i>
        </div>
        <label>{{ 'Widget.Adults' | translate }}</label>
      </div>

      <div class="amount-of__controls">
        <button class="btn amount-of__controls-icon" (click)="valueChange(false,'adults')">
          <i class="far fa-minus"></i>
        </button>
        <span class="amount-of__controls-number">{{profile.numberOfAdults}}</span>
        <button class="btn amount-of__controls-icon" (click)="valueChange(true,'adults')">
          <i class="far fa-plus"></i>
        </button>
      </div>
      <!-- <p-selectButton class="col-9" [options]="number" [(ngModel)]="profile.numberOfAdults"></p-selectButton> -->
    </div>

    <div class="amount-of">
      <div class="amount-of__label">
        <div class="amount-of__label-icon">
          <i class="far fa-child"></i>
        </div>
        <label>{{ 'Widget.Children' | translate }}</label>
      </div>

      <div class="amount-of__controls">
        <button class="btn amount-of__controls-icon" (click)="valueChange(false,'children')">
          <i class="far fa-minus"></i>
        </button>
        <span class="amount-of__controls-number">{{profile.numberOfChildren}}</span>
        <button class="btn amount-of__controls-icon" (click)="valueChange(true,'children')">
          <i class="far fa-plus"></i>
        </button>
      </div>
      <!-- <p-selectButton class="col-9" [options]="number" [(ngModel)]="profile.numberOfChildren"></p-selectButton> -->
    </div>
    <ng-container *ngTemplateOutlet="numberOfAdultsResult"></ng-container>
  </div>
</ng-template>


<ng-template #numberOfAdultsResult>
  <div>
    <!-- <h3 class="title">{{ 'Widget.NumberOfAdultsResult' | translate }}:</h3> -->
    <div *ngIf="(score$ | async) as score">
      <h3 *ngIf="maxCapacityResult(score)" class="title color-green">{{ 'Widget.Ok' | translate }}</h3>
      <h3 *ngIf="!maxCapacityResult(score)" class="title color-red">{{ 'Widget.NotOk' | translate }}</h3>
    </div>
  </div>
</ng-template>

<ng-template #numberOfCars>
  <div>
    <h3 class="title">{{ 'Widget.NumberOfCars' | translate }}</h3>
    <div class="amount-of">
      <div class="amount-of__label">
        <div class="amount-of__label-icon">
          <i class="far fa-car"></i>
        </div>
        <label>{{'Widget.CarCount' | translate}}</label>
      </div>

      <div class="amount-of__controls">
        <button class="btn amount-of__controls-icon" (click)="valueChange(false,'cars')">
          <i class="far fa-minus"></i>
        </button>
        <span class="amount-of__controls-number">{{profile.numberOfCars}}</span>
        <button class="btn amount-of__controls-icon" (click)="valueChange(true,'cars')">
          <i class="far fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="row text-center" *ngIf="(score$ | async) as score">
      <div class="col-12">
        <div class="number-title"><span
            class="valuta">€</span>{{(score.budgetNorm + score.rentalPrice) | number: '1.0-0'}}</div>
        <div class="total">*{{'Widget.IdealIncome' | translate}}</div>
      </div>
      <div class="col-6">
        <div class="number-title"><span class="valuta">€</span>{{profile.totalMonthlyIncome | number: '1.0-0'}}</div>
        <div class="total">{{'Widget.Income' | translate}}</div>
      </div>
      <div class="col-6" *ngIf="((score.budgetNorm + score.rentalPrice) - profile.totalMonthlyIncome) < 0">
        <div class="number-title color-green"><span
            class="valuta">€</span>{{(profile.totalMonthlyIncome - (score.budgetNorm + score.rentalPrice)) | number: '1.0-0'}}
        </div>
        <div class="total">{{'Widget.AboveBudget' | translate}}</div>
      </div>
      <div class="col-6" *ngIf="((score.budgetNorm + score.rentalPrice) - profile.totalMonthlyIncome) >= 0">
        <div class="number-title color-red"><span
            class="valuta">€</span>{{((score.budgetNorm + score.rentalPrice) - profile.totalMonthlyIncome) | number: '1.0-0' }}
        </div>
        <div class="total">{{'Widget.UnderBudget' | translate}}</div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="message-box">
          *{{'Widget.Info' | translate}}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #overview>
  <!-- <div *ngIf="(score$ | async) as score">
      {{ 'Widget.NumberOfCarsResult1' | translate }}: {{score.budgetNorm}}
  </div> -->

  <ul *ngIf="(score$ | async) as score" class="overview-list">
    <li class="overview-list-item">
      <button class="btn" (click)="navigateToStep(1)">
        <span class="title">{{'Widget.RenatalCapacity' | translate}}</span>
        <div class="block-right">
          <span *ngIf="maxCapacityResult(score)" class="title color-green">{{ 'Widget.Ok' | translate }}</span>
          <span *ngIf="!maxCapacityResult(score)" class="title color-red">{{ 'Widget.NotOk' | translate }}</span>
          <i class="far fa-chevron-right"></i>
        </div>
      </button>
    </li>
    <li class="overview-list-item">
      <button class="btn" (click)="navigateToStep(2)">
        <span class="title">{{'Widget.RentalTitle' | translate}}</span>
        <div class="block-right">
          <span class="title"
            [ngClass]="getResidentialLoadPercentageClassColor">{{score.residentialLoadPercentage | number: '1.0-0'}}%</span>
          <i class="far fa-chevron-right"></i>
        </div>
      </button>
    </li>
    <li class="overview-list-item">
      <button class="btn" (click)="navigateToStep(3)">
        <span class="title">{{'Widget.NeededIncome' | translate}}</span>
        <div class="block-right" *ngIf="((score.budgetNorm + score.rentalPrice) - profile.totalMonthlyIncome) < 0">
          <span
            class="title color-green">€{{(profile.totalMonthlyIncome - (score.budgetNorm + score.rentalPrice)) | number: '1.0-0'}}
            {{'Widget.AboveBudget' | translate}}</span>
          <i class="far fa-chevron-right"></i>
        </div>
        <div class="block-right" *ngIf="((score.budgetNorm + score.rentalPrice) - profile.totalMonthlyIncome) >= 0">
          <span
            class="title color-red">€{{(score.budgetNorm + score.rentalPrice) - profile.totalMonthlyIncome | number: '1.0-0'}}
            {{'Widget.UnderBudget' | translate}}</span>
          <i class="far fa-chevron-right"></i>
        </div>
      </button>
    </li>
  </ul>
</ng-template>

<ng-template #totalMonthlyIncome>
  <div>
    <h3 class="title">{{ 'Widget.TotalMonthlyIncome' | translate }}</h3>

    <div class="income-input-wrapper">
      <i class="fal fa-coins"></i>
      <span>€</span>
      <input type="number" pInputText [(ngModel)]="profile.totalMonthlyIncome" (change)="getProfileScore()"
        placeholder="0" style="max-width: 70px;" />
    </div>
    <p-slider [(ngModel)]="profile.totalMonthlyIncome" max="6000" animate="true" (onSlideEnd)="getProfileScore()">
    </p-slider>

    <div class="result-grid" *ngIf="(score$ | async) as score">
      <div class="result__score">
        <div>
          <div class="inner-circle">
            <span class="score"
              [ngClass]="rentPercentageColor">{{score.residentialLoadPercentage | number: '1.0-0'}}<span
                class="percentage">%</span></span>
            <span class="total">{{'Widget.RentalSpend' | translate}}</span>
          </div>
          <div class="circle">
            <div class="line" [ngStyle]="{'transform': transformStyle}"></div>
          </div>
        </div>

        <svg width="162" viewBox="0 0 162 83" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M80.9999 11C67.9472 11 55.7289 14.8006 45.2695 21.3233C45.0701 19.2876 44.3471 17.2757 43.0665 15.4958C42.0311 14.0567 40.736 12.915 39.2913 12.0887C51.4743 4.44986 65.7447 0 80.9999 0C94.796 0 107.786 3.63924 119.156 9.98517C119.722 12.2817 119.387 14.7966 118.022 16.953C117.159 18.3178 115.988 19.371 114.658 20.0799C104.671 14.3202 93.1999 11 80.9999 11Z"
            fill="#FBA705" />
          <path
            d="M117.515 21.8198C136.133 33.7741 149.007 54.4149 150.788 77.5017C151.022 80.5303 153.462 83 156.5 83C159.538 83 162.019 80.5309 161.817 77.5001C159.99 50.1084 144.644 25.7124 122.417 11.9065C122.463 14.1801 121.865 16.492 120.557 18.5574C119.73 19.8646 118.692 20.9575 117.515 21.8198Z"
            fill="#FBA705" />
          <path
            d="M40.6313 17.2479C41.9416 19.069 42.4736 21.214 42.2918 23.2864C24.8607 35.4193 12.9218 55.3327 11.2117 77.5017C10.9781 80.5303 8.53754 83 5.49994 83C2.46234 83 -0.0191029 80.5309 0.183046 77.5001C1.91974 51.4591 15.8755 28.1257 36.346 14.0252C38.0118 14.6169 39.5207 15.7043 40.6313 17.2479Z"
            fill="#FBA705" />
          <path
            d="M80.9999 11C67.9472 11 55.7289 14.8006 45.2695 21.3233C45.0701 19.2876 44.3471 17.2757 43.0665 15.4958C42.0311 14.0567 40.736 12.915 39.2913 12.0887C51.4743 4.44986 65.7447 0 80.9999 0C94.796 0 107.786 3.63924 119.156 9.98517C119.722 12.2817 119.387 14.7966 118.022 16.953C117.159 18.3178 115.988 19.371 114.658 20.0799C104.671 14.3202 93.1999 11 80.9999 11Z"
            fill="#FBA705" />
          <path
            d="M117.515 21.8198C136.133 33.7741 149.007 54.4149 150.788 77.5017C151.022 80.5303 153.462 83 156.5 83C159.538 83 162.019 80.5309 161.817 77.5001C159.99 50.1084 144.644 25.7124 122.417 11.9065C122.463 14.1801 121.865 16.492 120.557 18.5574C119.73 19.8646 118.692 20.9575 117.515 21.8198Z"
            fill="#FBA705" />
          <path
            d="M40.6313 17.2479C41.9416 19.069 42.4736 21.214 42.2918 23.2864C24.8607 35.4193 12.9218 55.3327 11.2117 77.5017C10.9781 80.5303 8.53754 83 5.49994 83C2.46234 83 -0.0191029 80.5309 0.183046 77.5001C1.91974 51.4591 15.8755 28.1257 36.346 14.0252C38.0118 14.6169 39.5207 15.7043 40.6313 17.2479Z"
            fill="#FBA705" />
          <path
            d="M80.9999 11C67.9472 11 55.7289 14.8006 45.2695 21.3233C45.0701 19.2876 44.3471 17.2757 43.0665 15.4958C42.0311 14.0567 40.736 12.915 39.2913 12.0887C51.4743 4.44986 65.7447 0 80.9999 0C94.796 0 107.786 3.63924 119.156 9.98517C119.722 12.2817 119.387 14.7966 118.022 16.953C117.159 18.3178 115.988 19.371 114.658 20.0799C104.671 14.3202 93.1999 11 80.9999 11Z"
            fill="#FBA705" />
          <path
            d="M117.515 21.8198C136.133 33.7741 149.007 54.4149 150.788 77.5017C151.022 80.5303 153.462 83 156.5 83C159.538 83 162.019 80.5309 161.817 77.5001C159.99 50.1084 144.644 25.7124 122.417 11.9065C122.463 14.1801 121.865 16.492 120.557 18.5574C119.73 19.8646 118.692 20.9575 117.515 21.8198Z"
            fill="#FBA705" />
          <path
            d="M40.6313 17.2479C41.9416 19.069 42.4736 21.214 42.2918 23.2864C24.8607 35.4193 12.9218 55.3327 11.2117 77.5017C10.9781 80.5303 8.53754 83 5.49994 83C2.46234 83 -0.0191029 80.5309 0.183046 77.5001C1.91974 51.4591 15.8755 28.1257 36.346 14.0252C38.0118 14.6169 39.5207 15.7043 40.6313 17.2479Z"
            fill="#FBA705" />
          <path
            d="M40.6313 17.2479C41.9416 19.069 42.4736 21.214 42.2918 23.2864C24.8607 35.4193 12.9218 55.3327 11.2117 77.5017C10.9781 80.5303 8.53754 83 5.49994 83C2.46234 83 -0.0191029 80.5309 0.183046 77.5001C1.91974 51.4591 15.8755 28.1257 36.346 14.0252C38.0118 14.6169 39.5207 15.7043 40.6313 17.2479Z"
            fill="#32D3CA" />
          <path
            d="M40.6313 17.2479C41.9416 19.069 42.4736 21.214 42.2918 23.2864C24.8607 35.4193 12.9218 55.3327 11.2117 77.5017C10.9781 80.5303 8.53754 83 5.49994 83C2.46234 83 -0.0191029 80.5309 0.183046 77.5001C1.91974 51.4591 15.8755 28.1257 36.346 14.0252C38.0118 14.6169 39.5207 15.7043 40.6313 17.2479Z"
            fill="#32D3CA" />
          <path
            d="M40.6313 17.2479C41.9416 19.069 42.4736 21.214 42.2918 23.2864C24.8607 35.4193 12.9218 55.3327 11.2117 77.5017C10.9781 80.5303 8.53754 83 5.49994 83C2.46234 83 -0.0191029 80.5309 0.183046 77.5001C1.91974 51.4591 15.8755 28.1257 36.346 14.0252C38.0118 14.6169 39.5207 15.7043 40.6313 17.2479Z"
            fill="#32D3CA" />
          <path
            d="M117.515 21.8198C136.133 33.7741 149.007 54.4149 150.788 77.5017C151.022 80.5303 153.462 83 156.5 83C159.538 83 162.019 80.5309 161.817 77.5001C159.99 50.1084 144.644 25.7124 122.417 11.9065C122.463 14.1801 121.865 16.492 120.557 18.5574C119.73 19.8646 118.692 20.9575 117.515 21.8198Z"
            fill="#FD5632" />
          <path
            d="M117.515 21.8198C136.133 33.7741 149.007 54.4149 150.788 77.5017C151.022 80.5303 153.462 83 156.5 83C159.538 83 162.019 80.5309 161.817 77.5001C159.99 50.1084 144.644 25.7124 122.417 11.9065C122.463 14.1801 121.865 16.492 120.557 18.5574C119.73 19.8646 118.692 20.9575 117.515 21.8198Z"
            fill="#FD5632" />
          <path
            d="M117.515 21.8198C136.133 33.7741 149.007 54.4149 150.788 77.5017C151.022 80.5303 153.462 83 156.5 83C159.538 83 162.019 80.5309 161.817 77.5001C159.99 50.1084 144.644 25.7124 122.417 11.9065C122.463 14.1801 121.865 16.492 120.557 18.5574C119.73 19.8646 118.692 20.9575 117.515 21.8198Z"
            fill="#FD5632" />
        </svg>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #totalMonthlyIncomeResult>
  <div>
    <h3 class="title">{{ 'Widget.TotalMonthlyIncomeResult' | translate }}:</h3>
    <div *ngIf="(score$ | async) as score">
      <div>
        <span>{{ score.residentialLoadPercentage | number: '1.0-0'}}%</span>
      </div>
      <div>
        <p-slider [(ngModel)]="score.residentialLoadPercentage" disabled=true; max="100"></p-slider>
      </div>
    </div>
  </div>
</ng-template>
